import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CustomTextField from '../../../_common/_form_fields/CustomTextField'
import CustomSelectField from '../../../_common/_form_fields/CustomSelectField'
import CustomDialog from '../../../_common/CustomDialog'
import CustomBackdrop from '../../../_common/CustomBackdrop'
import CustomText from '../../../_common/CustomText'
import CustomSplitPane from '../../../_common/CustomSplitPane'
import CustomPopover from '../../../_common/CustomPopover'
import constants from '../../../../config/constants'
import useWindowDimensions from '../../../_common/WindowDimensions'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { getTodayDate, getOneYearAgoDate, getTextedDate, getTextedDateTime, addTimeToDate } from '../../helpers/dates'
import PaymentsMenu from '../PaymentsMenu'
import PaypalWrapper from '../paypal/PaypalWrapper'
import PaymentsTable from './PaymentsTable'
import paypal_logo from '../../../../assets/images/paypal_logo.png'
import paypal_button from '../../../../assets/images/paypal_button.png'

const Payments = (props) => {
  const theme = useTheme()
  const { billingMenu, changeMenuActiveTab } = props
  const { width } = useWindowDimensions()

  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const mode = useSelector(state => state.settings.uiMode)
  
  const [billingType, setBillingType] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [paymentMethodList, setPaymentMethodList] = useState([])
  const [payDialogOpen, setPayDialogOpen] = useState(false)
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const [amount, setAmount] = useState('')
  const [paypalClientId, setPaypalClientId] = useState('')
  const [paypalOptions, setPaypalOptions] = useState('')
  const [paymentsData, setPaymentsData] = useState([])
  const [payments, setPayments] = useState([])
  const [paymentsSortParams, setPaymentsSortParams] = useState('')
  const [paymentDetailsDialogOpen, setPaymentDetailsDialogOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState('')
  const [selectedPaymentFormatted, setSelectedPaymentFormatted] = useState('')
  const [fetchDataRequired, setFetchDataReqiured] = useState(1)
  const [startDate, setStartDate] = useState(getOneYearAgoDate())
  const [endDate, setEndDate] = useState(getTodayDate())
  const [isLoading, setIsLoading] = useState(true)
  const [isPaypalAvailable, setIsPaypalAvailable] = useState(false)
  const [invoicesData, setInvoicesData] = useState([])
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [selectedInvoicesData, setSelectedInvoicesData] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [invoiceToAdd, setInvoiceToAdd] = useState('')
  const [invoiceComment, setInvoiceComment] = useState('')
  const [showDetailsRawData, setShowDetailsRawData] = useState(false)
  
  const payButtonStyle = {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    background: theme.palette.success.main,
    border: `${theme.palette.customGrayDark} 1px solid`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0px 30px',
    marginLeft: width >= 900 ? '10px' : '0px',

    '&:hover': {
      background: alpha(theme.palette.success.main, 0.7),
    }
  }

  const paypalLogoStyle = {
    height: '80px',
    background: `url(${paypal_logo}) no-repeat left top / contain`,
    marginBottom: '20px',
  }

  const paypalButtonStyle = {
    height: '50px',
    background: `url(${paypal_button}) no-repeat center top / contain`,
    marginTop: '20px',
  }

  const handleOpenPayDialog = () => {
    setPayDialogOpen(true)
  }

  const handleClosePayDialog = () => {
    setPayDialogOpen(false)
  }

  const handleOpenNotificationDialog = (text) => {
    setNotificationDialogOpen(true)
    setNotificationText(text)
    handleClosePayDialog()
  }

  const handleCloseNotificationDialog = () => {
    setNotificationDialogOpen(false)
    setNotificationText('')
    handleClosePayDialog()
  }

  const handleOpenPaymentDetailsDialog = useCallback((paymentId) => {
    setPaymentDetailsDialogOpen(true)
    const selected_payment = paymentsData.find(item => item.id === paymentId)
    setSelectedPayment(selected_payment ? selected_payment : '')
  }, [paymentsData])

  const handleClosePaymentDetailsDialog = () => {
    setPaymentDetailsDialogOpen(false)
    setSelectedPayment('')
  }

  const handleClickPaypal = () => {
    setPaymentMethod('paypal')
    handleOpenPayDialog()
  }

  const handleChangeAmount = (event) => {
    if (event.target.value && event.target.value.match(/^[0-9]*\.?[0-9]*$/)) {
      setAmount(event.target.value)
    }
  }

  const handlePaymentsSorting = (field, direction) => {
    const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
    setPaymentsSortParams(sort_param)
  }

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value)
  }

  const handleChangeInvoiceComment = (event) => {
    setInvoiceComment(event.target.value)
  }

  const handleFilterReset = () => {
    setStartDate(getOneYearAgoDate())
    setEndDate(getTodayDate())
  }

  const handleAddInvoiceToPayment = (id) => {
    if (!selectedInvoices.includes(id) && id) {
      setSelectedInvoices((prev) => [...prev, id])
    }
  }

  const handleRemoveInvoiceFromPayment = (id) => {
    setSelectedInvoices((prev) => prev.filter(item => item !== id))
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  
  const handlePaymentsDataFormatting = useCallback(() => {
    const sortByAmount = (list, direction) => {
      return direction === 'asc' ? [...list].sort((a, b) => a.amount > b.amount ? 1 : -1) : [...list].sort((a, b) => a.amount < b.amount ? 1 : -1)
    }

    const sortByDate = (list, direction) => {
      return direction === 'asc' ? [...list].sort((a, b) => a.time_stamp > b.time_stamp ? 1 : -1) : [...list].sort((a, b) => a.time_stamp < b.time_stamp ? 1 : -1)
    }

    let filtered_data = paymentsData

    if (paymentsSortParams) {
      const sort_array = paymentsSortParams.split('&&')
      if (sort_array.length > 2) {
        const sort_key = sort_array[1].replace('sort_key=', '')
        const sort_direction = sort_array[2].replace('sort_dir=', '')
        
        if (sort_key === 'amount') {
          filtered_data = sortByAmount(paymentsData, sort_direction)
        } else if (sort_key === 'time_stamp') {
          filtered_data = sortByDate(paymentsData, sort_direction)
        }
      }
    }

    const formatted_data = filtered_data.map((item) => {
        let new_item = { ...item }
        
        new_item.time_stamp = getTextedDate(item.time_stamp)

        new_item.payment_method = capitalizeFirstLetter(item.payment_method)

        new_item.transaction_data = <Button variant='outlined' size='small' disableRipple onClick={() => handleOpenPaymentDetailsDialog(item.id)}>{defaultTexts.showProjectsAccessTableLabelText}</Button>

        return new_item
    })
    
    setPayments(formatted_data)
  }, [paymentsData, defaultTexts, handleOpenPaymentDetailsDialog, paymentsSortParams])

  useEffect(() => {
    if (billingDeposit && billingDeposit.billing_type && billingDeposit.billing_type === 'prepaid') {
      setBillingType('prepaid')
    }

    if (billingDeposit && billingDeposit.billing_type && billingDeposit.billing_type === 'postpaid' && billingDeposit.postpaid_invoice && billingDeposit.postpaid_invoice === 'auto') {
      setBillingType('postpaid')
    }

    if (billingDeposit && billingDeposit.billing_type && billingDeposit.billing_type === 'postpaid' && billingDeposit.postpaid_invoice && billingDeposit.postpaid_invoice !== 'auto') {
      setBillingType('postpaid_manual')
    }
  }, [billingDeposit])

  useEffect(() => {
    if (billingAccountConfig && billingAccountConfig.payment_methods && billingAccountConfig.payment_methods.length > 0) {
      const payment_method_list = []

      billingAccountConfig.payment_methods.forEach(item => payment_method_list.push({
        value: item.name,
        keyword: item.display_name,
      }))

      setPaymentMethodList(payment_method_list)
    } else {
      setPaymentMethodList([])
    }
  }, [billingAccountConfig])

  useEffect(() => {
    if (paymentMethodList.length > 0) {
      setPaymentMethod(paymentMethodList[0].value)
    } else {
      setPaymentMethod('')
    }
  }, [paymentMethodList])

  useEffect(() => {
    let paypal_client_id = ''

    if (billingAccountConfig && billingAccountConfig.payment_methods && billingAccountConfig.payment_methods.length > 0) {
      const paypal_method = billingAccountConfig.payment_methods.find(item => item.name === 'paypal')
      if (paypal_method && paypal_method.client_id) {
        paypal_client_id = paypal_method.client_id
      }
    }

    setPaypalClientId(paypal_client_id)
  }, [billingAccountConfig])

  useEffect(() => {
    if (paypalClientId && billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) {
      setPaypalOptions({
        'client-id': paypalClientId,
        currency: billingAccountConfig.currency.name.toUpperCase(),
        intent: 'capture',
      })
    } else {
      setPaypalOptions('')
    }
  }, [paypalClientId, billingAccountConfig])

  useEffect(() => {
    const fetchPaymetsData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }

      const url = constants.billing_url + '/' + billingUrls.getDepositRefill + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)

      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setPaymentsData(response.data)
      } else {
        setPaymentsData([])
      }

      setTimeout(() => {setIsLoading(false)}, 700)
    }

    fetchPaymetsData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, fetchDataRequired, startDate, endDate])

  useEffect(() => {
    const fetchInvoicesData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }
      
      const url = constants.billing_url + '/' + billingUrls.getPostpaidInvoices + '/' + defaultAdminProject + filterString
      
      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }
      
      const response = await billingRequest(request_data)

      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setInvoicesData(response.data)
      } else {
        setInvoicesData([])
      }

      setTimeout(() => {setIsLoading(false)}, 700)
    }

    if (billingType === 'postpaid') {
      fetchInvoicesData()
    } else {
      setInvoicesData([])
    }
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, fetchDataRequired, startDate, endDate, billingType])
  
  useEffect(() => {
		if (paymentsData.length > 0) {
			handlePaymentsDataFormatting()
		} else {
			setPayments([])
		}
	},[
		paymentsData,
		handlePaymentsDataFormatting
	])

  useEffect(() => {
    function isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value)
    }

    if (selectedPayment) {
      let selected_payment = {}

      Object.keys(selectedPayment.transaction_data).forEach(item => {
        if (item !== 'links' && !Array.isArray(selectedPayment.transaction_data[item]) && !isObject(selectedPayment.transaction_data[item])) {
          selected_payment[item] = selectedPayment.transaction_data[item]
        }

        if (item === 'payer') {
          Object.keys(selectedPayment.transaction_data[item]).forEach(item2 => {
            if (item2 !== 'address' && item2 !== 'name') {
              selected_payment[item + ' ' + item2] = selectedPayment.transaction_data[item][item2]
            } else {
              Object.keys(selectedPayment.transaction_data[item][item2]).forEach(item3 => {
                selected_payment[item + ' ' + item2 + ' ' + item3] = selectedPayment.transaction_data[item][item2][item3]
              })
            }
          })
        }

        if (item === 'purchase_units') {
          selectedPayment.transaction_data[item].forEach(item2 => {
            Object.keys(item2).forEach(item3 => {
              if (!Array.isArray(item2[item3]) && !isObject(item2[item3])) {
                selected_payment[item + ' ' + item3] = item2[item3]
              } else if (item3 === 'amount') {
                Object.keys(item2[item3]).forEach(item4 => {
                  selected_payment[item + ' ' + item3 + ' ' + item4] = item2[item3][item4]
                })
              }
            })
          })
        }
      })

      selected_payment.create_time = getTextedDateTime(selected_payment.create_time)
      selected_payment.update_time = getTextedDateTime(selected_payment.update_time)

      setSelectedPaymentFormatted(selected_payment)
    } else {
      setSelectedPaymentFormatted('')
    }
  }, [selectedPayment])

  useEffect(() => {
    let is_paypal_available = false

    if (billingAccountConfig && billingAccountConfig.payment_methods && billingAccountConfig.payment_methods.length > 0) {
      const paypal_method = billingAccountConfig.payment_methods.find(item => item.name === 'paypal')

      if (paypal_method) {
        is_paypal_available = true
      }
    }

    setIsPaypalAvailable(is_paypal_available)
  }, [billingAccountConfig])

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      let selected_invoices_data = []
      let currency_name = ''

      invoicesData.forEach(item => {
        if (selectedInvoices.includes(item.id) && item.status !== 'paid' && (!currency_name || currency_name === item.currency.name)) {
          selected_invoices_data.push({ invoice_id: item.id, amount: item.amount, currency_name: item.currency.name.toUpperCase() })
          
          if (!currency_name) {
            currency_name = item.currency.name
          }
        }
      })

      setSelectedInvoicesData(selected_invoices_data)
    } else {
      setSelectedInvoicesData([])
    }
  }, [selectedInvoices, invoicesData])

  useEffect(() => {
    let invoice_list = []

    invoicesData.forEach(item => {
      if (!selectedInvoices.includes(item.id) && item.status !== 'paid') {
        invoice_list.push({
          value: item.id,
          keyword: item.amount.toFixed(2) + ' ' + item.currency.name.toUpperCase() + ' / ' + getTextedDate(item.time_stamp),
        })
      }
    })

    setInvoiceList(invoice_list)
  }, [invoicesData, selectedInvoices])

  useEffect(() => {
    if (selectedInvoicesData.length > 0) {
      let sum = 0
      selectedInvoicesData.forEach(item => sum += item.amount)
      setAmount(sum)
    } else if (billingType === 'postpaid') {
      setAmount('')
    }    
  }, [selectedInvoicesData, billingType])

  const getActionButtons = () => {
    return (
      <>
        <Stack 
          direction={ width >= 900 ? 'row' : 'column' }
          justifyContent="space-between" 
          alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
        >
          <Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
            <CustomSelectField
              items={paymentMethodList} 
              currentValue={paymentMethod}
              setCurrentValue={setPaymentMethod}
              self_item_titles={true}
              label={defaultTexts.paymentMethodsLabel}
              empty={true}
              size="small"
            />
          </Box>
          <Box
            onClick={handleOpenPayDialog}
            sx={payButtonStyle}
          >
            {defaultTexts.payLabel}
          </Box>
        </Stack>        
      </>
    )
  }

  const getFilter = () => {
    return (
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start" 
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        sx={{
          padding: width >= 900 ? '0px 20px 20px 20px' : '0px',
          width: "100%",
        }}
      >
        <Box>
          <DatePicker
            label=''
            value={new Date(startDate)}
            onChange={e => handleChangeStartDate(new Date(e))}
            maxDate={new Date(endDate)}
          />
        </Box>
        <Box sx={{ margin: '0px 10px' }}>{ width >= 900 ? '-' : <Box sx={{ width: '1px', height: '10px' }}></Box> }</Box>
        <Box>
          <DatePicker
            label=''
            value={new Date(endDate)}
            onChange={e => handleChangeEndDate(new Date(e))}
            minDate={new Date(startDate)}
          />
        </Box>
        <IconButton
          sx={{
            color: theme.palette.primary.main,
            marginLeft: width >= 900 ? '10px' : '0px',
            marginTop: width >= 900 ? '0px' : '10px',
          }}
          onClick={handleFilterReset}
        >
          <RefreshIcon fontSize='large' />
        </IconButton>
      </Stack>
    )
  }
  
  const getTopContent = () => {
    return (
      <>
        <Stack
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
          <PaymentsMenu
            menu={billingMenu}
            onClick={changeMenuActiveTab}
          />

          <Box>
            {
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
          </Box>
        </Stack>

        {
          width >= 900 ?
          getFilter() :
          <Box sx={{ paddingLeft: '10px' }}>
            <CustomPopover
              type='filterlist'
              horizontalOrigin='left'
            >
              {getFilter()}
            </CustomPopover>
          </Box>          
        }

        <PaymentsTable
          paymentsData={payments}
          setPaymentsData={setPayments}
          sortParams={paymentsSortParams}
          sortHandler={handlePaymentsSorting}
        />
      </>
    )
  }
  //
  const getBottomContent = () => {
    return (
      <>
        <Box
          sx={{
            padding: '20px',
            background: mode === 'dark' ? theme.palette.customLightBlack : undefined,
            marginTop: '-2px',
          }}
        >
          <CustomText sx={{ fontSize: '18px', color: theme.palette.secondary.main }}>{defaultTexts.paymentMethodsLabel}</CustomText>     
        </Box>

        <Box
          sx={{
            padding: '10px 20px',
            background: mode === 'light' ? theme.palette.customGrayLight : undefined,
            borderTop: `${theme.palette.customGrayDark} 1px solid`,
            cursor: 'pointer',
          }}
          onClick={handleClickPaypal}
        >
          <Grid container>
            {
              isPaypalAvailable &&
              <Grid item lg={4}>
                <Box
                  sx={{
                    background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
                    padding: '20px',
                    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: '5px',
                  }}
                >
                  <Box sx={paypalLogoStyle}></Box>
                  <Box>
                    <CustomText>{defaultTexts.paypalDescriptionText}</CustomText>
                  </Box>
                  <Box sx={paypalButtonStyle}></Box>
                </Box>
              </Grid>
            }
          </Grid>
        </Box>
      </>
    )
  }
  
  return (
    <>
      { isLoading && <CustomBackdrop open={isLoading} /> }

      <CustomSplitPane contentTop={getTopContent()} contentBottom={getBottomContent()} minSizePercentage='12' />

      <CustomDialog
        open={payDialogOpen}
        onClose={handleClosePayDialog}
        dialogTitle={{
          title: defaultTexts.paymentDetailsLabel, 
          sx: { color: 'primary.main' }
        }}
        dialogBody={{
          text: "", 
          sx: { color: 'text.primary' }
        }}
      >
        {
          (billingType === 'prepaid' || billingType === 'postpaid_manual') &&
          <>
            <Box sx={{ margin: '10px 0px 20px 0px' }}>
              <Grid container>
                <Grid item xs={10}>
                  <CustomTextField
                    currentValue={amount}
                    setCurrentValue={handleChangeAmount}
                    label={defaultTexts.amountLabel}
                    required={true}             
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ fontSize: '18px', padding: '15px 0px 0px 10px' }}>
                    {billingAccountConfig.currency.name.toUpperCase()}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {
              billingType === 'postpaid_manual' &&
              <Box sx={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid item xs={10}>
                    <CustomTextField
                      currentValue={invoiceComment}
                      setCurrentValue={handleChangeInvoiceComment}
                      label={defaultTexts.invoiceCommentLabel}
                      required={false}             
                    />
                  </Grid>
                </Grid>
              </Box>
            }

            <Box sx={{ marginBottom: '20px' }}>
              <Grid container>
                <Grid item xs={10}>
                  <CustomSelectField
                    items={paymentMethodList} 
                    currentValue={paymentMethod}
                    setCurrentValue={setPaymentMethod}
                    self_item_titles={true}
                    label={defaultTexts.paymentMethodsLabel}
                    empty={true}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container>
                <Grid item xs={10}>
                  {
                    (amount && parseFloat(amount) > 0) &&
                    <>
                      {
                        paymentMethod === 'paypal' &&
                        <PaypalWrapper
                          amount={amount}
                          paypalOptions={paypalOptions}
                          invoiceComment={invoiceComment}
                          handleOpenNotificationDialog={handleOpenNotificationDialog}
                          setFetchDataReqiured={setFetchDataReqiured}
                        />
                      }
                    </>
                  }                  
                </Grid>
              </Grid>
            </Box>
          </>
        }

        {
          billingType === 'postpaid' &&
          <>
            <Box sx={{ margin: '10px 0px 20px 0px' }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Box>
                    <CustomSelectField
                      items={invoiceList} 
                      currentValue={invoiceToAdd}
                      setCurrentValue={setInvoiceToAdd}
                      self_item_titles={true}
                      label={defaultTexts.invoiceLabel}
                      empty={true}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='outlined'
                    onClick={() => handleAddInvoiceToPayment(invoiceToAdd)}         
                    sx={{ width: '100%', height: '56px' }}
                    disableFocusRipple
                  >
                    {defaultTexts.selectButtonTitleText}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <CustomSelectField
                    items={paymentMethodList} 
                    currentValue={paymentMethod}
                    setCurrentValue={setPaymentMethod}
                    self_item_titles={true}
                    label={defaultTexts.paymentMethodsLabel}
                    empty={true}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Box>

            {
              selectedInvoicesData.map((item, index) => (
                <Box key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>{item.amount} {item.currency_name} (ID: {item.invoice_id})</Box>
                    <IconButton sx={{ marginLeft: '5px' }} onClick={() => handleRemoveInvoiceFromPayment(item.invoice_id)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </Box>                
              ))
            }

            {
              (amount && parseFloat(amount) > 0 && selectedInvoicesData.length > 0) &&
              <Box sx={{ margin: '10px 0px', color: theme.palette.error.main }}>
                {defaultTexts.totalLabel}: {amount} {selectedInvoicesData[0].currency_name}
              </Box>
            }

            <Box>
              <Grid container>
                <Grid item xs={9}>
                  {
                    (amount && parseFloat(amount) > 0) &&
                    <>
                      {
                        paymentMethod === 'paypal' &&
                        <PaypalWrapper
                          amount={amount}
                          selectedInvoicesData={selectedInvoicesData}
                          separatePayments={selectedInvoicesData.length > 0}
                          paypalOptions={paypalOptions}
                          handleOpenNotificationDialog={handleOpenNotificationDialog}
                          setFetchDataReqiured={setFetchDataReqiured}
                        />
                      }
                    </>
                  }                  
                </Grid>
              </Grid>
            </Box>
          </>
        }
      </CustomDialog>

      <CustomDialog
        open={notificationDialogOpen}
        onClose={handleCloseNotificationDialog}
        dialogTitle={{
          title: defaultTexts.paymentLabel, 
          sx: { color: 'primary.main' }
        }}
        dialogBody={{
          text: notificationText, 
          sx: { color: 'text.primary' }
        }}
			/>

      <CustomDialog
        open={paymentDetailsDialogOpen}
        onClose={handleClosePaymentDetailsDialog}
        dialogTitle={{
          title: defaultTexts.transactionDetailsLabel, 
          sx: { color: 'primary.main' }
        }}
        dialogBody={{
          text: "", 
          sx: { color: 'text.primary' }
        }}
        maxWidth='md'
      >
        <Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={showDetailsRawData ? true : false}
                  onChange={e => setShowDetailsRawData(e.target.checked)}
                />
              }
              label={defaultTexts.rawDataLabel}
            />
          </Box>
          {
            showDetailsRawData ?
            <Box>
              <pre>
                {JSON.stringify(selectedPayment.transaction_data, null, 2)}
              </pre>
            </Box> :
            <>
              {
                selectedPaymentFormatted && Object.keys(selectedPaymentFormatted).map((item, index) => (
                  <Box key={index}>
                    <Stack
                      key={index}
                      direction="row" 
                      justifyContent="space-between" 
                      alignItems="center"
                      sx={{ padding: '5px 0px' }}
                    >
                      <Box>{item}</Box>                  
                      <Box>{selectedPaymentFormatted[item]}</Box>
                    </Stack>
                    <Divider />
                  </Box>
                ))
              }
            </>
          }
        </Box>
      </CustomDialog>
    </>
  )
}

export default Payments