import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const ComputeServerSpecsV21 = (props) => {
	const theme = useTheme()
	
	const { serverData, selectedRow, serverGroups } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)
	
	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '300px',
		justifyContent: 'flex-start',
	}

	const getServerAge = () => {
			const age_mins = (new Date() - new Date(serverData.created)) / 1000 / 60
					if (age_mins < 60) {
							return `${parseInt(age_mins)} ${defaultTexts.minutesText}`
					} else if (age_mins < 1440) {
							return `${parseInt(age_mins / 60)} ${defaultTexts.hoursText}, ${parseInt(age_mins % 60)} ${defaultTexts.minutesText}`
					} else {
							return `${parseInt(age_mins / 1440)} ${defaultTexts.daysText}`
					}
	}

	const getLunchDate = () => {
			let lunch_date = new Date(serverData["OS-SRV-USG:launched_at"])
			const time_zone_offset = new Date().getTimezoneOffset() / 60
			lunch_date.setHours(lunch_date.getHours() - time_zone_offset)
			return lunch_date.toLocaleString()
	}
	
	return (
	<React.Fragment>
		<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
				<Grid item xs={12} lg={6}>
						<WrapperBox
								sx={detailTabCardConfig}
						>
								<Stack direction="column" spacing={1} sx={{width: "90%"}}>
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.nameFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{serverData.name}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.idFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{serverData ? serverData.id : ""}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.serverGroupsFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{serverData.server_groups && serverGroups.length > 0 ? 
																serverGroups.filter(
																		sg => sg.id === serverData.server_groups[0])[0].name : 
																		"-"
																}
												</CustomText>
										</Stack>
										<Divider />	
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.statusFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{serverData.status}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.ageTableHeaderTitleText}
												</CustomText>
												<CustomText size="p">
														{getServerAge()}
												</CustomText>
										</Stack>
										<Divider />
								</Stack>
						</WrapperBox>
				</Grid>
				<Grid item xs={12} lg={3}>
						<WrapperBox
								sx={detailTabCardConfig}
						>
								<Stack 
										direction="column" 
										spacing={1} 
										sx={{width: "90%"}}
								>
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.flavorNameTitleText}
												</CustomText>
												<CustomText size="p">
														{selectedRow.flavor ? 
																selectedRow.flavor.original_name : ""}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.vcpusFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{selectedRow.flavor ? 
																selectedRow.flavor.vcpus : ""}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.ramFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{selectedRow.flavor ? 
																selectedRow.flavor.ram : ""}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.diskTableHeaderLabel}
												</CustomText>
												<CustomText size="p">
														{selectedRow.flavor ? 
																selectedRow.flavor.disk : ""}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.swapTableHeaderLabel}
												</CustomText>
												<CustomText size="p">{
														selectedRow.flavor && 
														selectedRow.flavor.swap ? 
														selectedRow.flavor.swap : 
														"-"}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.ephemeralTableHeaderLabel}
												</CustomText>
												<CustomText size="p">{
														selectedRow.flavor && 
														selectedRow.flavor.ephemeral ? 
														selectedRow.flavor.ephemeral : 
														"-"}
												</CustomText>
										</Stack>
										<Divider />
								</Stack>
						</WrapperBox>
				</Grid>
				<Grid item xs={12} lg={3}>
						<WrapperBox
								sx={detailTabCardConfig}
						>
						<Stack direction="column" spacing={1} sx={{width: "90%"}}>
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.createTimeHeaderLabel}
												</CustomText>
												<CustomText size="p">
														{new Date(serverData.created).toLocaleString()}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.lunchedAtFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{getLunchDate()}
												</CustomText>
										</Stack>
										<Divider />
										<Stack 
												direction="row" 
												justifyContent="space-between" 
												alignItems="center"
										>
												<CustomText 
														size="p" 
														sx={{color: "text.secondary"}}
												>
														{defaultTexts.updatedAtFormFieldLabel}
												</CustomText>
												<CustomText size="p">
														{new Date(serverData.updated).toLocaleString()}
												</CustomText>
										</Stack>
										<Divider />
								</Stack>
						</WrapperBox>
				</Grid>
		</Grid>
		</React.Fragment>
	)
}

export default ComputeServerSpecsV21