import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const SecurityGroupSpecsV20 = (props) => {
	const theme = useTheme()
	const { securityGroupData } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '200px',
		justifyContent: 'flex-start',
	}

	return (
		<>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{securityGroupData.name ? securityGroupData.name : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{securityGroupData ? securityGroupData.id : ""}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{securityGroupData.description ? securityGroupData.description : "-"}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.statefulFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{securityGroupData.stateful  ? 
																			defaultTexts.formValueYes : 
																			defaultTexts.formValueNo
																	}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.revisionNumberFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{securityGroupData.revision_number}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.createTimeHeaderLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(securityGroupData.created_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.updatedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(securityGroupData.updated_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
		</>
	)
}

export default SecurityGroupSpecsV20