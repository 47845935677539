import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'
import WrapperBox from './WrapperBox';
import Box from '@mui/material/Box';
import CustomText from './CustomText';
import parse from 'html-react-parser';

const NotPurchasedService = (props) => {
    const { service } = props
    const uiMode = useSelector(state => state.settings.uiMode);
    const defaultWidgetTexts = useSelector(state => state.texts.langTexts)
    const theme = useTheme();

    return (
        <WrapperBox>
            <Box
                sx={{
                    borderRadius: 3,
                    p: 10,
                    backgroundColor: uiMode === 'dark' ? 
                        'background.paper' : 
                        theme.palette.vLightGray,
                }}
            >
                <CustomText 
                    size='h5' 
                    sx={{mb: 2}}
                >  
                    {service}
                </CustomText>
                {parse(defaultWidgetTexts.purchaseServiceTitle)}
                {parse(defaultWidgetTexts.purchaseServiceDescription)}
            </Box>
        </WrapperBox>
    )
};

export default NotPurchasedService;
