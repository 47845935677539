export const flavorDataSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    //{
    //    field_key: "description",
    //    is_id_field: false,
    //    field_type: "string", 
    //    label: "descriptionFormFieldLabel"
    //},
    {
         field_key: "vcpus",
         is_id_field: false,
         field_type: "string", 
         label: "vcpusFormFieldLabel"
     },
    {
        field_key: "ram",
        is_id_field: false,
        field_type: "string", 
        label: "ramTableHeaderLabel"
    },
    {
        field_key: "disk",
        is_id_field: false,
        field_type: "string", 
        label: "diskTableHeaderLabel"
    },
    {
        field_key: "os-flv-ext-data:ephemeral",
        is_id_field: false,
        field_type: "string", 
        label: "ephemeralTableHeaderLabel"
    },
    {
        field_key: "swap",
        is_id_field: false,
        field_type: "string", 
        label: "swapTableHeaderLabel"
    },
    {
        field_key: "rxtx_factor",
        is_id_field: false,
        field_type: "string",
        label: "rxtxFactorFormFieldLabel"
    },
    {
        field_key: "os-flv-disabled:disabled",
        is_id_field: false,
        field_type: "bool_reverse", 
        label: "disabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "os-flavor-access:is_public",
        is_id_field: false,
        field_type: "bool",
        label: "isPublicFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "os_flavor_access",
        is_id_field: false,
        field_type: "string",
        label: "flavorAccessFormFieldLabel"
    }
    //{
    //    field_key: "extra_specs",
    //    is_id_field: false,
    //    field_type: "json",
    //    label: "extraSpecsTypeFormFieldLabel"
    //}
]

export const flavorDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    //{
    //    field_key: "description",
    //    field_type: "string", 
    //    required: false,
    //    label: "descriptionFormFieldLabel",
    //    multiline: true
    //},
    {
        field_key: "vcpus",
        field_type: "integer", 
        required: true,
        label: "vcpusFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_value: 0,
        min: 0,
        with_help: true,
        help_text: "vCPUsHelpText"
    },
    {
        field_key: "ram",
        field_type: "integer", 
        required: true,
        label: "ramFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_value: 0,
        min: 0,
        with_help: true,
        help_text: "ramHelpText"
    },
    {
        field_key: "disk",
        field_type: "integer", 
        required: true,
        label: "diskFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_value: 0,
        min: 0,
        with_help: true,
        help_text: "diskHelpText"
    },
    {
        field_key: "OS-FLV-EXT-DATA:ephemeral",
        field_type: "integer", 
        required: false,
        label: "ephemeralDiskFormFieldLabel",
        default_value: 0,
        min: 0,
        with_help: true,
        help_text: "ephemeralDiskHelpText"
    },
    {
        field_key: "swap",
        field_type: "integer", 
        required: false,
        label: "swapFormFieldLabel",
        default_value: 0,
        min: 0,
        with_help: true,
        help_text: "swapHelpText"
    },
    {
        field_key: "rxtx_factor",
        is_id_field: false,
        field_type: "float",
        label: "rxtxFactorFormFieldLabel",
        default_value: 1.0,
        step: 0.1,
        min: 0,
        with_help: true,
        help_text: "rxtxFactorHelpText"
    },
    {
        field_key: "os-flavor-access:is_public",
        is_id_field: false,
        field_type: "bool",
        label: "isPublicFormFieldLabel",
        default_value: true,
        with_help: true,
        help_text: "flavorAccessTypeHelpText"
    }
]

export const flavorDataUpdateForm = [
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const flavorsFilterMenu = [
    {
        keyword: "accessTypeFormFieldLabel", 
        value: "is_public", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "formValuePublic", 
                value: "true", 
                default: true
            },
            {
                keyword: "formValuePrivate", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "minRamFormFieldLabel", 
        value: "minRam", 
        type: "integer",
        default_value: 0
    },
    {
        keyword: "minDiskFormFieldLabel", 
        value: "minDisk", 
        type: "integer"
    }
]

export const flavorsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "memory_mb",
        label: "ramFormFieldLabel"
    },
    {
        field_key: "vcpus",
        label: "vcpusFormFieldLabel"
    },
    {
        field_key: "root_gb",
        label: "diskFormFieldLabel"
    },
    {
        field_key: "swap",
        label: "swapFormFieldLabel"
    },
    {
        field_key: "ephemeral_gb",
        label: "ephemeralDiskFormFieldLabel"
    },
    {
        field_key: "created_at",
        label: "createTimeHeaderLabel"
    }
]