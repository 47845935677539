import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { imageDataSchema } from '../../../../../_data/openstack/glance/images/v2';
import { setImagesTableConfig } from '../../../../../store/reducers/imageGlanceSlice.js';
import Box from '@mui/material/Box'
import useWindowDimensions from '../../../../_common/WindowDimensions'

const ImagesTableV2 = (props) => {
	const { imagesData, deselectAll, setDeselectAll, publicImageIds, isSuspended } = props
	const { handleRowSelection } = props
	const { currentAction } = props
	const { setCurrentAction }  = props
	const { actionsTexts }  = props
	const { actionsList, setSelectedImageIds, setImagesData } = props
	const { sortParams, sortHandler } = props
	
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
	
	const imagesTableConfig = useSelector(
			state => state.imageGlance.imagesTableConfig)
	const rowsPerPage = useSelector(
			state => state.imageGlance.imagesTableConfig.filter(
			versions => versions.api_version === "v2")[0].rows_per_page)
	const imagesTableColumns = useSelector(
			state => state.imageGlance.imagesTableConfig.filter(
					versions => versions.api_version === "v2")[0].columns)
	
	const identityField = imagesTableColumns.filter(
			item => item.is_id_field)[0].field_key

	const dispatch = useDispatch();

	const handleImageTableConfig = (value_list) => {
			const newTableConfig = imageDataSchema.filter(
					n => value_list.includes(n.field_key))
			const new_data = imagesTableConfig.map(version => {
					if (version.api_version === "v2") {
							let new_item = {...version}
							new_item.columns = [...newTableConfig]
							return new_item
					} else {
							return version
					}
			})
			dispatch(setImagesTableConfig({
					imagesTableConfig: new_data
			}))
	}
	
	const handleRowsPerPageChange = (rows_num) => {
			const new_data = imagesTableConfig.map(version => {
					if (version.api_version === "v2") {
							let new_item = {...version}
							new_item.rows_per_page = rows_num
							return new_item
					} else {
							return version
					}
			})
			dispatch(setImagesTableConfig({
					imagesTableConfig: new_data
			}))
	}
	
	return (
		<Box
			sx={{
				width: drawerOpened ? `${width - 270 - 12}px` : `${width - 65 - 12}px`,
				overflowX: 'auto',
			}}
		>
			<CustomTable
				withPagination={false}
				withCheckbox={true}
				withActions={true}
				defaultDataTexts={defaultTexts} 
				tableHeaders={imagesTableColumns}
				tableHeadersConfigHandler={handleImageTableConfig}
				identityField={identityField}
				columns={imageDataSchema}
				rowsPerPage={rowsPerPage}
				handleRowsPerPageChange={handleRowsPerPageChange}
				dataRows={imagesData}
				handleRowSelection={handleRowSelection}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={actionsTexts}
				actionsList={actionsList}
				setSelectedIds={setSelectedImageIds}
				setDataRows={setImagesData}
				selfSorting={true}
				sortParams={sortParams}
				sortHandler={sortHandler}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				disabledCheckboxes={publicImageIds}
				isSuspended={isSuspended}
				showObjectInCell={true}
			/>
	</Box>
	)
}

export default ImagesTableV2