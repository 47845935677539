import React from 'react';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import CustomDialog from './CustomDialog';

const CustomDateTimePicker = (props) => {
    const { datetime } = props
    const { setDatetime } = props
    const { field_key } = props
    const { open, onClose, onAccept } = props

    const handleDatetimeChange = (newValue) => {
        setDatetime(newValue,field_key)
    }

    return (
        <CustomDialog
                open={open}
                onClose={onClose}
                dialogTitle={{
                    title: "", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                
        >
            <StaticDateTimePicker
                value={datetime ? datetime : new Date(new Date().setSeconds(0))}
                onChange={(newValue) => handleDatetimeChange(new Date(newValue.setSeconds(0)))}
                onAccept={onAccept}
            />
        </CustomDialog>
    )
};

export default CustomDateTimePicker;