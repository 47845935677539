import { 
    success_create_response,
    success_delete_response,
    success_update_response,
    bad_request_response,
    forbidden_response,
    not_found_response,
    conflict_response,
    service_unavailable,
    unknown_response
 } from '../common_v3';


export const backupsUrl = {
    post: {
        success_response: {
            ...success_create_response,
            status_code: 201
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            conflict_response,
            service_unavailable,
            not_found_response,
            unknown_response
        ]
    },
    post_async: {
        success_response: {
            ...success_create_response,
            status_code: 202
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            conflict_response,
            service_unavailable,
            not_found_response,
            unknown_response
        ]
    },
    get: {
        success_response: {
            status_code: 200
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            service_unavailable,
            not_found_response,
            unknown_response
        ]
    },
    put: {
        success_response: {
            ...success_update_response
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            service_unavailable,
            unknown_response
        ]
    },
    delete: {
        success_response: {
            ...success_delete_response,
            status_code: 202
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            conflict_response,
            service_unavailable,
            unknown_response
        ]
    }
}