import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import SegmentsTableV20 from './segmentsTableV2.0';
import SegmentDetailV20 from './segmentDetailV2.0';
import { segmentsFilterMenu } from '../../../../../../_data/openstack/neutron/segments/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { segmentsUrl as segmentUrlResponses } from '../../../../../../_api_responses/openstack/neutron/segments/v2.0';
import { networksUrl as networkUrlResponses} from '../../../../../../_api_responses/openstack/neutron/networks/v2.0'
import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import SubheaderButton from '../../../../../_common/SubheaderButton';
import ServiceDoubleMenu from '../../../../../_common/ServiceDoubleMenu';
import ServiceDoubleSubmenu from '../../../../../_common/ServiceDoubleSubmenu';
import SegmentAddV20 from './segmentAddV2.0';
// import CustomSplitPane from '../../../../../_common/CustomSplitPane';

const SERVICE_NAME = openStackServices.networkService

const SegmentsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	const {currentTab, setCurrentTab} = props
	const [segmentsData, setSegmentsData] = useState([])
	const [segments, setSegments] = useState([])
	const { width } = useWindowDimensions();
	const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
	const [detailCardOpen, setDetailCardOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedSegment, setSelectedSegment] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [segmentDeleteConfirmDialogOpen, setSegmentDeleteConfirmDialogOpen] = useState(false);
	const [selectedSegments, setSelectedSegments] = useState([])
	const [segmentsSortParams, setSegmentsSortParams] = useState("")
	
	const [segmentFilterQueryParams, setSegmentFilterQueryParams] = useState("")
	const [selectedSegmentFilter, setSelectedSegmentFilter] = useState(segmentsFilterMenu[0].value)
	const [selectedSegmentFilterValue, setSelectedSegmentFilterValue] = useState("")
	const [segmentsFilter, setSegmentsFilter] = useState([...segmentsFilterMenu])

	const [networks, setNetworks] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const segmentsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = segmentsFilterMenu.find(item => item.value === selectedSegmentFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		// if (selectedVirtualIPFilter === 'network_id') {
		// 	let networks_filter = []
		// 	networks.forEach(item => networks_filter.push({ keyword: item.name, value: item.id, default: false }))
		// 	return networks_filter
		// }

		const filterObject = segmentsFilterMenu.find(item => item.value === selectedSegmentFilter)
		return filterObject ? filterObject.items : []
	}

	const handleTabChange = (tab_name) => {
			setCurrentTab(tab_name)
	}

	const handleSegmentFilteredSearch = () => {
			if (selectedSegmentFilter && selectedSegmentFilterValue) {
					setSegmentFilterQueryParams(`${selectedSegmentFilter}=${selectedSegmentFilterValue}`)
			} else {
					setSegmentFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleSegmentsDataFormatting = useCallback(() => {
			const formatted_data = segmentsData.map((item) => {
					let new_item = {...item}
					const network = networks.filter(n => n.id === item.network_id)
					if (network.length > 0) {
							new_item.network_id = network[0].name
					}
					
					return new_item
			})

			setSegments(formatted_data)
	},[
			segmentsData,
			networks
	])

	const handleSegmentFilterReset = () => {
			setSelectedSegmentFilter(segmentsFilterMenu[0].value)
			setSelectedSegmentFilterValue("")
			setSegmentFilterQueryParams("")
			handleDataFetch()
	}


	const handleNavigateToNetwork = (network_id,tab_name) => {
			handleDetailCardClose()
			changeMenuActiveTab(tab_name)
			handleTabChange("networks")
			navigate("/networks/l2-networking/networks",{state: {network_id: network_id}})
	}


	const handleDetailCardOpen = useCallback((index) => {
			setSelectedSegment(segmentsData[index].id)
			setSelectedRow(segmentsData[index])
			setTimeout(() => setDetailCardOpen(true),100)
	},[segmentsData]);

	const handleDetailCardClose = () => {
			setTimeout(() => setDetailCardOpen(false),100)
			setSelectedRow(null)
			setSelectedSegment(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleSegmentsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setSegmentsSortParams(sort_param)
			handleDataFetch()
	}

	const handleSegmentDelete = async (p_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}/${p_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === segmentUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onSegmentDelete = async () => {
			handleSegmentDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedSegments) {
					const resp = await handleSegmentDelete(selectedSegments[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onSegmentDeleteConfirm = (n_list) => {
			const selected_n_list = segmentsData.filter(n => 
					n_list.includes(n.id))
			setSelectedSegments([...selected_n_list])
			setSegmentDeleteConfirmDialogOpen(true)
	}

	const handleSegmentDeleteConfirmDialogClose = () => {
			setSegmentDeleteConfirmDialogOpen(false)
	}

	const getSegmentsActionsList = () => {
			let segment_actions = []
			let new_action = {}
			new_action["value"] = "segment_delete"
			new_action["action"] = onSegmentDeleteConfirm
			new_action["keyword"] = "segmentDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			segment_actions.push({...new_action})
			
			return segment_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}?${segmentFilterQueryParams}${segmentsSortParams}`
							const method = "GET"
							const segment_response = await openstackRequest({url:url, method:method, token, })
							if (segment_response.status_code === segmentUrlResponses.get.success_response.status_code) {
									setSegmentsData(segment_response.data.segments)
									if (selectedSegment) {
											const selected_segment = segment_response.data.segments.filter(item => item.id === selectedSegment)
											if (selected_segment.length > 0) {
													setSelectedRow(selected_segment[0])
											}
									}
							} else {
								setSegmentsData([])
							}
							
					})();
			}
			setDataFetchingRequired(false)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			segmentsUrl, 
			segmentFilterQueryParams,
			dataFetchingRequired,
			token,
			segmentsSortParams,
			selectedSegment
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl,
			token
	]);


	useEffect(() => {
			if (segmentsData.length > 0) {
					handleSegmentsDataFormatting()
			} else {
				setSegments([])
			}
	},[
			segmentsData,
			handleSegmentsDataFormatting
	])

	useEffect(() => {
			let networks_filter = []
			if (networks.length > 0) {
					networks_filter = networks.map(network => {
							return {keyword: network.name, value: network.id, default: false}
					})
			}
			
			let segment_filter_menu = segmentsFilterMenu.map(nt => {
					let new_item = {...nt}
					if (nt.value === "network_id") {
							new_item.items = [...networks_filter]
					}
					return new_item
			})

			setSegmentsFilter(segment_filter_menu)
	},[
			segmentsData,
			networks
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const segment_id = location.state ? location.state.segment_id : null
							const segment_index = segmentsData.findIndex(v => v.id === segment_id);
							if (segment_index !== -1) {
									setTimeout(() => handleDetailCardOpen(segment_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			segmentsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [token])

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l2-networks')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedSegmentFilterValue('')
	}, [selectedSegmentFilter])

	const handleActionRun = () => {
		const actionsList = getSegmentsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						margin: '0px 20px',
						padding: '10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: `${theme.palette.customGray} 1px solid`,
					}}
				>
					<Box>
						<ServiceDoubleMenu
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
							onClick={changeMenuActiveTab}
							setCurrentTab={setCurrentTab}
						/>
					</Box>
					<Box
						sx={{
							width: '40px',
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
							borderRadius: '12px',
							cursor: 'pointer',
							boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5),
							}
						}}
					>
						<LiaNetworkWiredSolid size={36} />
					</Box>
				</Stack>

				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						margin: '0px 20px',
						padding: '10px 0px',
						width: 'calc(100% - 40px)',
					}}
				>
					<Box>
						<ServiceDoubleSubmenu
							parent='/l2-networks'
							serviceMenu={filteredSubmenu}
							currentTab={currentTab}
							selectedItems={selectedIds.length}
							allItems={segmentsData.length}
							onClick={setCurrentTab}
						/>
					</Box>

					<Box>
						<Stack 
							direction="row" 
							justifyContent="space-between" 
							alignItems="flex-end"
						>
							<SubheaderButton
								buttontype='refresh'
								onClick={handleSegmentFilterReset}
							/>

							{
								selectedIds.length > 0 && (
									<>
										<Box sx={{ marginLeft: '10px' }}>
											<CustomSelectField
												items={getSegmentsActionsList()} 
												currentValue={currentAction}
												setCurrentValue={setCurrentAction}
												item_titles={defaultTexts}
												label={defaultTexts.actionsButtonText}
												empty={true}
												size="small"
											/>
										</Box>
										{
											currentAction &&
											<Button 
												variant="contained"
												sx={{ height: '40px', marginLeft: '10px' }}
												onClick={handleActionRun}
											>
												{defaultTexts.applyButtonTitleText}
											</Button>
										}
									</>
								)
							}

							<SegmentAddV20
								handleDataFetch={handleDataFetch}
								networks={networks}
							/>
						</Stack>	
					</Box>
				</Stack>

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{ padding: '0px 20px 20px 20px', width: "100%" }}
				>
					<Box>
						{
							getSearchType() === 'text' &&
							<CustomTextField 
								size="small"
								variant="outlined"
								currentValue={selectedSegmentFilterValue}
								setCurrentValue={e => setSelectedSegmentFilterValue(e.target.value)}
								sx={{ width: '240px' }}
							/>
						}

						{
							getSearchType() === 'select' &&
							<CustomSelectField
								items={getSearchSelectItems()} 
								currentValue={selectedSegmentFilterValue}
								setCurrentValue={setSelectedSegmentFilterValue}
								item_titles={defaultTexts}
								empty={true}
								size="small"
								self_item_titles={false}
								sx={{ width: '240px' }}
							/>
						}
					</Box>
					<Box sx={{ marginLeft: '10px' }}>
						<CustomSelectField
							items={segmentsFilter} 
							currentValue={selectedSegmentFilter}
							setCurrentValue={setSelectedSegmentFilter}
							item_titles={defaultTexts}
							empty={true}
							size="small"
						/>
					</Box>
					<Box
						sx={{
							color: theme.palette.primary.main,
							margin: '5px 0px 0px 5px',
							cursor: 'pointer',
						}}
						onClick={handleSegmentFilteredSearch}
					>
						<SearchIcon fontSize='large' />
					</Box>
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<SegmentsTableV20 
				segmentsData={segments}
				setSegmentsData={setSegments}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getSegmentsActionsList()}
				sortHandler={handleSegmentsSorting}
				sortParams={segmentsSortParams}
				setSelectedIds={setSelectedIds}
			/>
		)
	}

	// const getNetworkContent = () => {
	// 	return (
	// 		<>
	// 			{ getNetworksHeader() }
	// 			{ getNetworksTable() }
	// 		</>
	// 	)
	// }

	// const getNetworkDetails = () => {
	// 	return (
	// 		<SegmentDetailV20
	// 			selectedRow={selectedRow}
	// 			setSelectedRow={setSelectedRow}
	// 			handleDataFetch={handleDataFetch}
	// 			handleDelete={onSegmentDeleteConfirm}
	// 			networks={networks}
	// 			handleNavigateToNetwork={handleNavigateToNetwork}
	// 		/>
	// 	)
	// }

	return (
		<>
			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

					{/* <SegmentsSubheaderV20 
							selectedFilter={selectedSegmentFilter} 
							setSelectedFilter={setSelectedSegmentFilter}
							selectedFilterValue={selectedSegmentFilterValue}
							setSelectedFilterValue={setSelectedSegmentFilterValue}
							handleDataFetch={handleDataFetch}
							filterMenu={segmentsFilter}
							handleFilteredSearch={handleSegmentFilteredSearch}
							handleFilterReset={handleSegmentFilterReset}
							currentTab={currentTab}
							handleTabChange={handleTabChange}
							networks={networks}
							segmentsSupported={segmentsSupported}
							trunksSupported={trunksSupported}
					/> */}
			
			{/* {!isLoading &&
					<SegmentsTableV20 
							segmentsData={segments}
							setSegmentsData={setSegments}
							handleRowSelection={handleDetailCardOpen}
							currentAction={currentAction}
							setCurrentAction={setCurrentAction}
							actionsTexts={defaultTexts}
							actionsList={getSegmentsActionsList()}
							sortHandler={handleSegmentsSorting}
					/>
			} */}

			{selectedRow !== null && 
					<CustomSideDrawer 
							open={detailCardOpen}
							widthWeight={WIDTH_WEIGHT}
							handleDrawerOpen={handleDetailCardOpen}
							handleDrawerClose={handleDetailCardClose}
					> 
							<SegmentDetailV20
									selectedRow={selectedRow}
									widthWeight={WIDTH_WEIGHT}
									handleDataFetch={handleDataFetch}
									handleDelete={onSegmentDeleteConfirm}
									networks={networks}
									handleNavigateToNetwork={handleNavigateToNetwork}
							/>
					</CustomSideDrawer>
			}
				<CustomDialog
						open={segmentDeleteConfirmDialogOpen}
						onClose={handleSegmentDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.segmentDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.segmentDeleteConfirmText}`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onSegmentDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				{error && <CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
				/>}
			</Box>
		</>
	)
}

export default SegmentsWrapperV20