const containsUppercase = (str) => {
  return /[A-Z]/.test(str)
}

const containsLowercase = (str) => {
  return /[a-z]/.test(str)
}

const containsNumber = (str) => {
  return /[0-9]/.test(str)
}

const containsSpecialSymbol = (str) => {
  return /[!@#$%^&*()\-+={}[\]:;"'<>,.?/|\\]/.test(str)
}

export const isValidPassword = (str) => {
  return containsUppercase(str) && containsLowercase(str) && containsNumber(str) && containsSpecialSymbol(str) && str.length > 7
}

export const isValidEmail = (string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(string)
}