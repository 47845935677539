export const vpnIKEPolicyDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "ike_version",
        is_id_field: false,
        field_type: "string", 
        label: "ikeVersionFormFieldLabel"
    },
    {
        field_key: "auth_algorithm",
        is_id_field: false,
        field_type: "string", 
        label: "authAlgorithmFormFieldLabel"
    },
    {
        field_key: "encryption_algorithm",
        is_id_field: false,
        field_type: "string", 
        label: "encryptionAlgorithmFormFieldLabel"
    },
    {
        field_key: "pfs",
        is_id_field: false,
        field_type: "string", 
        label: "pfsGroupFormFieldLabel"
    },
    {
        field_key: "phase1_negotiation_mode",
        is_id_field: false,
        field_type: "string", 
        label: "negotiationModeFormFieldLabel"
    },
    {
        field_key: "lifetime",
        is_id_field: false,
        field_type: "string", 
        label: "lifetimeFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const vpnIKEPolicyDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "auth_algorithm",
        field_type: "select",
        required: false,
        label: "authAlgorithmFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "sha1",
        items: [
            {
                keyword: "SHA1",
                value: "sha1",
                default: true
            },
            {
                keyword: "SHA256",
                value: "sha256",
                default: false
            },
            {
                keyword: "SHA384",
                value: "sha384",
                default: false
            },
            {
                keyword: "SHA512",
                value: "sha512",
                default: false
            }
        ]
    },
    {
        field_key: "encryption_algorithm",
        field_type: "select",
        required: false,
        label: "encryptionAlgorithmFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "aes-128",
        items: [
            {
                keyword: "3DES",
                value: "3des",
                default: true
            },
            {
                keyword: "AES-128",
                value: "aes-128",
                default: false
            },
            {
                keyword: "AES-192",
                value: "aes-192",
                default: false
            },
            {
                keyword: "AES-256",
                value: "aes-256",
                default: false
            }
        ]
    },
    {
        field_key: "pfs",
        field_type: "select",
        required: false,
        label: "pfsGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "group5",
        items: [
            {
                keyword: "Group 2",
                value: "group2",
                default: true
            },
            {
                keyword: "Group 5",
                value: "group5",
                default: false
            },
            {
                keyword: "Group 14",
                value: "group14",
                default: false
            }
        ]
    },
    {
        field_key: "phase1_negotiation_mode",
        field_type: "select",
        required: false,
        label: "negotiationModeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "main",
        items: [
            {
                keyword: "mainModeFormFieldLabel",
                value: "main",
                default: true
            }
        ]
    },
    {
        field_key: "ike_version",
        field_type: "select",
        required: false,
        label: "ikeVersionFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "v1",
        items: [
            {
                keyword: "V1",
                value: "v1",
                default: true
            },
            {
                keyword: "V2",
                value: "v2",
                default: false
            }
        ]
    },
    {
        field_key: "lifetime",
        field_type: "integer",
        required: false,
        label: "lifetimeFormFieldLabel",
        min: 60,
        step: 60
    }
]

export const vpnIKEPolicyUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]


export const vpnIKEPoliciesFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const vpnIPsecPolicyDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "auth_algorithm",
        is_id_field: false,
        field_type: "string", 
        label: "authAlgorithmFormFieldLabel"
    },
    {
        field_key: "encryption_algorithm",
        is_id_field: false,
        field_type: "string", 
        label: "encryptionAlgorithmFormFieldLabel"
    },
    {
        field_key: "encapsulation_mode",
        is_id_field: false,
        field_type: "string", 
        label: "encapsulationModeFormFieldLabel"
    },
    {
        field_key: "pfs",
        is_id_field: false,
        field_type: "string", 
        label: "pfsGroupFormFieldLabel"
    },
    {
        field_key: "transform_protocol",
        is_id_field: false,
        field_type: "string", 
        label: "transformProtocolFormFieldLabel"
    },
    {
        field_key: "lifetime",
        is_id_field: false,
        field_type: "string", 
        label: "lifetimeFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const vpnIPsecPolicyDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "auth_algorithm",
        field_type: "select",
        required: false,
        label: "authAlgorithmFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "sha1",
        items: [
            {
                keyword: "SHA1",
                value: "sha1",
                default: true
            },
            {
                keyword: "SHA256",
                value: "sha256",
                default: false
            },
            {
                keyword: "SHA384",
                value: "sha384",
                default: false
            },
            {
                keyword: "SHA512",
                value: "sha512",
                default: false
            }
        ]
    },
    {
        field_key: "encryption_algorithm",
        field_type: "select",
        required: false,
        label: "encryptionAlgorithmFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "aes-128",
        items: [
            {
                keyword: "3DES",
                value: "3des",
                default: true
            },
            {
                keyword: "AES128",
                value: "aes-128",
                default: false
            },
            {
                keyword: "AES192",
                value: "aes-192",
                default: false
            },
            {
                keyword: "AES256",
                value: "aes-256",
                default: false
            }
        ]
    },
    {
        field_key: "encapsulation_mode",
        field_type: "select",
        required: false,
        label: "encapsulationModeFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "tunnel",
        items: [
            {
                keyword: "Tunnel",
                value: "tunnel",
                default: true
            },
            {
                keyword: "Transport",
                value: "transport",
                default: false
            }
        ]
    },
    {
        field_key: "pfs",
        field_type: "select",
        required: false,
        label: "pfsGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "group5",
        items: [
            {
                keyword: "Group 2",
                value: "group2",
                default: true
            },
            {
                keyword: "Group 5",
                value: "group5",
                default: false
            },
            {
                keyword: "Group 14",
                value: "group14",
                default: false
            }
        ]
    },
    {
        field_key: "transform_protocol",
        field_type: "select",
        required: false,
        label: "transformProtocolFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "esp",
        items: [
            {
                keyword: "ESP",
                value: "esp",
                default: true
            },
            {
                keyword: "AH",
                value: "ah",
                default: false
            },
            {
                keyword: "AH-ESP",
                value: "ah-esp",
                default: false
            }
        ]
    },
    {
        field_key: "lifetime",
        field_type: "integer",
        required: false,
        label: "lifetimeFormFieldLabel",
        min: 60,
        step: 60
    }
]


export const vpnIPsecPoliciesFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const vpnIPsecPolicyUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const vpnServiceDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "router_id",
        is_id_field: false,
        field_type: "string", 
        label: "routerFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "external_v4_ip",
        is_id_field: false,
        field_type: "string", 
        label: "externalIPv4AddressFormFieldLabel"
    },
    {
        field_key: "external_v6_ip",
        is_id_field: false,
        field_type: "string", 
        label: "externalIPv6AddressFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const vpnServiceDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "router_id",
        field_type: "select",
        required: true,
        label: "routerFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "subnet_id",
        field_type: "select",
        required: false,
        label: "subnetFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    }
]

export const vpnServicesFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const vpnServiceUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]


export const vpnEndpointGroupDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "type",
        is_id_field: false,
        field_type: "string", 
        label: "typeFormFieldLabel"
    },
    {
        field_key: "endpoints",
        is_id_field: false,
        field_type: "list", 
        label: "endpointsFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const vpnEndpointGroupDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "type",
        field_type: "select",
        required: true,
        label: "typeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "cidr",
        items: [
            {
                keyword: "subnetFormFieldLabel",
                value: "subnet",
                default: true
            },
            {
                keyword: "cidrFormFieldLabel",
                value: "cidr",
                default: true
            }
        ]
    }
    
]

export const vpnEGCidrDataForm = [
    {
        field_key: "cidr",
        field_type: "string",
        required: false,
        label: "cidrFormFieldLabel"
    }
]

export const vpnEGSubnetDataForm = [
    {
        field_key: "subnet",
        field_type: "select",
        required: false,
        label: "subnetFormFieldLabel",
        self_item_titles: true,
        default_empty: true,
        multiple: true
    }
]

export const vpnEndpointGroupsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const vpnEndpointGroupUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]


export const vpnSiteConnectionDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "local_id",
        is_id_field: false,
        field_type: "string", 
        label: "localIDFormFieldLabel"
    },
    {
        field_key: "peer_address",
        is_id_field: false,
        field_type: "string", 
        label: "peerAddressFormFieldLabel"
    },
    {
        field_key: "peer_id",
        is_id_field: false,
        field_type: "string", 
        label: "peerIDFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo",
    },
    {
        field_key: "local_ep_group_id",
        is_id_field: false,
        field_type: "string", 
        label: "localEndpointGroupFormFieldLabel"
    },
    {
        field_key: "peer_ep_group_id",
        is_id_field: false,
        field_type: "string", 
        label: "peerEndpointGroupFormFieldLabel"
    },
    {
        field_key: "peer_cidrs",
        is_id_field: false,
        field_type: "list", 
        label: "peerCIDRsFormFieldLabel"
    },
    // {
    //     field_key: "ikepolicy_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "ikePolicyFormFieldLabel"
    // },
    // {
    //     field_key: "ipsecpolicy_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "ipsecPolicyFormFieldLabel"
    // },
    // {
    //     field_key: "vpnservice_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "vpnServiceFormFieldLabel"
    // },
    // {
    //     field_key: "initiator",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "initiatorFormFieldLabel"
    // },
    // {
    //     field_key: "dpd",
    //     is_id_field: false,
    //     field_type: "list", 
    //     label: "dpdFormFieldLabel"
    // },
    // {
    //     field_key: "auth_mode",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "authModeFormFieldLabel"
    // },
    // {
    //     field_key: "route_mode",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "routeModeFormFieldLabel"
    // },
    // {
    //     field_key: "mtu",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "mtuFormFieldLabel"
    // },
    // {
    //     field_key: "description",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "descriptionFormFieldLabel"
    // },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const vpnSiteConnectionDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "ikepolicy_id",
        field_type: "select",
        required: false,
        label: "ikePolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "ipsecpolicy_id",
        field_type: "select",
        required: false,
        label: "ipsecPolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "vpnservice_id",
        field_type: "select",
        required: false,
        label: "vpnServiceFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "local_ep_group_id",
        field_type: "select",
        required: false,
        label: "localEndpointGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "local_id",
        field_type: "string",
        required: false,
        label: "localIDFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_address",
        field_type: "string",
        required: true,
        label: "peerAddressFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_id",
        field_type: "string",
        required: true,
        label: "peerIDFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_ep_group_id",
        field_type: "select",
        required: false,
        label: "peerEndpointGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "peer_cidrs",
        field_type: "string",
        required: false,
        label: "peerCIDRFormFieldLabel"
    },
    {
        field_key: "psk",
        field_type: "password",
        required: true,
        label: "pskFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "initiator",
        field_type: "select",
        required: true,
        label: "initiatorFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: false,
        default_empty: false,
        default_value: "bi-directional",
        items: [
            {
                keyword: "bidirectionalFormFieldLable",
                value: "bi-directional",
                default: true
            },
            {
                keyword: "responseOnlyFormFieldLable",
                value: "response-only",
                default: false
            }
        ]
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    },
    {
        field_key: "mtu",
        field_type: "integer",
        required: false,
        label: "mtuFormFieldLabel",
        default_value: 1446
    },
    {
        field_key: "action",
        field_type: "select",
        required: false,
        label: "dpdActionFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "hold",
        items: [
            {
                keyword: "holdActionFormFieldLable",
                value: "hold",
                default: true
            },
            {
                keyword: "clearActionFormFieldLable",
                value: "clear",
                default: false
            },
            {
                keyword: "restartActionFormFieldLable",
                value: "restart",
                default: false
            },
            {
                keyword: "disabledActionFormFieldLable",
                value: "disabled",
                default: false
            },
            {
                keyword: "restartByPeerActionFormFieldLable",
                value: "restart-by-peer",
                default: false
            }
        ]
    },
    {
        field_key: "interval",
        field_type: "integer",
        required: false,
        label: "dpdIntervalFormFieldLabel",
        default_value: 30
    },
    {
        field_key: "timeput",
        field_type: "integer",
        required: false,
        label: "dpdTimeoutFormFieldLabel",
        default_value: 120
    }
]

export const vpnSiteConnectionUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "local_ep_group_id",
        field_type: "select",
        required: false,
        label: "localEndpointGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "local_id",
        field_type: "string",
        required: false,
        label: "localIDFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_address",
        field_type: "string",
        required: true,
        label: "peerAddressFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_id",
        field_type: "string",
        required: true,
        label: "peerIDFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "peer_ep_group_id",
        field_type: "select",
        required: false,
        label: "peerEndpointGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "peer_cidrs",
        field_type: "string",
        required: false,
        label: "peerCIDRFormFieldLabel"
    },
    {
        field_key: "psk",
        field_type: "password",
        required: true,
        label: "pskFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "initiator",
        field_type: "select",
        required: true,
        label: "initiatorFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: false,
        default_empty: false,
        default_value: "bi-directional",
        items: [
            {
                keyword: "bidirectionalFormFieldLable",
                value: "bi-directional",
                default: true
            },
            {
                keyword: "responseOnlyFormFieldLable",
                value: "response-only",
                default: false
            }
        ]
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    },
    {
        field_key: "mtu",
        field_type: "integer",
        required: false,
        label: "mtuFormFieldLabel",
        default_value: 1446
    },
    {
        field_key: "action",
        field_type: "select",
        required: false,
        label: "dpdActionFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "hold",
        items: [
            {
                keyword: "holdActionFormFieldLable",
                value: "hold",
                default: true
            },
            {
                keyword: "clearActionFormFieldLable",
                value: "clear",
                default: false
            },
            {
                keyword: "restartActionFormFieldLable",
                value: "restart",
                default: false
            },
            {
                keyword: "disabledActionFormFieldLable",
                value: "disabled",
                default: false
            },
            {
                keyword: "restartByPeerActionFormFieldLable",
                value: "restart-by-peer",
                default: false
            }
        ]
    },
    {
        field_key: "interval",
        field_type: "integer",
        required: false,
        label: "dpdIntervalFormFieldLabel",
        default_value: 30
    },
    {
        field_key: "timeout",
        field_type: "integer",
        required: false,
        label: "dpdTimeoutFormFieldLabel",
        default_value: 120
    }
]


export const vpnSiteConnectionsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // },
    {
        keyword: "peerAddressFormFieldLabel",
        value: "peer_address",
        type: "text"
    },
]