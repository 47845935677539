import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import CustomText from '../../_common/CustomText'
import constants from '../../../config/constants'
import parse from 'html-react-parser'

const HomePromo = (props) => {
  const { promoHeight } = props

  const promoConfig = useSelector(state => state.settings.promoConfig)

  const [promoBg, setPromoBg] = useState('none')
  
  const sxPromo = {
    minHeight: `${promoHeight}px`,
    margin: '-24px -24px -24px 0px',
    padding: '0px 10%',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const sxPromoTextContainer = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  }

  useEffect(() => {
    let promo_bg = 'none'

    if (promoConfig) {
      if (promoConfig.background_type === 'image') {
        promo_bg = `url(${ constants.s3URL + constants.s3Bucket + promoConfig.background_image }) no-repeat center / cover`
      }

      if (promoConfig.background_type === 'color' && promoConfig.background_color_type === 'solid') {
        promo_bg = `${promoConfig.background_color}`
      }

      if (promoConfig.background_type === 'color' && promoConfig.background_color_type === 'gradient') {
        promo_bg = `linear-gradient(to bottom right, ${promoConfig.gradient_color_1}, ${promoConfig.gradient_color_2})`
      }
    }

    setPromoBg(promo_bg)
  }, [promoConfig])

  return (
    <Box sx={{ ...sxPromo, background: promoBg }}>
      { promoConfig.heading && <CustomText size='h4'>{promoConfig.heading}</CustomText> }
      <Box sx={sxPromoTextContainer}>
        {
          promoConfig.promo_text.map((item, index) => (
            <Box key={index} sx={{ padding: '20px' }}>
              <CustomText>{parse(item)}</CustomText>
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

export default HomePromo