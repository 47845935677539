import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../_common/CustomDialog';
import { openStackServices, blockStorageCinderConstants, computeNovaConstants } from '../../../../../config/openStackConstants';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { volumeCinderRequest, computeNovaRequest } from '../../../../../_network/openstack_request';
import { volumeGroupsUrl as groupUrlResponses } from '../../../../../_api_responses/openstack/cinder/groups/v3';
import { volumeGroupDataForm } from '../../../../../_data/openstack/cinder/groups/v3';
import AddButtonWithText from '../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.volumeService
const COMPUTE_SERVICE_NAME = openStackServices.computeService

const GroupAddV3 = (props) => {
  const { handleDataFetch } = props
  const { volumeTypesList, groupTypesList } = props
  //const adminProjects = useSelector(state => state.profile.adminProjects)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [availabilityZones, setAvailabilityZones] = useState([])

  const cinderServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const cinderServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const groupsUrl = useSelector(
      state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
          version => version.api_version === "v3")[0].urls.filter(
              url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
  const computeServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
  const computeServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
  const availabilityZonesUrl = useSelector(
      state => state.computeNova.computeNovaApiUrls.filter(
          version => version.api_version === "v2.1")[0].urls.filter(
              url => url.keyword === computeNovaConstants.availabilityZonesUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleAddGroupFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in volumeGroupDataForm) {
          if (volumeGroupDataForm[n].field_type === "select" &&
          volumeGroupDataForm[n].multiple
          ) {
              new_form_data[volumeGroupDataForm[n].field_key] = []
          } else if (volumeGroupDataForm[n].field_type === "string" || 
          volumeGroupDataForm[n].field_type === "select") {
              new_form_data[volumeGroupDataForm[n].field_key] = ""
          } else if (volumeGroupDataForm[n].field_type === "bool") {
              new_form_data[volumeGroupDataForm[n].field_key] = volumeGroupDataForm[n].default_value ? 
              volumeGroupDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (volumeGroupDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (volumeGroupDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }


  const getDataForm = () => {
      return (
          <FormGroup>
              {volumeGroupDataForm.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "volume_types") {
                      form_field_options["items"] = [...volumeTypesList]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                      form_field_options["multiple"] = true
                  } else if (field.field_key === "availability_zone") {
                      form_field_options["items"] = [...availabilityZones]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  } else if (field.field_key === "group_type") {
                      form_field_options["items"] = [...groupTypesList]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in volumeGroupDataForm) {
          if (volumeGroupDataForm[n].required && !formData[volumeGroupDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[volumeGroupDataForm[n].field_key] = {}
              updatedDataFormOptions[volumeGroupDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[volumeGroupDataForm[n].field_key]["errorText"] = defaultTexts[volumeGroupDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddGroup = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          
        const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}`
        const method = "POST"
        const group_response = await volumeCinderRequest({
            url: url, 
            method: method, 
            data: {group: formData},
            token,
        })
        
        if (group_response.status_code === groupUrlResponses.post.success_response.status_code) {
            handleDataFetch()
            handleAddGroupFormReset()
            setFormDataOptions({})
        } else {
            const error_response = (groupUrlResponses && groupUrlResponses.post_async && groupUrlResponses.post_async.error_response) ? groupUrlResponses.post_async.error_response.filter(error_item => error_item.status_code === group_response.status_code) : []
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            } else {
                const error_response = (groupUrlResponses && groupUrlResponses.post_async && groupUrlResponses.post_async.error_response) ? groupUrlResponses.post_async.error_response.filter(error_item => error_item.status_code === "unknown") : []
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: group_response.error
                    }
                    setError(errorObject)
                }                
            }
        }
          
      }
      return validateFormData
  }

  useEffect(() => {
      (async () => {
          
        const url = `${computeServiceDomain}/${computeServiceVersion}/${availabilityZonesUrl}`
        const method = "GET"
        const zone_response = await computeNovaRequest({
            url: url, 
            method: method,
            token,
        })
        if (zone_response.status_code === 200) {
            const availability_zones = zone_response.data.availabilityZoneInfo.map(item => {
                return {keyword: item.zoneName, value: item.zoneName, default: false}
            })
            setAvailabilityZones(availability_zones)
        }
          
      })();
  },[
      computeServiceDomain,
      computeServiceVersion,
      availabilityZonesUrl,
      defaultAdminProject,
      token,
  ]);

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in volumeGroupDataForm) {
              if (
                  volumeGroupDataForm[n].field_type === "select" &&
                  volumeGroupDataForm[n].multiple
                  ) {
                  new_form_data[volumeGroupDataForm[n].field_key] = []
              } else if (
                  volumeGroupDataForm[n].field_type === "string" || 
                  volumeGroupDataForm[n].field_type === "select"
                  ) {
                  new_form_data[volumeGroupDataForm[n].field_key] = ""
              } else if (volumeGroupDataForm[n].field_type === "bool") {
                  new_form_data[volumeGroupDataForm[n].field_key] = volumeGroupDataForm[n].default_value ? 
                  volumeGroupDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddGroup}
        formReset={handleAddGroupFormReset}
        customTexts={{
          title: defaultTexts.addGroupDialogTitle
        }}
      />

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
            title: defaultTexts[error.error_title], 
            sx: {color: 'primary.main'}}}
          dialogBody={{
            text: `<span>${defaultTexts[error.error_message]}</span>
                    <br>
                    <br>
                    <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                    <span style="color: orange">
                        ${error.error_details}
                    </span>`, 
            sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default GroupAddV3