import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { BarChart } from '@mui/x-charts/BarChart'
import CustomPopover from '../../../_common/CustomPopover'
import useWindowDimensions from '../../../_common/WindowDimensions'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import {
  getCurrrentYearFirstDate,
  getCurrrentYearLastDate,
  addTimeToDate,
  getMonthShortNameFromYearAndMonth,
} from '../../helpers/dates'

const PlanningCompare = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)

  const [monthChargesData, setMonthChargesData] = useState([])
  const [monthCharges, setMonthCharges] = useState([])
  const [startDate, setStartDate] = useState(getCurrrentYearFirstDate())
  const [endDate, setEndDate] = useState(getCurrrentYearLastDate())
  const [isLoading, setIsLoading] = useState(true)

  const labelStyle = {
    color: theme.palette.secondary.main,
    fontSize: '18px',
  }

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value)
  }

  const handleMonthChargesDataFormatting = useCallback(() => {
    let formatted_data = monthChargesData.map((item) => {
      let new_item = { ...item }

      new_item.time = getMonthShortNameFromYearAndMonth(item.time, defaultTexts)

      return new_item
    })

    setMonthCharges(formatted_data)
  }, [monthChargesData, defaultTexts])

  useEffect(() => {
    const fetchMonthChargesData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }

      const url = constants.billing_url + '/' + billingUrls.getCompareMonthCharges + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setMonthChargesData(response.data)
      } else {
        setMonthChargesData([])
      }

      setIsLoading(false)
    }

    fetchMonthChargesData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, startDate, endDate])

  useEffect(() => {
		if (monthChargesData.length > 0) {
			handleMonthChargesDataFormatting()
		} else {
			setMonthCharges([])
		}
	},[
		monthChargesData,
		handleMonthChargesDataFormatting
	])

  const getFilter = () => {
    return (
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start" 
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        sx={{
          padding: width >= 900 ? '0px 20px 20px 20px' : '0px',
          width: "100%",
        }}
      >
        <Box>
          <DatePicker
            label=''
            value={new Date(startDate)}
            onChange={e => handleChangeStartDate(new Date(e))}
            maxDate={new Date(endDate)}
            slotProps={{ textField: { size: 'small' } }}
            views={['month', 'year']}
            sx={{ width: '180px' }}
          />
        </Box>
        <Box sx={{ margin: '0px 10px' }}>{ width >= 900 ? '-' : <Box sx={{ width: '1px', height: '10px' }}></Box> }</Box>
        <Box>
          <DatePicker
            label=''
            value={new Date(endDate)}
            onChange={e => handleChangeEndDate(new Date(e))}
            minDate={new Date(startDate)}
            slotProps={{ textField: { size: 'small' } }}
            views={['month', 'year']}
            sx={{ width: '180px' }}
          />
        </Box>
      </Stack>
    )
  }

  return (
    <>
      <Stack
        direction="row" 
        justifyContent="space-between" 
        alignItems="flex-start"
        sx={{
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <Box sx={labelStyle}>{defaultTexts.compareCostsLabel}</Box>

        {
          width >= 900 ?
          <Box>
            {getFilter()}
          </Box> :
          <Box sx={{ paddingRight: '10px' }}>
            <CustomPopover
              type='filterlist'
              horizontalOrigin='left'
            >
              {getFilter()}
            </CustomPopover>
          </Box>          
        }
      </Stack>
      
      {
        isLoading ?
        <Stack
          direction='column'
          spacing={4}
          sx={{ margin: '20px 20px 0px 20px' }}
        >
          <Skeleton variant="rectangular" width='90%' height={20} />
          <Skeleton variant="rectangular" width='90%' height={20} />
          <Skeleton variant="rectangular" width='90%' height={20} />
          <Skeleton variant="rectangular" width='90%' height={20} />
          <Skeleton variant="rectangular" width='90%' height={20} />
          <Skeleton variant="rectangular" width='90%' height={20} />
        </Stack> :
        <Box sx={{ margin: '-40px 10px 0px 10px' }}>
          <BarChart
            dataset={monthCharges}
            yAxis={[{ scaleType: 'band', dataKey: 'time' }]}
            series={[{ dataKey: 'total_cost' }]}
            layout='horizontal'
            height={400}
          />
        </Box>
      }      
    </>
  )
}

export default PlanningCompare