import React from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import RefreshIcon from '@mui/icons-material/Refresh'

const SubheaderButton = (props) => {
  const theme = useTheme()

  const { onClick } = props
  const { buttontype } = props
  const { buttontitle } = props
  const { color } = props
  const { size } = props
  const { sx } = props
  
  const sxBox = {
    ...sx,
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    border: `${theme.palette.customGrayDark} 1px solid`,
    borderRadius: '4px',
    cursor: onClick ? 'pointer' : 'default',
    padding: buttontitle ? '0px 10px' : '0px',

    '&:hover': {
      background: onClick ? alpha(theme.palette.customGray, 0.5) : theme.palette.customWhite,
    }
  }

  const sxIcon = {
    fontSize: '32px',
  }

  const handleClick = () => {
    onClick && onClick()
  }
  
  return (
    <Box
      onClick={handleClick}
      color={color ? color : 'primary'}
      size={size ? size : 'small'}
      sx={sxBox}
    >
      { buttontype === 'refresh' && <RefreshIcon sx={sxIcon} /> }
      {buttontitle}
    </Box>
  )
}

export default SubheaderButton