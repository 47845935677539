import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { subnetDataForm, subnetAllocationPoolForm, dnsNameserversForm, subnetHostRouteForm } from '../../../../../../_data/openstack/neutron/subnets/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { subnetsUrl as subnetUrlResponses } from '../../../../../../_api_responses/openstack/neutron/subnets/v2.0';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import CustomText from '../../../../../_common/CustomText';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const ip_checker = require('ip-address');

const SERVICE_NAME = openStackServices.networkService

const SubnetAddV2_0 = (props) => {
  const { handleDataFetch } = props
  const { networks } = props 
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successSubnetAdd, setSuccessSubnetAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [allocationPools, setAllocationPools] = useState([]);
  const [dnsNameservers, setDnsNameservers] = useState([]);
  const [hostRoutes, setHostRoutes] = useState([]);

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const subnetsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessSubnetAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddSubnetFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in subnetDataForm) {
          if (
              subnetDataForm[n].field_type === "string" || 
              subnetDataForm[n].field_type === "select"
              ) {
              new_form_data[subnetDataForm[n].field_key] = subnetDataForm[n].default_value ? 
              subnetDataForm[n].default_value : 
              ""
          } else if (subnetDataForm[n].field_type === "bool") {
              new_form_data[subnetDataForm[n].field_key] = subnetDataForm[n].default_value ? 
              subnetDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
      setAllocationPools([])
      setDnsNameservers([])
      setHostRoutes([])
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (subnetDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (subnetDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  const handleAllocationPoolFormDataChange = (event,field_key,index) => {
      let new_form_data = [...allocationPools]
      if (subnetAllocationPoolForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[index][field_key] = event.target.checked
      } else if (subnetAllocationPoolForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[index][field_key] = event.trim()
      } else {
          new_form_data[index][field_key] = event.target.value.trim()
      }
      setAllocationPools(new_form_data)
  }

  const handleDNSNameserverFormDataChange = (event, field_key, index) => {
      let new_form_data = [...dnsNameservers]
      new_form_data[index][field_key] = event.target.value.trim()
      setDnsNameservers(new_form_data)
  }

  const handleAllocationPoolAdd = () => {
      let new_form_data = {}
      for (const n in subnetAllocationPoolForm) {
          if (
              subnetAllocationPoolForm[n].field_type === "string" || 
              subnetAllocationPoolForm[n].field_type === "select"
              ) {
              new_form_data[subnetAllocationPoolForm[n].field_key] = ""
          } else if (subnetAllocationPoolForm[n].field_type === "bool") {
              new_form_data[subnetAllocationPoolForm[n].field_key] = subnetAllocationPoolForm[n].default_value ? 
              subnetAllocationPoolForm[n].default_value : 
              false
          }
      }
      const updated_data = [...allocationPools]
      updated_data.push(new_form_data)
      setAllocationPools(updated_data)
  }

  const handleHostRouteFormDataChange = (event,field_key,index) => {
      let new_form_data = [...hostRoutes]
      if (subnetHostRouteForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[index][field_key] = event.target.checked
      } else if (subnetHostRouteForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[index][field_key] = event.trim()
      } else {
          new_form_data[index][field_key] = event.target.value.trim()
      }
      setHostRoutes(new_form_data)
  }

  const handleHostRouteAdd = () => {
      let new_form_data = {}
      for (const n in subnetHostRouteForm) {
          if (
              subnetHostRouteForm[n].field_type === "string" || 
              subnetHostRouteForm[n].field_type === "select"
              ) {
              new_form_data[subnetHostRouteForm[n].field_key] = ""
          } else if (subnetHostRouteForm[n].field_type === "bool") {
              new_form_data[subnetHostRouteForm[n].field_key] = subnetHostRouteForm[n].default_value ? 
              subnetHostRouteForm[n].default_value : 
              false
          }
      }
      const updated_data = [...hostRoutes]
      updated_data.push(new_form_data)
      setHostRoutes(updated_data)
  }

  const handleHostRouteRemove = () => {
      let new_data = [...hostRoutes]
      new_data.pop()
      setHostRoutes(new_data)
  }

  const handleAllocationPoolRemove = () => {
      let new_data = [...allocationPools]
      new_data.pop()
      setAllocationPools(new_data)
  }

  const handleDNSNameserverAdd = () => {
      let new_form_data = {}
      new_form_data["dns_nameserver"] = ""
      const updated_data = [...dnsNameservers]
      updated_data.push(new_form_data)
      setDnsNameservers(updated_data)
  }

  const handleDNSNameserverRemove = () => {
      let new_data = [...dnsNameservers]
      new_data.pop()
      setDnsNameservers(new_data)
  }


  const getDataForm = () => {
      let form = [...subnetDataForm]
      if (formData.ip_version !== "6") {
          form = form.filter(item => item.field_key !== "ipv6_address_mode" && item.field_key !== "ipv6_ra_mode")
      } else if (!formData.enable_dhcp) {
          form = form.filter(item => item.field_key !== "ipv6_address_mode" && item.field_key !== "ipv6_ra_mode")
      }
      if (formData.disable_gateway) {
          form = form.filter(item => item.field_key !== "gateway_ip")
      }
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "ip_version" ||
                      field.field_key === "ipv6_address_mode" ||
                      field.field_key === "ipv6_ra_mode"
                  ) {
                      form_field_options["item_titles"] = defaultTexts
                      form_field_options["items"] = [...field.items]
                      form_field_options["self_item_titles"] = false
                      form_field_options["empty"] = false
                      form_field_options["multiple"] = false
                  }
                  if (field.field_key === "network_id") {
                      const networks_filter = networks.map(p => {
                          return {keyword: p.name, value: p.id, default: false}
                      })
                      form_field_options["items"] = [...networks_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
              {formData.enable_dhcp && formData.ip_version === "4" &&
                  <FormGroup>
                      {allocationPools.map((pool,index) => {
                      return (
                          <div key={index}>
                          <CustomText>{defaultTexts.allocationPoolFormFieldLabel} {index + 1}</CustomText>
                              {subnetAllocationPoolForm.map(item => {
                                  return (
                                      getFormFieldComponent(
                                          item,
                                          pool,
                                          handleAllocationPoolFormDataChange,
                                          defaultTexts[item.label],
                                          {index: index}
                                      )
                                  )
                              })}
                          </div>
                      )
                      })}
                      {allocationPools.length > 0 && <MinusButton 
                          style={{ transform: 'scale(0.7)' }}
                          sx={{height: 5}}
                          onClick={handleAllocationPoolRemove}
                      />}
                      {<Stack 
                          direction="row" 
                          spacing={2} 
                          alignItems="center"
                          onClick={handleAllocationPoolAdd}
                          sx={{cursor: "pointer"}}
                      >
                          <PlusButton 
                              style={{ transform: 'scale(0.7)' }}
                              sx={{height: 5}}
                              onClick={handleAllocationPoolAdd}
                          />
                          <CustomText>
                              {defaultTexts.addAllocationPoolFormFieldLabel}
                          </CustomText>
                      </Stack>}
                      {dnsNameservers.map((serv,index) => {
                          return (
                              <div key={index}>
                              <CustomText>{defaultTexts.dnsNameserverFormFieldLabel} {index + 1}</CustomText>
                                  {dnsNameserversForm.map(item => {
                                      return (
                                          getFormFieldComponent(
                                              item,
                                              serv,
                                              handleDNSNameserverFormDataChange,
                                              defaultTexts[item.label],
                                              {index: index}
                                          )
                                      )
                                  })}
                              </div>
                          )
                          })}
                          {dnsNameservers.length > 0 && <MinusButton 
                              style={{ transform: 'scale(0.7)' }}
                              sx={{height: 5}}
                              onClick={handleDNSNameserverRemove}
                          />}
                          {<Stack 
                              direction="row" 
                              spacing={2} 
                              alignItems="center"
                              onClick={handleDNSNameserverAdd}
                              sx={{cursor: "pointer"}}
                          >
                              <PlusButton 
                                  style={{ transform: 'scale(0.7)' }}
                                  sx={{height: 5}}
                                  onClick={handleDNSNameserverAdd}
                              />
                              <CustomText>
                                  {defaultTexts.addDnsNameserverFormFieldLabel}
                              </CustomText>
                          </Stack>}
                          {hostRoutes.map((route,index) => {
                              return (
                                  <div key={index}>
                                  <CustomText>{defaultTexts.hostRouteFormFieldLabel} {index + 1}</CustomText>
                                      {subnetHostRouteForm.map(item => {
                                          return (
                                              getFormFieldComponent(
                                                  item,
                                                  route,
                                                  handleHostRouteFormDataChange,
                                                  defaultTexts[item.label],
                                                  {index: index}
                                              )
                                          )
                                      })}
                                  </div>
                              )
                              })}
                              {hostRoutes.length > 0 && <MinusButton 
                                  style={{ transform: 'scale(0.7)' }}
                                  sx={{height: 5}}
                                  onClick={handleHostRouteRemove}
                              />}
                              {<Stack 
                                  direction="row" 
                                  spacing={2} 
                                  alignItems="center"
                                  onClick={handleHostRouteAdd}
                                  sx={{cursor: "pointer"}}
                              >
                                  <PlusButton 
                                      style={{ transform: 'scale(0.7)' }}
                                      sx={{height: 5}}
                                      onClick={handleHostRouteAdd}
                                  />
                                  <CustomText>
                                      {defaultTexts.addHostRouteFormFieldLabel}
                                  </CustomText>
                              </Stack>}
                  </FormGroup>
              }
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in subnetDataForm) {
          if (subnetDataForm[n].required && !formData[subnetDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[subnetDataForm[n].field_key] = {}
              updatedDataFormOptions[subnetDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[subnetDataForm[n].field_key]["errorText"] = defaultTexts[subnetDataForm[n].error_label]
          }
      }

      if (!formData.disable_gateway && formData.gateway_ip.length > 0) {
          let parsev4 = null
          let parsev6 = null
          try {
              parsev4 = new ip_checker.Address4(formData.gateway_ip)
          } catch {
              parsev4 = null
          }
          try {
              parsev6 = new ip_checker.Address6(formData.gateway_ip)
          } catch {
              parsev6 = null
          }
          if (!parsev4 && !parsev6) {
              validation_faild = false
              updatedDataFormOptions["gateway_ip"] = {}
              updatedDataFormOptions["gateway_ip"]["error"] = true
              updatedDataFormOptions["gateway_ip"]["errorText"] = defaultTexts.invalidIPAddressErrorText
          }
      }

      if (formData.cidr.length > 0) {
          let cidr_parts = formData.cidr.split("/")
          if (cidr_parts.length !== 2) {
              validation_faild = false
              updatedDataFormOptions["cidr"] = {}
              updatedDataFormOptions["cidr"]["error"] = true
              updatedDataFormOptions["cidr"]["errorText"] = defaultTexts.invalidCIDRErrorText
          } else {
              let parsev4 = null
              let parsev6 = null
              try {
                  parsev4 = new ip_checker.Address4(cidr_parts[0])
              } catch {
                  parsev4 = null
              }
              try {
                  parsev6 = new ip_checker.Address6(cidr_parts[0])
              } catch {
                  parsev6 = null
              }
              if (!parsev4 && !parsev6) {
                  validation_faild = false
                  updatedDataFormOptions["cidr"] = {}
                  updatedDataFormOptions["cidr"]["error"] = true
                  updatedDataFormOptions["cidr"]["errorText"] = defaultTexts.invalidIPAddressErrorText
              }
              if (formData.ip_version === "4" && parseInt(cidr_parts[1]) > 32) {
                  validation_faild = false
                  updatedDataFormOptions["cidr"] = {}
                  updatedDataFormOptions["cidr"]["error"] = true
                  updatedDataFormOptions["cidr"]["errorText"] = defaultTexts.invalidPrefixLengthErrorText
              } 
              if (formData.ip_version === "6" && parseInt(cidr_parts[1]) > 128) {
                  validation_faild = false
                  updatedDataFormOptions["cidr"] = {}
                  updatedDataFormOptions["cidr"]["error"] = true
                  updatedDataFormOptions["cidr"]["errorText"] = defaultTexts.invalidPrefixLengthErrorText
              } 
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddSubnet = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = {...formData}
          if (formData.ip_version === "4") {
              delete data.ipv6_ra_mode
              delete data.ipv6_address_mode
              if (formData.enable_dhcp) {
                  if (allocationPools.length > 0 ) {
                      data["allocation_pools"] = allocationPools.filter(item => item.start.length > 0 && item.end.length > 0)
                  }
                  if (dnsNameservers.length > 0) {
                      let dns_nameservers = dnsNameservers.filter(item => item.dns_nameserver.length > 0)
                      dns_nameservers = dns_nameservers.map(item => item.dns_nameserver)
                      data["dns_nameservers"] = dns_nameservers
                  }
                  if (hostRoutes.length > 0) {
                      data["host_routes"] = hostRoutes.filter(item => item.destination.length > 0 && item.nexthop.length > 0)
                  }
              }
          } else {
              if (formData.enable_dhcp) {
                  if (formData.ipv6_ra_mode === "none") {
                      delete data.ipv6_ra_mode
                  }
                  if (formData.ipv6_address_mode === "none") {
                      delete data.ipv6_address_mode
                  }
              } else {
                  delete data.ipv6_ra_mode
                  delete data.ipv6_address_mode
              }
          }
          if (formData.disable_gateway) {
              data.gateway_ip = null
          } else {
              if (formData.gateway_ip.length === 0) {
                  delete data.gateway_ip
              }
              
          }
          delete data.disable_gateway
          
          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
          const method = "POST"
          const subnet_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {subnet: data},
              token,
          })
          if (subnet_response.status_code === subnetUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddSubnetFormReset()
              setFormDataOptions({})
          } else {
              const error_response = subnetUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === subnet_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: subnet_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = subnetUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: subnet_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successSubnetAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in subnetDataForm) {
              if (
                  subnetDataForm[n].field_type === "string" || 
                  subnetDataForm[n].field_type === "select"
                  ) {
                  new_form_data[subnetDataForm[n].field_key] = subnetDataForm[n].default_value ? 
                  subnetDataForm[n].default_value : 
                  ""
              } else if (subnetDataForm[n].field_type === "bool") {
                  new_form_data[subnetDataForm[n].field_key] = subnetDataForm[n].default_value ? 
                  subnetDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddSubnet}
        formReset={handleAddSubnetFormReset}
        customTexts={{
          title: defaultTexts.addSubnetDialogTitle
        }}
      />

      {
        successSubnetAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successSubnetAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successSubnetAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default SubnetAddV2_0