export const serverDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        sort_key: 'display_name',
    },
    // {
    //     field_key: "tenant_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "flavor",
        is_id_field: false,
        field_type: "string", 
        label: "flavorFormFieldLabel"
    },    
    {
        field_key: "OS-EXT-STS:power_state",
        is_id_field: false,
        field_type: "string", 
        label: "powerStateFormFieldLabel",
        sort_key: 'power_state',
    },
    {
        field_key: "addresses",
        is_id_field: false,
        field_type: "list", 
        label: "addressesFormFieldLabel"
    },
    {
        field_key: "key_name",
        is_id_field: false,
        field_type: "string", 
        label: "sshKeyFormFieldLabel"
    },
    // {
    //     field_key: "image",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "imageFormFieldLabel"
    // },
    // {
    //     field_key: "OS-EXT-SRV-ATTR:host",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "hostFormFieldLabel"
    // },
    // {
    //     field_key: "user_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "userFormFieldLabel"
    // },
    {
        field_key: "age",
        is_id_field: false,
        field_type: "string", 
        label: "ageFormFieldLabel"
    },
    {
        field_key: "progress",
        is_id_field: false,
        field_type: "string", 
        label: "progressFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
]

export const serverUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        on_empty_delete: true,
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "accessIPv4",
        field_type: "string", 
        on_empty_delete: true,
        required: false,
        label: "accessIPv4FormFieldLabel"
    },
    {
        field_key: "accessIPv6",
        field_type: "string", 
        on_empty_delete: true,
        required: false,
        label: "accessIPv6FormFieldLabel"
    }
]

export const serverInterfaceForm = [
    {
        field_key: "net_id",
        field_type: "select", 
        required: true,
        label: "networkFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "fixed_ips",
        field_type: "string", 
        on_empty_delete: true,
        required: false,
        label: "fixedIPFormFieldLabel"
    }
]

export const serverVolumeForm = [
    {
        field_key: "volumeId",
        field_type: "select", 
        required: true,
        label: "volumeFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "delete_on_termination",
        field_type: "bool",
        required: false,
        label: "deleteOnTerminateFormFieldLabel",
        default_value: false
    }
]

export const serverResizeForm = [
    {
        field_key: "flavorRef",
        field_type: "select", 
        required: true,
        label: "flavorFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "OS-DCF:diskConfig",
        field_type: "select",
        required: false,
        label: "diskPartitionFormFieldLabel",
        default_value: false,
        items: [
            {
                keyword: "manualPartitionConfigChoice",
                value: "MANUAL",
                default: false
            },
            {
                keyword: "autoPartitionConfigChoice",
                value: "AUTO",
                default: false
            },
        ]
    }
]

export const power_states = [
    "noStatePowerStatus",
    "runningStatePowerStatus",
    "pausedStatePowerStatus",
    "shutdownStatePowerStatus",
    "crashedStatePowerStatus",
    "suspendedStatePowerStatus"
]

export const serversFilterMenu = [
    {
        keyword: "nameFormFieldLabel", 
        value: "name",
        type: "complex"
    },
    {
        keyword: "projectFormFieldLabel", 
        value: "project_id",
        type: "complex"
    },
    {
        keyword: "userFormFieldLabel", 
        value: "user_id",
        type: "complex"
    },
    {
        keyword: "stateFormFieldLabel", 
        value: "state",
        type: "complex"
    },
    {
        keyword: "flavorFormFieldLabel", 
        value: "flavor",
        type: "complex"
    },
    {
        keyword: "imageFormFieldLabel", 
        value: "image",
        type: "complex"
    },
    {
        keyword: "availabilityZoneFormFieldLabel", 
        value: "availability_zone",
        type: "complex"
    },
    {
        keyword: "hostnameFormFieldLabel", 
        value: "hostname",
        type: "complex"
    },
    {
        keyword: "sshKeyFormFieldLabel", 
        value: "key_name",
        type: "complex"
    },
    {
        keyword: "ipv4AddressFormFieldLabel", 
        value: "ipv4_address",
        type: "complex"
    },
    {
        keyword: "ipv6AddressFormFieldLabel", 
        value: "ipv6_address",
        type: "complex"
    },
    {
        keyword: "accessIPv4FormFieldLabel", 
        value: "ipv4_access_address",
        type: "complex"
    },
    {
        keyword: "accessIPv6FormFieldLabel", 
        value: "ipv6_access_address",
        type: "complex"
    },
    {
        keyword: "changesSinceFormFieldLabel", 
        value: "changes_since",
        type: "complex"
    },
    {
        keyword: "createTimeHeaderLabel", 
        value: "created_at",
        type: "complex"
    },
    {
        keyword: "tagsFormFieldLabel", 
        value: "tags",
        type: "complex"
    }
]

export const serversFilterConfig = [
    "name",
    "project_id",
    "user_id",
    "state",
    "flavor",
    "image",
    "availability_zone",
    "hostname",
    "key_name",
    "ipv4_address",
    "ipv6_address",
    "ipv4_access_address",
    "ipv6_access_address",
    "changes_since",
    "created_at",
    "tags"
]

export const serverGroupsDataSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel",
        min_api_version: null,
        max_api_version: null
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        min_api_version: null,
        max_api_version: null
    },
    {
        field_key: "policy",
        is_id_field: false,
        field_type: "string", 
        label: "policyFormFieldLabel",
        min_api_version: "2.64",
        max_api_version: null

    },
    {
        field_key: "policies",
        is_id_field: false,
        field_type: "list", 
        label: "policyFormFieldLabel",
        min_api_version: null,
        max_api_version: "2.63"
    },
    {
        field_key: "rules",
        is_id_field: false,
        field_type: "json_in_json", 
        label: "rulesFormFieldLabel",
        min_api_version: "2.64",
        max_api_version: null
    },
    {
        field_key: "members",
        is_id_field: false,
        field_type: "list", 
        label: "membersFormFieldLabel"
    },
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel",
        min_api_version: "2.13",
        max_api_version: null
    },
    {
        field_key: "user_id",
        is_id_field: false,
        field_type: "string", 
        label: "userFormFieldLabel",
        min_api_version: "2.13",
        max_api_version: null
    }
]

export const serverGroupDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "policy",
        field_type: "select", 
        required: true,
        label: "policyFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_value: false,
        self_item_titles: true,
        with_help: true,
        help_text: "serverGroupPolicyHelpText",
        items: [
            {
                keyword: "Anti Affinity",
                value: "anti-affinity",
                default: false
            },
            {
                keyword: "Affinity",
                value: "affinity",
                default: false
            },
            {
                keyword: "Soft Anti Affinity",
                value: "soft-anti-affinity",
                default: false
            },
            {
                keyword: "Soft Affinity",
                value: "soft-affinity",
                default: false
            },
        ]
    }
]

export const serverGroupRulesDataForm = [
    {
        field_key: "max_server_per_host",
        field_type: "integer", 
        min: 1,
        default_value: 1,
        required: false, 
        label: "maxServerPerHostFormFieldLabel"
    }
]


export const serversFilterGroups = [
    {
        "name": "group1",
        "title": ""
    },
    {
        "name": "group2",
        "title": ""
    },
    {
        "name": "group3",
        "title": ""
    }
]

export const serversFilters = {
    group1: [
        {
            field_key: "name",
            field_type: "string", 
            required: false,
            label: "nameFormFieldLabel"
        },
        // {
        //     field_key: "project_id",
        //     field_type: "select", 
        //     required: false,
        //     label: "projectFormFieldLabel",
        //     on_empty_delete: true,
        //     self_item_titles: true,
        //     default_empty: true
        // },
        // {
        //     field_key: "user_id",
        //     field_type: "select", 
        //     required: false,
        //     label: "userFormFieldLabel",
        //     on_empty_delete: true,
        //     self_item_titles: true,
        //     default_empty: true
        // },
        {
            field_key: "flavor",
            field_type: "select", 
            required: false,
            label: "flavorFormFieldLabel",
            on_empty_delete: true,
            self_item_titles: true,
            default_empty: true
        },
        //{
        //    field_key: "image",
        //    field_type: "select", 
        //    required: false,
        //    label: "imageFormFieldLabel",
        //    on_empty_delete: true,
        //    self_item_titles: true,
        //    default_empty: true
        //},
        {
            field_key: "status",
            field_type: "select", 
            required: false,
            label: "statusFormFieldLabel",
            on_empty_delete: true,
            self_item_titles: false,
            default_empty: true,
            items: [
                {
                    keyword: "ACTIVE",
                    value: "ACTIVE",
                    default: false
                },
                {
                    keyword: "BUILD",
                    value: "BUILD",
                    default: false
                },
                {
                    keyword: "REBUILD",
                    value: "REBUILD",
                    default: false
                },
                {
                    keyword: "SHUTOFF",
                    value: "SHUTOFF",
                    default: false
                },
                {
                    keyword: "PAUSED",
                    value: "PAUSED",
                    default: false
                },
                {
                    keyword: "SUSPENDED",
                    value: "SUSPENDED",
                    default: false
                },
                {
                    keyword: "HARD REBOOT",
                    value: "HARD_REBOOT",
                    default: false
                },
                {
                    keyword: "ERROR",
                    value: "ERROR",
                    default: false
                },
                {
                    keyword: "VERIFY RESIZE",
                    value: "VERIFY_RESIZE",
                    default: false
                },
            ]
        },
    ],
    group2: [
        {
            field_key: "ip",
            field_type: "string", 
            required: false,
            label: "ipv4AddressFormFieldLabel"
        },
        {
            field_key: "ip6",
            field_type: "string", 
            required: false,
            label: "ipv6AddressFormFieldLabel"
        },
        {
            field_key: "access_ip_v4",
            field_type: "string", 
            required: false,
            label: "accessIPv4FormFieldLabel"
        },
        {
            field_key: "access_ip_v6",
            field_type: "string", 
            required: false,
            label: "accessIPv6FormFieldLabel"
        },
    ],
    group3: [
        {
            field_key: "changes-since",
            field_type: "datetime", 
            required: false,
            label: "changesSinceFormFieldLabel"
        },
        {
            field_key: "created_at",
            field_type: "datetime", 
            required: false,
            label: "createTimeHeaderLabel"
        }
    ]
}

export const serverSortFields = [
    {
        field_key: "display_name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "power_state",
        label: "powerStateFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "access_ip_v4",
        label: "accessIPv4FormFieldLabel"
    },
    {
        field_key: "access_ip_v6",
        label: "accessIPv6FormFieldLabel"
    }
]