import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { firewallRuleDataForm } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const FirewallRuleAddV20 = (props) => {
  const { handleDataFetch } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successFirewallRuleAdd, setSuccessFirewallRuleAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const firewallRulesUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessFirewallRuleAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddFirewallRuleFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in firewallRuleDataForm) {
          if (
              firewallRuleDataForm[n].field_type === "string" || 
              firewallRuleDataForm[n].field_type === "select"
              ) {
              new_form_data[firewallRuleDataForm[n].field_key] = firewallRuleDataForm[n].default_value ? 
              firewallRuleDataForm[n].default_value : 
              ""
          } else if (firewallRuleDataForm[n].field_type === "bool") {
              new_form_data[firewallRuleDataForm[n].field_key] = firewallRuleDataForm[n].default_value ? 
              firewallRuleDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (firewallRuleDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (firewallRuleDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }


  const getDataForm = () => {
      let form = [...firewallRuleDataForm]
      if (formData.protocol !== "tcp" && formData.protocol !== "udp") {
          form = form.filter(item => 
              item.field_key !== "source_from_port" &&
              item.field_key !== "source_to_port" &&
              item.field_key !== "destination_from_port" &&
              item.field_key !== "destination_to_port"
          )
      }
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  
                  form_field_options["item_titles"] = defaultTexts
                  form_field_options = {...form_field_options, ...field}
                  delete form_field_options["label"]
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in firewallRuleDataForm) {
          if (firewallRuleDataForm[n].required && !formData[firewallRuleDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[firewallRuleDataForm[n].field_key] = {}
              updatedDataFormOptions[firewallRuleDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[firewallRuleDataForm[n].field_key]["errorText"] = defaultTexts[firewallRuleDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const formatData = () => {
      let data = {}
      data["project_id"] = formData.project_id
      data["name"] = formData.name
      data["description"] = formData.description
      data["ip_version"] = formData.ip_version
      if (formData.protocol === "any") {
          data["protocol"] = null
      } else {
          data["protocol"] = formData.protocol
      }
      if (formData.source_ip_address.length === 0) {
          if (formData.ip_version === "4") {
              data["source_ip_address"] = "0.0.0.0/0"
          } else {
              data["source_ip_address"] = "::/0"
          }
      } else {
          data["source_ip_address"] = formData.source_ip_address
      }
      if (formData.destination_ip_address.length === 0) {
          if (formData.ip_version === "4") {
              data["destination_ip_address"] = "0.0.0.0/0"
          } else {
              data["destination_ip_address"] = "::/0"
          }
      } else {
          data["destination_ip_address"] = formData.destination_ip_address
      }
      if (formData.protocol === "tcp" || formData.protocol === "udp") {
          if (formData.source_from_port > 0 && formData.source_to_port > 0) {
              data["source_port"] = `${formData.source_from_port}:${formData.source_to_port}`
          } else if (formData.source_from_port > 0) {
              data["source_port"] = formData.source_from_port
          }
          if (formData.destination_from_port > 0 && formData.destination_to_port > 0) {
              data["destination_port"] = `${formData.destination_from_port}:${formData.destination_to_port}`
          } else if (formData.destination_from_port > 0) {
              data["destination_port"] = formData.destination_from_port
          }
      }
      data["action"] = formData.action
      data["shared"] = formData.shared
      data["enabled"] = formData.enabled

      return data
  }

  const onAddFirewallRule = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          const formatted_data = formatData()
          
          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}`
          const method = "POST"
          const firewallRule_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {firewall_rule: formatted_data},
              token,
          })
          if (firewallRule_response.status_code === firewallUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddFirewallRuleFormReset()
              setFormDataOptions({})
          } else {
              const error_response = firewallUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === firewallRule_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: firewallRule_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = firewallUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: firewallRule_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successFirewallRuleAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in firewallRuleDataForm) {
              if (
                  firewallRuleDataForm[n].field_type === "string" || 
                  firewallRuleDataForm[n].field_type === "select"
                  ) {
                  new_form_data[firewallRuleDataForm[n].field_key] = firewallRuleDataForm[n].default_value ? 
                  firewallRuleDataForm[n].default_value : 
                  ""
              } else if (firewallRuleDataForm[n].field_type === "bool") {
                  new_form_data[firewallRuleDataForm[n].field_key] = firewallRuleDataForm[n].default_value ? 
                  firewallRuleDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddFirewallRule}
        formReset={handleAddFirewallRuleFormReset}
        customTexts={{
          title: defaultTexts.addFirewallRuleDialogTitle
        }}
      />

      {
        successFirewallRuleAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successFirewallRuleAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successFirewallRuleAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default FirewallRuleAddV20