import { createSlice } from '@reduxjs/toolkit'
import { commonTexts } from '../../lang/en/components/common/texts'

export const initialState = {
  notPurchasedServiceWidgetTexts: null,
  langTexts: { ...commonTexts },
}

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    setNotPurchasedServiceWidgetTexts: (state, action) => {
      state.notPurchasedServiceWidgetTexts = action.payload.notPurchasedServiceWidgetTexts
    },
    setLangTexts: (state, action) => {
      state.langTexts = action.payload.langTexts
    },
    textsReset: () => {
      return { ...initialState }
    }
  }
})

export const { 
  setNotPurchasedServiceWidgetTexts,
  setLangTexts,
  textsReset
} = textsSlice.actions

export default textsSlice.reducer