import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import CustomTable from '../../../_common/CustomTable'
import { billingPaymentsDataSchema } from '../../../../_data/billing/payments/payments'
import useWindowDimensions from '../../../_common/WindowDimensions'

const PaymentsTable = (props) => {
  const { paymentsData, setPaymentsData, sortParams, sortHandler, deselectAll, setDeselectAll } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
  
  return (
    <Box
      sx={{
        width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
      }}
    >
      <CustomTable
        withPagination={false}
        withCheckbox={false}
        withActions={false}
        withSorting={false}
        defaultDataTexts={{ ...defaultTexts }} 
        tableHeaders={billingPaymentsDataSchema}      
        columns={[...billingPaymentsDataSchema]}
        handleRowSelection={() => {}}   
        dataRows={paymentsData}
        setDataRows={setPaymentsData}      
        sortHandler={sortHandler}
        selfSorting={true}
        sortParams={sortParams}
        deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
        showObjectInCell={true}
      />
    </Box>    
  )
}

export default PaymentsTable