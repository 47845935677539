import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { volumeDataSchema, volumesSortFields } from '../../../../../_data/openstack/cinder/volumes/v3';
import { setVolumesTableConfig } from '../../../../../store/reducers/blockStorageCinderSlice';
import Box from '@mui/material/Box'
import useWindowDimensions from '../../../../_common/WindowDimensions'

const handleTableColumnsConfig = (columns_schema,api_version) => {
    let current_api_version = api_version
    if (!api_version) {
        current_api_version = "3.0"
    }

    let schema = columns_schema.filter(f => 
        (!f.min_api_version ||  parseFloat(f.min_api_version) <= parseFloat(current_api_version))
    )
    return schema
}

const VolumesTableV3 = (props) => {
	const { volumesData, setVolumesData, sortParams, deselectAll, setDeselectAll, isSuspended } = props
	const { handleRowSelection } = props
	const {currentAction} = props
	const {setCurrentAction}  = props
	const { actionsTexts }  = props
	const { actionsList } = props
	const { sortHandler, setSelectedVolumeIds } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
	
	const volumesTableConfig = useSelector(
			state => state.blockStorageCinder.volumesTableConfig)
	const rowsPerPage = useSelector(
			state => state.blockStorageCinder.volumesTableConfig.filter(
			versions => versions.api_version === "v3")[0].rows_per_page)
	const volumesTableHeaders = useSelector(
			state => state.blockStorageCinder.volumesTableConfig.filter(
					versions => versions.api_version === "v3")[0].columns)
	const cinderMaxApiVersion = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					versions => versions.api_version === "v3")[0].max_api_version)

	const volumesTableColumns = handleTableColumnsConfig(volumeDataSchema,cinderMaxApiVersion)
	
	const identityField = volumesTableColumns.filter(
			item => item.is_id_field)[0].field_key

	const dispatch = useDispatch();

	const handleVolumeTableConfig = (value_list) => {
			let schema = []
			if (volumesData.length > 0) {
					const v_keys = Object.keys(volumesData[0])
					schema = volumeDataSchema.filter(
							n => v_keys.includes(n.field_key))
			} else {
					schema = [...volumeDataSchema]
			}
			let newTableConfig = schema.filter(
					n => value_list.includes(n.field_key))
			const new_data = volumesTableConfig.map(version => {
					if (version.api_version === "v3") {
							let new_item = {...version}
							new_item.columns = [...newTableConfig]
							return new_item
					} else {
							return version
					}
			})
			dispatch(setVolumesTableConfig({
					volumesTableConfig: new_data
			}))
	}
	
	const handleRowsPerPageChange = (rows_num) => {
			const new_data = volumesTableConfig.map(version => {
					if (version.api_version === "v3") {
							let new_item = {...version}
							new_item.rows_per_page = rows_num
							return new_item
					} else {
							return version
					}
			})
			dispatch(setVolumesTableConfig({
					volumesTableConfig: new_data
			}))
	}
	
	return (
		<Box
			sx={{
				width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
			}}
		>
					<CustomTable
							withPagination={false}
							withCheckbox={true}
							withActions={true}
							withSorting={true}
							defaultDataTexts={{...defaultTexts}} 
							tableHeaders={
									volumesTableHeaders.length > 0 ? 
									volumesTableHeaders : 
									[...volumesTableColumns]}
							tableHeadersConfigHandler={handleVolumeTableConfig}
							identityField={identityField}
							columns={[...volumesTableColumns]}
							rowsPerPage={rowsPerPage}
							handleRowsPerPageChange={handleRowsPerPageChange}
							dataRows={volumesData}
							setDataRows={setVolumesData}
							handleRowSelection={handleRowSelection}
							currentAction={currentAction}
							setCurrentAction={setCurrentAction}
							actionsTexts={actionsTexts}
							actionsList={actionsList}
							sortFields={volumesSortFields}
							sortHandler={sortHandler}
							selfSorting={true}
							setSelectedIds={setSelectedVolumeIds}
							sortParams={sortParams}
							deselectAll={deselectAll}
							setDeselectAll={setDeselectAll}
							isSuspended={isSuspended}
							showObjectInCell={true}
					/>
			</Box>
	)
}

export default VolumesTableV3