import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CustomText from './CustomText'
import { setNetworkMenuMode } from '../../store/reducers/settingsSlice'
import { TiArrowMinimise } from "react-icons/ti"
import { TiArrowMaximise } from "react-icons/ti"

const ServiceDoubleSubmenu = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { parent, serviceMenu, currentTab, selectedItems, allItems, onClick } = props
  
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const menuMode = useSelector(state => state.settings.networkMenuMode)
  
  const [elWidth, setElWidth] = useState(null)
  const [forceAutoWidth, setForceAutoWidth] = useState(false)

  const elRef = useRef(null)
  const oneRef = useRef(null)

  const getMinimumWidth = () => {
    let wordCount = 0
    serviceMenu.forEach(item => wordCount += defaultTexts[item.keyword].length)
    return wordCount * 9
  }

  const buttonClick = (navigation) => {
    onClick(navigation)
  }

  const showDropdown = () => {
    setElWidth(50)
    setTimeout(() => {
      dispatch(setNetworkMenuMode({ networkMenuMode: { ...menuMode, [parent]: true } }))
    }, serviceMenu.length * 100)
  }

  const showTabs = () => {
    dispatch(setNetworkMenuMode({ networkMenuMode: { ...menuMode, [parent]: false } }))
    setTimeout(() => {
      setElWidth(getMinimumWidth())
      setForceAutoWidth(true)
    }, 100)
  }

  useEffect(() => {
    setElWidth(null)
  }, [serviceMenu])

  useEffect(() => {
    if (elRef && elRef.current && elRef.current.offsetWidth && !elWidth) {
      setElWidth(elRef.current.offsetWidth)
    }
  }, [elWidth])

  useEffect(() => {
    if (forceAutoWidth) {
      setTimeout(() => {
        setElWidth(null)
        setForceAutoWidth(false)
      }, serviceMenu.length * 100)     
    }
  }, [forceAutoWidth, serviceMenu])

  useEffect(() => {
    const findMenuObject = serviceMenu.find(item => item.is_active && item.navigation === currentTab)
    if (!findMenuObject) {
      onClick(currentTab)
    }    
  }, [currentTab, serviceMenu, onClick])

  useEffect(() => {
    if (menuMode && menuMode[parent] && oneRef && oneRef.current && oneRef.current.offsetWidth) {
      setElWidth(oneRef.current.offsetWidth)
    }
  }, [menuMode, parent])
  
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="center"      
    >
      {
        (menuMode && menuMode[parent]) ? (
          <Box
            sx={{
              display: 'inline-block',
              color: theme.palette.primary.main,
              marginRight: '10px',
            }}
            ref={oneRef}
          >
            <CustomText sx={{ fontSize: '16px' }}>
              { (serviceMenu.length > 0 && currentTab) && defaultTexts[serviceMenu.find(item => item.navigation === currentTab).keyword] }
              { ` ${selectedItems}/${allItems}` }
            </CustomText>      
          </Box>
        ) : (
          <Box
            sx={{
              width: elWidth ? `${elWidth}px` : 'auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'hidden',
              transition: 'all 1s',
            }}
            ref={elRef}
          >
            {
              serviceMenu.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'inline-block',
                    color: theme.palette.customGrayDark,
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      color: currentTab === item.navigation ? theme.palette.primary.main : theme.palette.customGrayDark,
                      cursor: currentTab === item.navigation ? 'auto' : 'pointer',
                      marginRight: '10px',
                      '&:hover': {
                        opacity: currentTab === item.navigation ? 1 : 0.7,
                      }
                    }}
                    onClick={() => buttonClick(item.navigation)}
                  >
                    <CustomText sx={{ fontSize: '17px' }}>
                      {defaultTexts[item.keyword]}
                    </CustomText>
                  </Box>

                  {
                    index < serviceMenu.length - 1 &&
                    <Box
                      sx={{
                        display: 'inline-block',
                        fontSize: '17px',
                        marginRight: '10px',
                      }}
                    >|</Box>
                  }										
                </Box>
              ))
            }
          </Box>
        )
      }

      {
        (menuMode && menuMode[parent]) ? (
          <Box
            sx={{
              color: theme.palette.customGrayDark,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={showTabs}
          >
            <TiArrowMaximise size={32} />
          </Box>
        ) : (
          <Box
            sx={{
              color: theme.palette.customGrayDark,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={showDropdown}
          >
            <TiArrowMinimise size={32} />
          </Box>
        )
      }
    </Stack>
  )
}

export default ServiceDoubleSubmenu