import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  drawerOpened: false
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerOpened: (state, action) => {
        state.drawerOpened = action.payload.drawerOpened
    },
    drawerStateReset: () => {
      return { ...initialState }
    }
  }
})

export const { setDrawerIndex, setDrawerSubIndex, setDrawerOpened, drawerStateReset } = drawerSlice.actions

export default drawerSlice.reducer