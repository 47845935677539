import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import SecurityGroupSpecsV20 from './securityGroupSpecsV2.0';
import SecurityGroupRulesV20 from './securityGroupRulesV2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import { openStackServices } from '../../../../../../config/openStackConstants';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from '../../../../../_common/CustomDialog';
import { securityGroupDataUpdateForm, secGroupRuleDataForm } from '../../../../../../_data/openstack/neutron/security/v2.0';
import { ipv4CidrChecker, ipv6CidrChecker } from '../../../../../_common/common_helpers';

const SERVICE_NAME = openStackServices.networkService

const SecurityGroupDetailV20 = (props) => {
	const theme = useTheme()
	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const { selectedRow, setSelectedRow, setSelectedSecurityGroup, handleDataFetch } = props
	const { handleDelete, handleStatefulStateChange } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const token = useSelector(state => state.profile.x_auth_token)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);

	const [securityGroupActions, setSecurityGroupActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [securityGroupUpdateData, setSecurityGroupUpdateData] = useState({})
	const [updateSecurityGroupDialogOpen, setUpdateSecurityGroupDialogOpen] = useState(false)
	const [addressGroups, setAddressGroups] = useState([])
	const [securityGroups, setSecurityGroups] = useState([])
	const [addRuleDialogOpen, setAddRuleDialogOpen] = useState(false);
	const [secGroupRuleData, setSecGroupRuleData] = useState({});
	const [secGroupRuleDataOptions, setSecGroupRuleDataOptions] = useState({})
		
	const [securityGroupSubMenu, setSecurityGroupSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/security-group-details", is_active: true},
			{keyword: "submenuSecGroupRules", navigation: "/security-group-rules", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/security-group-details")
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const securityGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)
	const addressGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.addressGroupsUrl)[0].url)
	const securityGroupRulesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.securityGroupRulesUrl)[0].url)

	const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}/${selectedRow.id}`

	const getFormattedSecurityGroupData = useCallback((data) => {
			let formatted_data = {...data}
			return formatted_data
	},[])

	const handleFormDataValidation = () => {
			let validation_faild = true
			let updatedDataFormOptions = {...secGroupRuleDataOptions}
			for (let n in secGroupRuleDataForm) {
					if (secGroupRuleDataForm[n].required && !secGroupRuleData[secGroupRuleDataForm[n].field_key]) {
							validation_faild = false
							updatedDataFormOptions[secGroupRuleDataForm[n].field_key] = {}
							updatedDataFormOptions[secGroupRuleDataForm[n].field_key]["error"] = true
							updatedDataFormOptions[secGroupRuleDataForm[n].field_key]["errorText"] = defaultTexts[secGroupRuleDataForm[n].error_label]
					}
			}

			if (secGroupRuleData.protocol_selection === "tcp" || secGroupRuleData.protocol_selection === "udp") {
					if (secGroupRuleData.port_range_min > -1 && secGroupRuleData.port_range_max > -1) {
							if (parseInt(secGroupRuleData.port_range_min) > parseInt(secGroupRuleData.port_range_max)) {
									updatedDataFormOptions["port_range_max"] = {}
									updatedDataFormOptions["port_range_max"]["error"] = true
									updatedDataFormOptions["port_range_max"]["errorText"] = defaultTexts.invalidPortRangeErrorText
							}
					}
			}

			if (secGroupRuleData.remote_ip_prefix.length > 0) {
					let valid_ip = false
					if (secGroupRuleData.ethertype === "ipv4") {
							valid_ip = ipv4CidrChecker(secGroupRuleData.remote_ip_prefix)
					} else {
							valid_ip = ipv6CidrChecker(secGroupRuleData.remote_ip_prefix)
					}
					if (!valid_ip) {
							updatedDataFormOptions["remote_ip_prefix"] = {}
							updatedDataFormOptions["remote_ip_prefix"]["error"] = true
							updatedDataFormOptions["remote_ip_prefix"]["errorText"] = defaultTexts.invalidCIDRErrorText
					}
			}

			setSecGroupRuleDataOptions(updatedDataFormOptions)
			return validation_faild
	}

	const formatRuleData = () => {
			let updated_data = {...secGroupRuleData}
			updated_data["security_group_id"] = selectedRow.id
			if (parseInt(secGroupRuleData.port_range_min) === -1 || 
					secGroupRuleData.protocol_selection === "other" || 
					secGroupRuleData.protocol_selection === "any") {
					delete updated_data.port_range_min
					delete updated_data.port_range_max
			}
			if (parseInt(secGroupRuleData.port_range_min) > -1 && parseInt(secGroupRuleData.port_range_max) === -1) {
					updated_data.port_range_max = secGroupRuleData.port_range_min
			}
			if (secGroupRuleData.protocol_selection === "icmp") {
					if (parseInt(secGroupRuleData.icmp_type) > -1) {
							updated_data["port_range_min"] = secGroupRuleData.icmp_type
					}
					if (parseInt(secGroupRuleData.icmp_code) > -1) {
							updated_data["port_range_max"] = secGroupRuleData.icmp_code
					}
			}
			delete updated_data.icmp_type
			delete updated_data.icmp_code
			if (secGroupRuleData.protocol_selection !== "other" && secGroupRuleData.protocol_selection !== "any") {
					updated_data.protocol = secGroupRuleData.protocol_selection
			}
			if (secGroupRuleData.protocol_selection === "any") {
					updated_data.protocol = 0
			}
			delete updated_data.protocol_selection
			if (secGroupRuleData.remote_type === "security_group") {
					delete updated_data.remote_address_group_id
					delete updated_data.remote_ip_prefix
			} else if (secGroupRuleData.remote_type === "address_group") {
					delete updated_data.remote_group_id
					delete updated_data.remote_ip_prefix
			}  else if (secGroupRuleData.remote_type === "ip_prefix") {
					delete updated_data.remote_group_id
					delete updated_data.remote_address_group_id
					if (secGroupRuleData.remote_ip_prefix.length === 0) {
							updated_data.remote_ip_prefix = secGroupRuleData.ethertype === "ipv4" ? "0.0.0.0/0" : "::/0"
					}
			}
			delete updated_data.remote_type
			return updated_data
	}

	const onAddSecurityGroupRule = async () => {
			const data_is_valid = handleFormDataValidation()
			if (!data_is_valid) {
					return false
			}
			const formatted_data = formatRuleData()
			console.log(formatted_data)
			
			const method = "POST"
			const securityGroup_response = await openstackRequest({
					url: `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupRulesUrl}`, 
					method: method, 
					data: {security_group_rule: formatted_data},
					token,
			})
			if (securityGroup_response.status_code === securityUrlResponses.post.success_response.status_code) {
					setCurrentAction("")
					handleAddRuleDialogClose()
					handleDataFetch()
			} else {
					const error_response = securityUrlResponses.post.error_response.filter(
							error_item => error_item.status_code === securityGroup_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					} else {
							const error_response = securityUrlResponses.post.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					}
			}			
	}

	const handleSecurityGroupDetailTabChange = useCallback((navigation) => {
			let newSecurityGroupSubmenuData = securityGroupSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setSecurityGroupSubMenu(newSecurityGroupSubmenuData)
			setCurrentTab(navigation)
	},[
			securityGroupSubMenu
	])

	const handleAddRuleDialogOpen = () => {
			setAddRuleDialogOpen(true)
	}

	const handleAddRuleDialogClose = () => {
			setSecGroupRuleData({})
			setSecGroupRuleDataOptions({})
			setAddRuleDialogOpen(false)
	}

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}			
	}

	const handleChangeStatefulStateDialogOpen = useCallback(() => {
			handleStatefulStateChange([selectedRow.id])
	},[handleStatefulStateChange, selectedRow])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			let form_data = [...form]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getSecGroupRuleForm = () => {
			let form = [...secGroupRuleDataForm]
			if (secGroupRuleData.protocol_selection !== "other") {
					form = form.filter(item => item.field_key !== "protocol")
			} else {
					form = form.filter(item => 
							item.field_key !== "icmp_type" && 
							item.field_key !== "icmp_code" &&
							item.field_key !== "port_range_min" && 
							item.field_key !== "port_range_max"
					)
			}
			if ((!Object.keys(secGroupRuleData).includes("protocol_selection") || 
					secGroupRuleData.protocol_selection.length === 0) || 
					secGroupRuleData.protocol_selection === "any") {
					form = form.filter(item => 
							item.field_key !== "port_range_min" && 
							item.field_key !== "port_range_max" &&
							item.field_key !== "icmp_type" && 
							item.field_key !== "icmp_code" &&
							item.field_key !== "protocol"
					)
			}

			if (secGroupRuleData.protocol_selection === "tcp" || secGroupRuleData.protocol_selection === "udp") {
					form = form.filter(item => 
							item.field_key !== "icmp_type" && 
							item.field_key !== "icmp_code"
					)
			}

			if (secGroupRuleData.protocol_selection === "icmp") {
					form = form.filter(item => 
							item.field_key !== "port_range_min" && 
							item.field_key !== "port_range_max"
					)
			}

			if (secGroupRuleData.remote_type !== "security_group") {
					form = form.filter(item => item.field_key !== "remote_group_id")
			}
			if (secGroupRuleData.remote_type !== "address_group") {
					form = form.filter(item => item.field_key !== "remote_address_group_id")
			}
			if (secGroupRuleData.remote_type !== "ip_prefix") {
					form = form.filter(item => item.field_key !== "remote_ip_prefix")
			}

			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {...secGroupRuleDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									if (field.field_key === "remote_group_id") {
											const sec_group_filter = securityGroups.map(sg => {
													return {keyword: sg.name, value: sg.id, default: false}
											})
											form_field_options["items"] = sec_group_filter
									}
									if (field.field_key === "remote_address_group_id") {
											const addr_group_filter = addressGroups.map(ag => {
													return {keyword: ag.name, value: ag.id, default: false}
											})
											form_field_options["items"] = addr_group_filter
									}
									return (
											getFormFieldComponent(
													field,
													secGroupRuleData,
													handleSecGroupRuleDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleSecGroupRuleDataChange = (event,field_key) => {
			let new_form_data_options = {...secGroupRuleDataOptions}
			delete new_form_data_options[field_key]
			setSecGroupRuleDataOptions(new_form_data_options)
			let new_form_data = {...secGroupRuleData}
			if (secGroupRuleDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (secGroupRuleDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setSecGroupRuleData(new_form_data)
	}

	const handleSecurityGroupUpdateDataChange = (event,field_key) => {
			let new_form_data = {...securityGroupUpdateData}
			if (securityGroupDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (securityGroupDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setSecurityGroupUpdateData(new_form_data)
	}

	const handleUpdateSecurityGroupDialogOpen = () => {
			setUpdateSecurityGroupDialogOpen(true)
	}

	const handleUpdateSecurityGroupDialogClose = () => {
			setUpdateSecurityGroupDialogOpen(false)
	}

	const onSecurityGroupUpdate = useCallback(async (event,data) => {
			let updated_data = {...securityGroupUpdateData}
			if (data) {
					updated_data = data
			}

			const method = "PUT"
			const securityGroup_response = await openstackRequest({
					url: common_url, 
					method: method, 
					data: {security_group: updated_data},
					token,
			})
			if (securityGroup_response.status_code === securityUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleUpdateSecurityGroupDialogClose()
					handleDataFetch()
			} else {
					const error_response = securityUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === securityGroup_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					} else {
							const error_response = securityUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					}
			}

	},[
			common_url,
			token,
			securityGroupUpdateData,
			handleDataFetch
	])

	const onRuleRemove = async (rule_id) => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			const method = "DELETE"
			const securityGroup_response = await openstackRequest({
					url: `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupRulesUrl}/${rule_id}`, 
					method: method,
					token,
			})
			if (securityGroup_response.status_code === securityUrlResponses.delete.success_response.status_code) {
					handleDataFetch()
			} else {
					const error_response = securityUrlResponses.delete.error_response.filter(
							error_item => error_item.status_code === securityGroup_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					} else {
							const error_response = securityUrlResponses.delete.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: securityGroup_response.error
							}
							setError(errorObject)
					}
			}
		}					
	}

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			securityGroupActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			let securityGroup_actions = []
			let new_action = {}
			new_action["value"] = "update_security-group"
			new_action["action"] = handleUpdateSecurityGroupDialogOpen
			new_action["keyword"] = "securityGroupUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			securityGroup_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "change_stateful_state"
			new_action["action"] = handleChangeStatefulStateDialogOpen
			new_action["keyword"] = "changeStatefulStateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			securityGroup_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "add_rule"
			new_action["action"] = handleAddRuleDialogOpen
			new_action["keyword"] = "addSecGroupRuleActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			securityGroup_actions.push({...new_action})
			
			setSecurityGroupActions(securityGroup_actions)
	},[
			selectedRow,
			handleChangeStatefulStateDialogOpen
	])

	useEffect(() => {
			if (Object.keys(securityGroupUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in securityGroupDataUpdateForm) {
							new_form_data[securityGroupDataUpdateForm[n].field_key] = selectedRow[securityGroupDataUpdateForm[n].field_key]
					}
					setSecurityGroupUpdateData(new_form_data)
			}
	},[securityGroupUpdateData, selectedRow]);

	useEffect(() => {
			if (Object.keys(secGroupRuleData).length === 0) {
					let new_form_data = {}
					for (const n in secGroupRuleDataForm) {
							if (
									secGroupRuleDataForm[n].field_type === "string" || 
									secGroupRuleDataForm[n].field_type === "select"
									) {
									new_form_data[secGroupRuleDataForm[n].field_key] = secGroupRuleDataForm[n].default_value ? 
									secGroupRuleDataForm[n].default_value : 
									""
							} else if (secGroupRuleDataForm[n].field_type === "integer") {
									new_form_data[secGroupRuleDataForm[n].field_key] = -1
							} else if (secGroupRuleDataForm[n].field_type === "bool") {
									new_form_data[secGroupRuleDataForm[n].field_key] = secGroupRuleDataForm[n].default_value ? 
									secGroupRuleDataForm[n].default_value : 
									false
							}
					}
					setSecGroupRuleData(new_form_data)
			}
	},[
			secGroupRuleData
	]);

	useEffect(() => {
			(async () => {

				const method = "GET"
				const securityGroup_response = await openstackRequest({
						url: `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}?project_id=${selectedRow.project_id}`, 
						method: method, 
						token,
				})
				if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
						setSecurityGroups(securityGroup_response.data.security_groups)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			securityGroupsUrl,
			selectedRow,
			token,
	]);

	useEffect(() => {
			(async () => {
				const method = "GET"
				const securityGroup_response = await openstackRequest({
						url: `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}?project_id=${selectedRow.project_id}`, 
						method: method, 
						token,
				})
				if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
						setAddressGroups(securityGroup_response.data.address_groups)
				}
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			addressGroupsUrl,
			selectedRow,
			token,
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])
	
	useEffect(() => {
		setCurrentTab('/security-group-details')
		setSecurityGroupSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/security-group-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={securityGroupSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleSecurityGroupDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedSecurityGroup}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/security-group-details" &&
					<SecurityGroupSpecsV20
						securityGroupData={getFormattedSecurityGroupData(selectedRow)}
					/>
				}

				{
					currentTab === "/security-group-rules" &&
					<SecurityGroupRulesV20
						securityGroupRules={selectedRow.security_group_rules}
						securityGroups={securityGroups}
						addressGroups={addressGroups}
						onRuleRemove={onRuleRemove}
					/>
				}
			</Box>

			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
						padding: '0px 20px 0px 12px',
					}}
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={securityGroupActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[securityGroupActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			}

			<CustomDialog
					open={updateSecurityGroupDialogOpen}
					onClose={handleUpdateSecurityGroupDialogClose}
					dialogTitle={{
							title: defaultTexts.updateSecurityGroupActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onSecurityGroupUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							securityGroupDataUpdateForm,
							{},
							securityGroupUpdateData,
							handleSecurityGroupUpdateDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={addRuleDialogOpen}
					onClose={handleAddRuleDialogClose}
					dialogTitle={{
							title: defaultTexts.addSecurityGroupRuleActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onAddSecurityGroupRule, 
							sx: {color: 'primary.main'}}]}
			>
					{getSecGroupRuleForm()}
			</CustomDialog>

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
  )
}

export default SecurityGroupDetailV20