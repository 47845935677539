import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import constants from '../../config/constants';
import request from '../../_network/request'
import useWindowDimensions from '../_common/WindowDimensions';
import { coreServiceURLs } from '../../_network/apiUrls';
import CustomBackdrop from '../_common/CustomBackdrop';
import CustomText from '../_common/CustomText';
import CustomTextField from '../_common/_form_fields/CustomTextField';

const CustomerSupportContent = () => {
  const theme = useTheme()
  const { width, height } = useWindowDimensions()

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const mode = useSelector(state => state.settings.uiMode)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const region_name = useSelector(state => state.settings.regionName)
  const domain = useSelector(state => state.settings.openstackDomain)
  const username = useSelector(state => state.profile.username)

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [hasSent, setHasSent] = useState(false)
  const [sentError, setSentError] = useState(false)

  let formWidth = width > 900 ? '600px' : '100%'

  const cardStyle = {
    width: '100%',
    height: `calc(${height - 160}px)`,
    background: mode === 'light' ? theme.palette.customWhite : undefined,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px',
    padding: '20px',
    margin: '0px 20px',
  }

  const labelStyle = {
		fontSize: '16px',
		fontWeight: '600',
    marginBottom: '10px',
	}

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  }

  const handleClickSend = async () => {
    setIsLoading(true)

    const postData = {
      group: 'openstack_admin',
      type: 'issue_report',
      client_id: clientAccountID,
      alert_data: {
        name: username,
        region_name,
        domain,
        data: { message }
      }
    }

    const url = `${constants.core_service_domain}/${coreServiceURLs.getOpenStackIssueReport}`

    const request_data = {
      url,
      method: 'POST',
      data: postData,
    }

    const response = await request(request_data)
    
    if (response && response.status_code && response.status_code < 400) {
      setHasSent(true)
      setSentError(false)
      setMessage('')
    } else {
      setHasSent(false)
      setSentError(true)
    }

    setIsLoading(false)
  }

  return (
    <>
      { isLoading && <CustomBackdrop open={isLoading} /> }

      <Box sx={{ padding: '20px' }}>
        <CustomText sx={{ color: theme.palette.primary.main, fontSize: '18px' }}>
          {defaultTexts.customerSupportNavLink}
        </CustomText>
      </Box>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Paper sx={cardStyle}>
          <Box sx={labelStyle}>{defaultTexts.reportAnIssue}</Box>

          <Box
            sx={{
              width: formWidth,
            }}
          >
            { hasSent && <Alert severity="success">{defaultTexts.yourMessageHasBeenSent}</Alert> }

            { sentError && <Alert severity="error">{defaultTexts.anErrorOccured}</Alert> }

            <CustomTextField
              currentValue={message}
              setCurrentValue={handleChangeMessage}
              label={defaultTexts.message}
              multiline={true}
              minRows={12}
              sx={{ width: '100%', margin: '20px 0px' }}
            />

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Button
                onClick={handleClickSend}
                variant='contained'
                size='large'
                sx={{ color: 'white' }}
              >
                {defaultTexts.send}
              </Button>
            </Stack>
          </Box>                    
        </Paper>
      </Stack>
    </>
  )
}

export default CustomerSupportContent