import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const RouterSpecsV20 = (props) => {
	const theme = useTheme()
	
	const { routerData, selectedRow, handleNavigateToNetwork } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '300px',
		justifyContent: 'flex-start',
	}
	
	return (
			<React.Fragment>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.name ? routerData.name : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData ? routerData.id : ""}
															</CustomText>
													</Stack>
													<Divider />													
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.statusFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.status}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.adminStateFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.admin_state_up ? 
																			defaultTexts.formValueYes : 
																			defaultTexts.formValueNo}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.revisionNumberFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.revision_number}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
															onClick={() => handleNavigateToNetwork(selectedRow.external_gateway_info.network_id, 'name')}
															sx={{cursor: "pointer"}}
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.networkFormFieldLabel}
															</CustomText>
															<CustomText size="p" sx={{color: "primary.main"}}>
																	{routerData.external_gateway_info ? 
																			routerData.external_gateway_info.network_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.enableSNatFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.external_gateway_info && routerData.external_gateway_info.enable_snat ? 
																			defaultTexts.formValueYes : 
																			defaultTexts.formValueNo}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.externalFixedIPFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.external_gateway_info ? 
																			routerData.external_gateway_info.external_fixed_ips[0].ip_address : 
																			"-"
																	}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{routerData.description ? routerData.description : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.createTimeHeaderLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(routerData.created_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.updatedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(routerData.updated_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
			</React.Fragment>
	)
}

export default RouterSpecsV20