import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import CustomText from '../CustomText';
import { ClickAwayListener } from '@mui/material';
import parse from 'html-react-parser';

const CustomTextField = (props) => {
    const { error, errorText } = props
    const { StartIcon, EndIcon } = props
    const { currentValue, setCurrentValue } = props;
    const { label, multiline, minRows, maxRows } = props;
    const { variant, required, type, size, color, step, min, max } = props;
    const { field_key } = props
    const { withHelp, helpText } = props
    const { sx, withoutLabel } = props;
    const { disabled } = props
    const { index, zIndex, helpTextWidth, helpTextHeight } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [showPassword, setShowPassword] = useState(false)
    const [inputType, setInputType] = useState('text')

    const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
    }
    
    const handleMouseDownPassword = (event) => {
    event.preventDefault()
    }
    
    const passwordEndAdornment = (
        <InputAdornment position='end'>
        <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge='end'
            >
                { showPassword ? <VisibilityOff /> : <Visibility /> }
            </IconButton>
        </InputAdornment>
    )

    const endAdornment = EndIcon ? (
        <InputAdornment position="end">
            {EndIcon}
        </InputAdornment>
    ) : ''

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleHelpTextClose = () => {
        setOpen(false)
    }

    const onChange = (event) => {
        setCurrentValue(event,field_key,index)
    }

    useEffect(() => {
        if (type === 'password' && showPassword) {
            setInputType('text')
        } else if (type) {
            setInputType(type)
        } else {
            setInputType('text')
        }
    }, [type, showPassword])

    if (withHelp) {
        return (
            <Grid 
            container 
            spacing={2} 
            direction="row" 
            alignItems="center"
            justifyContent="start"
        >
            <Grid item xs={10} lg={11}>
                <FormControl 
                    required={required} 
                    error={error} 
                    sx={{width: '100%',...sx}} 
                    variant={variant ? variant : "outlined"}
                >
                    {!withoutLabel && <InputLabel color="primary" size={size ? size : "medium"}>
                        {label}
                    </InputLabel>}
                    <OutlinedInput
                        size={size ? size : "medium"}
                        color={color ? color : "primary"}
                        type={inputType}
                        multiline={multiline ? multiline : false}
                        maxRows={maxRows ? maxRows : 20}
                        inputProps={{
                            max: max ? max : undefined , 
                            min: (min || min === 0) ? min : undefined , 
                            step: step ? step : 1
                        }}
                        value={currentValue}
                        onChange={onChange}
                        disabled={disabled ? disabled : false}
                        startAdornment={
                            StartIcon && <InputAdornment position="start">
                                {StartIcon}
                            </InputAdornment>
                        }
                        endAdornment={ type === 'password' ? passwordEndAdornment : endAdornment }
                        label={label}
                    />
                    {error && 
                        <FormHelperText>
                            {errorText}
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <ClickAwayListener onClickAway={handleHelpTextClose}>
                <IconButton 
                    onClick={handleClick('top-start')}
                >
                    <HelpIcon />
                </IconButton>
                </ClickAwayListener>
                <Popper
                    sx={{ zIndex: zIndex ? zIndex : 2200 }}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                >
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper 
                            sx={{
                                width: helpTextWidth ? helpTextWidth : 200, 
                                height: helpTextHeight ? helpTextHeight : undefined, 
                                p: 2, 
                                backgroundColor: "vLightGray",
                            }}
                        >
                            <CustomText 
                                size="p" 
                                sx={{color: "primary.main"}}
                            >
                            {parse(helpText)}
                            </CustomText>
                        </Paper>
                    </Fade>
                    )}
                </Popper>
            </Grid>
        </Grid>
        )
    }

    return (
        <FormControl 
            required={required} 
            error={error} 
            sx={{width: '100%', ...sx}} 
            variant={variant ? variant : "outlined"}
        >
            {!withoutLabel && <InputLabel color="primary" size={size ? size : "medium"}>
                {label}
            </InputLabel>}
            <OutlinedInput
                size={size ? size : "medium"}
                color={color ? color : "primary"}
                type={inputType}
                multiline={multiline ? multiline : false}
                minRows={minRows ? minRows : 5}
                maxRows={maxRows ? maxRows : 20}
                inputProps={{
                    max: max ? max : undefined, 
                    min: (min || min === 0) ? min : undefined,
                    step: step ? step : 1 
                }}
                value={currentValue}
                onChange={onChange}
                disabled={disabled ? disabled : false}
                startAdornment={
                    StartIcon && <InputAdornment position="start">
                        {StartIcon}
                    </InputAdornment>
                }
                endAdornment={ type === 'password' ? passwordEndAdornment : endAdornment }
                label={label}
            />
            {error && 
                <FormHelperText>
                    {errorText}
                </FormHelperText>
            }
        </FormControl>
    )
};

export default CustomTextField;