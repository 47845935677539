import React from 'react'
import { useSelector } from 'react-redux'
import CustomDialog from './CustomDialog'

const SuspendedAlertDialog = (props) => {
  const { isOpen, setIsOpen } = props

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)

  const currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? (billingAccountConfig.currency.name).toUpperCase() : ''

  let error = {}
  error["error_title"] = 'suspendedAlertLabel'
  error["error_message"] = 'suspendedDescriptionLabel'

  if (billingDeposit && billingDeposit.billing_type === 'prepaid') {
    error["error_details"] = defaultTexts.suspendedBalanceZeroLabel + ' ' + currencyName
  }

  if (billingDeposit && billingDeposit.billing_type === 'postpaid' && billingDeposit.amount) {
    error["error_details"] = defaultTexts.suspendedYourDebtIsLabel + ': ' + (billingDeposit.amount.toFixed(2) * -1) + ' ' + currencyName
  }

  const handleErrorDialogClose = () => {
    setIsOpen(false)
  }

  return (
    <CustomDialog
      open={isOpen}
      onClose={handleErrorDialogClose}
      dialogTitle={{
        title: defaultTexts[error.error_title], 
        sx: {color: 'primary.main'}}}
      dialogBody={{
        text: `<span>${defaultTexts[error.error_message]}</span>
          <br>
          <br>
          <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
          <span style="color: orange">
            ${error.error_details}
          </span>`,
        sx: {color: 'text.primary'}}}
    />    
  )
}

export default SuspendedAlertDialog