import { blue, pink, grey } from '@mui/material/colors'
import darkScrollbar from "@mui/material/darkScrollbar"

const getDesignTokens = (mode, primaryColor, secondaryColor) => ({
  palette: {
    mode,
    ...(primaryColor ? {
    primary: {
      main: primaryColor
    }} :
    {primary: {
      ...blue, main: blue[500]
    }}),
    ...(secondaryColor ? {
    secondary: {
      main: secondaryColor
    }} :
    {secondary: {
      ...pink, main: pink['A400'], dark: pink[800]
    }}),
    ...(mode === 'dark' && {background: {
      default: "#15141d",
      paper: "#2c2939",
    }}),
    ...(mode === 'light' ? {
    text: {
      primary: grey[700],
      secondary: grey[600]
    }} :
    {text: {
      primary: '#fff',
      secondary: grey[500]
    }}),
    customGray: '#D9D9D9',
    customGrayDark: '#BDBDBD',
    customGrayLight: '#F5F5F5',
    customBlack: mode === 'light' ? '#15141d' : 'white',
    customWhite: mode === 'light' ? 'white' : '#15141d',
    customLightBlack: "#2c2939",
    mediumGray: '#444057',
    vLightGray: '#F5F5F5',
    customBlueColor: {
      main: '#007FFF',
      light: '#007FFF',
      dark: '#007FFF',
      contrastText: '#ffffff'
    },
    folly: mode === 'light' ? '#F50057' : '#F50057',
    background: {
      default: mode === 'light' ? '#F5F5F5' : '#15141d'
    },    
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            mode === "light"
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400]
                }
              : undefined
          ),
          scrollbarWidth: "thin",
        },
        div: {
          ...darkScrollbar(
            mode === "light"
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400]
                }
              : undefined
          ),
          scrollbarWidth: "thin",
        }
      }
    }
  }
})

export default getDesignTokens