import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup'
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import RoutersTableV20 from './routersTableV2.0';
import RouterDetailV20 from './routerDetailV2.0';
import { routersFilterMenu } from '../../../../../../_data/openstack/neutron/routers/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { routersUrl as routerUrlResponses } from '../../../../../../_api_responses/openstack/neutron/routers/v2.0';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import RouterAddV20 from './routerAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const RoutersWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, currentTab, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	
	const [routersData, setRoutersData] = useState([])
	const [routers, setRouters] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRouter, setSelectedRouter] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [routerDeleteConfirmDialogOpen, setRouterDeleteConfirmDialogOpen] = useState(false);
	const [selectedRouters, setSelectedRouters] = useState([])
	const [routersSortParams, setRoutersSortParams] = useState("")
	
	const [routerFilterQueryParams, setRouterFilterQueryParams] = useState("")
	const [selectedRouterFilter, setSelectedRouterFilter] = useState(routersFilterMenu[0].value)
	const [selectedRouterFilterValue, setSelectedRouterFilterValue] = useState("")
	const [routersFilter, setRoutersFilter] = useState([...routersFilterMenu])

	const [networks, setNetworks] = useState([])
	const [externalNetworks, setExternalNetworks] = useState([])
	const [selectedAdminState,setSelectedAdminState] = useState(true)
	const [changeAdminStateDialogOpen, setChangeAdminStateDialogOpen] = useState(false)

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const routersUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = routersFilterMenu.find(item => item.value === selectedRouterFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		const filterObject = routersFilterMenu.find(item => item.value === selectedRouterFilter)
		return filterObject ? filterObject.items : []
	}

	const handleRouterFilteredSearch = () => {
			if (selectedRouterFilter && selectedRouterFilterValue) {
					setRouterFilterQueryParams(`${selectedRouterFilter}=${selectedRouterFilterValue}`)
			} else {
					setRouterFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleRoutersDataFormatting = useCallback(() => {
			const formatted_data = routersData.map((item) => {
					let new_item = {...item}
					
					if (item.external_gateway_info) {
							const network = networks.filter(n => n.id === item.external_gateway_info.network_id)
							if (network.length > 0) {
									new_item["external_network"] = network[0].name
							} else {
									new_item["external_network"] = "-"
							}
					}
					
					return new_item
			})
			setRouters(formatted_data)
	},[
			routersData,
			networks
	])

	const handleRouterFilterReset = () => {
			setSelectedRouterFilter(routersFilterMenu[0].value)
			setSelectedRouterFilterValue("")
			setRouterFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToNetwork = (network_name, type) => {
		handleDetailCardClose()

		if (type === 'name') {
			navigate("/networks/l2-networking/networks", { state: { network_name } })
		}

		if (type === 'id') {
			navigate("/networks/l2-networking/networks", { state: { network_id: network_name } })
		}			
	}

	const handleNavigateToSubnet = (fixed_ips) => {
		handleDetailCardClose()
		if (fixed_ips && fixed_ips.subnet_id) {
			navigate("/networks/l3-networking/subnets", { state: { subnet_id: fixed_ips.subnet_id } })
		}		
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedRouter(routersData[index].id)
			setSelectedRow(routersData[index])
	},[routersData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedRouter(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleRoutersSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setRoutersSortParams(sort_param)
			handleDataFetch()
	}

	const handleRouterDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === routerUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const handleAdminStateUpdate = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}/${n_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {router: {admin_state_up: selectedAdminState}},
				token,
		})

		if (nt_response.status_code === routerUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onAdminStateUpdate = async() => {
			handleChangeAdminStateDialogClose()
			let err = []
			for (let n in selectedRouters) {
					const resp = await handleAdminStateUpdate(selectedRouters[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onRouterDelete = async () => {
			handleRouterDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedRouters) {
					const resp = await handleRouterDelete(selectedRouters[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onRouterDeleteConfirm = (n_list) => {
			const selected_n_list = routersData.filter(n => 
					n_list.includes(n.id))
			setSelectedRouters([...selected_n_list])
			setRouterDeleteConfirmDialogOpen(true)
	}

	const handleRouterDeleteConfirmDialogClose = () => {
			setRouterDeleteConfirmDialogOpen(false)
	}

	const handleChangeAdminStateDialogClose = () => {
			setChangeAdminStateDialogOpen(false)
	}

	const handleChangeAdminStateDialogOpen = (router_list) => {
			const selected_r_list = routersData.filter(r => 
					router_list.includes(r.id))
			setSelectedRouters([...selected_r_list])
			setChangeAdminStateDialogOpen(true)
	}

	const handleAdminStateChange = (event) => {
			setSelectedAdminState(event.target.checked)
	}

	const getRoutersActionsList = () => {
			let router_actions = []
			let new_action = {}
			new_action["value"] = "router_delete"
			new_action["action"] = onRouterDeleteConfirm
			new_action["keyword"] = "routerDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			router_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "admin_state_update"
			new_action["action"] = handleChangeAdminStateDialogOpen
			new_action["keyword"] = "changeAdminStateActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			router_actions.push({...new_action})
			
			return router_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedRouter(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

								let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}?${routerFilterQueryParams}${routersSortParams}`
								const method = "GET"
								const router_response = await openstackRequest({url:url, method:method, token, })
								
								if (router_response.status_code === routerUrlResponses.get.success_response.status_code) {
										setRoutersData(router_response.data.routers)
										if (selectedRouter) {
												const selected_router = router_response.data.routers.filter(item => item.id === selectedRouter)
												if (selected_router.length > 0) {
														setSelectedRow(selected_router[0])
												}
										}
								} else {
									setRoutersData([])
								}
								
								
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			routersUrl, 
			routerFilterQueryParams,
			dataFetchingRequired,
			token,
			routersSortParams,
			selectedRouter
	]);

	useEffect(() => {
			(async () => {

				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === routerUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
						const external_networks = network_response.data.networks.filter(item => item["router:external"])
						const external_networks_list = external_networks.map(n => {
								return {keyword: n.name, value: n.id, default: false}
						})
						setExternalNetworks(external_networks_list)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl, 
			token,
	]);

	useEffect(() => {
		if (routersData.length > 0) {
			handleRoutersDataFormatting()
		} else {
			setRouters([])
		}
	},[
		routersData,
		handleRoutersDataFormatting
	])

	useEffect(() => {
			let networks_filter = []
			if (networks.length > 0) {
					networks_filter = networks.map(network => {
							return {keyword: network.name, value: network.id, default: false}
					})
			}
			
			let router_filter_menu = routersFilterMenu.map(nt => {
					let new_item = {...nt}
					
					if (nt.value === "network_id") {
							new_item.items = [...networks_filter]
					}
					return new_item
			})

			setRoutersFilter(router_filter_menu)
	},[
			routersData,
			networks
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const router_id = location.state ? location.state.router_id : null
							const router_index = routersData.findIndex(v => v.id === router_id);
							if (router_index !== -1) {
									setTimeout(() => handleDetailCardOpen(router_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			routersData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l3-networking')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedRouterFilterValue('')
	}, [selectedRouterFilter])

	const handleActionRun = () => {
		const actionsList = getRoutersActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedRouters([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getRoutersActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<RouterAddV20
					handleDataFetch={handleDataFetch}
					externalNetworks={externalNetworks}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedRouterFilterValue}
							setCurrentValue={e => setSelectedRouterFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedRouterFilterValue}
							setCurrentValue={setSelectedRouterFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							self_item_titles={selectedRouterFilter === 'network_id' ? true : false}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={routersFilter} 
						currentValue={selectedRouterFilter}
						setCurrentValue={setSelectedRouterFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleRouterFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleRouterFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuL3Networks}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{routersData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<RoutersTableV20 
				routersData={routers}
				setRoutersData={setRouters}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getRoutersActionsList()}
				sortHandler={handleRoutersSorting}
				sortParams={routersSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<RouterDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedRouter={setSelectedRouter}
				handleDataFetch={handleDataFetch}
				handleNavigateToNetwork={handleNavigateToNetwork}
				handleNavigateToSubnet={handleNavigateToSubnet}
				handleDelete={onRouterDeleteConfirm}
				onAdminStateChange={handleChangeAdminStateDialogOpen}
				networks={networks}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
					open={routerDeleteConfirmDialogOpen}
					onClose={handleRouterDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.routerDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.routerDeleteConfirmText}: [${selectedRouters.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onRouterDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
					open={changeAdminStateDialogOpen}
					onClose={handleChangeAdminStateDialogClose}
					dialogTitle={{
							title: defaultTexts.updateRouterAdminStateActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onAdminStateUpdate, 
							sx: {color: 'primary.main'}}]}
				>
					<FormGroup sx={{m:3}}>
							<FormLabel component="legend">
									{defaultTexts.routerAdminStateFormFieldLabel}
							</FormLabel>
							<FormControlLabel
									control={
											<Switch 
													checked={selectedAdminState} 
													onChange={handleAdminStateChange} 
													name="adminState" 
											/>
									}
									label={
											selectedAdminState ? 
											defaultTexts.upFormFieldLabel : 
											defaultTexts.downFormFieldLabel 
									}
							/>
					</FormGroup>
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default RoutersWrapperV20