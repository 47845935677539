import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const VirtualIPSpecsV20 = (props) => {
	const theme = useTheme()
	const { virtualIPData, selectedRow, handleNavigateToNetwork, handleNavigateToPort } = props
	//const { handleNavigateToServer } = props
	//const { handleNavigateToRouter } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	//const handleNavigationToDevice = () => {
	//    if (virtualIPData.port_details && virtualIPData.port_details.device_owner === networkNeutronConstants.computeNovaOwner) {
	//        handleNavigateToServer(selectedRow.port_details.device_id)
	//    } else if (virtualIPData.port_details &&  virtualIPData.port_details.device_owner === networkNeutronConstants.routerInterfaceOwner) {
	//        handleNavigateToRouter(selectedRow.port_details.device_id, "/l3-networks")
	//    }
	//}

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '250px',
		justifyContent: 'flex-start',
	}

	return (
			<React.Fragment>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData.name ? virtualIPData.name : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData ? virtualIPData.id : ""}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
															onClick={() => handleNavigateToNetwork(selectedRow.network_id,"/l2-networks")}
															sx={{cursor: "pointer"}}
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.networkFormFieldLabel}
															</CustomText>
															<CustomText size="p" sx={{color: "primary.main"}}>
																	{virtualIPData.network_id ? 
																			virtualIPData.network_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData.description ? virtualIPData.description : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.revisionNumberFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData.revision_number}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipAddressFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData.local_ip_address}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.localPortFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																<Box
																	sx={{
																		cursor: 'pointer',
																		color: 'primary.main',
																	}}
																	onClick={() => handleNavigateToPort(virtualIPData.local_port_id)}
																>
																	{virtualIPData.local_port_id}
																</Box>																	
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipModeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{virtualIPData.ip_mode}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.createTimeHeaderLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(virtualIPData.created_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.updatedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(virtualIPData.updated_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
			</React.Fragment>
	)
}

export default VirtualIPSpecsV20