import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useWindowDimensions from './WindowDimensions'
import dimensions from '../../config/dimensions'

const SettingsSideDrawer = (props) => {
  const { width, height } = useWindowDimensions()
  const { drawerOpen, handleOpenDrawer, handleCloseDrawer, drawerWidth } = props
  const widthWeight = width < dimensions.tablet_mini.width ? 0.9 : 0.8

  const sxDrawer = {
    width: (drawerWidth && drawerWidth < width) ? `${drawerWidth}px` : `${width * widthWeight}px`,
    height: `${height}px`,
    overflow: 'auto',
    background: 'background.default',
    borderRadius: '12px 0px 0px 12px',
  }

  const handleOpen = () => {
    handleOpenDrawer()
  }

  const handleClose = () => {
    handleCloseDrawer()
  }

  return (
    <SwipeableDrawer
      anchor='right'
      open={drawerOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      PaperProps={{ sx: sxDrawer }}
    >
      {props.children}
    </SwipeableDrawer>
  )
}

export default SettingsSideDrawer