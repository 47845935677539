import { domainDataSchema as domainDataSchemaV3 }  from '../../_data/openstack/identity/domains/v3'
import { regionDataSchema as regionDataSchemaV3 }  from '../../_data/openstack/identity/regions/v3'
import { projectDataSchema as projectDataSchemaV3} from '../../_data/openstack/identity/projects/v3'
import { userDataSchema as userDataSchemaV3} from '../../_data/openstack/identity/users/v3'
import { groupDataSchema as groupDataSchemaV3} from '../../_data/openstack/identity/groups/v3'
import { roleDataSchema as roleDataSchemaV3} from '../../_data/openstack/identity/roles/v3'
import { servicesCatalogDataSchema } from '../../_data/openstack/identity/services_catalog/v3'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  identityKeystoneApiUrls: [
    {
      api_version: "v3",
      urls: [
        {id: "", keyword: 'authTokenUrl', url: "auth/tokens", api_group: 'authTokensApiUrls'},
        {id: "", keyword:'projectsUrl', url: "projects", api_group: 'identityProjectsApiUrls'},
        {id: "", keyword:'domainsUrl', url: "domains", api_group: 'identityDomainsApiUrls'},
        {id: "", keyword:'regionsUrl', url: "regions", api_group: 'identityRegionsApiUrls'},
        {id: "", keyword:'groupsUrl', url: "groups", api_group: 'identityGroupsApiUrls'},
        {id: "", keyword:'systemUrl', url: "system", api_group: 'identitySystemApiUrls'},
        {id: "", keyword:'roleAssignmentsUrl', url: "role_assignments", api_group: 'rolesApiUrls'},
        {id: "", keyword:'rolesUrl', url: "roles", api_group: 'rolesApiUrls'},
        {id: "", keyword:'usersUrl', url: "users", api_group: 'usersApiUrls'},
        {id: "", keyword:'servicesUrl', url: "services", api_group: 'serviceCatalogApiUrls'},
        {id: "", keyword:'endpointsUrl', url: "endpoints", api_group: 'serviceCatalogApiUrls'},
      ]
    }
  ],
  identityDomainsTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 5,
      columns: [
        ...domainDataSchemaV3
      ]
    }
  ],
  identityRegionsTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 5,
      columns: [
        ...regionDataSchemaV3
      ]
    }
  ],
  identityProjectsTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 5,
      columns: [
        ...projectDataSchemaV3
      ]
    }
  ],
  identityUsersTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 10,
      columns: [
        ...userDataSchemaV3
      ]
    }
  ],
  identityGroupsTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 10,
      columns: [
        ...groupDataSchemaV3
      ]
    }
  ],
  identityRolesTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 10,
      columns: [
        ...roleDataSchemaV3
      ]
    }
  ],
  identityServicesCatalogTableConfig: [
    {
      api_version: "v3",
      rows_per_page: 10,
      columns: [
        ...servicesCatalogDataSchema
      ]
    }
  ]
}

const identityKeystoneSlice = createSlice({
  name: 'identityKeystone',
  initialState,
  reducers: {
    setIdentityKeystoneApiUrls: (state, action) => {
        state.identityKeystoneApiUrls = action.payload.identityKeystoneApiUrls
    },
    setIdentityDomainsTableConfig: (state, action) => {
      state.identityDomainsTableConfig = action.payload.identityDomainsTableConfig
    },
    setIdentityRegionsTableConfig: (state, action) => {
      state.identityRegionsTableConfig = action.payload.identityRegionsTableConfig
    },
    setIdentityProjectsTableConfig: (state, action) => {
      state.identityProjectsTableConfig = action.payload.identityProjectsTableConfig
    },
    setIdentityUsersTableConfig: (state, action) => {
      state.identityUsersTableConfig = action.payload.identityUsersTableConfig
    },
    setIdentityGroupsTableConfig: (state, action) => {
      state.identityGroupsTableConfig = action.payload.identityGroupsTableConfig
    },
    setIdentityRolesTableConfig: (state, action) => {
      state.identityRolesTableConfig = action.payload.identityRolesTableConfig
    },
    setIdentityServicesCatalogTableConfig: (state, action) => {
      state.identityServicesCatalogTableConfig = action.payload.identityServicesCatalogTableConfig
    },
    identityKeystoneApiUrlsReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setIdentityKeystoneApiUrls, 
    setIdentityDomainsTableConfig,
    setIdentityRegionsTableConfig,
    setIdentityProjectsTableConfig,
    setIdentityUsersTableConfig,
    setIdentityGroupsTableConfig,
    setIdentityRolesTableConfig,
    setIdentityServicesCatalogTableConfig,
    identityKeystoneApiUrlsReset
} = identityKeystoneSlice.actions

export default identityKeystoneSlice.reducer