import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import useWindowDimensions from './WindowDimensions';
import Constants from '../../config/constants';
import { store } from '../../store/configStore';

export const GoogleReCapcha = forwardRef((props, ref) => {
    const { width } = useWindowDimensions();
    const { error } = props
    const captchaSiteKey = useSelector(state => state.settings.captchaConfig.site_key)
    return (
        <Box sx={{ mt: 1 }}>
            <ReCAPTCHA 
                size={
                    width > 400 ? 
                    "normal" : 
                    "compact"
                } 
                style={{width: '100%'}} 
                sitekey={captchaSiteKey} 
                ref={ref} 
            />
            {error && 
                <FormHelperText 
                    style={{color: 'red'}}
                >
                    Invalid reCaptcha!
                </FormHelperText>
            }
        </Box>
    )
});

export const googleCapchaValidation = async (token) => {
    const state = store.getState()
    const captchaSecretKey = state.settings.captchaConfig.secret_key
    const response = await fetch(
      `${Constants.google_reCapcha_url}?secret=${captchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    
    if (!response.ok) {
        const resErrData = await response.json();
        console.log(resErrData)
        } else {
        const resData = await response.json();
        return resData.success
    }   
  }