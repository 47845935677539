import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import constants from '../../../config/constants';
import CustomText from '../CustomText';
import { ClickAwayListener } from '@mui/material';
import parse from 'html-react-parser';

const CustomCheckboxField = (props) => {
	const { withHelp, helpText, helpIsFile, helpIsUrl } = props
	const { currentValue, setCurrentValue } = props;
	const { label } = props;
	const { required } = props;
	const { field_key } = props;
	const { sx } = props;
	const { disabled } = props
	const { index, zIndex, helpTextWidth, helpTextHeight } = props

	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState();

	const handleClick = (newPlacement) => (event) => {
			setAnchorEl(event.currentTarget);
			setOpen((prev) => placement !== newPlacement || !prev);
			setPlacement(newPlacement);
	};

	const onChange = (event) => {
			setCurrentValue(event,field_key,index)
	}

	const handleHelpTextClose = () => {
			setOpen(false)
	}

	const openFile = () => {
		if (helpIsUrl) {
			window.open(helpText, '_blank')
		}
		
		if (helpIsFile) {
			window.open(constants.s3URL + constants.s3Bucket + helpText, '_blank')
		}
	}

	if (withHelp) {
		return (
			<Grid 
				container 
				spacing={2} 
				direction="row" 
				alignItems="center"
				justifyContent="start"
			>
				<Grid item xs={10} sm={11}>
					<FormControlLabel 
						checked={typeof(currentValue) === "boolean" ? currentValue : false}
						onChange={onChange}
						required={required ? required : false}
						control={<Checkbox disabled={disabled ? disabled : false} />} 
						label={label}
						sx={{...sx}}
					/>
				</Grid>
				<Grid item xs={1}>
					{
						(helpIsFile || helpIsUrl) ?
						<IconButton onClick={openFile}>
							<HelpIcon />
						</IconButton> :
						<>
							<ClickAwayListener onClickAway={handleHelpTextClose}>
								<IconButton onClick={handleClick('top-start')}>
									<HelpIcon />
								</IconButton>
							</ClickAwayListener>
							<Popper
								sx={{ zIndex: zIndex ? zIndex : 1200 }}
								open={open}
								anchorEl={anchorEl}
								placement={placement}
								transition
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Paper 
											sx={{
												width: helpTextWidth ? helpTextWidth : 200, 
												height: helpTextHeight ? helpTextHeight : undefined, 
												p: 2, 
												backgroundColor: "vLightGray",
											}}
										>
											<CustomText 
												size="p" 
												sx={{color: "primary.main"}}
											>
												{parse(helpText)}
											</CustomText>
										</Paper>
									</Fade>
								)}
							</Popper>
						</>
					}
					</Grid>
			</Grid>
		)
	}

	return (
		<FormControlLabel 
			checked={typeof(currentValue) === "boolean" ? currentValue : false}
			onChange={onChange}
			required={required ? required : false}
			control={<Checkbox disabled={disabled ? disabled : false} />} 
			label={label}
			sx={{...sx}}
		/>
	)
}

export default CustomCheckboxField