import React from 'react'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import PaypalCheckout from './PaypalCheckout'

const PaypalWrapper = (props) => {
  const {
    amount,
    paypalOptions,
    handleOpenNotificationDialog,
    setFetchDataReqiured,
    selectedInvoicesData,
    separatePayments,
    invoiceComment,
  } = props
  
  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PaypalCheckout
        amount={amount}
        selectedInvoicesData={selectedInvoicesData}
        separatePayments={separatePayments}
        invoiceComment={invoiceComment}
        handleOpenNotificationDialog={handleOpenNotificationDialog}
        setFetchDataReqiured={setFetchDataReqiured}
      />
    </PayPalScriptProvider>
  )
}

export default PaypalWrapper