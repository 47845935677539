import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import CustomText from '../CustomText';
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const InnerAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:first-of-type)': {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const InnerAccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    paddingRight: 0,
    borderTop: `1px solid ${theme.palette.divider}`
}));

const CustomInnerAccordion = (props) => {
    const { item, parent } = props
    const { innerExpanded, handleInnerChange } = props
    const { handleInstructionSelection } = props
    const { selectedItemInstruction } = props
    const { handleGroupSelection } = props
    const { selectedGroupItems } = props
    const { handleItemSelection } = props
    const { selectedItems } = props
    
    return (
        <InnerAccordion 
            key={item.value} 
            expanded={innerExpanded.includes(item.value)} 
            onChange={handleInnerChange(item.value)}
        >
            <Stack 
                direction="row" 
                justifyContent="space-between"
            >
                <InnerAccordionSummary 
                    onClick={() => {handleInstructionSelection(item)}}
                >
                    <CustomText
                        sx={{
                            color: selectedItemInstruction.value === item.value ? 
                            "primary.main" : 
                            undefined
                        }}
                    >{item.title}</CustomText>
                </InnerAccordionSummary>
                <IconButton onClick={() => handleGroupSelection(item,parent)}>
                    {selectedGroupItems.includes(item.value) ?
                        <CheckBoxIcon color="primary" />
                        :
                        <CheckBoxOutlineBlankIcon />
                    }
                </IconButton>
            </Stack>
            <AccordionDetails>
                <Stack direction="column" spacing={2}>
                    {item.properties.map(pr => {
                    return (
                        <Stack 
                            key={pr.value}
                            direction="row" 
                            justifyContent="space-between" 
                            alignItems="center"
                            sx={{pl: 2}}
                            onClick={() => {handleInstructionSelection(pr)}}
                        >
                            <CustomText  
                                sx={{
                                    color: selectedItemInstruction.value === pr.value ? 
                                    "primary.main" : 
                                    undefined
                                }}
                            >
                                {pr.title}
                            </CustomText>
                            <IconButton onClick={() => handleItemSelection(pr.value,parent,item)}>
                                {selectedItems.includes(pr.value) ?
                                    <CheckBoxIcon color="primary" />
                                    :
                                    <CheckBoxOutlineBlankIcon />
                                }
                            </IconButton>
                        </Stack>
                    )
                    })}
                </Stack>
            </AccordionDetails>
        </InnerAccordion>
    )
};

export default CustomInnerAccordion