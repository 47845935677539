import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import getDesignTokens from './themes/Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppRoutes from './AppRoutes'

function App() {
  const mode = useSelector(state => state.settings.uiMode)
  const primaryColor = useSelector(state => state.settings.primaryColor)
  const secondaryColor = useSelector(state => state.settings.secondaryColor)
  
  const appTheme = createTheme(getDesignTokens(mode, primaryColor, secondaryColor))
  
  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <CssBaseline enableColorScheme />
          <AppRoutes />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App