import React from 'react'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

const PlusButton = (props) => {
  const { onClick } = props
  const { color } = props
  const { size } = props
  const { sx } = props

  const handleClick = () => {
      onClick()
  }

  return (
    <Fab
      onClick={handleClick}
      color={color ? color : 'primary'}
      size={size ? size : 'small'}
      sx={sx}
      {...props}
    >
      <AddIcon />
    </Fab>
  )
}

export default PlusButton