import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomText from '../CustomText';
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid';
import PlusButton from '../PlusButton';
import MinusButton from '../MinusButton';
import Stack from '@mui/material/Stack';
import CustomTextField from '../_form_fields/CustomTextField';

const boxStyle = {
    position: "relative",
    overflow: "hidden",
    borderRadius: 1,
    minHeight: "21vh", 
    maxHeight: "21vh",
    borderColor: "darkGray",
    width: "100%",
    border: 0.2,
    backgroundColor: "background.paper"
}

const boxTitle = {
    display: "block", 
    p: 1, 
    color: "secondary.main"
}

const innerBoxStyle = {
    position: "absolute",
    right: -9,
    overflowY: "scroll",
    width: "102%",
    height: "100%", 
    pb: 10
}

const CustomFieldsCard = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { customCardTitle } = props
    const { selectedCustomFields, setSelectedCustomFields } = props
    const { handleCustomFieldChange, handleSelectedCustomFieldsRemove } = props
    const { appliedFields } = props
    return (
        <Box sx={{...boxStyle}}>
            <CustomText sx={{...boxTitle}}>
                {  
                    customCardTitle ? 
                    customCardTitle : 
                    defaultTexts.customFieldsTitleText
                }
            </CustomText>
            <Divider />
            <Box sx={{...innerBoxStyle}}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={2}>
                        <PlusButton 
                            color="info"
                            style={{ transform: 'scale(0.7)' }}
                            onClick={() => {
                                let old_props = {...selectedCustomFields}
                                old_props[Object.keys(selectedCustomFields).length + 1] = ""
                                setSelectedCustomFields(old_props)
                            }} 
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {Object.keys(selectedCustomFields).map((field,index) => {
                            const last_item = Object.keys(selectedCustomFields).length === index + 1
                            return (
                                <Stack
                                    key={field}
                                    direction="row" 
                                    spacing={2} 
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{m: 1}}
                                >
                                <CustomTextField
                                    currentValue={selectedCustomFields[field]} 
                                    setCurrentValue={handleCustomFieldChange}
                                    field_key={field}
                                    label={""}
                                    size="small"
                                    sx={{width: last_item ? "90%" : "86%"}}
                                    error={appliedFields.filter(
                                        item => item.field_key === selectedCustomFields[field]).length > 0 ||
                                        Object.keys(selectedCustomFields).map(
                                            key => selectedCustomFields[key]).filter(
                                                item => item && item === selectedCustomFields[field]).length > 1
                                    }
                                    errorText={defaultTexts.duplicateFieldsAreNotAllowedErrorText}
                                />
                                {last_item && <MinusButton 
                                    color="error"
                                    style={{ transform: 'scale(0.7)' }}
                                    sx={{height: 5}}
                                    onClick={() => handleSelectedCustomFieldsRemove(field)}
                                />}
                            </Stack>
                            )
                        })}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
};

export default CustomFieldsCard;