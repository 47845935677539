export const snapshotDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    },
    {
        field_key: "volume_id",
        field_type: "select",
        required: true,
        label: "volumeFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    }
]

export const snapshotSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const snapshotsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "statusFormFieldLabel",
        value: "status",
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "volumeStatusChoiceAvailable", 
                value: "available", 
                default: true
            },
            {
                keyword: "volumeStatusChoiceError", 
                value: "error", 
                default: false
            },
            {
                keyword: "volumeStatusChoiceCreating", 
                value: "creating", 
                default: false
            },
            {
                keyword: "snapshotStatusChoiceDeleting", 
                value: "deleting", 
                default: false
            }
        ]
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // },
    {
        keyword: "volumeFormFieldLabel",
        value: "volume_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const snapshotDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        sort_key: 'name',
    },    
    {
        field_key: "size",
        is_id_field: false,
        field_type: "string", 
        label: "sizeFormFieldLabel"
    },
    // {
    //     field_key: "os-extended-snapshot-attributes:project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "volume_id",
        is_id_field: false,
        field_type: "string", 
        label: "volumeFormFieldLabel"
    },
    {
        field_key: "group_snapshot_id",
        is_id_field: false,
        field_type: "string", 
        label: "groupSnapshotFormFieldLabel",
        min_api_version: "3.14"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
]

export const snapshotStatusList = [
    {
        keyword: "availableStatusFormFieldLabel",
        value: "available",
        default: false
    },
    {
        keyword: "errorStatusFormFieldLabel",
        value: "error",
        default: false
    },
    {
        keyword: "creatingStatusFormFieldLabel",
        value: "creating",
        default: false
    },
    {
        keyword: "deletingStatusFormFieldLabel",
        value: "deleting",
        default: false
    },
    {
        keyword: "errorDeletingStatusFormFieldLabel",
        value: "error_deleting",
        default: false
    }
]

export const snapshotUpdateDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    }
]