import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'
import CustomPopover from '../../../_common/CustomPopover'
import useWindowDimensions from '../../../_common/WindowDimensions'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { getCurrentMonthFirstDate, addTimeToDate} from '../../helpers/dates'
import { chargesSummarySchema } from '../../helpers/billingHelpers'

const PlanningBreakdown = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)

  const [monthBreakdownData, setMonthBreakdownData] = useState([])
  const [chartData, setChartData] = useState([])
  const [startDate, setStartDate] = useState(getCurrentMonthFirstDate())
  const [isLoading, setIsLoading] = useState(true)

  const labelStyle = {
    color: theme.palette.secondary.main,
    fontSize: '18px',
  }

  const pieChartLabelStyle = {
    fill: 'white',
    fontSize: '14px',
  }

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  useEffect(() => {
    const fetchMonthBreakdownData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`month_time=${encodeURIComponent(startDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }

      const url = constants.billing_url + '/' + billingUrls.getServicesMonthBreakdown + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setMonthBreakdownData(response.data)
      } else {
        setMonthBreakdownData([])
      }

      setIsLoading(false)
    }

    fetchMonthBreakdownData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, startDate])

  useEffect(() => {
    if (monthBreakdownData.length > 0) {
      let chart_data = []

      monthBreakdownData.forEach(item => {
        chart_data.push({
          value: item.service_cost,
          label: (chargesSummarySchema && chargesSummarySchema[item.service_name]) ? chargesSummarySchema[item.service_name].label : item.service_name,
        })
      })

      setChartData(chart_data)
    } else {
      setChartData([])
    }
  }, [monthBreakdownData])

  const getFilter = () => {
    return (
      <Box sx={{ padding: width >= 900 ? '0px 20px 20px 20px' : '0px', }}>
        <DatePicker
          label=''
          value={new Date(startDate)}
          onChange={e => handleChangeStartDate(new Date(e))}
          slotProps={{ textField: { size: 'small' } }}
          views={['month', 'year']}
          sx={{ width: '180px' }}
        />
      </Box>
    )
  }

  return (
    <>
      <Stack
        direction="row" 
        justifyContent="space-between" 
        alignItems="flex-start"
        sx={{
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <Box sx={labelStyle}>{defaultTexts.costBreakdownLabel}</Box>

        {
          width >= 900 ?
          <Box>
            {getFilter()}
          </Box> :
          <Box sx={{ paddingRight: '10px' }}>
            <CustomPopover
              type='filterlist'
              horizontalOrigin='left'
            >
              {getFilter()}
            </CustomPopover>
          </Box>          
        }
      </Stack>
      
      {
        isLoading ?
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          spacing={1}
        >
          <Skeleton variant="circular" width={200} height={200} />
          {
            width >= 1200 &&
            <Stack
              direction='column'
              spacing={2}
            >
              <Skeleton variant="rounded" width={200} height={30} />
              <Skeleton variant="rounded" width={200} height={30} />
              <Skeleton variant="rounded" width={200} height={30} />
            </Stack>
          }
        </Stack> :
        <Box sx={{ margin: '0px', height: width >= 1400 ? '350px' : '500px' }}>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                data: chartData,
                innerRadius: width >= 1400 ? 44 : 36,
                outerRadius: width >= 1400 ? 150 : 120,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 180,
                arcLabelMinAngle: 15,
                cx: width >= 1400 ? 220 : 160,
                cy: 160,
              },
            ]}
            slotProps={{
              legend: {
                position: width >= 1400 ? { horizontal: 'right', vertical: 'middle' } : { horizontal: 'left', vertical: 'bottom' }
              }
            }}
            sx={{ [`& .${pieArcLabelClasses.root}`]: pieChartLabelStyle }}
          />
        </Box>
      }      
    </>
  )
}

export default PlanningBreakdown