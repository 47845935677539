import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import NetworksTableV20 from './networksTableV2.0';
import NetworkDetailV20 from './networkDetailV2.0';
import { networksFilterMenu, networkDataUpdateForm } from '../../../../../../_data/openstack/neutron/networks/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } from '../../../../../../_api_responses/openstack/neutron/networks/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import NetworkAdd from './NetworkAdd';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const NetworksWrapperV20 = (props) => {
	const theme = useTheme()

	const { navigate, location, currentTab, serviceSubmenu } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	
	const [networksData, setNetworksData] = useState([])
	const [networks, setNetworks] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedNetwork, setSelectedNetwork] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [networkDeleteConfirmDialogOpen, setNetworkDeleteConfirmDialogOpen] = useState(false);
	const [selectedNetworks, setSelectedNetworks] = useState([])
	const [networksSortParams, setNetworksSortParams] = useState("")
	
	const [networkFilterQueryParams, setNetworkFilterQueryParams] = useState("")
	const [selectedNetworkFilter, setSelectedNetworkFilter] = useState(networksFilterMenu[0].value)
	const [selectedNetworkFilterValue, setSelectedNetworkFilterValue] = useState("")
	const [networksFilter, setNetworksFilter] = useState([...networksFilterMenu])
	const [networkUpdateData, setNetworkUpdateData] = useState({})
	const [selectedUpdateField, setSelectedUpdateField] = useState("")
	const [networkUpdateDialogOpen, setNetworkUpdateDialogOpen] = useState(false)
	
	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = networksFilterMenu.find(item => item.value === selectedNetworkFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		const filterObject = networksFilterMenu.find(item => item.value === selectedNetworkFilter)
		return filterObject ? filterObject.items : []
	}

	const handleNetworkFilteredSearch = () => {
			if (selectedNetworkFilter && selectedNetworkFilterValue) {
					setNetworkFilterQueryParams(`${selectedNetworkFilter}=${selectedNetworkFilterValue}`)
			} else {
					setNetworkFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleNetworksDataFormatting = useCallback(() => {
			const formatted_data = networksData.map((item) => {
					let new_item = {...item}
					new_item.project_id = "-"
					if (Object.keys(item).includes("segments")) {
							new_item.segments = item.segments.length
					} else {
							new_item.segments = "-"
					}
					return new_item
			})

			setNetworks(formatted_data)
	},[
			networksData,
	])

	const handleNetworkFilterReset = () => {
			setSelectedNetworkFilter(networksFilterMenu[0].value)
			setSelectedNetworkFilterValue("")
			setNetworkFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToSubnet = (subnet_id) => {
		handleDetailCardClose()
		navigate("/networks/l3-networking/subnets",{state: { subnet_id }})
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedNetwork(networksData[index].id)
			setSelectedRow(networksData[index])
	},[networksData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedNetwork(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleNetworksSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setNetworksSortParams(sort_param)
			handleDataFetch()
	}

	const handleNetworkUpdate = async (n_id,data) => {			
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${n_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {network: data},
				token,
		})

		if (nt_response.status_code === networkUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onNetworkUpdate = async () => {
			handleNetworkUpdateDialogClose()
			let err = []
			const data = {}
			data[selectedUpdateField] = networkUpdateData[selectedUpdateField]
			for (let n in selectedNetworks) {
					const resp = await handleNetworkUpdate(selectedNetworks[n].id, data)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorUpdateRecordTitle"
					error_object["error_message"] = "errorUpdateRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleNetworkDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === networkUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onNetworkDelete = async () => {
			handleNetworkDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedNetworks) {
					const resp = await handleNetworkDelete(selectedNetworks[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onNetworkDeleteConfirm = (n_list) => {
			const selected_n_list = networksData.filter(n => 
					n_list.includes(n.id))
			setSelectedNetworks([...selected_n_list])
			setNetworkDeleteConfirmDialogOpen(true)
	}

	const handleNetworkDeleteConfirmDialogClose = () => {
			setNetworkDeleteConfirmDialogOpen(false)
	}

	const onNetworkUpdateAdminState = (n_list) => {
			const selected_n_list = networksData.filter(n => 
					n_list.includes(n.id))
			setSelectedNetworks([...selected_n_list])
			setSelectedUpdateField("admin_state_up")
			setNetworkUpdateDialogOpen(true)
	}

	const onNetworkUpdatePortSecurity = (n_list) => {
			const selected_n_list = networksData.filter(n => 
					n_list.includes(n.id))
			setSelectedNetworks([...selected_n_list])
			setSelectedUpdateField("port_security_enabled")
			setNetworkUpdateDialogOpen(true)
	}

	const onNetworkUpdateDefaultStatus = (n_list) => {
			const selected_n_list = networksData.filter(n => 
					n_list.includes(n.id))
			setSelectedNetworks([...selected_n_list])
			setSelectedUpdateField("is_default")
			setNetworkUpdateDialogOpen(true)
	}

	const onNetworkUpdateMTU = (n_list) => {
			const selected_n_list = networksData.filter(n => 
					n_list.includes(n.id))
			setSelectedNetworks([...selected_n_list])
			setSelectedUpdateField("mtu")
			setNetworkUpdateDialogOpen(true)
	}

	const handleNetworkUpdateDialogClose = () => {
			setNetworkUpdateDialogOpen(false)
	}

	const handleNetworkUpdateDataChange = (event,field_key) => {
			let updated_data = {...networkUpdateData}
			const field_type = networkDataUpdateForm.filter(field => field.field_key === field_key)[0].field_type
			if (field_type === "bool") {
					updated_data[field_key] = event.target.checked
			} else if (field_type === "select") {
					updated_data[field_key] = event
			} else {
					updated_data[field_key] = event.target.value
			}
			setNetworkUpdateData(updated_data)        
	}

	const getUpdateForm = () => {
			let form = networkDataUpdateForm.filter(field => field.field_key === selectedUpdateField)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													networkUpdateData,
													handleNetworkUpdateDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getNetworksActionsList = () => {
			let network_actions = []
			let new_action = {}
			new_action["value"] = "update_admin_state"
			new_action["action"] = onNetworkUpdateAdminState
			new_action["keyword"] = "networkUpdateAdminStateActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			network_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "update_port_security"
			new_action["action"] = onNetworkUpdatePortSecurity
			new_action["keyword"] = "networkUpdatePortSecurityActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			network_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "update_shared_status"
			// new_action["action"] = onNetworkUpdateSharedStatus
			// new_action["keyword"] = "networkUpdateSharedStatusActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// network_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "update_external_status"
			// new_action["action"] = onNetworkUpdateExternalStatus
			// new_action["keyword"] = "networkUpdateExternalStatusActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// network_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "update_default_status"
			new_action["action"] = onNetworkUpdateDefaultStatus
			new_action["keyword"] = "networkUpdateDefaultStatusActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			network_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "update_mtu"
			new_action["action"] = onNetworkUpdateMTU
			new_action["keyword"] = "networkUpdateMTUActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			network_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "network_delete"
			new_action["action"] = onNetworkDeleteConfirm
			new_action["keyword"] = "networkDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			network_actions.push({...new_action})
			
			return network_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedNetwork(null)
		setSelectedRow(null)
	}, [token])
	
	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
						handleLoading(true)
						
						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}?${networkFilterQueryParams}${networksSortParams}`
						const method = "GET"
						const network_response = await openstackRequest({url:url, method:method, token, })
						if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
								setNetworksData(network_response.data.networks)
								if (selectedNetwork) {
										const selected_network = network_response.data.networks.filter(item => item.id === selectedNetwork)
										if (selected_network.length > 0) {
												setSelectedRow(selected_network[0])
										}
								}
						}	else {
							setNetworksData([])
						}
						
						
					})();
			}			
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl, 
			networkFilterQueryParams,
			dataFetchingRequired,
			networksSortParams,
			selectedNetwork,
			token,
	]);

	useEffect(() => {
		if (networksData.length > 0) {
			handleNetworksDataFormatting()
		} else {
			setNetworks([])
		}
	},[
		networksData,
		handleNetworksDataFormatting
	])

	useEffect(() => {			
		let network_filter_menu = networksFilterMenu.map(nt => {
				let new_item = {...nt}
				
				return new_item
		})

		setNetworksFilter(network_filter_menu)
	},[
		networksData,
	])

	useEffect(() => {
		if (location.state && location.state.network_id && networks.length > 0) {
			const findNetwork = networks.find(item => item.id === location.state.network_id)
			setSelectedNetwork(findNetwork ? findNetwork : null)
			setSelectedRow(findNetwork ? findNetwork : null)
			navigate(location.path, {})
		}

		if (location.state && location.state.network_name && networks.length > 0) {
			const findNetwork = networks.find(item => item.name === location.state.network_name)
			setSelectedNetwork(findNetwork ? findNetwork : null)
			setSelectedRow(findNetwork ? findNetwork : null)
			navigate(location.path, {})
		}
	}, [location, navigate, networks])
	
	useEffect(() => {
			if (Object.keys(networkUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in networkDataUpdateForm) {
							if (
									networkDataUpdateForm[n].field_type === "string" || 
									networkDataUpdateForm[n].field_type === "select"
									) {
									new_form_data[networkDataUpdateForm[n].field_key] = ""
							} else if (networkDataUpdateForm[n].field_type === "bool") {
									new_form_data[networkDataUpdateForm[n].field_key] = networkDataUpdateForm[n].default_value ? 
									networkDataUpdateForm[n].default_value : 
									false
							}
					}
					setNetworkUpdateData(new_form_data)
			}
	},[
			networkUpdateData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l2-networking')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedNetworkFilterValue('')
	}, [selectedNetworkFilter])

	const handleActionRun = () => {
		const actionsList = getNetworksActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedNetworks([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getNetworksActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<NetworkAdd
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedNetworkFilterValue}
							setCurrentValue={e => setSelectedNetworkFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedNetworkFilterValue}
							setCurrentValue={setSelectedNetworkFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={networksFilter} 
						currentValue={selectedNetworkFilter}
						setCurrentValue={setSelectedNetworkFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleNetworkFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleNetworkFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}
	
	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuL2Networks}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{networksData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<NetworksTableV20 
				networksData={networks}
				setNetworksData={setNetworks}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getNetworksActionsList()}
				sortHandler={handleNetworksSorting}
				sortParams={networksSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<NetworkDetailV20
				selectedRow={selectedRow}
				handleDataFetch={handleDataFetch}
				handleNavigateToSubnet={handleNavigateToSubnet}
				handleDelete={onNetworkDeleteConfirm}
				setSelectedRow={setSelectedRow}
				setSelectedNetwork={setSelectedNetwork}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
					open={networkDeleteConfirmDialogOpen}
					onClose={handleNetworkDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.networkDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.networkDeleteConfirmText}: [${selectedNetworks.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onNetworkDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
					open={networkUpdateDialogOpen}
					onClose={handleNetworkUpdateDialogClose}
					dialogTitle={{
							title: defaultTexts.networkUpdateTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onNetworkUpdate, 
							sx: {color: 'primary.main'}}]}
				>
					{getUpdateForm()}
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default NetworksWrapperV20