export const groupTypeDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    },
    {
        field_key: "is_public",
        field_type: "bool",
        required: false,
        label: "isPublicFormFieldLabel",
        default_value: true
    }
]

export const groupTypeSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const groupTypeFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "string"
    },
    
]

export const groupTypeDataSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "is_public",
        is_id_field: false,
        field_type: "bool", 
        label: "isPublicFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    }
]