import React from 'react';
import Box from '@mui/material/Box';
import SubnetsWrapperV20 from './subnets/subnetsWrapperV2.0';
import FloatingIPsWrapperV20 from './floating_ips/floatingIPsWrapperV2.0';
import VirtualIPsWrapperV20 from './virtual_ips/virtualIPsWrapperV2.0';
import RoutersWrapperV20 from './routers/routersWrapperV2.0';

const L3NetworkingWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, virtualIPsSupported, subsection } = props

	return (
		<Box>
				{subsection === "/subnets" && 
						<SubnetsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/floating-ips" && 
						<FloatingIPsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{(subsection === "/virtual-ips" && virtualIPsSupported) &&
						<VirtualIPsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/routers" &&
						<RoutersWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default L3NetworkingWrapperV20