import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { MdSecurity } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const FirewallPolicyRulesV20 = (props) => {
    const { firewallPolicyRules, onRuleRemove } = props
    const { firewallGroups } = props

    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    return (
        <React.Fragment>
            <WrapperBox
            sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <MdSecurity
                    size={28}
                />
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.firewallPolicyRulesTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {firewallPolicyRules.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{defaultTexts.ipVersionFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.protocolFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.fwSourceFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.sourcePortsFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.fwDestinationFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.destinationPortsFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.actionFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.enabledFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.descriptionFormFieldLabel}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {firewallPolicyRules.map((row) => {
                                let source = ""
                                let destination = ""
                                if (row.source_firewall_group_id) {
                                    const fw_group = firewallGroups.filter(sg => sg.id === row.source_firewall_group_id)[0]
                                    source = `${defaultTexts.firewallGroupFormFieldLabel}: ${fw_group.name}`
                                } else if (row.source_ip_address) {
                                    source = `${row.source_ip_address}`
                                } else {
                                    if (row.ip_version === 4) {
                                        source = "0.0.0.0/0"
                                    } else {
                                        source = "::/0"
                                    }
                                }
                                if (row.destination_firewall_group_id) {
                                    const fw_group = firewallGroups.filter(sg => sg.id === row.destination_firewall_group_id)[0]
                                    destination = `${defaultTexts.firewallGroupFormFieldLabel}: ${fw_group.name}`
                                } else if (row.destination_ip_address) {
                                    destination = `${row.destination_ip_address}`
                                } else {
                                    if (row.ip_version === 4) {
                                        destination = "0.0.0.0/0"
                                    } else {
                                        destination = "::/0"
                                    }
                                }
                                let protocol = ""
                                if (row.protocol) {
                                    protocol = `${row.protocol.toUpperCase()}`
                                } else {
                                    protocol = "Any"
                                }
                                let source_ports = ""
                                if (row.source_port) {
                                    source_ports = `${row.source_port}`
                                } else {
                                    source_ports = "Any"
                                }
                                let destination_ports = ""
                                if (row.destination_port) {
                                    destination_ports = `${row.destination_port}`
                                } else {
                                    destination_ports = "Any"
                                }
                                return (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                    >
                                        {row.ip_version === 4 ? "IPv4" : "IPv6"}
                                    </TableCell>
                                    <TableCell align="left">
                                    {protocol}
                                    </TableCell>
                                    <TableCell align="left">
                                        {source}
                                    </TableCell>
                                    <TableCell align="left">
                                        {source_ports}
                                    </TableCell>
                                    <TableCell align="left">
                                        {destination}
                                    </TableCell>
                                    <TableCell align="left">
                                        {destination_ports}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.action.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.enabled ? 
                                            defaultTexts.formValueYes : 
                                            defaultTexts.formValueNo
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onRuleRemove(row.id)}
                                        >
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default FirewallPolicyRulesV20;