import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { vpnServiceDataSchema } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { setVpnServicesTableConfig } from '../../../../../../store/reducers/networkNeutronSlice';
import Box from '@mui/material/Box'
import useWindowDimensions from '../../../../../_common/WindowDimensions'

const VPNServicesTableV20 = (props) => {
    const { vpnServicesData, setVPNServicesData, setSelectedIds, deselectAll, setDeselectAll, isSuspended } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
    
    const vpnServicesTableConfig = useSelector(
        state => state.networkNeutron.vpnServicesTableConfig)
    const rowsPerPage = useSelector(
        state => state.networkNeutron.vpnServicesTableConfig.filter(
        versions => versions.api_version === "v2.0")[0].rows_per_page)
    const vpnServicesTableHeaders = useSelector(
        state => state.networkNeutron.vpnServicesTableConfig.filter(
            versions => versions.api_version === "v2.0")[0].columns)

    
    const identityField = vpnServicesTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleVPNServiceTableConfig = (value_list) => {
        const newTableConfig = vpnServiceDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = vpnServicesTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVpnServicesTableConfig({
            vpnServicesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = vpnServicesTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVpnServicesTableConfig({
            vpnServicesTableConfig: new_data
        }))
    }

    return (
        <Box
			sx={{
				width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
			}}
		>
            <CustomTable
                withPagination={false}
                withCheckbox={true}
                withActions={true}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={vpnServicesTableHeaders}
                tableHeadersConfigHandler={handleVPNServiceTableConfig}
                identityField={identityField}
                columns={[...vpnServiceDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={vpnServicesData}
                setDataRows={setVPNServicesData}
                getSelectedRows={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortHandler={sortHandler}
                selfSorting={true}
                setSelectedIds={setSelectedIds}
                deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
                isSuspended={isSuspended}
            />
        </Box>
    )
};

export default VPNServicesTableV20;