import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup';
import FirewallPolicySpecsV20 from './firewallPolicySpecsV2.0';
import FirewallPolicyRulesV20 from './firewallPolicyRulesV2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { openStackServices } from '../../../../../../config/openStackConstants';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from '../../../../../_common/CustomDialog';
import { firewallPolicyDataUpdateForm } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
// import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

const SERVICE_NAME = openStackServices.networkService

const FirewallPolicyDetailV20 = (props) => {
	const theme = useTheme()
	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const { selectedRow, setSelectedRow, setSelectedFirewallPolicy } = props
	const { handleDataFetch } = props
	const { auditedStateChange } = props
	const { sharedStateChange } = props
	const { handleDelete } = props
	const { firewallPolicies } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const token = useSelector(state => state.profile.x_auth_token)
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const mode = useSelector(state => state.settings.uiMode)

	const [firewallPolicyActions, setFirewallPolicyActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [firewallPolicyUpdateData, setFirewallPolicyUpdateData] = useState({})
	const [updateFirewallPolicyDialogOpen, setUpdateFirewallPolicyDialogOpen] = useState(false)
	const [firewallPolicyRules, setFirewallPolicyRules] = useState([]);
	const [projectFWRules, setProjectFWRules] = useState([])
	const [selectedRules, setSelectedRules] = useState([])
	const [selectedRulesObjects, setSelectedRulesObjects] = useState([])
	const [addRuleDialogOpen, setAddRuleDialogOpen] = useState(false)
	const [firewallGroups, setFirewallGroups] = useState([])
	const [ruleSelectMode, setRuleSelectMode] = useState(true)
	const [totalFWRules, setTotalFWRules] = useState([...firewallPolicyRules, ...selectedRulesObjects])
		
	const [firewallPolicySubMenu, setFirewallPolicySubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/firewall-policy-details", is_active: true},
			{keyword: "submenuFWPolicyRules", navigation: "/firewall-policy-rules", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/firewall-policy-details")
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const firewallPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)
	const firewallGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)
	const firewallRulesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)

	const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${selectedRow.id}`

	const getFormattedFirewallPolicyData = useCallback((data) => {
			let formatted_data = {...data}
			
			return formatted_data
	},[])

	const handleFirewallPolicyDetailTabChange = useCallback((navigation) => {
			let newFirewallPolicySubmenuData = firewallPolicySubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setFirewallPolicySubMenu(newFirewallPolicySubmenuData)
			setCurrentTab(navigation)
	},[
			firewallPolicySubMenu
	])

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}			
	}

	const handleAuditStateChangeDialogOpen = useCallback(() => {
			auditedStateChange([selectedRow.id])
	},[selectedRow,auditedStateChange])

	const handleSharedStateChangeDialogOpen = useCallback(() => {
			sharedStateChange([selectedRow.id])
	},[selectedRow,sharedStateChange])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			let form_data = [...form]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleSelectRule = (event,rule) => {
			if (event.target.checked) {
					// const updated_list = [...new Set([...selectedRules, rule.id])]
					const updated_list = [...new Set([rule.id])]
					setSelectedRules(updated_list)
					let updated_objects = []
					for (let i in updated_list) {
							const item = projectFWRules.filter(pr => pr.id === updated_list[i])
							updated_objects.push(item[0])
					}
					setSelectedRulesObjects(updated_objects)
			} else {
					const updated_list = selectedRules.filter(sr => sr !== rule.id)
					setSelectedRules(updated_list)
					const updated_objects = selectedRulesObjects.filter(sr => sr.id !==rule.id)
					setSelectedRulesObjects(updated_objects)
			}
	}

	const handleFirewallPolicyUpdateDataChange = (event,field_key) => {
			let new_form_data = {...firewallPolicyUpdateData}
			if (firewallPolicyDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (firewallPolicyDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setFirewallPolicyUpdateData(new_form_data)
	}

	const handleUpdateFirewallPolicyDialogOpen = () => {
			setUpdateFirewallPolicyDialogOpen(true)
	}

	const handleUpdateFirewallPolicyDialogClose = () => {
			setUpdateFirewallPolicyDialogOpen(false)
	}

	const handleAddRuleDialogClose = () => {
			setSelectedRules([])
			setSelectedRulesObjects([])
			setRuleSelectMode(true)
			setAddRuleDialogOpen(false)
	}

	const handleAddRuleDialogOpen = () => {
			setAddRuleDialogOpen(true)
	}

	const onAddRule = async () => {
			handleAddRuleDialogClose()
			const total_rules = totalFWRules.map(r => r.id)
			let count = 0
			for (let i in total_rules) {
					if (selectedRules.includes(total_rules[i])) {
							let data = {}
							data["firewall_rule_id"] = total_rules[i]
							if (firewallPolicyRules.length === 0 && count === 0) {
									data["insert_after"] = ""
									data["insert_before"] = ""
							} else if (i === "0") {
									data["insert_before"] = total_rules[parseInt(i) + 1]
							} else {
									data["insert_after"] = total_rules[parseInt(i) - 1]
							}
							console.log(data)
							await onFirewallPolicyRuleInsert(data)
							count += 1
					}
					
			}
			
	}

	const onFirewallPolicyRuleInsert = useCallback(async (data) => {
		const method = "PUT"
		const firewallPolicy_response = await openstackRequest({
				url: `${common_url}/insert_rule`, 
				method: method, 
				data: data,
				token,
		})
		if (firewallPolicy_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				setCurrentAction("")
				handleDataFetch()
		} else {
				const error_response = firewallUrlResponses.put.error_response.filter(
						error_item => error_item.status_code === firewallPolicy_response.status_code)
				if (error_response.length > 0) {
						const errorObject = {
								error_title: error_response[0].response_title, 
								error_message: error_response[0].response_message,
								error_details: firewallPolicy_response.error
						}
						setError(errorObject)
				} else {
						const error_response = firewallUrlResponses.put.error_response.filter(
								error_item => error_item.status_code === "unknown")
						const errorObject = {
								error_title: error_response[0].response_title, 
								error_message: error_response[0].response_message,
								error_details: firewallPolicy_response.error
						}
						setError(errorObject)
				}
		}
			
	},[
			common_url,
			token,
			handleDataFetch
	])

	const onFirewallPolicyUpdate = useCallback(async (event,data) => {
			let updated_data = {...firewallPolicyUpdateData}
			if (data) {
					updated_data = data
			}
			
			const method = "PUT"
			const firewallPolicy_response = await openstackRequest({
					url: common_url, 
					method: method, 
					data: {firewall_policy: updated_data},
					token,
			})
			if (firewallPolicy_response.status_code === firewallUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleUpdateFirewallPolicyDialogClose()
					handleDataFetch()
			} else {
					const error_response = firewallUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === firewallPolicy_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: firewallPolicy_response.error
							}
							setError(errorObject)
					} else {
							const error_response = firewallUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: firewallPolicy_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			token,
			firewallPolicyUpdateData,
			handleDataFetch
	])

	const handleRuleSequenceChange = (index,direction) => {
			if (direction === "up") {
					const new_index = index - 1
					let new_list = [...totalFWRules]
					const removed_object = new_list.splice(index,1)
					const newList = [
							...new_list.slice(0, new_index),
							...removed_object,
							...new_list.slice(new_index)
					];
					setTotalFWRules(newList)
			} else {
					const new_index = index + 1
					let new_list = [...totalFWRules]
					const removed_object = new_list.splice(index,1)
					const newList = [
							...new_list.slice(0, new_index),
							...removed_object,
							...new_list.slice(new_index)
					];
					setTotalFWRules(newList)
			}
	}

	const onRuleRemove = useCallback(async (rule_id) => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			const method = "PUT"
			const fw_response = await openstackRequest({
					url: `${common_url}/remove_rule`, 
					method: method, 
					data: {firewall_rule_id: rule_id},
					token,
			})
			if (fw_response.status_code === firewallUrlResponses.put.success_response.status_code) {
					handleDataFetch()
			} else {
					const error_response = firewallUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === fw_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fw_response.error
							}
							setError(errorObject)
					} else {
							const error_response = firewallUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fw_response.error
							}
							setError(errorObject)
					}
			}
		}
	},[
			common_url,
			token,
			handleDataFetch,
			isSuspended,
			setSuspendedDialogOpen,
	])

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			firewallPolicyActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			let firewallPolicy_actions = []
			let new_action = {}
			new_action["value"] = "update_firewall_policy"
			new_action["action"] = handleUpdateFirewallPolicyDialogOpen
			new_action["keyword"] = "firewallPolicyUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			firewallPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "audit_state_update"
			new_action["action"] = handleAuditStateChangeDialogOpen
			new_action["keyword"] = "auditedStateChangeActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			firewallPolicy_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "share_state_update"
			// new_action["action"] = handleSharedStateChangeDialogOpen
			// new_action["keyword"] = "shareStateChangeActionTitle"
			// new_action["button_text"] = "selectButtonTitleText"
			// firewallPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "fw_policy_rule_add"
			new_action["action"] = handleAddRuleDialogOpen
			new_action["keyword"] = "fwPolicyAddRuleActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			firewallPolicy_actions.push({...new_action})
			
			setFirewallPolicyActions(firewallPolicy_actions)
	},[
			selectedRow,
			handleAuditStateChangeDialogOpen,
			handleSharedStateChangeDialogOpen
	])

	useEffect(() => {
			if (Object.keys(firewallPolicyUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in firewallPolicyDataUpdateForm) {
							new_form_data[firewallPolicyDataUpdateForm[n].field_key] = selectedRow[firewallPolicyDataUpdateForm[n].field_key]
					}
					setFirewallPolicyUpdateData(new_form_data)
			}
	},[firewallPolicyUpdateData, selectedRow]);

	useEffect(() => {
			(async () => {
				const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}`
				const method = "GET"
				
				const fp_response = await openstackRequest({
						url:url, 
						method:method,
						token,
				})

				if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
						const firewall_rules = fp_response.data.firewall_rules.filter(rl =>
								selectedRow.firewall_rules.includes(rl.id)
						)
						const project_rules = fp_response.data.firewall_rules.filter(rl =>
								!selectedRow.firewall_rules.includes(rl.id) && 
								(rl.project_id === selectedRow.project_id || 
										rl.shared
								)
						)
						setFirewallPolicyRules(firewall_rules)
						setProjectFWRules(project_rules)
				}					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			firewallRulesUrl,
			token,
			firewallPolicies,
			selectedRow
	]);

	useEffect(() => {
			(async () => {
				const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}`
				const method = "GET"
				
				const fp_response = await openstackRequest({
						url:url, 
						method:method,
						token,
				})

				if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
						setFirewallGroups(fp_response.data.firewall_groups)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			firewallGroupsUrl,
			token
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setTotalFWRules([...firewallPolicyRules, ...selectedRulesObjects])
	},[
			firewallPolicyRules,
			selectedRulesObjects
	]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])

	useEffect(() => {
		setCurrentTab('/firewall-policy-details')
		setFirewallPolicySubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/firewall-policy-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])
	
	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={firewallPolicySubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleFirewallPolicyDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedFirewallPolicy}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{currentTab === "/firewall-policy-details" &&
					<FirewallPolicySpecsV20
							firewallPolicyData={getFormattedFirewallPolicyData(selectedRow)}
					/>
				}
				{currentTab === "/firewall-policy-rules" &&
						<FirewallPolicyRulesV20
								firewallPolicyRules={firewallPolicyRules}
								firewallGroups={firewallGroups}
								onRuleRemove={onRuleRemove}
						/>
				}
			</Box>
			
			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
						padding: '0px 20px 0px 12px',
					}} 
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={firewallPolicyActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[firewallPolicyActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			}

			<CustomDialog
					open={updateFirewallPolicyDialogOpen}
					onClose={handleUpdateFirewallPolicyDialogClose}
					dialogTitle={{
							title: defaultTexts.updateFirewallPolicyActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onFirewallPolicyUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							firewallPolicyDataUpdateForm,
							{},
							firewallPolicyUpdateData,
							handleFirewallPolicyUpdateDataChange
					)}
			</CustomDialog>
			<CustomDialog
					open={addRuleDialogOpen}
					onClose={handleAddRuleDialogClose}
					dialogTitle={{
							title: defaultTexts.fwPolicyAddRuleActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					maxWidth="lg"
			>
			{ruleSelectMode && <Stack direction="row" justifyContent="flex-end">
					<Button 
							variant="contained" 
							sx={{width: 100}}
							onClick={() => setRuleSelectMode(false)}
					>
							{defaultTexts.selectButtonTitleText}
					</Button>
			</Stack>}
			{!ruleSelectMode && <Stack direction="row" justifyContent="flex-end">
					<Button 
							variant="contained" 
							sx={{width: 100}}
							onClick={onAddRule}
					>
							{defaultTexts.applyButtonTitleText}
					</Button>
			</Stack>}
			<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
			<Grid item xs={12} md={12}>
					<TableContainer>
							<Table sx={{ width: "100%" }}>
									<TableHead>
											<TableRow>
													<TableCell align="left"></TableCell>
													<TableCell align="left">{defaultTexts.ipVersionFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.protocolFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.fwSourceFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.sourcePortsFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.fwDestinationFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.destinationPortsFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.actionFormFieldLabel}</TableCell>
													<TableCell align="left">{defaultTexts.enabledFormFieldLabel}</TableCell>
											</TableRow>
									</TableHead>
									<TableBody>
									{ruleSelectMode ? projectFWRules.map((row) => {
											let source = ""
											let destination = ""
											if (row.source_ip_address) {
													source = `${row.source_ip_address}`
											} else {
													if (row.ip_version === 4) {
															source = "0.0.0.0/0"
													} else {
															source = "::/0"
													}
											}
											if (row.destination_ip_address) {
													destination = `${row.destination_ip_address}`
											} else {
													if (row.ip_version === 4) {
															destination = "0.0.0.0/0"
													} else {
															destination = "::/0"
													}
											}
											let protocol = ""
											if (row.protocol) {
													protocol = `${row.protocol.toUpperCase()}`
											} else {
													protocol = "Any"
											}
											let source_ports = ""
											if (row.source_port) {
													source_ports = `${row.source_port}`
											} else {
													source_ports = "Any"
											}
											let destination_ports = ""
											if (row.destination_port) {
													destination_ports = `${row.destination_port}`
											} else {
													destination_ports = "Any"
											}
											const checked = selectedRules.includes(row.id)
											return (
											<TableRow
													key={row.id}
											>
															<TableCell align="right">
																	{/* <Checkbox
																			color="primary"
																			checked={checked}
																			onChange={(event) => handleSelectRule(event, row)}
																	/> */}
																	<Radio
																		color="primary"
																		checked={checked}
																		onChange={(event) => handleSelectRule(event, row)}
																	/>
															</TableCell>
															<TableCell 
															component="th" 
															align="left"
															scope="row"
													>
															{row.ip_version === 4 ? "IPv4" : "IPv6"}
													</TableCell>
													<TableCell align="left">
													{protocol}
													</TableCell>
													<TableCell align="left">
															{source}
													</TableCell>
													<TableCell align="left">
															{source_ports}
													</TableCell>
													<TableCell align="left">
															{destination}
													</TableCell>
													<TableCell align="left">
															{destination_ports}
													</TableCell>
													<TableCell align="left">
															{row.action.toUpperCase()}
													</TableCell>
													<TableCell align="left">
															{row.enabled ? 
																	defaultTexts.formValueYes : 
																	defaultTexts.formValueNo
															}
													</TableCell>
											</TableRow>
									)}) : totalFWRules.map((row, index) => {
											let source = ""
											let destination = ""
											if (row.source_ip_address) {
													source = `${row.source_ip_address}`
											} else {
													if (row.ip_version === 4) {
															source = "0.0.0.0/0"
													} else {
															source = "::/0"
													}
											}
											if (row.destination_ip_address) {
													destination = `${row.destination_ip_address}`
											} else {
													if (row.ip_version === 4) {
															destination = "0.0.0.0/0"
													} else {
															destination = "::/0"
													}
											}
											let protocol = ""
											if (row.protocol) {
													protocol = `${row.protocol.toUpperCase()}`
											} else {
													protocol = "Any"
											}
											let source_ports = ""
											if (row.source_port) {
													source_ports = `${row.source_port}`
											} else {
													source_ports = "Any"
											}
											let destination_ports = ""
											if (row.destination_port) {
													destination_ports = `${row.destination_port}`
											} else {
													destination_ports = "Any"
											}
											return (
											<TableRow
													key={row.id}
											>
													<TableCell 
															align="left"
													>
															{index !== 0 && 
															<IconButton onClick={() => handleRuleSequenceChange(index,"up")}>
																	<TiArrowSortedUp />
															</IconButton>}
															{index !== totalFWRules.length - 1 && 
															<IconButton onClick={() => handleRuleSequenceChange(index,"down")}>
																	<TiArrowSortedDown />
															</IconButton>}
													</TableCell>
													<TableCell 
															component="th" 
															align="left"
															scope="row"
													>
															{row.ip_version === 4 ? "IPv4" : "IPv6"}
													</TableCell>
													<TableCell align="left">
													{protocol}
													</TableCell>
													<TableCell align="left">
															{source}
													</TableCell>
													<TableCell align="left">
															{source_ports}
													</TableCell>
													<TableCell align="left">
															{destination}
													</TableCell>
													<TableCell align="left">
															{destination_ports}
													</TableCell>
													<TableCell align="left">
															{row.action.toUpperCase()}
													</TableCell>
													<TableCell align="left">
															{row.enabled ? 
																	defaultTexts.formValueYes : 
																	defaultTexts.formValueNo
															}
													</TableCell>
											</TableRow>
									)})}
									</TableBody>
							</Table>
					</TableContainer>
			</Grid>
			</Grid>
			</CustomDialog>
			{error && <CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
			/>}
		</>
	)
}

export default FirewallPolicyDetailV20