import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import WrapperBox from '../../../../_common/WrapperBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import useWindowDimensions from '../../../../_common/WindowDimensions';
import Box from '@mui/material/Box';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../_network/openstack_request';
import { openStackServices } from '../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../_common/ServiceCardContentHeader';
import ImageSpecsV2 from './imageSpecsV2';
import ImageActionsV2 from './imageActionsV2';
import ImageMetadataV2 from './imageMetadataV2';
import { styled } from '@mui/material/styles';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';

const SERVICE_NAME = openStackServices.imageService
const RESOURCE_NAME = "OS::Glance::Image"

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const ImageDetailV2 = forwardRef((props,ref) => {
	const theme = useTheme()

	const { selectedRow, selectedImage, handleDataFetch, setSelectedRow, setSelectedImage, selectedImageForMetadata } = props
	const {setImageFile, setImageRequiredFileUpload} = props
	const { setFileUploadRequired, onImageDeleteConfirm } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const [imageMetadata, setImageMetadata] = useState({});
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const mode = useSelector(state => state.settings.uiMode)
	const [metadataNamespaces, setMetadataNamespaces] = useState([])
	const [metadataCatalog, setMetadataCatalog] = useState([])

	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const token = useSelector(state => state.profile.x_auth_token)

	const drawerWidth = drawerOpened ? 270 : 65

	const [imageSubMenu, setImageSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/image-details", is_active: true},
			{keyword: "submenuMetadata", navigation: "/image-metadata", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/image-details")
	
	const imageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const imageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)


	const handleImageDetailTabChange = useCallback((navigation) => {
			let newImageSubmenuData = imageSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setImageSubMenu(newImageSubmenuData)
			setCurrentTab(navigation)
	},[
			imageSubMenu
	])

	const handleImageUpload = (event) => {
			setImageRequiredFileUpload(selectedRow)
			setImageFile(event.target.files[0])
			setFileUploadRequired(true)
			handleDataFetch()
	}

	const handleMetadataFormatting = useCallback((objs,props) => {
			const metadata_catalog = metadataNamespaces.map(ns => {
					let new_item = {...ns}
					const resource_type_association = ns.resource_type_associations.filter(rs => rs.name === RESOURCE_NAME)[0]
					new_item["value"] = ns.namespace
					new_item["title"] = ns.display_name
					const prefix = resource_type_association.prefix ? resource_type_association.prefix : ""
					new_item["prefix"] = prefix
					new_item["objects"] = objs[ns.namespace].map((obj,index) => {
							let new_object = {...obj}
							new_object["title"] = obj.name
							new_object["value"] = `${ns.namespace}_${prefix}object${index}`
							new_object["properties"] = []
							const obj_props = Object.keys(obj.properties).map(key => {
									let new_prop = {}
									new_prop["value"] = `${prefix}${key}`
									for (const [k,v] of Object.entries(obj.properties[key])) {
											if (Array.isArray(v)) {
													new_prop[k] = [...v]
											} else if (v instanceof Object) {
													new_prop[k] = {...v}
											} else {
													new_prop[k] = v
											}
									}
									return new_prop
							})
							new_object["properties"] = obj_props
							return new_object
					})
					new_item["properties"] = Object.keys(props[ns.namespace]).map(pr_key => {
							let new_pr = {}
							new_pr["value"] = `${prefix}${pr_key}`
							for (let k in props[ns.namespace][pr_key]) {
									if (k === "items") {
											new_pr["enum"] = props[ns.namespace][pr_key][k]["enum"]
									} else if (Array.isArray(props[ns.namespace][pr_key][k])) {
											new_pr[k] = [...props[ns.namespace][pr_key][k]]
									} else if (props[ns.namespace][pr_key][k] instanceof Object) {
											new_pr[k] = {...props[ns.namespace][pr_key][k]}
									} else {
											new_pr[k] = props[ns.namespace][pr_key][k]
									}
							}
							return  new_pr
					})
					return new_item
			})
			
			setMetadataCatalog(metadata_catalog)
	},[metadataNamespaces])

	useEffect(() => {
			if (selectedImageForMetadata) {
					(async () => {
							let image_metadata = {}
							const url = `${imageServiceDomain}/${imageServiceVersion}/schemas/image`
							const method = "GET"
							
							
							const schema_response = await openstackRequest({
									url, 
									method, 
									token
							})

							if (schema_response.status_code === imagesUrlResponses.get.success_response.status_code) {
									const schema_keys = Object.keys(schema_response.data.properties)
									const image_metadata_keys = Object.keys(selectedImageForMetadata).filter(key => !schema_keys.includes(key))
									if (image_metadata_keys.length > 0) {
											for (let k in image_metadata_keys) {
													image_metadata[image_metadata_keys[k]] = selectedImageForMetadata[image_metadata_keys[k]]
											}
									}
							}
							
							setImageMetadata(image_metadata)
					})();
			} else {
					setImageMetadata({})
			}
	},[
		selectedImageForMetadata,
		selectedImage,
		imageServiceDomain,
		imageServiceVersion,
		token,
	])

	useEffect(() => {
			(async () => {
					const url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces`
					const method = "GET"
					
					let namespace_list = []
					const metadata_response = await openstackRequest({
							url, 
							method, 
							token,
					})

					if (metadata_response.status_code === imagesUrlResponses.get.success_response.status_code) {
							const namespaces = metadata_response.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
									item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
							)
							namespace_list = [...namespace_list, ...namespaces]
							if (Object.keys(metadata_response.data).includes("next")) {
									let next_url = `${imageServiceDomain}${metadata_response.data.next}`
									while (next_url) {
											const next_request = await openstackRequest({
													url:next_url, 
													method, 
													token,
											})
											const next_namespaces = next_request.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
													item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
											)
											namespace_list = [...namespace_list, ...next_namespaces]
											if (Object.keys(next_request.data).includes("next")) {
													next_url = `${imageServiceDomain}${next_request.data.next}`
											} else {
													next_url = null
											}
									}
							}
							const compare = ( a, b ) => {
									if ( a["display_name"].toLowerCase() < b["display_name"].toLowerCase() ){
										return -1;
									}
									if ( a["display_name"].toLowerCase() > b["display_name"].toLowerCase() ){
										return 1;
									}
									return 0;
							}
							namespace_list.sort(compare)
							setMetadataNamespaces(namespace_list)
					} else {
							setMetadataNamespaces([])
					}
					
			})();
	},[
			selectedRow,
			imageServiceDomain,
			imageServiceVersion,
			token
	]);

	useEffect(() => {
			if (metadataNamespaces.length > 0) {
					(async () => {
							let total_objects = {}
							let total_properties = {}
							
							const method = "GET"
							for (let i in metadataNamespaces) {
									let namespace_objects = []
									let namespace_properties = {}

									const object_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/objects`
									const object_response = await openstackRequest({
											url:object_url, 
											method:method, 
											token,
									})
									if (object_response.status_code === imagesUrlResponses.get.success_response.status_code) {
											namespace_objects = [...namespace_objects, ...object_response.data.objects]
									}
									const props_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/properties`
									const props_response = await openstackRequest({
											url:props_url, 
											method:method, 
											token,
									})
									if (props_response.status_code === imagesUrlResponses.get.success_response.status_code) {
											namespace_properties = {...namespace_properties, ...props_response.data.properties}
									}
									
									total_objects[metadataNamespaces[i].namespace] = namespace_objects
									total_properties[metadataNamespaces[i].namespace] = namespace_properties
							}
							handleMetadataFormatting(total_objects, total_properties)
					})();
			}
	},[
			metadataNamespaces,
			imageServiceDomain,
			imageServiceVersion,
			selectedRow,
			handleMetadataFormatting,
			token
	]);

	useEffect(() => {
		setCurrentTab('/image-details')
		setImageSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/image-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={imageSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleImageDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedImage}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/image-details" &&
					<ImageSpecsV2 
						imageData={selectedImage}
					/>
				}

				{
					currentTab === "/image-metadata" && 
					<ImageMetadataV2
						imageMetadata={imageMetadata}
					/>
				}
			</Box>
			
			<Paper sx={{ 
					position: 'fixed',
					width: `calc(100% - ${drawerWidth}px)`,
					bottom: 0, 
					height: '60px',
					left: `${drawerWidth}px`,
					borderTop: `${theme.palette.customGrayDark} 2px solid`,
					borderRadius: '0px',
					padding: '0px 20px 0px 12px',
				}}
			>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
								{
									selectedImage.owner === defaultAdminProject &&
									<ImageActionsV2 
										imageData={selectedRow}
										imageMetadata={imageMetadata}
										handleDataFetch={handleDataFetch}
										onImageDeleteConfirm={onImageDeleteConfirm}
										metadataCatalog={metadataCatalog}
										isSuspended={isSuspended}
										setSuspendedDialogOpen={setSuspendedDialogOpen}
									/>
								}									
							</Grid>
							{
								(selectedRow.status.toLowerCase() === "queued" && !isSuspended) &&
								<Grid item>
									<Button 
											component="label"
											role={undefined}
											variant="contained"
											tabIndex={-1}
											startIcon={<CloudUploadIcon />} 
											color="secondary"
											sx={{m: 1}}
									>
											{defaultTexts.uploadImageFileButtonText}
											<VisuallyHiddenInput type="file" onChange={handleImageUpload} ref={ref} />
									</Button>
								</Grid>
							}
					</Grid>
			</Paper> 
		</>
	)
})

export default ImageDetailV2