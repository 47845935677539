import { createSlice } from '@reduxjs/toolkit'
import constants from '../../config/constants'

const initialState = {
  defaultLang: 'en',
  currentLang: 'en',
  supportedLangs: [
    { value: 'en', label: 'English' },
    { value: 'ka', label: 'ქართული' },
  ],
  uiMode: 'light',
  clientAccountID: null,
  apiVersion: null,
  logo: null,
  regionName: null,
  openstackDomain: null,
  openstackRole: null,
  serviceDomain: null,
  primaryColor: null,
  secondaryColor: null,
  captchaConfig: {
    site_key: constants.google_reCapcha_site_key, 
    secret_key: constants.google_reCapcha_secret_key,
  },
  promoConfig: {
    background_type: null,
    background_color_type: null,
    background_color: null,
    background_image: null,
    heading: null,
    promo_text: [],
  },
  signupConfig: {
    enabled: false,
    documents: [],
    form_fields: [],
  },
  contactUs: {
    company: null,
    email: [],
    phone: [],
  },
  serviceMenuMode: 'tabs',
  networkMenuMode: {},
  savedProjectId: null,
  chargesSummaryOpen: true,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentLang: (state, action) => {
      state.currentLang = action.payload.currentLang
    },
    setSupportedLangs: (state, action) => {
      state.supportedLangs = action.payload.supportedLangs
    },
    setUIColors: (state, action) => {
      state.primaryColor = action.payload.primaryColor
      state.secondaryColor = action.payload.secondaryColor
    },
    setUIMode: (state, action) => {
      state.uiMode = action.payload.uiMode
    },
    setClientAccountID: (state, action) => {
      state.clientAccountID = action.payload.clientAccountID
    },
    setApiVersion: (state, action) => {
      state.apiVersion = action.payload.apiVersion
    },
    setLogo: (state, action) => {
      state.logo = action.payload.logo
    },
    setOpenstackDomain: (state, action) => {
      state.openstackDomain = action.payload.openstackDomain
    },
    setOpenstackRole: (state, action) => {
      state.openstackRole = action.payload.openstackRole
    },
    setServiceDomain: (state, action) => {
      state.serviceDomain = action.payload.serviceDomain
    },
    setCaptchaConfig: (state, action) => {
      state.captchaConfig = action.payload.captchaConfig
    },
    setPromoConfig: (state, action) => {
      state.promoConfig = action.payload.promoConfig
    },
    setSignupConfig: (state, action) => {
      state.signupConfig = action.payload.signupConfig
    },
    setContactUs: (state, action) => {
      state.contactUs = action.payload.contactUs
    },
    setServiceMenuMode: (state, action) => {
      state.serviceMenuMode = action.payload.serviceMenuMode
    },
    setNetworkMenuMode: (state, action) => {
      state.networkMenuMode = action.payload.networkMenuMode
    },
    setRegionName: (state, action) => {
      state.regionName = action.payload.regionName
    },
    setSavedProjectId: (state, action) => {
      state.savedProjectId = action.payload.savedProjectId
    },
    setChargesSummaryOpen: (state, action) => {
      state.chargesSummaryOpen = action.payload.chargesSummaryOpen
    },
    settingsReset: () => {
      return { ...initialState }
    }
  }
})

export const { 
  setCurrentLang, 
  setSupportedLangs,
  setUIColors,
  setUIMode,
  setClientAccountID,
  setApiVersion,
  setLogo,
  setOpenstackDomain,
  setOpenstackRole,
  setServiceDomain,
  setCaptchaConfig,
  setPromoConfig,
  setSignupConfig,
  setContactUs,
  setServiceMenuMode,
  setNetworkMenuMode,
  setRegionName,
  settingsReset,
  setSavedProjectId,
  setChargesSummaryOpen,
} = settingsSlice.actions

export default settingsSlice.reducer