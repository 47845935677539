import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomDrawer from '../../_common/CustomDrawer'
import HomeLoginWrapper from './HomeLoginWrapper'
import HomeContent from './homeContent'
import Loading from '../../_common/Loading'
import constants from '../../../config/constants'
import { coreServiceURLs, languageServiceURLs } from '../../../_network/apiUrls'
import request from '../../../_network/request'
import {
  setSupportedLangs,
  setUIColors,
  setClientAccountID,
  setApiVersion,
  setLogo,
  setOpenstackDomain,
  setOpenstackRole,
  setServiceDomain,
  setCaptchaConfig,
  setPromoConfig,
  setSignupConfig,
  setContactUs,
} from '../../../store/reducers/settingsSlice'

const HomeWrapper = (props) => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const supportedLangsInitial = [
      { value: 'en', label: 'English' },
    ]

    const captchaConfigInitial = {
      site_key: constants.google_reCapcha_site_key, 
      secret_key: constants.google_reCapcha_secret_key,
    }

    const promoConfigInitial = {
      background_type: null,
      background_color_type: null,
      background_color: null,
      background_image: null,
      heading: null,
      promo_text: [],
    }

    const signupConfigInitial = {
      enabled: false,
      documents: [],
      form_fields: [],
    }
    
    const fetchData = async () => {
      const serviceDomain = document.location.hostname === 'localhost' ? 'openstack-client-test.grena.ge' : document.location.hostname
      // const serviceDomain = document.location.hostname === 'localhost' ? 'my.gcloud.ge' : document.location.hostname

      const query_params = `?service_group=${constants.config_service_group}&service_type=${constants.config_service_type_frontend}&service=${constants.config_service_custom_admin_ui}&service_domain=${serviceDomain}`
      
      const url = `${constants.core_service_domain}/${coreServiceURLs.getClientInitConfig}/${query_params}`
      
      const request_data = {
        url: url,
        method: 'GET',
      }

      const response = await request(request_data)
      
      if (response.status_code && response.data && response.status_code < 400) {
        dispatch(setClientAccountID({ clientAccountID: response.data.client_id }))

        dispatch(setUIColors({
          primaryColor: response.data.config_params.primary_color ? response.data.config_params.primary_color : null,
          secondaryColor: response.data.config_params.secondary_color ? response.data.config_params.secondary_color : null,
        }))

        dispatch(setApiVersion({
          apiVersion: response.data.config_params.api_version ? response.data.config_params.api_version : null
        }))

        dispatch(setLogo({
          logo: response.data.config_params.logo ? response.data.config_params.logo : null
        }))

        dispatch(setOpenstackDomain({
          openstackDomain: response.data.config_params.openstack_domain ? response.data.config_params.openstack_domain : null
        }))

        dispatch(setOpenstackRole({
          openstackRole: response.data.config_params.openstack_role ? response.data.config_params.openstack_role : null
        }))

        dispatch(setServiceDomain({
          serviceDomain: response.data.config_params.service_domain ? response.data.config_params.service_domain : null
        }))

        dispatch(setCaptchaConfig({
          captchaConfig: response.data.config_params.captcha_config ? response.data.config_params.captcha_config : captchaConfigInitial
        }))

        dispatch(setPromoConfig({
          promoConfig: response.data.config_params.promo_config ? response.data.config_params.promo_config : promoConfigInitial
        }))

        dispatch(setSignupConfig({
          signupConfig: response.data.config_params.signup_config ? response.data.config_params.signup_config : signupConfigInitial
        }))

        dispatch(setContactUs({
          contactUs: response.data.config_params.contact_us ? response.data.config_params.contact_us : null
        }))

        setIsLoading(false)
      }
    }

    const fetchLanguageData = async () => {
      const url = `${constants.language_service_domain}/${languageServiceURLs.getLangConfigs}/${constants.client_credential_id}`
      
      const request_data = { url, method: 'GET' }

      const response = await request(request_data)
      
      if (response.status_code && response.data && response.status_code < 400) {
        dispatch(setSupportedLangs({ supportedLangs: response.data.supported_languages }))
      } else {
        dispatch(setSupportedLangs({ supportedLangs: supportedLangsInitial }))
      }
    }

    fetchData()
    fetchLanguageData()
  }, [dispatch])
  
  return (
    <>
      {
        isLoading ?
        <Loading /> :
        <CustomDrawer>
          { !isAuthenticated && <HomeLoginWrapper /> }
          { isAuthenticated && <HomeContent /> }
        </CustomDrawer>
      }
    </>
  )
}

export default HomeWrapper