import React from 'react';
import Box from '@mui/material/Box';
import SecurityGroupsWrapperV20 from './security_groups/securityGroupsWrapperV2.0';
import AddressGroupsWrapperV20 from './address_groups/addressGroupsWrapperV2.0';

const L3NetworkingWrapperV20 = (props) => {
  const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, subsection } = props

	return (
		<Box>
				{subsection === "/security-groups" && 
						<SecurityGroupsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/address-groups" && 
						<AddressGroupsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default L3NetworkingWrapperV20