import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup'
import request from '../../../_network/request';
import constants from '../../../config/constants';
import { coreServiceURLs } from '../../../_network/apiUrls';
import { projectsUrl as projectsUrlResponses} from '../../../_api_responses/openstack/identity/projects/v3';
import { keypairsUrl as keypairsUrlResponses } from '../../../_api_responses/openstack/compute/keypairs/v2.1';
import { openstackRequest, volumeCinderRequest } from '../../../_network/openstack_request';
import { openStackServices } from '../../../config/openStackConstants';
import { networkNeutronConstants, computeNovaConstants, blockStorageCinderConstants } from '../../../config/openStackConstants';
import CustomBackdrop from '../../_common/CustomBackdrop';
import { limitsDataSchema, limitsUpdateDataForm } from '../../../_data/openstack/neutron/limits/v2.0';
import { computeLimitsUpdateDataForm } from '../../../_data/openstack/compute/limits/v2.1';
import { cinderLimitsUpdateDataForm } from '../../../_data/openstack/cinder/limits/v3';
import CustomTable from '../../_common/CustomTable';
import { getFormFieldComponent } from '../../../components/_common/_form_fields/form_helpers';
import { resourceUsageSchema } from '../../home/helpers/homeData'
import CustomDialog from '../../_common/CustomDialog';
import CustomText from '../../_common/CustomText';
import useWindowDimensions from '../../_common/WindowDimensions';

const NETWORK_SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const CINDER_SERVICE_NAME = openStackServices.volumeService

const LimitsContent = () => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const token = useSelector(state => state.profile.x_auth_token)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const [limitsData, setLimitsData] = useState({})
  const [computeLimitsData, setComputeLimitsData] = useState({})
  const [cinderLimitsData, setCinderLimitsData] = useState({})
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const [updateLimitsDialogOpen, setUpdateLimitsDialogOpen] = useState(false)
  const [limitsCurrentData, setLimitsCurrentData] = useState({})
  const [limitsUpdateData, setLimitsUpdateData] = useState({})
  const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
  
  const selectedProject = useSelector(state => state.profile.defaultAdminProject)
  const drawerOpened = useSelector(state => state.drawer.drawerOpened)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const region_name = useSelector(state => state.settings.regionName)
  const domain = useSelector(state => state.settings.openstackDomain)
  const username = useSelector(state => state.profile.username)
	const { width } = useWindowDimensions()

  const [keypairsData, setKeypairsData] = useState([])

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
  const limitsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.limitsUrl)[0].url)
  
  const computeServiceDomain = useSelector(
    state => state.openstack.purchasedServices.filter(
    service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
  const computeServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
  const computeLimitsUrl = useSelector(
    state => state.computeNova.computeNovaApiUrls.filter(
        version => version.api_version === "v2.1")[0].urls.filter(
            url => url.keyword === computeNovaConstants.limitsUrl)[0].url)
  const keypairsUrl = useSelector(
    state => state.computeNova.computeNovaApiUrls.filter(
        version => version.api_version === "v2.1")[0].urls.filter(
            url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)

  const cinderServiceDomain = useSelector(
    state => state.openstack.purchasedServices.filter(
    service => service.service === CINDER_SERVICE_NAME)[0].config_params.service_domain)
  const cinderServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === CINDER_SERVICE_NAME)[0].config_params.api_version)
  const cinderLimitsUrl = useSelector(
      state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
          version => version.api_version === "v3")[0].urls.filter(
              url => url.keyword === blockStorageCinderConstants.limitsUrl)[0].url)

  const handleLoading = () => {
      setIsLoading(true)
  }

  const handleUpdateLimitsDialogOpen = () => {
    setUpdateLimitsDialogOpen(true)
  }

  const handleUpdateLimitsDialogClose = () => {
      setUpdateLimitsDialogOpen(false)
  }

  const handleUpdateLimitsDataChange = (event, field_key) => {
      let updated_data = {...limitsUpdateData}
      updated_data[field_key] = event.target.value
      
      setLimitsUpdateData(updated_data)
  }

  const getLimitsDataForm = () => {
    let form = [...computeLimitsUpdateDataForm, ...cinderLimitsUpdateDataForm, ...limitsUpdateDataForm]
    let filteredForm = []

    form.forEach(field => {
      filteredForm.push(field)
    })

    return (
      <FormGroup sx={{my: 3}}>
        {
          filteredForm.map(field => {
            let form_field_options = {...field}
            delete form_field_options.label
              return (
                getFormFieldComponent(
                  field,
                  limitsUpdateData,
                  handleUpdateLimitsDataChange,
                  defaultTexts[field.label],
                  {...form_field_options}
                )
              )
          })
        }
      </FormGroup>
    )
  }

  const convertMBtoGB = (v) => {
    if (v < 1024) {
      return v + ' MB'
    } else {
      return Math.round(v / 1024) + ' GB'
    }
  }

  const getLimitLabel = (keyword) => {
    let result = keyword

    const schema_object = resourceUsageSchema.find(item => item.keyword === keyword)

    if (schema_object) {
      result = defaultTexts[schema_object.label]
    }

    return result
  }

  const handleLimitsDataFormatting = (data, computeData, cinderData) => {
    const keys_network = limitsUpdateDataForm.map(field => field.field_key)
    const keys_compute = computeLimitsUpdateDataForm.map(field => field.field_key)
    const keys_cinder = cinderLimitsUpdateDataForm.map(field => field.field_key)
    
    const network_formatted_data = keys_network.map((item) => {
        let new_item = {...data[item]}
        new_item["available"] = data[item].limit === -1 ? defaultTexts.unlimitedValueText : new_item.limit - new_item.used
        new_item.limit = data[item].limit === -1 ? defaultTexts.unlimitedValueText : data[item].limit
        new_item["resource_name"] = getLimitLabel(item)
        return new_item
    })

    const compute_formatted_data = keys_compute.map((item) => {
      let new_item = {...computeData[item]}
      new_item["available"] = computeData[item].limit === -1 ? defaultTexts.unlimitedValueText : new_item.limit - new_item.used
      if (!new_item.used && new_item.used !== 0) {
        new_item["available"] = ''
      }
      new_item.limit = computeData[item].limit === -1 ? defaultTexts.unlimitedValueText : computeData[item].limit
      new_item["resource_name"] = getLimitLabel(item)

      if (item === 'ram') {
        new_item.limit = convertMBtoGB(new_item.limit)
        new_item.used = convertMBtoGB(new_item.used)
        new_item.available = convertMBtoGB(new_item.available)
      }

      return new_item
    })

    const cinder_formatted_data = keys_cinder.map((item) => {
      let new_item = {...cinderData[item]}
      new_item["used"] = new_item.in_use
      new_item["available"] = cinderData[item].limit === -1 ? defaultTexts.unlimitedValueText : new_item.limit - new_item.in_use
      new_item.limit = cinderData[item].limit === -1 ? defaultTexts.unlimitedValueText : cinderData[item].limit
      new_item["resource_name"] = getLimitLabel(item)
      return new_item
    })

    return [...compute_formatted_data, ...cinder_formatted_data, ...network_formatted_data]
  }

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const onLimitsUpdate = async () => {
    let new_data = {}

    const limit_keys = Object.keys(limitsCurrentData)

    limit_keys.forEach(k => {
      if (parseInt(limitsCurrentData[k]) !== parseInt(limitsUpdateData[k])) {
        new_data[k] = parseInt(limitsUpdateData[k])
      }
    })

    if (Object.keys(new_data).length > 0) {
      const postData = {
        group: 'openstack_admin',
        type: 'limits_update',
        client_id: clientAccountID,
        alert_data: {
          name: username,
          region_name,
          domain,
          data: new_data,
        }
      }
  
      const url = `${constants.core_service_domain}/${coreServiceURLs.getOpenStackLimitsUpdate}`
  
      const request_data = {
        url,
        method: 'POST',
        data: postData,
      }
  
      const response = await request(request_data)
      
      if (response && response.status_code && response.status_code < 400) {
        handleUpdateLimitsDialogClose()
        setError(null)
      } else {
        setError({ error_details: defaultTexts.anErrorOccured })
      }
    } else {
      handleUpdateLimitsDialogClose()
      setError(null)
    }    
  }

  useEffect(() => {
      if (dataFetchingRequired) {
          (async () => {
              handleLoading()
              
              const url = `${neutronServiceDomain}/${neutronServiceVersion}/${limitsUrl}/${selectedProject}/details.json`
              const method = "GET"
              const limits_response = await openstackRequest({url:url, method:method, token, })
              if ( limits_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                  setLimitsData(limits_response.data.quota)
              }
              
          })()
      }
      setDataFetchingRequired(false)
      setIsLoading(false)
  },[
      neutronServiceDomain,
      neutronServiceVersion,
      limitsUrl,
      token,
      selectedProject,
      dataFetchingRequired,
  ])
  
  useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${computeServiceDomain}/${computeServiceVersion}/${computeLimitsUrl}`
        const method = "GET"
        const limits_response = await openstackRequest({url:url, method:method, token, })

        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.limits && limits_response.data.limits.absolute) {
            const absoulteItem = limits_response.data.limits.absolute

            updated_data.cpu_cores = { limit: absoulteItem.maxTotalCores, used: absoulteItem.totalCoresUsed, reserved: 0, }
            updated_data.ram = { limit: absoulteItem.maxTotalRAMSize, used: absoulteItem.totalRAMUsed, reserved: 0, }
            updated_data.server_instances = { limit: absoulteItem.maxTotalInstances, used: absoulteItem.totalInstancesUsed, reserved: 0, }
            updated_data.total_allowed_keypairs = { limit: absoulteItem.maxTotalKeypairs, used: keypairsData.length, reserved: 0, }
        }

        setComputeLimitsData(updated_data)
        
    })()
  },[
    computeServiceDomain,
    computeServiceVersion,
    computeLimitsUrl,
    defaultAdminProject,
    token,
    keypairsData,
  ])

  useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${cinderLimitsUrl}/${defaultAdminProject}?usage=true`
        const method = "GET"
        const limits_response = await volumeCinderRequest({url:url, method:method, token, })
        
        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.quota_set) {
          updated_data = limits_response.data.quota_set
        }
        
        setCinderLimitsData(updated_data)        
    })()
  },[
    cinderServiceDomain,
    cinderServiceVersion,
    cinderLimitsUrl,
    defaultAdminProject,
    token,
  ])

  useEffect(() => {
    (async () => {
        
      const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
      const method = "GET"

      const keypairs_response = await openstackRequest({url:url, method:method, token, })
      
      if (keypairs_response.status_code === keypairsUrlResponses.get.success_response.status_code) {
        setKeypairsData([...keypairs_response.data.keypairs])
      } else {
        setKeypairsData([])
      }
        
    })();
  },[
    computeServiceDomain, 
    computeServiceVersion, 
    keypairsUrl,
    token,
  ]);

  useEffect(() => {
    let new_data_compute = {}
    let new_data_cinder = {}
    let new_data_network = {}

    if (Object.keys(limitsData).length > 0) {      
      for (let i in limitsUpdateDataForm) {
        new_data_compute[limitsUpdateDataForm[i].field_key] = limitsData[limitsUpdateDataForm[i].field_key].limit
      }
    }

    if (Object.keys(computeLimitsData).length > 0) {      
      for (let i in computeLimitsUpdateDataForm) {
        new_data_compute[computeLimitsUpdateDataForm[i].field_key] = computeLimitsData[computeLimitsUpdateDataForm[i].field_key].limit
      }
    }

    if (Object.keys(cinderLimitsData).length > 0) {      
      for (let i in cinderLimitsUpdateDataForm) {
        new_data_cinder[cinderLimitsUpdateDataForm[i].field_key] = cinderLimitsData[cinderLimitsUpdateDataForm[i].field_key].limit
      }
    }

    const full_data = { ...new_data_compute, ...new_data_cinder, ...new_data_network }

    setLimitsCurrentData(full_data)
    setLimitsUpdateData(full_data) 
  },[
    limitsData,
    computeLimitsData,
    cinderLimitsData,
  ])
  
  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);
  
  return (
    <>
      { isLoading && <CustomBackdrop open={isLoading} /> }

      <Box sx={{ padding: '20px' }}>
        <CustomText sx={{ color: theme.palette.primary.main, fontSize: '18px' }}>
          {defaultTexts.resourcesQuotasLabel}
        </CustomText>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: '0px 20px 20px 20px',
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 50, mr: 2, color: 'white' }}
            onClick={handleUpdateLimitsDialogOpen}
          >
            {defaultTexts.updateLimitsButtonText}
          </Button>
        </Box>          
      </Box>

      {
        !isLoading && Object.keys(limitsData).length > 0 &&
        <Box
          sx={{
            paddingBottom: '50px',
            width: drawerOpened ? `${width - 270 - 12}px` : `${width - 65 - 12}px`,
				    overflowX: 'auto',
          }}
        >
          <CustomTable
            withRowCount={true}
            withoutColumnsHide={true}
            defaultDataTexts={defaultTexts} 
            tableHeaders={limitsDataSchema}
            columns={limitsDataSchema}
            handleRowSelection={()=>{}}
            dataRows={handleLimitsDataFormatting(limitsData, computeLimitsData, cinderLimitsData)}
          />
        </Box>        
      }

      <CustomDialog
          open={updateLimitsDialogOpen}
          onClose={handleUpdateLimitsDialogClose}
          dialogTitle={{
              title: defaultTexts.updateLimitsActionTitle, 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts.updateLimitsActionText, 
              sx: {color: 'text.primary'}}}
          actionButtons={[{
              title: defaultTexts.submitButtonText, 
              onClick: onLimitsUpdate, 
              variant: "contained",
              sx: { color: 'white' }
              }]}
      >
          {getLimitsDataForm()}
      </CustomDialog>

      {
        error &&
        <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default LimitsContent