import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { BsEthernet } from "react-icons/bs";

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 120
}

const TrunkSubPortsV20 = (props) => {
    const { subports, handleNavigateToPort } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={4}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack
                            direction="row" 
                            alignItems="start" 
                            justifyContent="center"
                            spacing={0}
                        >
                        <Stack
                            direction="column" 
                            alignItems="center" 
                            justifyContent="flex-start"
                            spacing={0}
                        >
                            <CustomText 
                                size="h4" 
                                sx={{
                                    color: theme.palette.dashboardPink
                                }}
                            >
                                {subports.length}
                            </CustomText>
                            <CustomText 
                                size="p" 
                                sx={{fontSize: {xs: 16, lg: 18}}}
                            >
                                {defaultTexts.subportsTableTitle}
                            </CustomText>
                        </Stack>
                        <BsEthernet
                            size={28}
                        />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {defaultTexts.portFormFieldLabel}
                                    </TableCell>
                                    <TableCell align="center">
                                        {defaultTexts.macAddressFormFieldLabel}
                                    </TableCell>
                                    <TableCell align="center">
                                        {defaultTexts.segmentationIDFormFieldLabel}
                                    </TableCell>
                                    <TableCell align="center">
                                        {defaultTexts.segmentationTypeFormFieldLabel}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {subports.map(item => (
                                <TableRow
                                    key={item.port_id}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleNavigateToPort(item.port_id)}
                                >
                                <TableCell 
                                    component="th" 
                                    scope="row"
                                    align="center"
                                >
                                    {item.port_id}
                                </TableCell>
                                <TableCell 
                                    component="th" 
                                    scope="row"
                                    align="center"
                                >
                                    {item.mac_address}
                                </TableCell>
                                <TableCell 
                                    align="center"
                                >
                                    {item.segmentation_id}
                                </TableCell>
                                <TableCell 
                                    align="center"
                                >
                                    {item.segmentation_type}
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default TrunkSubPortsV20;