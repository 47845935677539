export const subnetDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "network_id",
        is_id_field: false,
        field_type: "string", 
        label: "networkFormFieldLabel",
        sort_key: 'network_id',
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        sort_key: 'name',
    },
    {
        field_key: "cidr",
        is_id_field: false,
        field_type: "string", 
        label: "cidrFormFieldLabel",
        sort_key: 'cidr',
    },
    {
        field_key: "gateway_ip",
        is_id_field: false,
        field_type: "string", 
        label: "gatewayIpFormFieldLabel"
    },
    {
        field_key: "ip_version",
        is_id_field: false,
        field_type: "integer", 
        label: "ipVersionFormFieldLabel",
        sort_key: 'ip_version',
    },
    {
        field_key: "enable_dhcp",
        is_id_field: false,
        field_type: "bool", 
        label: "enableDhcpFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const subnetDataForm = [
    {
        field_key: "network_id",
        field_type: "select",
        required: true,
        label: "networkFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "ip_version",
        field_type: "select",
        required: true,
        label: "ipVersionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        default_value: "4",
        items: [
            {
                keyword: "ipv4FormFieldLabel",
                value: "4",
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel",
                value: "6",
                default: false
            }
        ]
    },
    {
        field_key: "cidr",
        field_type: "string",
        required: true,
        label: "cidrFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "gateway_ip",
        field_type: "string",
        required: false,
        label: "gatewayIpFormFieldLabel"
    },
    {
        field_key: "disable_gateway",
        field_type: "bool", 
        required: false,
        label: "disableGatewayFormFieldLabel",
        default_value: false
    },
    {
        field_key: "enable_dhcp",
        field_type: "bool", 
        required: false,
        label: "enableDhcpFormFieldLabel",
        default_value: true
    },
    {
        field_key: "ipv6_address_mode",
        field_type: "select",
        required: false,
        label: "ipv6AddressModeFormFieldLabel",
        default_empty: false,
        default_value: "none",
        multiple: false,
        items: [
            {
                keyword: "notSelectedFormFieldLabel",
                value: "none",
                default: true
            },
            {
                keyword: "slaacFormFieldLabel",
                value: "slaac",
                default: false
            },
            {
                keyword: "dhcpv6StatefullFormFieldLabel",
                value: "dhcpv6-stateful",
                default: false
            },
            {
                keyword: "dhcpv6StatelessFormFieldLabel",
                value: "dhcpv6-stateless",
                default: false
            }
        ]
    },
    {
        field_key: "ipv6_ra_mode",
        field_type: "select",
        required: false,
        label: "ipv6RouterModeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "none",
        multiple: false,
        items: [
            {
                keyword: "notSelectedFormFieldLabel",
                value: "none",
                default: true,
            },
            {
                keyword: "slaacFormFieldLabel",
                value: "slaac",
                default: false
            },
            {
                keyword: "dhcpv6StatefullFormFieldLabel",
                value: "dhcpv6-stateful",
                default: false
            },
            {
                keyword: "dhcpv6StatelessFormFieldLabel",
                value: "dhcpv6-stateless",
                default: false
            }
        ]
    }
]

export const subnetHostRouteForm = [
    {
        field_key: "destination",
        field_type: "string",
        required: false,
        label: "destinationFormFieldLabel"
    },
    {
        field_key: "nexthop",
        field_type: "string",
        required: false,
        label: "nextHopFormFieldLabel"
    }
]

export const subnetAllocationPoolForm = [
    {
        field_key: "start",
        field_type: "string",
        required: false,
        label: "startFormFieldLabel"
    },
    {
        field_key: "end",
        field_type: "string",
        required: false,
        label: "endFormFieldLabel"
    }
]

export const dnsNameserversForm = [
    {
        field_key: "dns_nameserver",
        field_type: "string",
        required: false,
        label: "dnsNameserverFormFieldLabel"
    }
]

export const subnetsSortFields = [
    {
        field_key: "cidr",
        label: "cidrFormFieldLabel"
    },
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "network_id",
        label: "networkFormFieldLabel"
    },
    {
        field_key: "ip_version",
        label: "ipVersionFormFieldLabel"
    }
]


export const subnetsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // },
    {
        keyword: "networkFormFieldLabel",
        value: "network_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "cidrFormFieldLabel",
        value: "cidr",
        type: "text"
    },
    {
        keyword: "ipVersionFormFieldLabel", 
        value: "ip_version", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel", 
                value: "4", 
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel", 
                value: "6", 
                default: false
            }
        ]
    },
    {
        keyword: "enableDhcpFormFieldLabel", 
        value: "enable_dhcp", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "sharedNetworkFormFieldLabel", 
        value: "shared", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const subnetDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]