import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import FirewallRulesTableV20 from './firewallRulesTableV2.0';
import FirewallRuleDetailV20 from './firewallRuleDetailV2.0';
import { firewallRulesFilterMenu } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import FirewallRuleAddV20 from './firewallRuleAddV2.0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService

const FirewallRulesWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, serviceSubmenu, currentTab } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	
	const [firewallRulesData, setFirewallRulesData] = useState([])
	const [firewallRules, setFirewallRules] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedFirewallRule, setSelectedFirewallRule] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [firewallRuleDeleteConfirmDialogOpen, setFirewallRuleDeleteConfirmDialogOpen] = useState(false);
	const [selectedFirewallRules, setSelectedFirewallRules] = useState([])
	const [firewallRulesSortParams, setFirewallRulesSortParams] = useState("")
	const [firewallPolicies, setFirewallPolicies] = useState([])
	const [firewallGroups, setFirewallGroups] = useState([])
	const [adminStateChangeDialogOpen, setAdminStateChangeDialogOpen] = useState(false);
	const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
	const [selectedAdminState, setSelectedAdminState] = useState(true);
	const [selectedSharedState, setSelectedSharedState] = useState(true);
	
	const [firewallRuleFilterQueryParams, setFirewallRuleFilterQueryParams] = useState("")
	const [selectedFirewallRuleFilter, setSelectedFirewallRuleFilter] = useState(firewallRulesFilterMenu[0].value)
	const [selectedFirewallRuleFilterValue, setSelectedFirewallRuleFilterValue] = useState("")
	const [firewallRulesFilter, setFirewallRulesFilter] = useState([...firewallRulesFilterMenu])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const firewallRulesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)
	const firewallPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)
	const firewallGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = firewallRulesFilterMenu.find(item => item.value === selectedFirewallRuleFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		// if (selectedSubnetFilter === 'network_id') {
		// 	let networks_filter = []
		// 	networks.forEach(item => networks_filter.push({ keyword: item.name, value: item.id, default: false }))
		// 	return networks_filter
		// }

		const filterObject = firewallRulesFilterMenu.find(item => item.value === selectedFirewallRuleFilter)
		return filterObject ? filterObject.items : []
	}

	const handleSelectedSharedStateChange = (event) => {
			setSelectedSharedState(event.target.checked)
	}
	const handleSelectedAdminStateChange = (event) => {
			setSelectedAdminState(event.target.checked)
	}

	const handleSharedStateChangeDialogClose = () => {
			setSharedStateChangeDialogOpen(false)
	}

	const handleAdminStateChangeDialogClose = () => {
			setAdminStateChangeDialogOpen(false)
	}

	const handleSharedStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallRulesData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallRules([...selected_list])
			setSharedStateChangeDialogOpen(true)
	}

	const handleAdminStateChangeDialogOpen = (fg_list) => {
			const selected_list = firewallRulesData.filter(f => 
					fg_list.includes(f.id))
			setSelectedFirewallRules([...selected_list])
			setAdminStateChangeDialogOpen(true)
	}

	const handleChangeAdminState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_rule: {enabled: selectedAdminState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onChangeAdminState = async () => {
			handleAdminStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallRules) {
					const resp = await handleChangeAdminState(selectedFirewallRules[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleChangeSharedState = async (fw_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${fw_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {firewall_rule: {shared: selectedSharedState}},
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onChangeSharedState = async () => {
			handleSharedStateChangeDialogClose()
			let err = []
			for (let n in selectedFirewallRules) {
					const resp = await handleChangeSharedState(selectedFirewallRules[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleFirewallRuleFilteredSearch = () => {
			if (selectedFirewallRuleFilter && selectedFirewallRuleFilterValue) {
					setFirewallRuleFilterQueryParams(`${selectedFirewallRuleFilter}=${selectedFirewallRuleFilterValue}`)
			} else {
					setFirewallRuleFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleFirewallRulesDataFormatting = useCallback(() => {
			const formatted_data = firewallRulesData.map((row) => {
					let new_item = {...row}
					
					let source = ""
					let destination = ""
					if (row.source_ip_address) {
							source = `${row.source_ip_address}`
					} else {
							source = "Any"
					}
					if (row.destination_ip_address) {
							destination = `${row.destination_ip_address}`
					} else {
							destination = "Any"
					}
					let protocol = ""
					if (row.protocol) {
							protocol = `${row.protocol.toUpperCase()}`
					} else {
							protocol = "Any"
					}
					let source_ports = ""
					if (row.source_port) {
							source_ports = `${row.source_port}`
					} else {
							source_ports = "Any"
					}
					let destination_ports = ""
					if (row.destination_port) {
							destination_ports = `${row.destination_port}`
					} else {
							destination_ports = "Any"
					}
					new_item.protocol = protocol
					new_item["source"] = source
					new_item["destination"] = destination
					new_item["source_ports"] = source_ports
					new_item["destination_ports"] = destination_ports

					const firewall_policy = firewallPolicies.filter(fp => fp.id === row.firewall_policy_id)[0]
					
					new_item.firewall_policy_id = firewall_policy ? firewall_policy.name : row.firewall_policy_id
					new_item.ip_version = `IPv${row.ip_version}`
					new_item.action = row.action.toUpperCase()
					return new_item
			})
			setFirewallRules(formatted_data)
	},[
			firewallRulesData,
			firewallPolicies
	])

	const handleFirewallRuleFilterReset = () => {
			setSelectedFirewallRuleFilter(firewallRulesFilterMenu[0].value)
			setSelectedFirewallRuleFilterValue("")
			setFirewallRuleFilterQueryParams("")
			handleDataFetch()
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedFirewallRule(firewallRulesData[index].id)
			setSelectedRow(firewallRulesData[index])
	},[firewallRulesData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedFirewallRule(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFirewallRulesSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setFirewallRulesSortParams(sort_param)
			handleDataFetch()
	}

	const handleFirewallRuleDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onFirewallRuleDelete = async () => {
			handleFirewallRuleDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedFirewallRules) {
					const resp = await handleFirewallRuleDelete(selectedFirewallRules[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onFirewallRuleDeleteConfirm = (n_list) => {
			const selected_n_list = firewallRulesData.filter(n => 
					n_list.includes(n.id))
			setSelectedFirewallRules([...selected_n_list])
			setFirewallRuleDeleteConfirmDialogOpen(true)
	}

	const handleFirewallRuleDeleteConfirmDialogClose = () => {
			setFirewallRuleDeleteConfirmDialogOpen(false)
	}

	const getFirewallRulesActionsList = () => {
			let firewallRule_actions = []
			let new_action = {}
			new_action["value"] = "admin_state_update"
			new_action["action"] = handleAdminStateChangeDialogOpen
			new_action["keyword"] = "enableDisableRuleActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallRule_actions.push({...new_action})
			// new_action = {}
			// new_action["value"] = "share_state_update"
			// new_action["action"] = handleSharedStateChangeDialogOpen
			// new_action["keyword"] = "shareStateChangeActionTitle"
			// new_action["button_text"] = "applyButtonTitleText"
			// firewallRule_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "firewallRule_delete"
			new_action["action"] = onFirewallRuleDeleteConfirm
			new_action["keyword"] = "firewallRuleDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			firewallRule_actions.push({...new_action})
			
			return firewallRule_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedFirewallRule(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
								let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}?${firewallRuleFilterQueryParams}${firewallRulesSortParams}`
								const method = "GET"
								const firewallRule_response = await openstackRequest({url:url, method:method, token, })
								if (firewallRule_response.status_code === firewallUrlResponses.get.success_response.status_code) {
										setFirewallRulesData(firewallRule_response.data.firewall_rules)
										if (selectedFirewallRule) {
												const selected_firewallRule = firewallRule_response.data.firewall_rules.filter(item => item.id === selectedFirewallRule)
												if (selected_firewallRule.length > 0) {
														setSelectedRow(selected_firewallRule[0])
												}
										}
								} else {
									setFirewallRulesData([])
								}

								
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			firewallRulesUrl, 
			firewallRuleFilterQueryParams,
			dataFetchingRequired,
			token,
			firewallRulesSortParams,
			selectedFirewallRule
	]);

	useEffect(() => {
			(async () => {
				const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}`
				const method = "GET"
				
				const fp_response = await openstackRequest({
						url:url, 
						method:method,
						token,
				})

				if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
						setFirewallPolicies(fp_response.data.firewall_policies)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			firewallPoliciesUrl,
			token
	]);

	useEffect(() => {
			(async () => {
				const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}`
				const method = "GET"
				
				const fp_response = await openstackRequest({
						url:url, 
						method:method,
						token,
				})

				if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
						setFirewallGroups(fp_response.data.firewall_groups)
				}
					
			})();
	},[
			neutronServiceDomain,
			neutronServiceVersion,
			firewallGroupsUrl,
			token
	]);

	useEffect(() => {
			if (firewallRulesData.length > 0) {
					handleFirewallRulesDataFormatting()
			} else {
				setFirewallRules([])
			}
	},[
			firewallRulesData,
			handleFirewallRulesDataFormatting
	])

	useEffect(() => {
			let fw_groups_filter = []
			if (firewallGroups.length > 0) {
					fw_groups_filter = firewallGroups.map(fg => {
							
							return {keyword: `${fg.name}`, value: fg.id, default: false}
					})
			}

			
			let firewallRule_filter_menu = firewallRulesFilterMenu.map(nt => {
					let new_item = {...nt}
					if (nt.value === "source_firewall_group_id" || 
							nt.value === "destination_firewall_group_id") {
							new_item.items = [...fw_groups_filter]
					}
					return new_item
			})

			setFirewallRulesFilter(firewallRule_filter_menu)
	},[
			firewallRulesData,
			firewallGroups
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const firewallRule_id = location.state ? location.state.firewallRule_id : null
							const firewallRule_index = firewallRulesData.findIndex(v => v.id === firewallRule_id);
							if (firewallRule_index !== -1) {
									setTimeout(() => handleDetailCardOpen(firewallRule_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			firewallRulesData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-fwaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		setSelectedFirewallRuleFilterValue('')
	}, [selectedFirewallRuleFilter])

	const handleActionRun = () => {
		const actionsList = getFirewallRulesActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedFirewallRules([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getFirewallRulesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<FirewallRuleAddV20
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedFirewallRuleFilterValue}
							setCurrentValue={e => setSelectedFirewallRuleFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedFirewallRuleFilterValue}
							setCurrentValue={setSelectedFirewallRuleFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							self_item_titles={false}
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={firewallRulesFilter} 
						currentValue={selectedFirewallRuleFilter}
						setCurrentValue={setSelectedFirewallRuleFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFirewallRuleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFirewallRuleFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuFWaaS}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{firewallRulesData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<FirewallRulesTableV20 
				firewallRulesData={firewallRules}
				seFirewallRulesData={setFirewallRules}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getFirewallRulesActionsList()}
				sortHandler={handleFirewallRulesSorting}
				sortParams={firewallRulesSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<FirewallRuleDetailV20
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedFirewallRule={setSelectedFirewallRule}
				handleDataFetch={handleDataFetch}
				handleDelete={onFirewallRuleDeleteConfirm}
				firewallPolicies={firewallPolicies}
				firewallRules={firewallRulesData}
				adminStateChange={handleAdminStateChangeDialogOpen}
				sharedStateChange={handleSharedStateChangeDialogOpen}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
						open={firewallRuleDeleteConfirmDialogOpen}
						onClose={handleFirewallRuleDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.firewallRuleDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.firewallRuleDeleteConfirmText}`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onFirewallRuleDelete, 
								sx: {color: 'primary.main'}}]}
				/>
				<CustomDialog
						open={adminStateChangeDialogOpen}
						onClose={handleAdminStateChangeDialogClose}
						dialogTitle={{
								title: defaultTexts.enableDisableRuleTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onChangeAdminState, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={selectedAdminState}
								setCurrentValue={handleSelectedAdminStateChange}
								label={defaultTexts.enabledFormFieldLabel}
								default_value={false}
						/>
				</CustomDialog>
				<CustomDialog
						open={sharedStateChangeDialogOpen}
						onClose={handleSharedStateChangeDialogClose}
						dialogTitle={{
								title: defaultTexts.changeSharedStateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onChangeSharedState, 
								sx: {color: 'primary.main'}}]}
				>
						<CustomCheckboxField
								currentValue={selectedSharedState}
								setCurrentValue={handleSelectedSharedStateChange}
								label={defaultTexts.sharedFormFieldLabel}
								default_value={false}
						/>
				</CustomDialog>
				{error && <CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
				/>}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default FirewallRulesWrapperV20