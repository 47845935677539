import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants} from '../../../../../../config/openStackConstants';
import { firewallGroupDataForm } from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const FirewallGroupAddV20 = (props) => {
  const { handleDataFetch } = props
  const { firewallPolicies } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successFirewallGroupAdd, setSuccessFirewallGroupAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const firewallGroupsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessFirewallGroupAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddFirewallGroupFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in firewallGroupDataForm) {
          if (
              firewallGroupDataForm[n].field_type === "string" || 
              firewallGroupDataForm[n].field_type === "select"
              ) {
              new_form_data[firewallGroupDataForm[n].field_key] = firewallGroupDataForm[n].default_value ? 
              firewallGroupDataForm[n].default_value : 
              ""
          } else if (firewallGroupDataForm[n].field_type === "bool") {
              new_form_data[firewallGroupDataForm[n].field_key] = firewallGroupDataForm[n].default_value ? 
              firewallGroupDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (firewallGroupDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (firewallGroupDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }


  const getDataForm = () => {
      let form = [...firewallGroupDataForm]
      if (!formData.project_id) {
          form = form.filter(item => item.field_key !== "ingress_firewall_policy_id" && 
              item.field_key !== "egress_firewall_policy_id"
          )
      }
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "ingress_firewall_policy_id") {
                      const in_policies = firewallPolicies.filter(fp => fp.project_id === formData.project_id)
                      const in_filter = in_policies.map(p => {
                          return {keyword: p.name, value: p.id, default: false}
                      })
                      
                      form_field_options["items"] = [...in_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  } else if (field.field_key === "egress_firewall_policy_id") {
                      const out_policies = firewallPolicies.filter(fp => fp.project_id === formData.project_id)
                      const out_filter = out_policies.map(p => {
                          return {keyword: p.name, value: p.id, default: false}
                      })
                      
                      form_field_options["items"] = [...out_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in firewallGroupDataForm) {
          if (firewallGroupDataForm[n].required && !formData[firewallGroupDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[firewallGroupDataForm[n].field_key] = {}
              updatedDataFormOptions[firewallGroupDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[firewallGroupDataForm[n].field_key]["errorText"] = defaultTexts[firewallGroupDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddFirewallGroup = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = {...formData}
          if (formData.ingress_firewall_policy_id.length === 0) {
              data.ingress_firewall_policy_id = null
          }
          if (formData.egress_firewall_policy_id.length === 0) {
              data.egress_firewall_policy_id = null
          }

          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}`
          const method = "POST"
          const firewallGroup_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {firewall_group: data},
              token,
          })
          if (firewallGroup_response.status_code === firewallUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddFirewallGroupFormReset()
              setFormDataOptions({})
          } else {
              const error_response = firewallUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === firewallGroup_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: firewallGroup_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = firewallUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: firewallGroup_response.error
                  }
                  setError(errorObject)
              }
          }          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successFirewallGroupAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in firewallGroupDataForm) {
              if (
                  firewallGroupDataForm[n].field_type === "string" || 
                  firewallGroupDataForm[n].field_type === "select"
                  ) {
                  new_form_data[firewallGroupDataForm[n].field_key] = firewallGroupDataForm[n].default_value ? 
                  firewallGroupDataForm[n].default_value : 
                  ""
              } else if (firewallGroupDataForm[n].field_type === "bool") {
                  new_form_data[firewallGroupDataForm[n].field_key] = firewallGroupDataForm[n].default_value ? 
                  firewallGroupDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddFirewallGroup}
        formReset={handleAddFirewallGroupFormReset}
        customTexts={{
          title: defaultTexts.addFirewallGroupDialogTitle
        }}
      />

      {
        successFirewallGroupAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successFirewallGroupAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successFirewallGroupAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default FirewallGroupAddV20