import { imageDataSchema as imageDataSchemaV2 }  from '../../_data/openstack/glance/images/v2';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  imageGlanceApiUrls: [
    {
      api_version: "v2",
      urls: [
        {id: "", keyword: 'imagesUrl', url: "images", api_group: 'imagesApiUrls'},
      ]
    }
  ],
  imagesTableConfig: [
    {
      api_version: "v2",
      rows_per_page: 10,
      columns: [
        ...imageDataSchemaV2
      ]
    }
  ]
}

const imageGlanceSlice = createSlice({
  name: 'imageGlance',
  initialState,
  reducers: {
    setImageGlanceApiUrls: (state, action) => {
        state.imageGlanceApiUrls = action.payload.imageGlanceApiUrls
    },
    setImagesTableConfig: (state, action) => {
      state.imagesTableConfig = action.payload.imagesTableConfig
    },
    imageGlanceApiUrlsReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setImageGlanceApiUrls, 
    setImagesTableConfig,
    imageGlanceApiUrlsReset
} = imageGlanceSlice.actions

export default imageGlanceSlice.reducer