import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import SearchIcon from '@mui/icons-material/Search';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import SnapshotsTableV3 from './groupSnapshotsTableV3';
import { snapshotsFilterMenu } from '../../../../../_data/openstack/cinder/group_snapshots/v3';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import { groupsUrl as groupUrlResponses } from '../../../../../_api_responses/openstack/cinder/group_snapshots/v3';
import GroupSnapshotAddV3 from './GroupSnapshotAddV3';
import useWindowDimensions from '../../../../_common/WindowDimensions'
import CustomPopover from '../../../../_common/CustomPopover'
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'

const SERVICE_NAME = openStackServices.volumeService

const SnapshotsWrapperV3 = (props) => {
	const theme = useTheme()
	const location = useLocation()
	const navigate = useNavigate()
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const token = useSelector(state => state.profile.x_auth_token)
	const { width } = useWindowDimensions()
	const [snapshotsData, setSnapshotsData] = useState([])
	const [snapshots, setSnapshots] = useState([])
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [snapshotDeleteConfirmDialogOpen, setSnapshotDeleteConfirmDialogOpen] = useState(false);
	const [selectedSnapshots, setSelectedSnapshots] = useState([])
	const [snapshotsSortParams, setSnapshotsSortParams] = useState("")
	
	const [snapshotFilterQueryParams, setSnapshotFilterQueryParams] = useState("")

	const [groups, setGroups] = useState([])
	const [groupTypes, setGroupTypes] = useState([]);

	const [selectedGroupSnapshotIds, setSelectedGroupSnapshotIds] = useState([])
	const [searchBy, setSearchBy] = useState(snapshotsFilterMenu.length > 0 ? snapshotsFilterMenu[0].value : '')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const snapshotsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupSnapshotsUrl)[0].url)
	const groupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
	const groupTypesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = snapshotsFilterMenu.find(item => item.value === searchBy)
		return filterObject ? filterObject.type : ''
	}
	
	const getSearchSelectItems = () => {
		if (searchBy === 'group_id') {
			let select_items = []

			groups.forEach(item => select_items.push({
				value: item.id,
				keyword: item.name,				
			}))
			
			return select_items
		} else {
			const filterObject = snapshotsFilterMenu.find(item => item.value === searchBy)
			return filterObject ? filterObject.items : []
		}
	}

	const isValidSelectValue = (value, list) => {
		const findObject = list.find(item => item.value === value)
		return findObject ? true : false
	}

	const handleFilteredSearch = () => {
		if (searchBy && searchKeyword) {
			setSnapshotFilterQueryParams(`?${searchBy}=${searchKeyword}`)
		} else {
			setSnapshotFilterQueryParams('')
		}

		handleDataFetch()
	}

	const handleSnapshotsDataFormatting = useCallback(() => {
			const formatted_data = snapshotsData.map((item) => {
					let new_item = {...item}
					
					const group = groups.filter(gr => gr.id === item.group_id)
					if (group.length > 0) {
							new_item.group_id = group[0].name ? group[0].name : group[0].id
					}
					const group_type = groupTypes.filter(grt => grt.id === item.group_type_id)
					if (group_type.length > 0) {
							new_item.group_type_id = group_type[0].name ? group_type[0].name : group_type[0].id
					}
					return new_item
			})
			setSnapshots(formatted_data)
	},[
			snapshotsData,
			groups,
			groupTypes,
	])

	const handleSnapshotFilterReset = () => {
		setSearchKeyword('')
		setSearchBy(snapshotsFilterMenu[0].value)
		setSnapshotFilterQueryParams("")
		handleDataFetch()
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleSnapshotsSorting = (field,direction) => {
			const sort_param = `sort=${field}:${direction}`
			setSnapshotsSortParams(sort_param)
			handleDataFetch()
	}

	const handleSnapshotDelete = async (s_id) => {
			
		const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${s_id}`
		const method = "DELETE"
		
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				token,
		})

		if (vt_response.status_code === snapshotUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return vt_response.error
		}
			
	};

	const onSnapshotDelete = async () => {
			handleSnapshotDeleteConfirmDialogClose()
			let err = []
			for (let s in selectedSnapshots) {
					const resp = await handleSnapshotDelete(selectedSnapshots[s].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onSnapshotDeleteConfirm = (s_list) => {
			const selected_s_list = snapshotsData.filter(s => 
					s_list.includes(s.id))
			setSelectedSnapshots([...selected_s_list])
			setSnapshotDeleteConfirmDialogOpen(true)
	}

	const handleSnapshotDeleteConfirmDialogClose = () => {
			setSnapshotDeleteConfirmDialogOpen(false)
	}

	const getSnapshotsActionsList = () => {
			let snapshot_actions = []
			let new_action = {}
			new_action["value"] = "snapshot_delete"
			new_action["action"] = onSnapshotDeleteConfirm
			new_action["keyword"] = "snapshotDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			snapshot_actions.push({...new_action})
			
			return snapshot_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [defaultAdminProject, token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)

							const firstSymbol = snapshotFilterQueryParams ? '&&' : '?'
							const sort_params = snapshotsSortParams ? firstSymbol + snapshotsSortParams : ''
							
							let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail${snapshotFilterQueryParams}${sort_params}`
							
							const method = "GET"
							const snapshots_response = await volumeCinderRequest({url:url, method:method, token, })

							if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
								setSnapshotsData(snapshots_response.data.group_snapshots)
							} else {
								setSnapshotsData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			snapshotsUrl, 
			snapshotFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			snapshotsSortParams,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let all_group_types = []
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}`
				const method = "GET"

				const group_types_response = await volumeCinderRequest({url:url, method:method, token, })
				if (group_types_response.status_code === groupUrlResponses.get.success_response.status_code) {
						all_group_types = [...all_group_types, ...group_types_response.data.group_types]
				}
				url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}?is_public=false`
				const response = await volumeCinderRequest({url:url, method:method, token, })
				if (response.status_code === groupUrlResponses.get.success_response.status_code) {
						const private_vt = response.data.group_types.filter(vt => !all_group_types.map(i => i.id).includes(vt.id))
						all_group_types = [...private_vt, ...all_group_types]
				}
				setGroupTypes([...all_group_types])
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			groupTypesUrl,
			token,
	]);

	useEffect(() => {
			(async () => {
					
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail?`
				const method = "GET"

				const group_response = await volumeCinderRequest({url:url, method:method, token, })
				if (group_response.status_code === groupUrlResponses.get.success_response.status_code) {
						setGroups(group_response.data.groups)
				}
					
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			groupsUrl,
			token,
	]);


	useEffect(() => {
		if (snapshotsData.length > 0) {
			handleSnapshotsDataFormatting()
		} else {
			setSnapshots([])
		}
	},[
		snapshotsData,
		handleSnapshotsDataFormatting
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		if (location.state && location.state.group_snapshot_id && snapshotsData.length > 0) {
			const find_snapshot = snapshotsData.find(item => item.id === location.state.group_snapshot_id)

			if (find_snapshot) {
				setSearchBy('name')
				setSearchKeyword(find_snapshot.name)
				setSnapshotFilterQueryParams(`?name=${find_snapshot.name}`)
				setDataFetchingRequired(true)
			}

			navigate(location.path, {})
		}
	}, [location, navigate, snapshotsData])

	const handleActionRun = () => {
		const selected_snapshot_list = snapshotsData.filter(item => selectedGroupSnapshotIds.includes(item.id))
		setSelectedSnapshots([...selected_snapshot_list])
		
		if (currentAction === 'snapshot_delete') {
			setSnapshotDeleteConfirmDialogOpen(true)
		}
	}

	const handleDeselectAll = () => {
		setSelectedGroupSnapshotIds([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedGroupSnapshotIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getSnapshotsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<GroupSnapshotAddV3
					handleDataFetch={handleDataFetch}
					groups={groups}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={searchKeyword}
							setCurrentValue={e => setSearchKeyword(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={ isValidSelectValue(searchKeyword, getSearchSelectItems()) ? searchKeyword : '' }
							setCurrentValue={setSearchKeyword}
							item_titles={defaultTexts}
							self_item_titles={ searchBy === 'group_id' ? true : false }
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={snapshotsFilterMenu} 
						currentValue={searchBy}
						setCurrentValue={setSearchBy}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleSnapshotFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getGroupSnapshotsHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuGroupSnapshots} ({selectedGroupSnapshotIds.length}/{snapshotsData.length})
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>			

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 5px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedGroupSnapshotIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getGroupSnapshotsTable = () => {
		return (
			<SnapshotsTableV3 
				data={snapshots}
				setData={setSnapshots}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getSnapshotsActionsList()}
				sortHandler={handleSnapshotsSorting}
				sortParams={snapshotsSortParams}
				setSelectedGroupSnapshotIds={setSelectedGroupSnapshotIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}
	
	return (
		<>
			<Box>
				{isLoading && <CustomBackdrop open={isLoading} />}

				{ getGroupSnapshotsHeader() }

				{ !isLoading && getGroupSnapshotsTable() }						
					
				<CustomDialog
					open={snapshotDeleteConfirmDialogOpen}
					onClose={handleSnapshotDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.snapshotDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.snapshotDeleteConfirmText}: [${selectedSnapshots.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onSnapshotDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}
			</Box>
		</>
	)
}

export default SnapshotsWrapperV3