import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const BackupSpecsV3 = (props) => {
	const theme = useTheme()
	
	const { backupData,selectedRow, handleNavigateToVolume } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '320px',
		justifyContent: 'flex-start',
	}

	return (
		<>
				<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
						<Grid item xs={12} lg={6}>
								<WrapperBox sx={detailTabCardConfig}>
										<Stack direction="column" spacing={1} sx={{width: "90%"}}>
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.nameFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.name ? backupData.name : ""}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.idFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData ? backupData.id : ""}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.availabilityZoneFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.availability_zone ? backupData.availability_zone : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.descriptionFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.description ? backupData.description : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.statusFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.status.toUpperCase()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.encryptionKeyFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.encryption_key_id ? backupData.encryption_key_id : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.hasDependendBackupFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.has_dependent_backups ? defaultTexts.formValueYes : defaultTexts.formValueNo}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.objectCountFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.object_count ? backupData.object_count : ""}
														</CustomText>
												</Stack>
												<Divider />
										</Stack>
								</WrapperBox>
						</Grid>
						<Grid item xs={12} lg={6}>
								<WrapperBox
										sx={detailTabCardConfig}
								>
										<Stack 
												direction="column" 
												spacing={1} 
												sx={{width: "90%"}}
										>
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.sizeFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.size}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
														onClick={() => 
																handleNavigateToVolume(selectedRow.volume_id,"/volumes")
														}
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.volumeFormFieldLabel}
														</CustomText>
														<CustomText size="p" sx={{color: "primary.main", cursor: "pointer" }}>
																{backupData.volume_id}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.incrementalFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.is_incremental  ? defaultTexts.formValueYes : defaultTexts.formValueNo}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.containerFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.container ? backupData.container : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.dataTimestampFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.data_timestap ? new Date(backupData.data_timestap).toLocaleString() : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.createTimeHeaderLabel}
														</CustomText>
														<CustomText size="p">
																{new Date(backupData.created_at).toLocaleString()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.updatedAtFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{new Date(backupData.updated_at).toLocaleString()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.failReasonFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{backupData.fail_reason ? backupData.fail_reason : ""}
														</CustomText>
												</Stack>
												<Divider />
										</Stack>
								</WrapperBox>
						</Grid>
				</Grid>
		</>
	)
}

export default BackupSpecsV3