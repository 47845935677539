import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import useWindowDimensions from '../../_common/WindowDimensions'
import HomePromo from './HomePromo'
import LoginForm from '../forms/loginForm';

const HomeLoginWrapper = () => {
  const { height } = useWindowDimensions()
  
  const [promoHeight, setPromoHeight] = useState(height - 64)

  useEffect(() => {
    setPromoHeight(height - 64)
  }, [height])

  return (
    <Grid container>
      <Grid lg={4}>
        <LoginForm promoHeight={promoHeight} />
      </Grid>
      <Grid lg={8}>
        <HomePromo promoHeight={promoHeight} />
      </Grid>
    </Grid>
  )
}

export default HomeLoginWrapper