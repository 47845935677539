import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CustomText from './CustomText';
import WrapperBox from './WrapperBox';

const IncorrectServiceVersionNote = (props) => {
    const uiMode = useSelector(state => state.settings.uiMode);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { service_title } = props
    const { service_version } = props

    const theme = useTheme();

    return (
        <WrapperBox sx={{minHeight: "70vh"}}>
            <Box
                sx={{
                    textAlign: 'center',
                    borderRadius: 3,
                    p: 10,
                    backgroundColor: uiMode === 'dark' ? 
                        'background.paper' : 
                        theme.palette.vLightGray,
                }}
            >
                <CustomText size={'h6'} sx={{color: 'customPink', mb: 2}}>{service_title} ({service_version})</CustomText>
                <CustomText size={'h6'}>
                    {defaultTexts.notSupportedServiceVersionNote}
                </CustomText>
            </Box>
        </WrapperBox>
    )
};

export default IncorrectServiceVersionNote;