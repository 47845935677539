import React, { useState, useCallback } from 'react'
import Billing from './billing/Billing'
import Planning from './planning/Planning'

const BillingContent = () => {  
  const [billingMenu, setBillingMenu] = useState([
		{ keyword: "billingLabel", navigation: "billing", is_active: true, component: Billing },
		{ keyword: "planningAndCompareLabel", navigation: "planning", is_active: false, component: Planning },
	])

  const getCurrentTab = () => {
		const currentItem = billingMenu.filter(item => item.is_active)[0]
		return currentItem
	}

  const [currentTab, setCurrentTab] = useState(getCurrentTab())

  const changeMenuActiveTab = useCallback((navigation) => {
		const updated_menu = billingMenu.map(item => {
			if (item.navigation === navigation) {
				item.is_active = true
				setCurrentTab(item)
			} else {
				item.is_active = false
			}

			return item
		})

		setBillingMenu(updated_menu)
	}, [billingMenu])

  return (
		<currentTab.component 
			billingMenu={billingMenu}
			changeMenuActiveTab={changeMenuActiveTab}
		/>
  )
}

export default BillingContent