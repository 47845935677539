import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import CustomText from '../CustomText';
import { ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CustomDateTimePicker from '../CustomDateTimePicker';
import WrapperBox from '../WrapperBox';
import parse from 'html-react-parser';


const CustomDateTimeField = (props) => {
    const { error, errorText } = props
    const { currentValue, setCurrentValue } = props;
    const { label } = props;
    const { variant, required } = props;
    const { field_key } = props
    const { withHelp, helpText } = props
    const { sx } = props;
    const { index, zIndex, helpTextWidth, helpTextHeight } = props

    const [pickerOpen, setPickerOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleHelpTextClose = () => {
        setOpen(false)
    }

    const handleDateTimePickerOpen = () => {
        setPickerOpen(true)
    }

    const handlePickerClose = () => {
        setPickerOpen(false)
    }

    const onChange = (event) => {
        setCurrentValue(event,field_key,index)
    }

    if (withHelp) {
        return (
            <Grid 
            container 
            spacing={2} 
            direction="row" 
            alignItems="center"
            justifyContent="start"
        >
            <Grid item xs={10} lg={11}>
                <FormControl 
                    required={required} 
                    error={error} 
                    sx={{width: '100%',...sx}} 
                    variant={variant ? variant : "outlined"}
                >
                    <InputLabel color="primary">
                        {label}
                    </InputLabel>
                    <IconButton onClick={handleDateTimePickerOpen}>
                        <CalendarMonthIcon />
                    </IconButton>
                    {error && 
                        <FormHelperText>
                            {errorText}
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <ClickAwayListener onClickAway={handleHelpTextClose}>
                <IconButton 
                    onClick={handleClick('top-start')}
                >
                    <HelpIcon />
                </IconButton>
                </ClickAwayListener>
                <Popper
                    sx={{ zIndex: zIndex ? zIndex : 2200 }}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                >
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper 
                            sx={{
                                width: helpTextWidth ? helpTextWidth : 200, 
                                height: helpTextHeight ? helpTextHeight : undefined, 
                                p: 2, 
                                backgroundColor: "vLightGray",
                            }}
                        >
                            <CustomText 
                                size="p" 
                                sx={{color: "primary.main"}}
                            >
                                {parse(helpText)}
                            </CustomText>
                        </Paper>
                    </Fade>
                    )}
                </Popper>
            </Grid>
            <CustomDateTimePicker 
                open={pickerOpen}
                onOpen={setPickerOpen}
                datetime={currentValue}
                setDatetime={onChange}
                onClose={handlePickerClose}
                onAccept={handlePickerClose}
            />
        </Grid>
        )
    }

    return (
        <FormControl 
            required={required} 
            error={error} 
            sx={{width: '100%',...sx}} 
            variant={variant ? variant : "outlined"}
        >
            <CustomText size="p" color="text.secondary" sx={{my: 2}}>
                {label}
            </CustomText>
            <WrapperBox 
                sx={{
                    border: 1,
                    borderRadius: 1,
                    borderColor: "primary",
                    p:1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start"
                }}
            >
                {currentValue && currentValue.toString()}
                <IconButton onClick={handleDateTimePickerOpen}>
                    <CalendarMonthIcon />
                </IconButton>
            </WrapperBox>
            
            {error && 
                <FormHelperText>
                    {errorText}
                </FormHelperText>
            }
            <CustomDateTimePicker 
                open={pickerOpen}
                onOpen={setPickerOpen}
                datetime={currentValue}
                setDatetime={onChange}
                onClose={handlePickerClose}
                onAccept={handlePickerClose}
            />
        </FormControl>
    )
};

export default CustomDateTimeField;