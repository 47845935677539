import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import HeaderBalance from './HeaderBalance'

const HeaderBilling = () => {
  const theme = useTheme()

  const langTexts = useSelector(state => state.texts.langTexts)
  const hasBilling = useSelector(state => state.profile.hasBilling)
  const mode = useSelector(state => state.settings.uiMode)
  
  const sxWrapper = {
    height: '32px',
    backgroundColor: mode === 'light' ? theme.palette.customGrayLight : theme.palette.customLightBlack,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: mode === 'dark' ? `${theme.palette.customGray} 1px solid` : 'none',
  }

  const sxLabel = {
    height: '32px',
    padding: '0px 10px',
    borderRight: mode === 'light' ? `${theme.palette.customGrayDark} 1px solid` : `${theme.palette.customGray} 1px solid`,
    color: mode === 'light' ? theme.palette.customGrayDark : theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
  }

  const sxAmount = {
    padding: '0px 10px',
    color: mode === 'light' ? theme.palette.customBlack : theme.palette.primary.main,
  }

  return (
    <>
      {
        hasBilling &&
        <Box sx={sxWrapper}>
          <Box sx={sxLabel}>{langTexts.balanceLabel}</Box>
          <Box sx={sxAmount}>
            <HeaderBalance />
          </Box>
        </Box>
      }
    </>
  )
}

export default HeaderBilling