export const getCurrentMonthFirstDate = () => {
  let jsDate = new Date()
  return jsDate.getFullYear() + '-' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '-01'
}

export const getCurrentMonthLastDate = () => {
  let jsDate = new Date()
  jsDate = new Date(jsDate.getFullYear(), jsDate.getMonth() + 1, 0)
  return jsDate.getFullYear() + '-' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '-' + ('0' + jsDate.getDate()).slice(-2)
}

export const getTodayDate = () => {
  let jsDate = new Date()
  return jsDate.getFullYear() + '-' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '-' + ('0' + jsDate.getDate()).slice(-2)
}

export const getOneYearAgoDate = () => {
  let jsDate = new Date()
  return (jsDate.getFullYear() - 1) + '-' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '-' + ('0' + jsDate.getDate()).slice(-2)
}

export const addTimeToDate = (d, par) => {
  let fdate = new Date(d)

  const year = fdate.getFullYear()
  const month = ('0' + (fdate.getMonth() + 1)).slice(-2)
  const day = ('0' + fdate.getDate()).slice(-2)

  if (par === 'end') {
    fdate = year + '-' + month + '-' + day + ' 23:59:59'
  } else {
    fdate = year + '-' + month + '-' + day + ' 00:00:00'
  }

  return fdate
}

export const getTextedDate = (date) => {
  const jsDate = new Date(date)	
  return ('0' + jsDate.getDate()).slice(-2) + '.' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '.' + jsDate.getFullYear()
}

export const getTextedDateTime = (date) => {
  const jsDate = new Date(date)	
  return ('0' + jsDate.getDate()).slice(-2) + '.' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '.' + jsDate.getFullYear() + ' ' + ('0' + jsDate.getHours()).slice(-2) + ':' + ('0' + jsDate.getMinutes()).slice(-2) + ':' + ('0' + jsDate.getSeconds()).slice(-2)
}

export const getMonthShortName = (month, defaultTexts) => { 
  const months = {
    'm1': defaultTexts.janShortMonthName,
    'm2': defaultTexts.febShortMonthName,
    'm3': defaultTexts.marShortMonthName,
    'm4': defaultTexts.aprShortMonthName,
    'm5': defaultTexts.mayShortMonthName,
    'm6': defaultTexts.junShortMonthName,
    'm7': defaultTexts.julShortMonthName,
    'm8': defaultTexts.augShortMonthName,
    'm9': defaultTexts.sepShortMonthName,
    'm10': defaultTexts.octShortMonthName,
    'm11': defaultTexts.novShortMonthName,
    'm12': defaultTexts.decShortMonthName,
  }

  return months[`m${month}`] ? months[`m${month}`] : ''
}

export const getCurrrentYearFirstDate = () => {
  let jsDate = new Date()
  return jsDate.getFullYear() + '-01-01'
}

export const getCurrrentYearLastDate = () => {
  let jsDate = new Date()
  return jsDate.getFullYear() + '-12-31'
}

export const getMonthShortNameFromYearAndMonth = (d, defaultTexts) => {
  const arr = d.split('-')
  return arr.length > 1 ? getMonthShortName(parseInt(arr[1]), defaultTexts) : ''
}

export const getCurrentMonthLastDay = () => {
  let jsDate = new Date()
  jsDate = new Date(jsDate.getFullYear(), jsDate.getMonth() + 1, 0)
  return ('0' + jsDate.getDate()).slice(-2)
}