import React from 'react'
import Box from '@mui/material/Box'

const WrapperBox = (props) => {
  const { sx } = props
  
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minWidth: "100%",
      alignItems: 'center', 
      justifyContent: 'center',
      ...sx
    }}>
      {props.children}
    </Box>
  )
}

export default WrapperBox