export function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            // If the current value is an array or object, recursively copy it
            if (Array.isArray(obj[key]) || typeof obj[key] === 'object') {
                if (key === 'properties') {
                    copy[key] = obj[key].map(item => deepCopy(item));
                } else if (key === 'operators' || key === 'enum') {
                    copy[key] = obj[key].slice(); // Create a shallow copy of the array
                } else {
                    copy[key] = deepCopy(obj[key]);
                }
            } else {
                // If it's a primitive value or a function, just assign it directly
                copy[key] = obj[key];
            }
        }
    }
    return copy;
}