import { useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { profileReset } from '../../../store/reducers/profileSlice';
import { drawerStateReset } from '../../../store/reducers/drawerSlice';
import { openStackConfigReset } from '../../../store/reducers/openStackSlice';
import { textsReset, setLangTexts } from '../../../store/reducers/textsSlice';
import { setCurrentLang } from '../../../store/reducers/settingsSlice';
import { computeNovaApiUrlsReset } from '../../../store/reducers/computeNovaSlice';
import { imageGlanceApiUrlsReset } from '../../../store/reducers/imageGlanceSlice';
import { networkNeutronApiUrlsReset } from '../../../store/reducers/networkNeutronSlice';
import { blockStorageCinderApiUrlsReset } from '../../../store/reducers/blockStorageCinderSlice';
import { getTranslatedText } from '../../../lang/translator';

const useAutoLogout = (props) => {
	const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
	const sessionDatetime = useSelector(state => state.profile.sessionDatetime)
	const defaultLang = useSelector(state => state.settings.defaultLang)
  const currentLang = useSelector(state => state.settings.currentLang)
	const defaultTexts = useSelector(state => state.texts.langTexts)

	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!isAuthenticated || !sessionDatetime) return

		const handleLangChange = (value) => {
			dispatch(textsReset())
	
			if (defaultLang !== value) {
				getTranslatedText(value).then((translatedItems) => {
					let new_data = { ...defaultTexts, ...translatedItems }
					dispatch(setLangTexts({langTexts: new_data}))
					dispatch(setCurrentLang({currentLang: value}))
				})
			} else {
				dispatch(setCurrentLang({currentLang: value}))
			}
		}

		const handleLogout = () => {
			const now = new Date()
			const loginDate = new Date(sessionDatetime)
			const diffInHours = Math.abs(now - loginDate) / 36e5

			if (diffInHours >= 24) {
				dispatch(profileReset())
				dispatch(drawerStateReset())
				dispatch(openStackConfigReset())
				handleLangChange(currentLang)
				dispatch(computeNovaApiUrlsReset())
				dispatch(networkNeutronApiUrlsReset())
				dispatch(blockStorageCinderApiUrlsReset())
				dispatch(imageGlanceApiUrlsReset())
				navigate('/')
			}
		}

		handleLogout()
		const delay = 60 * 60 * 1000
		const interval = setInterval(() => handleLogout(), delay)
		
		return () => clearInterval(interval)
	}, [isAuthenticated, dispatch, navigate, sessionDatetime, currentLang, defaultLang, defaultTexts])
}

export default useAutoLogout