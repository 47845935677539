import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PaymentIcon from '@mui/icons-material/Payment'
import SettingsIcon from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import HeaderBalance from './HeaderBalance'

const BurgerMenu = (props) => {
  const theme = useTheme()
  const { handleCloseBurgerDrawer, logoutHandler, handleOpenSettingsDrawer } = props

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const hasBilling = useSelector(state => state.profile.hasBilling)

  const sxHead = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '50px',
    padding: '0px 15px',
  }

  const sxIcon = {
    color: theme.palette.primary.main,
  }

  const sxStack = {
    height: '50px',
    padding: '0px 20px',
    fontSize: '18px',
  }

  const sxStackClickable = {
    height: '50px',
    padding: '0px 20px',
    fontSize: '18px',
    cursor: 'pointer',
    color: theme.palette.primary.main,

    '&:hover': {
      background: alpha(theme.palette.customGray, 0.3),
    }
  }

  return (
    <>
      <Box sx={sxHead}>
        <IconButton sx={sxIcon} onClick={handleCloseBurgerDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>

      {
        hasBilling &&
        <>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            sx={sxStack}
          >
            <Box sx={{ margin: '5px 10px 0px 0px' }}>
              <PaymentIcon fontSize='large' />
            </Box>
            <Box>{defaultTexts.balanceLabel}: <HeaderBalance /></Box>
          </Stack>
          <Divider />
        </>
      }      

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={sxStackClickable}
        onClick={handleOpenSettingsDrawer}
      >
        <Box sx={{ margin: '5px 10px 0px 0px' }}>
          <SettingsIcon fontSize='large' />
        </Box>
        <Box>{defaultTexts.settings}</Box>
      </Stack>
      <Divider />

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={sxStackClickable}
        onClick={logoutHandler}
      >
        <Box sx={{ margin: '5px 10px 0px 0px', color: theme.palette.primary.main }}>
          <Logout fontSize='large' />
        </Box>
        <Box>{defaultTexts.logout}</Box>
      </Stack>
      <Divider />
    </>
  )
}

export default BurgerMenu