export const portDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "network_id",
        is_id_field: false,
        field_type: "string", 
        label: "networkFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        sort_key: 'name',
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel",
        sort_key: 'status',
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "upStateFormFieldLabel",
        textOnFalse: "downStateFormFieldLabel",
        sort_key: 'admin_state_up',
    },
    {
        field_key: "mac_address",
        is_id_field: false,
        field_type: "string", 
        label: "macAddressFormFieldLabel"
    },
    {
        field_key: "fixed_ips",
        is_id_field: false,
        field_type: "list", 
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "device_owner",
        is_id_field: false,
        field_type: "string", 
        label: "attachedToFormFieldLabel"
    },
    {
        field_key: "port_security_enabled",
        is_id_field: false,
        field_type: "bool",
        label: "portSecurityEnabledFormFieldLabel"
    },
    {
        field_key: "qos_policy_id",
        is_id_field: false,
        field_type: "string",
        label: "qosPolicyFormFieldLabel"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const portDataForm = [
    {
        field_key: "network_id",
        field_type: "select",
        required: true,
        label: "networkFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "binding:vnic_type",
        field_type: "select",
        required: false,
        label: "vnicTypeFormFieldLabel",
        self_item_titles: false,
        default_value: "normal",
        items: [
            {
                keyword: "normalVnicTypeChoiceText",
                value: "normal",
                default: true
            },
            {
                keyword: "directVnicTypeChoiceText",
                value: "direct",
                default: false
            },
            {
                keyword: "directPhysicalVnicTypeChoiceText",
                value: "direct-physical",
                default: false
            },
            {
                keyword: "macVTapVnicTypeChoiceText",
                value: "macvtap",
                default: false
            },
            {
                keyword: "bareMetalVnicTypeChoiceText",
                value: "baremetal",
                default: false
            },
            {
                keyword: "virtioForwarderVnicTypeChoiceText",
                value: "virtio-forwarder",
                default: false
            },
            {
                keyword: "smartNicVnicTypeChoiceText",
                value: "smart-nic",
                default: false
            },
            {
                keyword: "remoteManagedVnicTypeChoiceText",
                value: "remote-managed",
                default: false
            }
        ]
    },
    {
        field_key: "ip_address_or_subnet",
        field_type: "bool",
        required: false,
        default_value: false,
        label: "ipAddressOrSubnetFormFieldLabel",
        non_openstack_field: true
    },
    {
        field_key: "subnet_id",
        field_type: "select",
        required: false,
        label: "subnetFormFieldLabel",
        self_item_titles: true,
        default_empty: true,
        non_openstack_field: true
    },
    {
        field_key: "ip_address",
        field_type: "string",
        required: false,
        label: "ipAddressFormFieldLabel",
        non_openstack_field: true
    },
    {
        field_key: "port_security_enabled",
        field_type: "bool", 
        required: false,
        label: "portSecurityEnabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "security_groups",
        field_type: "select",
        required: false,
        label: "securityGroupsFormFieldLabel",
        self_item_titles: true,
        default_empty: true,
        multiple: true
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateUpFormFieldLabel",
        default_value: true
    }
    //{
    //    field_key: "propagate_uplink_status",
    //    field_type: "bool", 
    //    required: false,
    //    label: "propagateUplinkStatusFormFieldLabel",
    //    default_value: true
    //},
    //{
    //    field_key: "mac_learning_enabled",
    //    field_type: "bool", 
    //    required: false,
    //    label: "macLearningEnabledFormFieldLabel",
    //    default_value: true
    //}
]

export const portsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        label: "adminStateFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    }
]


export const portsFilterMenu = [
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // },
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "adminStateFormFieldLabel", 
        value: "admin_state_up", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "ipAddressFormFieldLabel",
        value: "fixed_ips=ip_address",
        type: "text"
    }
]

export const portDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "port_security_enabled",
        field_type: "bool", 
        required: false,
        label: "portSecurityEnabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "security_groups",
        field_type: "select",
        required: false,
        label: "securityGroupsFormFieldLabel",
        self_item_titles: true,
        default_empty: true,
        multiple: true
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateUpFormFieldLabel",
        default_value: true
    }
]

export const portIPAddressForm = [
    {
        field_key: "subnet_id",
        field_type: "select",
        required: false,
        label: "subnetFormFieldLabel",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "ip_address",
        field_type: "string",
        required: false,
        label: "ipAddressFormFieldLabel"
    },
]