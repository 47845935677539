import request from '../_network/request';
import Constants from '../config/constants';
import { languageServiceURLs } from '../_network/apiUrls';

export const getTranslatedText = async (lang, varNames) => {
  let url = `${Constants.language_service_domain}/${languageServiceURLs.getTranslatedItems}/${Constants.client_credential_id}/?lang=${lang}`

  let request_data = {
    url: url,
    method: "POST",
    data: varNames ? {items: varNames} : {}
  }
  
  try {      
    const result = await request(request_data)
    
    if (result.status_code < 400 && result.data) {
        return result.data
    } else {
        return {}
    }
  } catch(err) {
    //console.log(err)
    return {}
  }
}