import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { setBillingDeposit } from '../../../../store/reducers/profileSlice'
import { chargesSummarySchema } from '../../helpers/billingHelpers'
import CustomCheckboxField from '../../../_common/_form_fields/CustomCheckboxField'
import useWindowDimensions from '../../../_common/WindowDimensions'

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const BillingSummary = (props) => {
  const theme = useTheme()
  const { handleChargesSummaryClose } = props
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const mode = useSelector(state => state.settings.uiMode)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)

  const [chargesSummary, setChargesSummary] = useState('')
  const [monthToDateCost, setMonthToDateCost] = useState(0)
  const [forcastedCost, setForcastedCost] = useState(0)
  const [prevMonthCost, setPrevMonthCost] = useState(0)
  const [sharedAccount, setSharedAccount] = useState((billingDeposit && billingDeposit.shared) ? billingDeposit.shared : false)
  const [chargesSummaryServices, setChargesSummaryServices] = useState([])
  const [chartData, setChartData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? (billingAccountConfig.currency.name).toUpperCase() : ''

  const accountType = (billingDeposit && billingDeposit.billing_type) ? capitalizeFirstLetter(billingDeposit.billing_type) : ''
  const balance = (billingDeposit && billingDeposit.amount) ? billingDeposit.amount.toFixed(2) : 0

  const summaryContainerStyle = {
    background: mode === 'light' ? theme.palette.customWhite : undefined,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    margin: '0px 20px 20px 20px',
    padding: '20px',
    borderRadius: '0px',
    position: 'relative',
    minHeight: '350px',
  }

  const summaryCloseIconStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: theme.palette.primary.main,
  }

  const summaryLeftBlockStyle = {
    paddingRight: '20px,'
  }

  const summaryRightBlockStyle = {
    paddingLeft: '20px',
    borderLeft: width >=900 ? `${theme.palette.customGrayDark} 1px dashed` : 'none',
  }

  const summaryLabelStyle = {
    color: theme.palette.secondary.main,
    fontSize: '18px',
    paddingBottom: '20px',
  }

  const summaryTitleStyle = {
    marginBottom: '10px',
  }

  const summaryAmountStyle = {
    color: theme.palette.primary.main,
    margin: '0px 0px 20px 5px',
    fontWeight: '500',
  }

  const summaryBalanceStyle = {
    color: theme.palette.success.main,
    margin: '0px 0px 20px 5px',
    fontWeight: '500',
  }

  const pieChartLabelStyle = {
    fill: 'white',
    fontSize: '14px',
  }

  const fetchProjectDeposits = async () => {
    const url = constants.billing_url + '/' + billingUrls.getProjectDeposits + '/' + defaultAdminProject

    const request_data = {
      url,
      method: 'GET',
      accessToken,
      xAuthToken,
      clientAccountID,
    }

    const response = await billingRequest(request_data)
    
    if (response.status_code && response.status_code < 400 && response.data) {
      dispatch(setBillingDeposit({ billingDeposit: response.data }))
    }
  }

  const handleChangeSharedAccount = async (event) => {
    setSharedAccount(event.target.checked)

    const url = constants.billing_url + '/' + billingUrls.getProjectDeposits + '/share/' + billingDeposit.id

    const request_data = {
      url,
      method: 'PUT',
      data: { shared: event.target.checked },
      accessToken,
      xAuthToken,
      clientAccountID,
    }

    const response = await billingRequest(request_data)
    
    if (response.status_code && response.status_code < 400) {
      await fetchProjectDeposits()
    }
  }

  useEffect(() => {
    const fetchChargesSummaryData = async () => {
      setIsLoading(true)

      const url = constants.billing_url + '/' + billingUrls.getResourceChargesSummary + '/' + defaultAdminProject

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        setChargesSummary(response.data)
      } else {
        setChargesSummary('')
      }

      setIsLoading(false)
    }

    fetchChargesSummaryData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject])

  useEffect(() => {
    if (chargesSummary) {
      setMonthToDateCost(chargesSummary.month_to_date_cost ? chargesSummary.month_to_date_cost : 0)
      setForcastedCost(chargesSummary.current_month_total_forcast ? chargesSummary.current_month_total_forcast : 0)
      setPrevMonthCost(chargesSummary.previous_month_cost ? chargesSummary.previous_month_cost : 0)
    } else {
      setMonthToDateCost(0)
      setForcastedCost(0)
      setPrevMonthCost(0)
    }
  }, [chargesSummary])

  useEffect(() => {
    setSharedAccount((billingDeposit && billingDeposit.shared) ? billingDeposit.shared : false)
  }, [billingDeposit])

  useEffect(() => {
    const getServiceParams = (keyword) => {
      return (chargesSummarySchema && chargesSummarySchema[keyword]) ? chargesSummarySchema[keyword] : {}
    }

    if (chargesSummary && chargesSummary.services && chargesSummary.services.length > 0) {
      let charges_summary_services = []

      chargesSummary.services.forEach(item => {
        charges_summary_services.push({
          ...item,
          ...getServiceParams(item.service_name),
        })
      })

      setChargesSummaryServices(charges_summary_services)
    } else {
      setChargesSummaryServices([])
    }
  }, [chargesSummary])
  
  useEffect(() => {
    if (chargesSummaryServices.length > 0) {
      let chart_data = []

      chargesSummaryServices.forEach(item => {
        chart_data.push({
          value: item.service_cost,
          label: item.label,
        })
      })

      setChartData(chart_data)
    } else {
      setChartData([])
    }
  }, [chargesSummaryServices])
  
  return (
    <Paper sx={summaryContainerStyle}>
      <IconButton sx={summaryCloseIconStyle} onClick={handleChargesSummaryClose}>
        <CloseIcon />
      </IconButton>

      <Grid container>
        <Grid xs={12} md={6}>
          <Box sx={summaryLeftBlockStyle}>
            <Box sx={summaryLabelStyle}>{defaultTexts.costSummary}</Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid lg={7}>
                    {
                      isLoading ?
                      <Stack spacing={2}>
                        <Skeleton variant="rounded" width='90%' height={50} />
                        <Skeleton variant="rounded" width='90%' height={50} />
                        <Skeleton variant="rounded" width='90%' height={50} />
                      </Stack> :
                      <Box>
                        <Box sx={summaryTitleStyle}>{defaultTexts.monthToDateCost}</Box>
                        <Box sx={summaryAmountStyle}>{monthToDateCost} {currencyName}</Box>
                        <Box sx={summaryTitleStyle}>{defaultTexts.totalForcastedCostForCurrentMonth}</Box>
                        <Box sx={summaryAmountStyle}>{forcastedCost} {currencyName}</Box>
                        <Box sx={summaryTitleStyle}>{defaultTexts.totalCostForLastMonth}</Box>
                        <Box sx={summaryAmountStyle}>{prevMonthCost} {currencyName}</Box>
                      </Box>
                    }
                  </Grid>
                  <Grid lg={5}>
                    {
                      isLoading ?
                      <Stack spacing={2}>
                        <Skeleton variant="rounded" width='90%' height={50} />
                        <Skeleton variant="rounded" width='90%' height={50} />
                        <Skeleton variant="rounded" width='90%' height={50} />
                      </Stack> :
                      <Box>
                        <Box sx={summaryTitleStyle}>{defaultTexts.accountTypeLabel}</Box>
                        <Box sx={summaryAmountStyle}>{accountType}</Box>
                        <Box sx={summaryTitleStyle}>{defaultTexts.balanceLabel}</Box>
                        <Box sx={summaryBalanceStyle}>{balance} {currencyName}</Box>
                        {
                          (billingDeposit && billingDeposit.billing_type && billingDeposit.billing_type === 'prepaid') &&
                          <CustomCheckboxField
                            currentValue={sharedAccount}
                            setCurrentValue={handleChangeSharedAccount}
                            label={defaultTexts.shareWithOtherProjectsLabel}
                            default_value={false}
                            sx={{ color: theme.palette.error.main }}
                          />
                        }
                      </Box>
                    }
                  </Grid>
                </Grid>
              </Box>
          </Box>            
        </Grid>
        <Grid xs={12} md={6}>
          <Box sx={summaryRightBlockStyle}>
            <Box sx={summaryLabelStyle}>{defaultTexts.costBreakdownLabel}</Box>
            {
              isLoading ?
              <Stack
                direction={ width >= 1200 ? 'row' : 'column' }
                alignItems='center'
                justifyContent='space-around'
                sx={{ width: '100%' }}
              >
                <Box>
                  <Skeleton variant="circular" width={200} height={200} />
                </Box>
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={200} height={30} />
                  <Skeleton variant="rounded" width={200} height={30} />
                  <Skeleton variant="rounded" width={200} height={30} />
                </Stack>
              </Stack> :
              <Box sx={{ margin: '0px', height: width >= 1400 ? '350px' : '500px' }}>
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.value}`,
                      data: chartData,
                      innerRadius: width >= 1400 ? 44 : 36,
                      outerRadius: width >= 1400 ? 150 : 120,
                      paddingAngle: 5,
                      cornerRadius: 5,
                      startAngle: -90,
                      endAngle: 180,
                      arcLabelMinAngle: 15,
                      cx: width >= 1400 ? 220 : 160,
                      cy: 160,
                    },
                  ]}
                  slotProps={{
                    legend: {
                      position: width >= 1400 ? { horizontal: 'right', vertical: 'middle' } : { horizontal: 'left', vertical: 'bottom' }
                    }
                  }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: pieChartLabelStyle,
                  }}
                />
              </Box>
            }
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BillingSummary