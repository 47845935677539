import React from 'react';
import Box from '@mui/material/Box';
import FirewallGroupsWrapperV20 from './fw_groups/firewallGroupsWrapperV2.0';
import FirewallPoliciesWrapperV20 from './fw_policies/firewallPoliciesWrapperV2.0';
import FirewallRulesWrapperV20 from './fw_rules/firewallRulesWrapperV2.0';

const FirewallWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, subsection } = props
	
	return (
		<Box>
				{subsection === "/firewall-groups" && 
						<FirewallGroupsWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/firewall-policies" && 
						<FirewallPoliciesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/firewall-rules" &&
						<FirewallRulesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default FirewallWrapperV20