import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { LuDatabaseBackup } from "react-icons/lu";
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { backupsUrl as backupUrlResponses } from '../../../../../_api_responses/openstack/cinder/backups/v3';
import CustomDialog from '../../../../_common/CustomDialog';

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const SERVICE_NAME = openStackServices.volumeService

const VolumBackupsV3 = (props) => {
	const { volumeBackups, handleNavigateToBackup, handleDataFetch } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const theme = useTheme();

	const [selectedBackups, setSelectedBackups] = useState([])
	const [backupDeleteConfirmDialogOpen, setBackupDeleteConfirmDialogOpen] = useState(false);

	const cinderServiceDomain = useSelector(
		state => state.openstack.purchasedServices.filter(
		service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
		state => state.openstack.purchasedServices.filter(
		service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const backupsUrl = useSelector(
		state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
				version => version.api_version === "v3")[0].urls.filter(
						url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)

	const handleBackupDelete = async (s_id) => {
			
		const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${s_id}/action`
		const method = "POST"
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				data: {"os-force_delete": {}},
				token,
		})
		
		if (vt_response.status_code === backupUrlResponses.post_async.success_response.status_code) {
				return true
		} else {
				return false
		}
			
	}

	const handleBackupDeleteConfirmDialogOpen = (backup) => {
		setBackupDeleteConfirmDialogOpen(true)
		setSelectedBackups([backup])
	}

	const handleBackupDeleteConfirmDialogClose = () => {
		setBackupDeleteConfirmDialogOpen(false)
		setSelectedBackups([])
	}

	const onBackupDelete = async () => {
		handleBackupDeleteConfirmDialogClose()
		if (selectedBackups.length > 0) {
			await handleBackupDelete(selectedBackups[0].id)
		}			
		handleDataFetch()
	}

	return (
			<React.Fragment>
					<WrapperBox
							sx={detailTabCardConfig}
					>
							<Stack
									direction="row" 
									alignItems="center" 
									justifyContent="center"
									spacing={4}
							>
							<LuDatabaseBackup
									size={28}
							/> 
									<CustomText 
											size="p" 
											sx={{fontSize: {xs: 16, lg: 18}}}
									>
											{defaultTexts.volumeBackupsTitleText}
									</CustomText>
									<CustomText 
											size="h4" 
											sx={{
													color: theme.palette.dashboardPink
											}}
									>
											{volumeBackups.length}
									</CustomText>
							</Stack>
					</WrapperBox>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} md={12}>
									<TableContainer>
											<Table sx={{ width: "100%" }}>
													<TableHead>
															<TableRow>
																	<TableCell></TableCell>
																	<TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.sizeFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.statusFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.isIncrementalFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.createdFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.updatedAtFormFieldLabel}</TableCell>
															</TableRow>
													</TableHead>
													<TableBody>
													{volumeBackups.map((row) => (
															<TableRow
																	key={row.id}	
																	hover
																	sx={{ cursor: 'pointer' }}
															>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																	>
																			<IconButton onClick={() => handleBackupDeleteConfirmDialogOpen(row)}>
																					<DeleteIcon color="error" />
																			</IconButton>
																	</TableCell>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																			onClick={() => handleNavigateToBackup(row.id, '/backups')}
																	>
																			{row.name}
																	</TableCell>
																	<TableCell 
																			align="left"
																	>
																			{`${row.size} GB`}
																	</TableCell>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																			onClick={() => handleNavigateToBackup(row.id, '/backups')}
																	>
																			{row.status}
																	</TableCell>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																			onClick={() => handleNavigateToBackup(row.id, '/backups')}
																	>
																			{row.is_incremental ? defaultTexts.formValueYes : defaultTexts.formValueNo}
																	</TableCell>
																	<TableCell 
																			align="left"
																			onClick={() => handleNavigateToBackup(row.id, '/backups')}
																	>
																			{new Date(row.created_at).toLocaleString()}
																	</TableCell>
																	<TableCell 
																			align="left"
																			onClick={() => handleNavigateToBackup(row.id, '/backups')}
																	>
																			{new Date(row.updated_at).toLocaleString()}
																	</TableCell>
															</TableRow>
													))}
													</TableBody>
											</Table>
									</TableContainer>
					</Grid>
			</Grid>

			<CustomDialog
				open={backupDeleteConfirmDialogOpen}
				onClose={handleBackupDeleteConfirmDialogClose}
				dialogTitle={{
						title: defaultTexts.snapshotDeleteConfirmTitle, 
						sx: {color: 'primary.main'}}}
				dialogBody={{
						text: `${defaultTexts.snapshotDeleteConfirmText}: [${selectedBackups.map(v => v.name).toString()}]`, 
						sx: {color: 'text.primary'}}}
				actionButtons={[{
						title: defaultTexts.confirmButtonText, 
						onClick: onBackupDelete, 
						sx: {color: 'primary.main'}}]}
			/>
	</React.Fragment>
	)
}

export default VolumBackupsV3