import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import CustomText from 
'./CustomText';
import { styled } from '@mui/material/styles';
import TableCell, 
{ tableCellClasses } from 
'@mui/material/TableCell';
import Box from '@mui/material/Box';
const ip_checker = require('ip-address');

export const getDetailDataComponent = ({
    fieldType,
    fieldKey,
    label,
    value,
    textOnTrue,
    textOnFalse,
    defaultTexts,
    formFields,
    labelColor,
    textColor,
    onClick}) => {
    let data_comp = null
    switch (fieldType) {
        case "string": 
            data_comp = (
                <Stack 
                    key={fieldKey}
                    spacing={1}
                    onClick={onClick}
                    sx={{cursor: onClick ? "pointer" : undefined}}
                >
                    <Stack 
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: labelColor ? 
                                labelColor : "text.secondary"
                            }}
                        >
                            {label}:
                        </CustomText>
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: textColor ? 
                                textColor : "text.primary",
                                flexWrap: 'wrap',
                                maxWidth: 350
                            }}
                        >
                            {value}
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
            break;
        case "bool": 
            data_comp = (
            <Stack 
                key={fieldKey}
                spacing={2} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        size="p"
                        sx={{ 
                            color: labelColor ? 
                            labelColor : "text.secondary"
                        }}
                    >
                        {label}:
                    </CustomText>
                    <CustomText 
                        size="p"
                        sx={{ 
                            color: textColor ? 
                            textColor : value ? 
                            "success.main" : 
                            "error.main"
                        }}
                    >
                        {value ?
                            textOnTrue :
                            textOnFalse
                        }
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
            case "datetime": 
            data_comp = (
            <Stack 
                key={fieldKey}
                spacing={2} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        size="p"
                        sx={{ 
                            color: labelColor ? 
                            labelColor : "text.secondary"
                        }}
                    >
                        {label}:
                    </CustomText>
                    <CustomText 
                        size="p"
                    >
                        { value ? new Date(value).toString() : null }
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
        case "json": 
            data_comp = (
                <Stack 
                    key={fieldKey}
                    spacing={2} 
                >
                    <Stack
                        direction="row" 
                        justifyContent="space-between"
                        sx={{flexWrap: "wrap"}}
                    >
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: labelColor ? 
                                labelColor : "text.secondary"
                            }}
                        >
                            {label}:
                        </CustomText>
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: textColor ? 
                                textColor : "text.primary"
                            }}
                        >
                            
                            <Stack direction="column">
                            {Object.keys(value).map(
                                (item,index) => {
                                const formField = formFields.filter(
                                    f => f.field_key === item)[0]
                                return (
                                    <Stack
                                        direction="row"
                                        spacing={2} 
                                        key={`${item}_${index}`}
                                    >
                                        <CustomText 
                                            size="p"
                                            sx={{ 
                                                color: textColor ? 
                                                textColor : "text.primary"
                                            }}
                                        >
                                            {`${defaultTexts[formField.label]}: `}
                                        </CustomText>
                                        <CustomText 
                                            size="p"
                                            sx={{ 
                                                color: typeof(
                                                    value[item]) === "boolean" ? 
                                                    (value[item] ? 
                                                        "success.main" : 
                                                        "error.main") : 
                                                    "text.primary"
                                            }}
                                        >
                                            {
                                                typeof(
                                                    value[item]) === "boolean" ? 
                                                    (value[item] ? 
                                                        textOnTrue : 
                                                        textOnFalse) : 
                                                    (value[item])
                                            }
                                        </CustomText>
                                    </Stack>
                                )
                            })}
                        </Stack>
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
            break;
        case "list": 
            data_comp = (
                <Stack 
                    key={fieldKey}
                    spacing={2} 
                >
                    <Stack
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: labelColor ? 
                                labelColor : "text.secondary"
                            }}
                        >
                            {label}:
                        </CustomText>
                        <Stack direction="column">
                            {value.map(
                                (item,index) => {
                                return (
                                    <CustomText 
                                        key={`${item}_${index}`}
                                        size="p"
                                        sx={{ 
                                            color: textColor ? 
                                            textColor : "text.primary"
                                        }}
                                    >
                                        {item}
                                    </CustomText>
                                )
                            })}
                        </Stack>
                    </Stack>
                    <Divider />
                </Stack>);
            break;
        default:
            data_comp = (
                <Stack 
                    key={fieldKey}
                    spacing={1} 
                >
                    <Stack 
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: labelColor ? 
                                labelColor : "text.secondary"
                            }}
                        >
                            {label}:
                        </CustomText>
                        <CustomText 
                            size="p"
                            sx={{ 
                                color: textColor ? 
                                textColor : "text.primary",
                                flexWrap: 'wrap',
                                maxWidth: 300
                            }}
                        >
                            {value}
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
    }
    return data_comp
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontWeight: 600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const isNotObject = (value) => {
return typeof value !== 'object' && value !== null;
}

export const getTableCell = ({
    fieldType,
    value,
    index,
    textOnTrue,
    textOnFalse,
    onClickFunction,
    row,
    listItemNumber,
    defaultTexts,
    formFields,
    showObjectInCell,
    }) => {
    let table_cell = null
    switch (fieldType) {
      case "string": 
        table_cell = (
          <StyledTableCell 
            key={`${value}_${index}`} 
            align="left"
            onClick={() => onClickFunction(row)}
            sx={{flexWrap: 'wrap'}}
          >
            { (isNotObject(value) || showObjectInCell) ? value : '' }
          </StyledTableCell>);
        break;
      case "bool": 
        table_cell = (
          <StyledTableCell 
            key={`${value}_${index}`} 
            align="left"
            onClick={() => onClickFunction(row)}
            sx={{ 
                color: value ? 
                "success.main" : 
                "error.main"
            }}
          >
            {
              value ? 
              textOnTrue : 
              textOnFalse
            }
          </StyledTableCell>);
        break;
        case "bool_reverse":
            table_cell = (
                <StyledTableCell 
                  key={`${value}_${index}`} 
                  align="left"
                  onClick={() => onClickFunction(row)}
                  sx={{ 
                      color: value ? 
                      "error.main" :
                      "success.main"
                  }}
                >
                  {
                    value ? 
                    textOnTrue : 
                    textOnFalse
                  }
                </StyledTableCell>);
            break;
        case "datetime": 
        table_cell = (
          <StyledTableCell 
            key={`${value}_${index}`} 
            align="left"
            onClick={() => onClickFunction(row)}
          >
            { value ? new Date(value).toLocaleString() : null }
          </StyledTableCell>);
        break;
      case "json": 
        table_cell = (
          <StyledTableCell 
            key={`${value}_${index}`} 
            align="left"
            onClick={() => onClickFunction(row)}
          >
            <Stack direction="column">
              {Object.keys(value).map((item,index) => {
                const formField = formFields.filter(f => f.field_key === item)[0]
                return (
                        <Stack 
                            key={index} 
                            direction="row" 
                            justifyContent="space-between"
                            alignItems="end"
                        >
                            <CustomText size="p">{defaultTexts[formField.label]}:</CustomText>
                            <CustomText 
                                size="p"
                                sx={{ 
                                    color: typeof(
                                        value[item]) === "boolean" ? 
                                        (value[item] ? 
                                            "success.main" : 
                                            "error.main") : 
                                        "text.primary"
                                }}
                            >
                                {
                                    typeof(
                                        value[item]) === "boolean" ? 
                                        (value[item] ? textOnTrue : textOnFalse) : 
                                        (value[item])
                                }
                            </CustomText>
                        </Stack>
                      
                  )
              })}
            </Stack>
          </StyledTableCell>);
        break;
      case "list": 
        table_cell = (
          <StyledTableCell 
            key={`${value}_${index}`} 
            align="left"
            onClick={() => onClickFunction(row)}
          >
            <Stack direction="column">
              {value.slice(0,listItemNumber).map((item,index) => {
                  return (
                      <CustomText 
                          key={index}
                          size="p"
                      >
                          {item}
                      </CustomText>
                  )
              })}
              {value.length > listItemNumber && <CustomText size="p">...</CustomText>}
            </Stack>
          </StyledTableCell>);
        break;
    case "json_in_json":
        table_cell = (
            <StyledTableCell 
              key={`${index}`} 
              align="left"
              onClick={() => onClickFunction(row)}
              sx={{flexWrap: 'wrap'}}
            >
              {Object.keys(value).length > 0 ? Object.keys(value).map((item,i) => {
                return (
                    <Box key={i}>
                        <CustomText size="p">
                            {item}: {value[item]}
                        </CustomText>
                    </Box>
                )
            }) : ""}
            </StyledTableCell>);
        break;
    default:
    table_cell = (
        <StyledTableCell 
        key={`${value}_${index}`} 
        align="left"
        onClick={() => onClickFunction(row)}
        sx={{flexWrap: 'wrap'}}
        >
        {JSON.stringify(value)}
        </StyledTableCell>);
    }
    return table_cell
}

export const sortObjectListAscending = (data_list,field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...data_list]
    sorted_data.sort(compare)
    return sorted_data
}


export const sortObjectListDescending = (data_list,field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...data_list]
    sorted_data.sort(compare)
    sorted_data.reverse()
    return sorted_data
}

export const ipv4CidrChecker = (cidr) => {
    if (cidr.length > 0) {
        let cidr_parts = cidr.split("/")
        if (cidr_parts.length !== 2) {
            return false
        } else {
            let parsev4 = null
            try {
                parsev4 = new ip_checker.Address4(cidr_parts[0])
            } catch {
                parsev4 = null
            }
            
            if (!parsev4) {
                return false
            }
            if (parseInt(cidr_parts[1]) < 0 && parseInt(cidr_parts[1]) > 32) {
                return false
            } 
            return true
        }
    } else {
        return false
    }
}

export const ipv6CidrChecker = (cidr) => {
    if (cidr.length > 0) {
        let cidr_parts = cidr.split("/")
        if (cidr_parts.length !== 2) {
            return false
        } else {
            let parsev6 = null
            try {
                parsev6 = new ip_checker.Address6(cidr_parts[0])
            } catch {
                parsev6 = null
            }
            
            if (!parsev6) {
                return false
            }
            if (parseInt(cidr_parts[1]) < 0 && parseInt(cidr_parts[1]) > 128) {
                return false
            } 
            return true
        }
    } else {
        return false
    }
}