export const firewallGroupDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "shared",
        is_id_field: false,
        field_type: "bool", 
        label: "sharedFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "ingress_firewall_policy_id",
        is_id_field: false,
        field_type: "string", 
        label: "ingressFWPolicyFormFieldLabel"
    },
    {
        field_key: "egress_firewall_policy_id",
        is_id_field: false,
        field_type: "string", 
        label: "egressFWPolicyFormFieldLabel"
    },
    {
        field_key: "ports",
        is_id_field: false,
        field_type: "string", 
        label: "portsCountFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const firewallGroupDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    },
    // {
    //     field_key: "shared",
    //     field_type: "bool", 
    //     required: false,
    //     label: "sharedFormFieldLabel",
    //     default_value: false
    // },
    {
        field_key: "ingress_firewall_policy_id",
        field_type: "select",
        required: false,
        label: "ingressFWPolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "egress_firewall_policy_id",
        field_type: "select",
        required: false,
        label: "egressFWPolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    }
]

export const firewallGroupsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        label: "adminStateFormFieldLabel"
    },
]


export const firewallGroupsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const firewallGroupDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const firewallPolicyDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const firewallGroupPoliciesUpdateForm = [
    {
        field_key: "ingress_firewall_policy_id",
        field_type: "select",
        required: false,
        label: "ingressFWPolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "egress_firewall_policy_id",
        field_type: "select",
        required: false,
        label: "egressFWPolicyFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    }
]

export const firewallPolicyDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "audited",
        is_id_field: false,
        field_type: "bool", 
        label: "auditedFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "shared",
        is_id_field: false,
        field_type: "bool", 
        label: "sharedFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "firewall_rules",
        is_id_field: false,
        field_type: "string", 
        label: "rulesFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const firewallPolicyDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    // {
    //     field_key: "shared",
    //     field_type: "bool", 
    //     required: false,
    //     label: "sharedFormFieldLabel",
    //     default_value: false
    // },
    {
        field_key: "audited",
        field_type: "bool", 
        required: false,
        label: "auditedFormFieldLabel",
        default_value: false
    }
]

export const firewallPoliciesSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    }
]


export const firewallPoliciesFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // }
]

export const firewallRuleDataForm = [
    // {
    //     field_key: "project_id",
    //     field_type: "select",
    //     required: true,
    //     label: "projectFormFieldLabel",
    //     self_item_titles: true,
    //     default_empty: false,
    //     error_label: "requiredFormFieldError"
    // },
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "ip_version",
        field_type: "select",
        required: true,
        label: "ipVersionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel",
                value: "4",
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel",
                value: "6",
                default: false
            }
        ]
    },
    {
        field_key: "protocol",
        field_type: "select",
        required: true,
        label: "protocolFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "tcpFormFieldLabel",
                value: "tcp",
                default: false
            },
            {
                keyword: "udpFormFieldLabel",
                value: "udp",
                default: false
            },
            {
                keyword: "icmpFormFieldLabel",
                value: "icmp",
                default: false
            },
            {
                keyword: "anyFormFieldLabel",
                value: "any",
                default: false
            },
        ]
    },
    //{
    //    field_key: "source_type",
    //    field_type: "select",
    //    required: true,
    //    label: "sourceFormFieldLabel",
    //    error_label: "requiredFormFieldError",
    //    default_empty: false,
    //    self_item_titles: false,
    //    items: [
    //        {
    //            keyword: "firewallGroupFormFieldLabel",
    //            value: "firewall_group",
    //            default: false
    //        },
    //        {
    //            keyword: "ipAddressFormFieldLabel",
    //            value: "ip_address",
    //            default: false
    //        }
    //    ]
    //},
    //{
    //    field_key: "source_firewall_group_id",
    //    field_type: "select",
    //    required: false,
    //    label: "sourceFirewallGroupFormFieldLabel",
    //    self_item_titles: true,
    //    default_empty: false
    //},
    {
        field_key: "source_ip_address",
        field_type: "string",
        required: false,
        label: "sourceIPAddressFormFieldLabel"
    },
    {
        field_key: "source_from_port",
        field_type: "integer",
        required: false,
        label: "sourceFromPortFormFieldLabel"
    },
    {
        field_key: "source_to_port",
        field_type: "integer",
        required: false,
        label: "sourceToPortFormFieldLabel"
    },
    //{
    //    field_key: "destination_type",
    //    field_type: "select",
    //    required: true,
    //    label: "destinationTypeFormFieldLabel",
    //    error_label: "requiredFormFieldError",
    //    default_empty: false,
    //    self_item_titles: false,
    //    items: [
    //        {
    //            keyword: "firewallGroupFormFieldLabel",
    //            value: "firewall_group",
    //            default: false
    //        },
    //        {
    //            keyword: "ipAddressFormFieldLabel",
    //            value: "ip_address",
    //            default: false
    //        }
    //    ]
    //},
    //{
    //    field_key: "destination_firewall_group_id",
    //    field_type: "select",
    //    required: false,
    //    label: "destinationFirewallGroupFormFieldLabel",
    //    self_item_titles: true,
    //    default_empty: false
    //},
    {
        field_key: "destination_ip_address",
        field_type: "string",
        required: false,
        label: "destinationIPAddressFormFieldLabel"
    },
    {
        field_key: "destination_from_port",
        field_type: "integer",
        required: false,
        label: "destinationFromPortFormFieldLabel"
    },
    {
        field_key: "destination_to_port",
        field_type: "integer",
        required: false,
        label: "destinationToPortFormFieldLabel"
    },
    {
        field_key: "action",
        field_type: "select",
        required: true,
        label: "actionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        default_value: "deny",
        items: [
            {
                keyword: "allowFormFieldLabel",
                value: "allow",
                default: false
            },
            {
                keyword: "denyFormFieldLabel",
                value: "deny",
                default: true
            }
        ]
    },
    // {
    //     field_key: "shared",
    //     field_type: "bool", 
    //     required: false,
    //     label: "sharedFormFieldLabel",
    //     default_value: false
    // },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]

export const firewallRuleDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "ip_version",
        field_type: "select",
        required: true,
        label: "ipVersionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel",
                value: "4",
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel",
                value: "6",
                default: false
            }
        ]
    },
    {
        field_key: "protocol",
        field_type: "select",
        required: true,
        label: "protocolFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "tcpFormFieldLabel",
                value: "tcp",
                default: false
            },
            {
                keyword: "udpFormFieldLabel",
                value: "udp",
                default: false
            },
            {
                keyword: "icmpFormFieldLabel",
                value: "icmp",
                default: false
            },
            {
                keyword: "anyFormFieldLabel",
                value: "any",
                default: false
            },
        ]
    },
    {
        field_key: "source_ip_address",
        field_type: "string",
        required: false,
        label: "sourceIPAddressFormFieldLabel"
    },
    {
        field_key: "source_from_port",
        field_type: "integer",
        required: false,
        label: "sourceFromPortFormFieldLabel"
    },
    {
        field_key: "source_to_port",
        field_type: "integer",
        required: false,
        label: "sourceToPortFormFieldLabel"
    },
    {
        field_key: "destination_ip_address",
        field_type: "string",
        required: false,
        label: "destinationIPAddressFormFieldLabel"
    },
    {
        field_key: "destination_from_port",
        field_type: "integer",
        required: false,
        label: "destinationFromPortFormFieldLabel"
    },
    {
        field_key: "destination_to_port",
        field_type: "integer",
        required: false,
        label: "destinationToPortFormFieldLabel"
    },
    {
        field_key: "action",
        field_type: "select",
        required: true,
        label: "actionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        default_value: "deny",
        items: [
            {
                keyword: "allowFormFieldLabel",
                value: "allow",
                default: false
            },
            {
                keyword: "denyFormFieldLabel",
                value: "deny",
                default: true
            }
        ]
    },
    // {
    //     field_key: "shared",
    //     field_type: "bool", 
    //     required: false,
    //     label: "sharedFormFieldLabel",
    //     default_value: false
    // },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]

export const firewallRulesFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    // {
    //     keyword: "projectFormFieldLabel",
    //     value: "project_id",
    //     type: "select", 
    //     multiple: false,
    //     self_item_titles: true,
    //     items: []
    // },
    {
        keyword: "ipVersionFormFieldLabel",
        value: "ip_version",
        type: "select", 
        multiple: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel", 
                value: "4", 
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel", 
                value: "6", 
                default: false
            }
        ]
    },
    {
        keyword: "protocolFormFieldLabel",
        value: "protocol",
        type: "select", 
        multiple: false,
        self_item_titles: false,
        items: [
            {
                keyword: "tcpFormFieldLabel", 
                value: "tcp", 
                default: false
            },
            {
                keyword: "udpFormFieldLabel", 
                value: "udp", 
                default: false
            },
            {
                keyword: "icmpFormFieldLabel", 
                value: "icmp", 
                default: false
            }
        ]
    },
    {
        keyword: "sourceAddressFormFieldLabel",
        value: "source_ip_address",
        type: "text"
    },
    {
        keyword: "destinationAddressFormFieldLabel",
        value: "destination_ip_address",
        type: "text"
    },
    {
        keyword: "actionFormFieldLabel",
        value: "action",
        type: "select", 
        multiple: false,
        self_item_titles: false,
        items: [
            {
                keyword: "allowActionFormFieldLabel", 
                value: "allow", 
                default: false
            },
            {
                keyword: "denyActionFormFieldLabel", 
                value: "deny", 
                default: false
            }
        ]
    },
    {
        keyword: "enabledFormFieldLabel", 
        value: "enabled", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "sharedFormFieldLabel", 
        value: "shared", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const firewallRulesSortFields = [
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "ip_version",
        label: "ipVersionFormFieldLabel"
    },
    {
        field_key: "protocol",
        label: "protocolFormFieldLabel"
    },
    {
        field_key: "source_ip_address",
        label: "sourceAddressFormFieldLabel"
    },
    {
        field_key: "destination_ip_address",
        label: "destinationAddressFormFieldLabel"
    }
]

export const firewallRuleDataSchema = [
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "ip_version",
        is_id_field: false,
        field_type: "string", 
        label: "ipVersionFormFieldLabel"
    },
    {
        field_key: "protocol",
        is_id_field: false,
        field_type: "string", 
        label: "protocolFormFieldLabel"
    },
    {
        field_key: "source",
        is_id_field: false,
        field_type: "string", 
        label: "fwSourceFormFieldLabel"
    },
    {
        field_key: "source_ports",
        is_id_field: false,
        field_type: "string", 
        label: "sourcePortsFormFieldLabel"
    },
    {
        field_key: "destination",
        is_id_field: false,
        field_type: "string", 
        label: "fwDestinationFormFieldLabel"
    },
    {
        field_key: "destination_ports",
        is_id_field: false,
        field_type: "string", 
        label: "destinationPortsFormFieldLabel"
    },
    {
        field_key: "action",
        is_id_field: false,
        field_type: "string", 
        label: "actionFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "shared",
        is_id_field: false,
        field_type: "bool", 
        label: "sharedFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "firewall_policy_id",
        is_id_field: false,
        field_type: "string", 
        label: "firewallPoicyFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

