import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import CustomText from './CustomText'
import ServiceDoubleSubmenuDropdown from './ServiceDoubleSubmenuDropdown'

const ServiceDoubleMenu = (props) => {
  const theme = useTheme()

  const { serviceMenu, serviceSubmenu, onClick, setCurrentTab } = props
  
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const menuMode = useSelector(state => state.settings.networkMenuMode)

  const buttonClick = (navigation) => {
    if (menuMode === undefined || !menuMode[navigation]) {
      onClick(navigation)
    }
  }
  
  return (
    <>
      {
        serviceMenu.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'inline-block',
              color: theme.palette.customGrayDark,
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                color: item.is_active ? theme.palette.primary.main : theme.palette.customGrayDark,
                cursor: item.is_active ? 'auto' : 'pointer',
                marginRight: '12px',
                '&:hover': {
                  opacity: item.is_active ? 1 : 0.7,
                }
              }}
              onClick={() => buttonClick(item.navigation)}
            >
              {
                (menuMode && menuMode[item.navigation]) ? (
                  <ServiceDoubleSubmenuDropdown
                    label={item.keyword}
                    isActive={item.is_active}
                    dropdownMenu={serviceSubmenu.filter(item2 => item2.parent === item.navigation)}
                    setCurrentTab={setCurrentTab}
                    parentNavigation={item.navigation}
                    onClick={onClick}
                  />
                ) : (
                  <CustomText sx={{ fontSize: '17px' }}>
                    {defaultTexts[item.keyword]}
                  </CustomText>
                )
              }
              
            </Box>            
            {
              index < serviceMenu.length - 1 &&
              <Box
                sx={{
                  display: 'inline-block',
                  fontSize: '17px',
                  marginRight: '12px',
                }}
              >|</Box>
            }										
          </Box>
        ))
      }
    </>
  )
}

export default ServiceDoubleMenu