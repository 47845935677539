import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import IPsecPoliciesTableV20 from './ipsecPoliciesTableV2.0';
import { vpnIPsecPoliciesFilterMenu, vpnIPsecPolicyDataForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import IPsecPolicyAddV20 from './ipsecPolicyAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'

const SERVICE_NAME = openStackServices.networkService

const IPsecPoliciesWrapperV20 = (props) => {
	const theme = useTheme()
	const location = useLocation()
	const navigate = useNavigate()
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	const { currentTab, serviceSubmenu } = props
	const [ipsecPoliciesData, setIPsecPoliciesData] = useState([])
	const [ipsecPolicies, setIPsecPolicies] = useState([])
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [ipsecPolicyDeleteConfirmDialogOpen, setIPsecPolicyDeleteConfirmDialogOpen] = useState(false);
	const [selectedIPsecPolicies, setSelectedIPsecPolicies] = useState([])
	
	const [ipsecPolicyFilterQueryParams, setIPsecPolicyFilterQueryParams] = useState("")
	const [selectedIPsecPolicyFilter, setSelectedIPsecPolicyFilter] = useState(vpnIPsecPoliciesFilterMenu[0].value)
	const [selectedIPsecPolicyFilterValue, setSelectedIPsecPolicyFilterValue] = useState("")

	const [updateDataOptions, setUpdateDataOptions] = useState({});
	const [updateFormData, setUpdateFormData] = useState({});
	const [ipsecPolicyUpdateDialogOpen, setIPsecPolicyUpdateDialogOpen] = useState(false)
	const [selectedFieldKey, setSelectedFieldKey] = useState("")
	const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const vpnIPsecPoliciesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnIPsecPoliciesUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleIPsecPolicyFilteredSearch = () => {
			if (selectedIPsecPolicyFilter && selectedIPsecPolicyFilterValue) {
					setIPsecPolicyFilterQueryParams(`${selectedIPsecPolicyFilter}=${selectedIPsecPolicyFilterValue}`)
			} else {
					setIPsecPolicyFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleIPsecPoliciesDataFormatting = useCallback(() => {
			const formatted_data = ipsecPoliciesData.map((item) => {
					let new_item = {...item}
					
					new_item.lifetime = `${item.lifetime.value} ${item.lifetime.units}`
					return new_item
			})
			setIPsecPolicies(formatted_data)
	},[
			ipsecPoliciesData,
	])

	const handleIPsecPolicyFilterReset = () => {
			setSelectedIPsecPolicyFilter(vpnIPsecPoliciesFilterMenu[0].value)
			setSelectedIPsecPolicyFilterValue("")
			setIPsecPolicyFilterQueryParams("")
			handleDataFetch()
	}


	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFormDataChange = (event,field_key) => {
			setUpdateDataOptions({})
			let new_form_data = {...updateFormData}
			if (vpnIPsecPolicyDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (vpnIPsecPolicyDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUpdateFormData(new_form_data)
	}

	const getDataForm = () => {
			let form = vpnIPsecPolicyDataForm.filter(i => i.field_key === selectedFieldKey)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {...updateDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									return (
											getFormFieldComponent(
													field,
													updateFormData,
													handleFormDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleUpdateDataValidation = (field_key, data) => {
			if (data[field_key].length === 0) {
					let options = {}
					options[field_key] = {}
					options[field_key]["error"] = true
					options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
					setUpdateDataOptions(options)
					return false
			} else {
					return true
			}
	}

	const handleIPsecPolicyUpdate = async (eg_id,field_key,data) => {
			const data_is_valid = handleUpdateDataValidation(field_key,data)
			let update_data = {}
			update_data[field_key] = data[field_key]
			if (data_is_valid) {
					handleIPsecPolicyUpdateDialogClose()
					
					const method = "PUT"
					const vpnGroup_response = await openstackRequest({
							url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}/${eg_id}`, 
							method: method, 
							data: {ipsecpolicy: update_data},
							token,
					})
					if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
							return null
					} else {
							return vpnGroup_response.error
					}
					
			} 
	}

	const onIPsecPolicyUpdate = async () => {
			let err = []
			for (let n in selectedIPsecPolicies) {
					const resp = await handleIPsecPolicyUpdate(
							selectedIPsecPolicies[n].id,
							selectedFieldKey,
							updateFormData)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			setUpdateDataOptions({})
			setUpdateFormData({})
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onIPsecPolicyUpdateNameDialogOpen = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("name")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyNameTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdateDescriptionDialogOpen = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("description")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyDescriptionTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdateAuthAlgDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("auth_algorithm")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyAuthAlgTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdateEncrAlgDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("encryption_algorithm")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyEncrAlgTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdatePFSGroupDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("pfs")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyPFSGroupTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdateEncapModeDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("encapsulation_mode")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyEncapModeTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const onIPsecPolicyUpdateTransProtoDialogOpen  = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setSelectedFieldKey("transform_protocol")
			setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyTransProtoTitle)
			setIPsecPolicyUpdateDialogOpen(true)
	},[ipsecPoliciesData, defaultTexts])

	const handleIPsecPolicyUpdateDialogClose = () => {
			setIPsecPolicyUpdateDialogOpen(false)
	}

	const handleIPsecPolicyDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}		
	}

	const onIPsecPolicyDelete = async () => {
			handleIPsecPolicyDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedIPsecPolicies) {
					const resp = await handleIPsecPolicyDelete(selectedIPsecPolicies[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onIPsecPolicyDeleteConfirm = useCallback((n_list) => {
			const selected_n_list = ipsecPoliciesData.filter(n => 
					n_list.includes(n.id))
			setSelectedIPsecPolicies([...selected_n_list])
			setIPsecPolicyDeleteConfirmDialogOpen(true)
	},[ipsecPoliciesData])

	const handleIPsecPolicyDeleteConfirmDialogClose = () => {
			setIPsecPolicyDeleteConfirmDialogOpen(false)
	}

	const getIPsecPoliciesActionsList = useCallback(() => {
			let ipsecPolicy_actions = []
			let new_action = {}
			new_action["value"] = "ipsec_policy_update_name"
			new_action["action"] = onIPsecPolicyUpdateNameDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateNameActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_description"
			new_action["action"] = onIPsecPolicyUpdateDescriptionDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateDescriptionActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_auth_alg"
			new_action["action"] = onIPsecPolicyUpdateAuthAlgDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateAuthAlgActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_encr_alg"
			new_action["action"] = onIPsecPolicyUpdateEncrAlgDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateEncrAlgActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_pfs"
			new_action["action"] = onIPsecPolicyUpdatePFSGroupDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdatePFSGroupActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_encap_mode"
			new_action["action"] = onIPsecPolicyUpdateEncapModeDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateEncapModeActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsec_policy_update_trans_proto"
			new_action["action"] = onIPsecPolicyUpdateTransProtoDialogOpen
			new_action["keyword"] = "ipsecPolicyUpdateTransProtoActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "ipsecPolicy_delete"
			new_action["action"] = onIPsecPolicyDeleteConfirm
			new_action["keyword"] = "ipsecPolicyDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			ipsecPolicy_actions.push({...new_action})
			
			return ipsecPolicy_actions
	},[
			onIPsecPolicyDeleteConfirm,
			onIPsecPolicyUpdateNameDialogOpen,
			onIPsecPolicyUpdateDescriptionDialogOpen,
			onIPsecPolicyUpdateAuthAlgDialogOpen,
			onIPsecPolicyUpdateEncrAlgDialogOpen,
			onIPsecPolicyUpdatePFSGroupDialogOpen,
			onIPsecPolicyUpdateEncapModeDialogOpen,
			onIPsecPolicyUpdateTransProtoDialogOpen
	])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}?${ipsecPolicyFilterQueryParams}`
							const method = "GET"
							const ipsecPolicy_response = await openstackRequest({url:url, method:method, token, })
							if (ipsecPolicy_response.status_code === vpnUrlResponses.get.success_response.status_code) {
									setIPsecPoliciesData(ipsecPolicy_response.data.ipsecpolicies)
							} else {
								setIPsecPoliciesData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			vpnIPsecPoliciesUrl, 
			ipsecPolicyFilterQueryParams,
			dataFetchingRequired,
			token
	]);

	useEffect(() => {
			if (Object.keys(updateFormData).length === 0) {
					let new_form_data = {}
					for (const n in vpnIPsecPolicyDataForm) {
							if (
									vpnIPsecPolicyDataForm[n].field_type === "string" || 
									vpnIPsecPolicyDataForm[n].field_type === "select"
									) {
									new_form_data[vpnIPsecPolicyDataForm[n].field_key] = vpnIPsecPolicyDataForm[n].default_value ? 
									vpnIPsecPolicyDataForm[n].default_value : 
									""
							} else if (vpnIPsecPolicyDataForm[n].field_type === "integer") {
									new_form_data[vpnIPsecPolicyDataForm[n].field_key] = 3600
							} else if (vpnIPsecPolicyDataForm[n].field_type === "bool") {
									new_form_data[vpnIPsecPolicyDataForm[n].field_key] = vpnIPsecPolicyDataForm[n].default_value ? 
									vpnIPsecPolicyDataForm[n].default_value : 
									false
							}
					}
					setUpdateFormData(new_form_data)
			}
	},[
			updateFormData
	]);

	useEffect(() => {
			if (ipsecPoliciesData.length > 0) {
					handleIPsecPoliciesDataFormatting()
			} else {
				setIPsecPolicies([])
			}
	},[
			ipsecPoliciesData,
			handleIPsecPoliciesDataFormatting
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-vpnaas')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	useEffect(() => {
		if (location.state && location.state.ipsec_policy_name) {
			setSelectedIPsecPolicyFilterValue(location.state.ipsec_policy_name)
			setIPsecPolicyFilterQueryParams(`${selectedIPsecPolicyFilter}=${location.state.ipsec_policy_name}`)
			setDataFetchingRequired(true)
			navigate(location.path, {})
		}
	}, [location, navigate, selectedIPsecPolicyFilter])

	const handleActionRun = () => {
		const actionsList = getIPsecPoliciesActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedIPsecPolicies([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getIPsecPoliciesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<IPsecPolicyAddV20
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
						size="small"
						variant="outlined"
						label={defaultTexts[vpnIPsecPoliciesFilterMenu[0].keyword]}
						currentValue={selectedIPsecPolicyFilterValue}
						setCurrentValue={e => setSelectedIPsecPolicyFilterValue(e.target.value)}
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleIPsecPolicyFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleIPsecPolicyFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuVPNaaS}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{ipsecPoliciesData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<IPsecPoliciesTableV20 
				ipsecPoliciesData={ipsecPolicies}
				setIPsecPoliciesData={setIPsecPolicies}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getIPsecPoliciesActionsList()}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	return (
		<>
			{ isLoading && <CustomBackdrop open={isLoading} /> }

			{ getNetworksHeader() }

			{ !isLoading && getNetworksTable() }

			<CustomDialog
					open={ipsecPolicyDeleteConfirmDialogOpen}
					onClose={handleIPsecPolicyDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.ipsecPolicyDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.ipsecPolicyDeleteConfirmText}`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onIPsecPolicyDelete, 
							sx: {color: 'primary.main'}}]}
			/>
			<CustomDialog
					open={ipsecPolicyUpdateDialogOpen}
					onClose={handleIPsecPolicyUpdateDialogClose}
					dialogTitle={{
							title: selectedFieldTitleText, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onIPsecPolicyUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm()}
			</CustomDialog>
			{error && <CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
			/>}
		</>
	)
}

export default IPsecPoliciesWrapperV20