import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import WrapperBox from './WrapperBox';
import useWindowDimensions from '../_common/WindowDimensions'
import dimensions from '../../config/dimensions';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const VISIBLE_MENU_ITEMS_NUM = 6

const ServiceCardContentHeader = (props) => {
  const { service_menu, setSelectedRow, setSelectedId } = props;
  const { service_menu_titles } = props;
  const { onClick } = props;
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const mode = useSelector(state => state.settings.uiMode)

  const buttonClick = (navigation) => {
      onClick(navigation)
  }

  const handleCardClose = () => {
    setSelectedRow(null)
    if (setSelectedId) {
      setSelectedId(null)
    }
  }
  
  const getBurgerMenu = (items,Icon,active_tab) => {
      return (<PopupState key="downArrowKey" variant="popover" popupId="burger-menu">
          {(popupState) => (
              <React.Fragment>
                  <IconButton 
                      {...bindTrigger(popupState)}
                      color={active_tab ? "primary" : ""}
                  >
                      <Icon />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                      {items.map(item => {
                          return (
                              <MenuItem 
                                  key={item.navigation}
                                  sx={{
                                      textTransform: 'uppercase',
                                      fontSize: 14,
                                      color: item.is_active ?
                                      theme.palette.primary.main :
                                      theme.palette.text.primary
                                  }}
                                  onClick={() => {
                                      buttonClick(item.navigation); 
                                      popupState.close()
                                  }}
                              >
                                  {service_menu_titles[item.keyword]}
                              </MenuItem>
                          )
                      })}
                  </Menu>
              </React.Fragment>
          )}
      </PopupState>)
  }

  const getMenuItems = () => {
      let visible_items = []
      let hidden_items = []
      if (service_menu.length > VISIBLE_MENU_ITEMS_NUM) {
          visible_items = service_menu.slice(0,VISIBLE_MENU_ITEMS_NUM)
          hidden_items = service_menu.slice(VISIBLE_MENU_ITEMS_NUM)
      }
      else {
          visible_items = [...service_menu]
      }
      let items = visible_items.map(item => {
          return (
              <Button 
                  key={item.navigation}
                  variant="text"
                  sx={{ color: item.is_active ? theme.palette.primary.main : theme.palette.text.primary }}
                  onClick={() => buttonClick(item.navigation)}
              >
                  {service_menu_titles[item.keyword]}
              </Button>
          )
      })
      if (hidden_items.length > 0) {
          let active_tab = hidden_items.filter(item => item.is_active).length > 0
          items.push(
              getBurgerMenu(hidden_items,ArrowDropDownIcon,active_tab)
          )
      } 
      return items
  }

  return (
    <WrapperBox 
      sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: '20px 20px 5px 20px',
          background: mode === 'dark' ? theme.palette.customLightBlack : undefined,
          marginTop: '-2px',
      }}>

      {
        width <= dimensions.tablet.width &&
          <PopupState variant="popover" popupId="burger-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton {...bindTrigger(popupState)}>
                <MenuIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                {
                  service_menu.map(item => {
                    return (
                      <MenuItem 
                        key={item.navigation}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 14,
                          color: item.is_active ?
                          theme.palette.primary.main :
                          theme.palette.text.primary
                        }}
                        onClick={() => {
                          buttonClick(item.navigation); 
                          popupState.close()
                        }}
                      >
                        {service_menu_titles[item.keyword]}
                      </MenuItem>
                    )
                  })
                }
              </Menu>
            </React.Fragment>
          )}
          </PopupState> 
      }

      {
        width > dimensions.tablet.width && 
        <Stack
          direction="row"
          divider={<Divider 
              orientation="vertical" 
              flexItem 
          />}
        >
          {getMenuItems()}
        </Stack>
      }

      <IconButton
        sx={{ color: theme.palette.primary.main }}
        onClick={handleCardClose}
      >
        <CloseIcon />
      </IconButton>
    </WrapperBox>
  )
}

export default ServiceCardContentHeader