import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { resourceUsageSchema } from '../helpers/homeData'
import HomeResourceUsageItem from './HomeResourceUsageItem'

const HomeResourceUsageCategory = (props) => {
  const theme = useTheme()
  const { keyword, label, limits } = props

  const defaultTexts = useSelector(state => state.texts.langTexts)

  const [categoryExpanded, setCategoryExpanded] = useState(false)
  const [filteredLimits, setFilteredLimits] = useState([])
  const [limitsContainerHeight, setLimitsContainerHeight] = useState(500)

  const handleToggleCategory = () => {
    setCategoryExpanded((prev) => !prev)
  }

  const sxContainer = {
    marginBottom: '32px',
  }

  const sxCategory = {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 5px 0px 20px',    
    border: `${theme.palette.customGray} 1px solid`,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: '5px',

    '&:hover': {
      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
    }
  }

  const sxLimitsContainer = {
    padding: '5px',
    overflow: 'hidden',
    transition: 'all 0.3s',
    borderRadius: '0px 0px 5px 5px',
  }

  useEffect(() => {
    if (limits.length > 0) {
      const resource_usage_schema = [...resourceUsageSchema]
      const filtered_resource_usage_schema = resource_usage_schema.filter(item => item.category === keyword)

      let resource_names = []
      filtered_resource_usage_schema.forEach(item => resource_names.push(item.keyword))

      const filtered_limits = limits.filter(item => resource_names.includes(item.resource_name))
      let updated_filtered_limts = []

      filtered_limits.forEach(item => {
        const find_res = filtered_resource_usage_schema.find(item2 => item2.keyword === item.resource_name)
        const updated_resource_name = find_res ? defaultTexts[find_res.label] : item.resource_name
        updated_filtered_limts.push({ ...item, resource_name: updated_resource_name })
      })

      setFilteredLimits(updated_filtered_limts)   
    } else {
      setFilteredLimits([])
    }
  }, [limits, keyword, defaultTexts])

  useEffect(() => {
    let unlimited_count = 0

    filteredLimits.forEach(item => {
      if (item.available === 'Unlimited') {
        unlimited_count ++
      }
    })

    setLimitsContainerHeight((53 * (filteredLimits.length - unlimited_count)) + (29 * unlimited_count) + 10)
  }, [filteredLimits])
  
  return (
    <Box sx={sxContainer}>
      <Box
        sx={{
          ...sxCategory,
          borderRadius: categoryExpanded ? '5px 5px 0px 0px' : '5px',
        }}
        onClick={handleToggleCategory}
      >
        <Box>{defaultTexts[label]}</Box>
        <Box sx={{ marginTop: '5px' }}>{ categoryExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }</Box>
      </Box>
      <Box
        sx={{
          ...sxLimitsContainer,
          height: categoryExpanded ? `${limitsContainerHeight}px` : '0px',
          padding: categoryExpanded ? '5px' : '0px 5px',
          border: categoryExpanded ? `${theme.palette.customGray} 1px solid` : 'none',
          borderTop: 'none',
        }}
      >
        {
          filteredLimits.map((item, index) => (
            <HomeResourceUsageItem key={index} {...item} />
          ))
        }
      </Box>
    </Box>    
  )
}

export default HomeResourceUsageCategory