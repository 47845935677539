import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const ImageSpecsV2 = (props) => {
	const theme = useTheme()

	const { imageData } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '300px',
		justifyContent: 'flex-start',
	}

	return (
		<>
			<Grid container spacing={2} sx={{ mt: 1, mb: 4 }}>
				<Grid item xs={12} lg={5}>
					<WrapperBox sx={detailTabCardConfig}>
							<Stack direction="column" spacing={1} sx={{width: "90%"}}>
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.nameFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.name : ""}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.idFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.id : ""}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
									<CustomText 
											size="p" 
											sx={{color: "text.secondary"}}
									>
											{defaultTexts.statusFormFieldLabel}
									</CustomText>
									<CustomText size="p" sx={{ color: imageData.status === 'Active' ? theme.palette.success.main : theme.palette.warning.main }}>
										{ imageData ? imageData.status : "" } { imageData && imageData.status_icon }
									</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.createTimeHeaderLabel}
											</CustomText>
											<CustomText size="p">
													{imageData.created_at}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.updatedAtFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData.updated_at}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.visibilityFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.visibility.slice(0,1).toUpperCase()+imageData.visibility.slice(1) : "-"}
											</CustomText>
									</Stack>
									<Divider />
							</Stack>
					</WrapperBox>
				</Grid>
				<Grid item xs={12} lg={3}>
					<WrapperBox sx={detailTabCardConfig}>
							<Stack 
									direction="column" 
									spacing={1} 
									sx={{width: "90%"}}
							>
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.minDiskFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? `${imageData.min_disk} GB` : "-"} 
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.minRamFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? `${imageData.min_ram} MB` : "-"}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.containerFormatFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.container_format : "-"}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.diskFormatFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.disk_format : "-"}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.architectureFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.architecture : "-"}
											</CustomText>
									</Stack>
									<Divider />
									<Stack 
											direction="row" 
											justifyContent="space-between" 
											alignItems="center"
									>
											<CustomText 
													size="p" 
													sx={{color: "text.secondary"}}
											>
													{defaultTexts.sizeFormFieldLabel}
											</CustomText>
											<CustomText size="p">
													{imageData ? imageData.size : "-"}
											</CustomText>
									</Stack>
									<Divider />
									
							</Stack>
					</WrapperBox>
				</Grid>
				<Grid item xs={12} lg={4}>
					<WrapperBox sx={detailTabCardConfig}>
						<Stack 
							direction="column" 
							spacing={1} 
							sx={{width: "90%"}}
						>
							<Stack 
								direction="row" 
								justifyContent="space-between" 
								alignItems="center"
							>
								<CustomText 
									size="p" 
									sx={{color: "text.secondary"}}
								>
									{defaultTexts.hashAlgorithmFormFieldLabel}
								</CustomText>
								<CustomText size="p">
									{imageData ? imageData.os_hash_algo : "-"}
								</CustomText>
							</Stack>
							<Divider />
							<Stack 
								direction="column"
								justifyContent="center" 
								alignItems="flex-start"
							>
								<CustomText 
									size="p" 
									sx={{color: "text.secondary"}}
								>
									{defaultTexts.checksumFormFieldLabel}
								</CustomText>
								<CustomText size="p">
									{imageData ? imageData.checksum : "-"}
								</CustomText>
							</Stack>
							<Divider />
							<Stack 
								direction="column"
								justifyContent="center" 
								alignItems="flex-start"
							>
								<CustomText 
									size="p" 
									sx={{color: "text.secondary"}}
								>
									{defaultTexts.hashValueFormFieldLabel}
								</CustomText>
								<div style={{wordWrap: "break-word", width: 300}}> 
								<CustomText size="p">
									{imageData ? imageData.os_hash_value : "-"}
								</CustomText>
								</div>
							</Stack>
							<Divider />
						</Stack>
					</WrapperBox>
				</Grid>
			</Grid>
		</>
	)
}

export default ImageSpecsV2