import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Chip from '@mui/material/Chip'

const HomeResourcesItem = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { label, count, navigation, menu, submenu } = props

  const langTexts = useSelector(state => state.texts.langTexts)

  const sxContainer = {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px',
    border: `${theme.palette.customGray} 1px solid`,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: '5px',

    '&:hover': {
      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
    }
  }

  const handleClick = () => {
    if (navigation && menu && submenu) {
      navigate(navigation, { state: { menu, submenu } })
    } else if (navigation && menu) {
      navigate(navigation, { state: { menu } })
    } else if (navigation) {
      navigate(navigation)
    }    
  }
  
  return (
    <Grid xs={12} sm={6} lg={4}>
      <Box sx={sxContainer} onClick={handleClick}>
        <Box>{label ? langTexts[label] : ''}</Box>
        <Chip label={count} variant="outlined" color="primary" />
      </Box>
    </Grid>
  )
}

export default HomeResourcesItem