export const regionDataSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "uri", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "parent_region_id",
        is_id_field: false,
        field_type: "string", 
        label: "parentRegionFormFieldLabel"
    }
]

export const regionDataForm = [
    {
        field_key: "id",
        field_type: "string", 
        required: true, 
        label: "idFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "parent_region_id",
        field_type: "string", 
        required: false,
        label: "parentRegionFormFieldLabel",
        on_empty_null: true
    }
]


export const regionDataUpdateForm = [
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "parent_region_id",
        field_type: "string",
        required: false,
        label: "parentRegionFormFieldLabel",
        on_empty_null: true
    }
]


export const regionsfilterMenu = [
    {
        keyword: "filterParentRegionID", 
        value: "parent_region_id", 
        type: "text"
    }
];