import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import FilterListIcon from '@mui/icons-material/FilterList'

const CustomPopover = (props) => {
  const { type, horizontalOrigin } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        { type === 'menu' && <MenuIcon /> }
        { type === 'filterlist' && <FilterListIcon /> }
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalOrigin ? horizontalOrigin : 'left',
        }}
      >
        <Box sx={{ padding: '20px' }}>
          {props.children}
        </Box>        
      </Popover>
    </>
  )
}

export default CustomPopover