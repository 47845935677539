import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdSecurity } from "react-icons/md";

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const RouterInterfacesV20 = (props) => {
    const { securityGroupRules, onRuleRemove } = props
    const { addressGroups } = props
    const { securityGroups } = props

    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    return (
        <React.Fragment>
            <WrapperBox
            sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <MdSecurity
                    size={28}
                />
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.securityGroupRulesTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {securityGroupRules.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{defaultTexts.directionFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.ethertypeFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.protocolFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.portsFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.remoteFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.descriptionFormFieldLabel}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {securityGroupRules.map((row) => {
                                let remote = ""
                                if (row.remote_group_id) {
                                    const sec_group = securityGroups.filter(sg => sg.id === row.remote_group_id)[0]
                                    remote = `${defaultTexts.securityGroupFormFieldLabel}: ${sec_group.name}`
                                } else if (row.remote_address_group_id) {
                                    const address_group = addressGroups.filter(sg => sg.id === row.remote_address_group_id)[0]
                                    remote = `${defaultTexts.addressGroupFormFieldLabel}: ${address_group.name}`
                                } else if (row.remote_ip_prefix) {
                                    remote = `${row.remote_ip_prefix}`
                                } else {
                                    if (row.ethertype.toLowerCase() === "ipv4") {
                                        remote = "0.0.0.0/0"
                                    } else {
                                        remote = "::/0"
                                    }
                                }
                                let protocol = ""
                                if (row.protocol  && parseInt(row.protocol) !== 0) {
                                    protocol = `${row.protocol.toUpperCase()}`
                                } else {
                                    protocol = "Any"
                                }
                                let ports = ""
                                if (row.port_range_min) {
                                    ports = `${row.port_range_min}`
                                } 
                                if (row.port_range_max > -1 && row.port_range_max !== row.port_range_min) {
                                    ports = `${ports} - ${row.port_range_max}`
                                }
                                if (!row.port_range_min && !row.port_range_max) {
                                    ports = "Any"
                                }
                                return (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                    >
                                        {row.direction.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="left">
                                    {row.ethertype}
                                    </TableCell>
                                    <TableCell align="left">
                                        {protocol}
                                    </TableCell>
                                    <TableCell align="left">
                                        {ports}
                                    </TableCell>
                                    <TableCell align="left">
                                        {remote}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.description ? row.description : "-"}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onRuleRemove(row.id)}
                                        >
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default RouterInterfacesV20;