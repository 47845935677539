import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CustomText from '../../_common/CustomText'

const PaymentsMenu = (props) => {
  const theme = useTheme()

  const { menu, onClick } = props

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)

  const [filteredMenu, setFilteredMenu] = useState([])
  
  const buttonClick = (navigation) => {
    onClick(navigation)
  }

  useEffect(() => {
    setFilteredMenu(menu.filter(item => item.navigation !== 'invoices' || (billingDeposit && billingDeposit.billing_type && billingDeposit.billing_type === 'postpaid' && billingDeposit.postpaid_invoice && billingDeposit.postpaid_invoice === 'auto')))
  }, [billingDeposit, menu])

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"  
    >
      {
        filteredMenu.map((item, index) => (
          <Box
            key={index}
            sx={{
              color: theme.palette.customGrayDark,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                color: item.is_active ? theme.palette.primary.main : theme.palette.customGrayDark,
                cursor: item.is_active ? 'auto' : 'pointer',
                marginRight: '12px',
                '&:hover': {
                  opacity: item.is_active ? 1 : 0.7,
                }
              }}
              onClick={() => buttonClick(item.navigation)}
            >
              <CustomText sx={{ fontSize: '17px' }}>
                {defaultTexts[item.keyword]}
              </CustomText>
            </Box>            
            {
              index < filteredMenu.length - 1 &&
              <Box
                sx={{
                  marginRight: '12px',
                }}
              >
                <CustomText sx={{ fontSize: '17px', lineHeight: '17px' }}>|</CustomText>
              </Box>
            }						
          </Box>
        ))
      }
    </Stack>
  )
}

export default PaymentsMenu