export const authTokenApiDataV3 = {
    "auth": {
        "identity": {
            "methods": [
                "password"
            ],
            "password": {
                "user": {
                    "name": "{missingUsername}",
                    "domain": {
                        "id": "{missingDomain}"
                    },
                    "password": "{missingPassword}"
                }
            }
        },
    //     "scope": {
    //       "system": {
    //           "all": true
    //     }
    //   }
    }
}


export const authTokenProjectScopeApiDataV3 = {
    "auth": {
        "identity": {
            "methods": [
                "password"
            ],
            "password": {
                "user": {
                    "id": "{missingUserID}",
                    "password": "{missingPassword}"
                }
            }
        },
        "scope": {
          "project": {
              "id": "{missingProjectID}"
        }
      }
    }
}

export const authTokenApplicationCredentialApiDataV3 = {
    "auth": {
        "identity": {
            "methods": [
                "application_credential"
            ],
            "application_credential": {
                "id": "{id}",
                "secret": "{secret}"
            }
        }
    }
}