import React from 'react'
import CustomText from './CustomText';
import WrapperBox from './WrapperBox';
import CustomCard from './CustomCard';

const NoDataNote = (props) => {
    const { text } = props
    return (
        <WrapperBox>
            <CustomCard 
                overflow="hidden" 
                cardHeight={220}
                cardWidth={300}
                card_sx={{
                    mt: 15, 
                    opacity: 0.5, 
                    borderRadius: 50,
                    
                }}
                box_sx={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center",
                    overflow: "hidden",
                    right: 0
                }}
            >
                <CustomText 
                    size="h5" 
                    sx={{textAlign: "center"}}
                >
                    {text}
                </CustomText>
            </CustomCard>
        </WrapperBox>
    )
};

export default NoDataNote;