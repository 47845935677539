import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import AddressGroupsTableV20 from './addressGroupsTableV2.0';
import { addressGroupsFilterMenu, addressGroupUpdateForm } from '../../../../../../_data/openstack/neutron/security/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import FormGroup from '@mui/material/FormGroup';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import AddressGroupAddV20 from './addressGroupAddV2.0';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'

const SERVICE_NAME = openStackServices.networkService

const AddressGroupsWrapperV20 = (props) => {
	const theme = useTheme()
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	const { currentTab, serviceSubmenu } = props
	const [addressGroupsData, setAddressGroupsData] = useState([])
	const [addressGroups, setAddressGroups] = useState([])
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [addressGroupDeleteConfirmDialogOpen, setAddressGroupDeleteConfirmDialogOpen] = useState(false);
	const [selectedAddressGroups, setSelectedAddressGroups] = useState([])
	const [addressGroupsSortParams, setAddressGroupsSortParams] = useState("")
	
	const [addressGroupFilterQueryParams, setAddressGroupFilterQueryParams] = useState("")
	const [selectedAddressGroupFilter, setSelectedAddressGroupFilter] = useState(addressGroupsFilterMenu[0].value)
	const [selectedAddressGroupFilterValue, setSelectedAddressGroupFilterValue] = useState("")
	const [addressGroupUpdateData, setAddressGroupUpdateData] = useState({});
	const [updateAddressGroupDialogOpen, setUpdateAddressGroupDialogOpen] = useState(false)
	const [actionsList, setActionsList] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const addressGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.addressGroupsUrl)[0].url)	

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleAddressGroupFilteredSearch = () => {
			if (selectedAddressGroupFilter && selectedAddressGroupFilterValue) {
					setAddressGroupFilterQueryParams(`${selectedAddressGroupFilter}=${selectedAddressGroupFilterValue}`)
			} else {
					setAddressGroupFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handleAddressGroupsDataFormatting = useCallback(() => {
			const formatted_data = addressGroupsData.map((item) => {
					let new_item = {...item}
					
					return new_item
			})
			setAddressGroups(formatted_data)
	},[
			addressGroupsData,
	])

	const handleAddressGroupFilterReset = () => {
			setSelectedAddressGroupFilter(addressGroupsFilterMenu[0].value)
			setSelectedAddressGroupFilterValue("")
			setAddressGroupFilterQueryParams("")
			handleDataFetch()
	}


	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleAddressGroupsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setAddressGroupsSortParams(sort_param)
			handleDataFetch()
	}

	const handleUpdateAddressGroupDialogClose = () => {
			setAddressGroupUpdateData({})
			setUpdateAddressGroupDialogOpen(false)
	}

	const handleAddressGroupUpdateDataChange = (event, field_key) => {
			let new_form_data = {...addressGroupUpdateData}
			if (addressGroupUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (addressGroupUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setAddressGroupUpdateData(new_form_data)
	}

	const getDataForm = () => {
			let form_data = [...addressGroupUpdateForm]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													addressGroupUpdateData,
													handleAddressGroupUpdateDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleAddressGroupDelete = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}/${n_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === securityUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}

	const onAddressGroupDelete = async () => {
			handleAddressGroupDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedAddressGroups) {
					const resp = await handleAddressGroupDelete(selectedAddressGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleAddressGroupSelection = (selected_rows) => {
			setSelectedIds(selected_rows)
	}

	const onAddressGroupDeleteConfirm = useCallback((n_list) => {
			const selected_n_list = addressGroupsData.filter(n => 
					n_list.includes(n.id))
			setSelectedAddressGroups([...selected_n_list])
			setAddressGroupDeleteConfirmDialogOpen(true)
	},[addressGroupsData])

	const handleAddressGroupDeleteConfirmDialogClose = () => {
			setAddressGroupDeleteConfirmDialogOpen(false)
	}

	const handleAddressGroupUpdate = async (n_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}/${n_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {address_group: addressGroupUpdateData},
				token,
		})

		if (nt_response.status_code === securityUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}			
	}


	const onAddressGroupUpdate = async () => {
			handleUpdateAddressGroupDialogClose()
			let err = []
			for (let n in selectedAddressGroups) {
					const resp = await handleAddressGroupUpdate(selectedAddressGroups[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			setAddressGroupUpdateData({})
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onAddressGroupUpdateDialogOpen = useCallback((ag_list) => {
			const selected_n_list = addressGroupsData.filter(n => 
					ag_list.includes(n.id))
			setSelectedAddressGroups([...selected_n_list])
			setUpdateAddressGroupDialogOpen(true)
	},[
			addressGroupsData
	])

	const getAddressGroupsActionsList = useCallback(() => {
			let addressGroup_actions = []
			let new_action = {}
			if (selectedIds.length === 1) {
					new_action["value"] = "update_address_group"
					new_action["action"] = onAddressGroupUpdateDialogOpen
					new_action["keyword"] = "addressGroupUpdateActionTitle"
					new_action["button_text"] = "applyButtonTitleText"
					addressGroup_actions.push({...new_action})
					new_action = {}
			}
			new_action["value"] = "addressGroup_delete"
			new_action["action"] = onAddressGroupDeleteConfirm
			new_action["keyword"] = "securityGroupDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			addressGroup_actions.push({...new_action})
			
			return addressGroup_actions
	},[
			onAddressGroupUpdateDialogOpen,
			onAddressGroupDeleteConfirm,
			selectedIds
	])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							
							let url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}?${addressGroupFilterQueryParams}${addressGroupsSortParams}`
							const method = "GET"
							const addressGroup_response = await openstackRequest({url:url, method:method, token, })
							if (addressGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
									setAddressGroupsData(addressGroup_response.data.address_groups)
							} else {
								setAddressGroupsData([])
							}
							
							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			addressGroupsUrl, 
			addressGroupFilterQueryParams,
			dataFetchingRequired,
			token,
			addressGroupsSortParams
	]);

	useEffect(() => {
		if (addressGroupsData.length > 0) {
			handleAddressGroupsDataFormatting()
		} else {
			setAddressGroups([])
		}
	},[
		addressGroupsData,
		handleAddressGroupsDataFormatting
	])

	useEffect(() => {
		if (selectedAddressGroups.length === 1) {
				let new_update_data = {}
				for (let i in addressGroupUpdateForm) {
						new_update_data[addressGroupUpdateForm[i].field_key] = selectedAddressGroups[0][addressGroupUpdateForm[i].field_key]
				}
				setAddressGroupUpdateData(new_update_data)
		}
	},[
			selectedAddressGroups
	]);

	useEffect(() => {
			const actions_list = getAddressGroupsActionsList()
			setActionsList(actions_list)
	},[
			selectedAddressGroups,
			getAddressGroupsActionsList
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/network-security')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu])

	const handleActionRun = () => {
		const actionsList = getAddressGroupsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedAddressGroups([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getAddressGroupsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<AddressGroupAddV20
					handleDataFetch={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					<CustomTextField 
							size="small"
							variant="outlined"
							label={defaultTexts[addressGroupsFilterMenu[0].keyword]}
							currentValue={selectedAddressGroupFilterValue}
							setCurrentValue={e => setSelectedAddressGroupFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleAddressGroupFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleAddressGroupFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuSecurity}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{addressGroupsData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<AddressGroupsTableV20 
				addressGroupsData={addressGroups}
				setAddressGroupsData={setAddressGroups}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={actionsList}
				sortHandler={handleAddressGroupsSorting}
				handleRowSelection={handleAddressGroupSelection}
				sortParams={addressGroupsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	return (
		<>
			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ getNetworksHeader() }

				{ !isLoading && getNetworksTable() }

				<CustomDialog
					open={addressGroupDeleteConfirmDialogOpen}
					onClose={handleAddressGroupDeleteConfirmDialogClose}
					dialogTitle={{
							title: defaultTexts.addressGroupDeleteConfirmTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `${defaultTexts.addressGroupDeleteConfirmText}: [${selectedAddressGroups.map(v => v.name).toString()}]`, 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.confirmButtonText, 
							onClick: onAddressGroupDelete, 
							sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
					open={updateAddressGroupDialogOpen}
					onClose={handleUpdateAddressGroupDialogClose}
					dialogTitle={{
							title: defaultTexts.addressGroupUpdateActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onAddressGroupUpdate, 
							sx: {color: 'primary.main'}}]}
				>
					{getDataForm()}
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}
			</Box>
		</>
	)
}

export default AddressGroupsWrapperV20