import React, { useState, useEffect, useCallback } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ComputeServersWrapperV21 from './servers/computeServersWrapperV2_1'
import ComputeKeypairsWrapperV21 from './keypairs/computeKeypairsWrapperV2_1'

const ComputeNovaContentV2_1 = (props) => {
	const theme = useTheme()
	const { navigate, location, section } = props

	const [serviceMenu, setServiceMenu] = useState([
		{ keyword: "submenuServers", navigation: "/instances", is_active: true, component: ComputeServersWrapperV21 },
		{ keyword: "submenuKeypairs", navigation: "/keypairs", is_active: false, component: ComputeKeypairsWrapperV21 },
	])

	const getCurrentTab = useCallback(() => {
		const currentItem = section ? serviceMenu.filter(item => item.navigation === section)[0] : serviceMenu.filter(item => item.is_active)[0]
		return currentItem
	}, [section, serviceMenu])

	const [currentTab, setCurrentTab] = useState(getCurrentTab())

	const changeMenuActiveTab = useCallback((navigation) => {
		const updated_menu = serviceMenu.map(item => {
			if (item.navigation === navigation) {
				item.is_active = true
				setCurrentTab(item)
			} else {
				item.is_active = false
			}

			return item
		})

		setServiceMenu(updated_menu)
	}, [serviceMenu])

	useEffect(() => {
		if (location.state && location.state.menu && location.state) {
			changeMenuActiveTab(location.state.menu)
			navigate(location.path, {})
		}
	}, [navigate, location, changeMenuActiveTab])

	useEffect(() => {
		setCurrentTab(getCurrentTab())
	}, [getCurrentTab, section])

	return (
		<Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
			<currentTab.component
				navigate={navigate}
				location={location}
				serviceMenu={serviceMenu}
				changeMenuActiveTab={changeMenuActiveTab}
			/>
		</Box>
	)
}

export default ComputeNovaContentV2_1