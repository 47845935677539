import React from 'react';
import Stack from '@mui/material/Stack';
import CustomText from '../CustomText';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../_form_fields/CustomTextField';

const boxTitle = {
    display: "block", 
    p: 1, 
    color: "secondary.main"
}

const CustomHeader = (props) => {
    const { cardHeaderTitle } = props
    const { items } = props
    const { handleDeselectAllItems } = props
    const { currentFilter, handleFilterChange } = props
    const { search_field_sx } = props

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Stack
                direction="row"
                alignItems="center"
            >
                <CustomText 
                    sx={{...boxTitle}}
                >
                    {cardHeaderTitle}
                </CustomText>
                <CustomText sx={{color: "text.secondary"}}>
                    {`Selected: ${items.length}`}
                </CustomText>
                {items.length > 0 && 
                    <IconButton onClick={handleDeselectAllItems}>
                        <DeselectIcon />
                    </IconButton>
                }
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                sx={{width: "50%", m: 1, ...search_field_sx}}
            >
                <SearchIcon sx={{color: "divider", mr: 1}} />
                <CustomTextField 
                    currentValue={currentFilter}
                    setCurrentValue={handleFilterChange}
                    size="small"
                />
            </Stack>
        </Stack>
    )
};

export default CustomHeader;