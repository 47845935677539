import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ImagesWrapperV2 from './images/imagesWrapperV2';

const ImagesGlanceContentV2 = (props) => {
	const theme = useTheme()

	const serviceMenu = [
		{ keyword: "submenuImages", navigation: "/images", is_active: true, component: ImagesWrapperV2 },
	]

	const getCurrentTab = () => {
			const currentItem = serviceMenu.filter(item => item.is_active)[0]
			return currentItem
	}

	const currentTab = getCurrentTab()

	return (
		<Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
			<currentTab.component />
		</Box>
	)
}

export default ImagesGlanceContentV2