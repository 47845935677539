export const userDataSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "email",
        is_id_field: false,
        field_type: "string", 
        label: "emailFormFieldLabel"
    },
    {
        field_key: "domain_id",
        is_id_field: false,
        field_type: "string", 
        label: "domainFormFieldLabel"
    },
    {
        field_key: "default_project_id",
        is_id_field: false,
        field_type: "string", 
        label: "defaultProjectFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createTimeHeaderLabel"
    },
    {
        field_key: "password_expires_at",
        is_id_field: false,
        field_type: "string", 
        label: "passwordExpiresAtFormFieldLabel"
    },
    {
        field_key: "options",
        is_id_field: false,
        field_type: "json", 
        label: "optionsFormFieldLabel",
        items: [
            {
                field_key: "ignore_password_expiry",
                label: "passwordNotExpiresFormFieldLabel",
                field_type: "bool",
                value_on_true: "formValueYes",
                value_on_false: "formValueNo"
            },
            {
                field_key: "ignore_change_password_upon_first_use",
                label: "doNotAskForPasswordChangeOnFirstUseFormFieldLabel",
                field_type: "bool",
                value_on_true: "formValueYes",
                value_on_false: "formValueNo"
            },
            {
                field_key: "ignore_lockout_failure_attempts",
                label: "doNotLockOutOnFailureAttemptsFormFieldLabel",
                field_type: "bool",
                value_on_true: "formValueYes",
                value_on_false: "formValueNo"
            },
            {
                field_key: "lock_password",
                label: "lockPasswordFromChangeFormFieldLabel",
                field_type: "bool",
                value_on_true: "formValueYes",
                value_on_false: "formValueNo"
            }
        ]
    }
]

export const userDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "usernameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "password",
        field_type: "password", 
        required: true, 
        label: "passwordFormFieldLabel", 
        error_label: "requiredFormFieldError",
        error_mismatch: "passwordMismatchFormFieldError"
    },
    {
        field_key: "confirm_password",
        field_type: "password", 
        required: true, 
        label: "confirmPasswordFormFieldLabel", 
        error_label: "requiredFormFieldError",
        error_mismatch: "passwordMismatchFormFieldError"
    },
    {
        field_key: "email",
        field_type: "email", 
        required: true, 
        label: "emailFormFieldLabel", 
        error_label: "requiredFormFieldError",
        field_type_mismatch: "invalidEmailFormFieldError",
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "domain_id",
        field_type: "select", 
        required: true,
        label: "domainFormFieldLabel",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "default_project_id",
        field_type: "select", 
        required: false,
        label: "defaultProjectFormFieldLabel",
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]

export const userOptionsForm = [
    {
        field_key: "ignore_password_expiry",
        label: "passwordNotExpiresFormFieldLabel",
        field_type: "bool",
        required: false,
        default_value: false
    },
    {
        field_key: "ignore_change_password_upon_first_use",
        label: "doNotAskForPasswordChangeOnFirstUseFormFieldLabel",
        field_type: "bool",
        required: false,
        default_value: false
    },
    {
        field_key: "ignore_lockout_failure_attempts",
        label: "doNotLockOutOnFailureAttemptsFormFieldLabel",
        field_type: "bool",
        required: false,
        default_value: false
    },
    {
        field_key: "lock_password",
        label: "lockPasswordFromChangeFormFieldLabel",
        field_type: "bool",
        required: false,
        default_value: false
    }
]

export const userDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false, 
        label: "usernameFormFieldLabel"
    },
    {
        field_key: "email",
        field_type: "email", 
        required: false, 
        label: "emailFormFieldLabel",
        field_type_mismatch: "invalidEmailFormFieldError",
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "default_project_id",
        field_type: "select", 
        required: false,
        label: "defaultProjectFormFieldLabel",
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel"
    }
]


export const usersfilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterDomain", 
        value: "domain_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    },
    {
        keyword: "filterEnabled", 
        value: "enabled", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
];

export const usersSortFields = [
    {
        field_key: "name",
        label: "usernameFormFieldLabel"
    },
    {
        field_key: "email",
        label: "emailFormFieldLabel"
    },
    {
        field_key: "created_at",
        label: "createTimeHeaderLabel"
    }
]

export const userAppCredentialsDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "secret",
        field_type: "string", 
        required: false, 
        label: "secretFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "project_id",
        field_type: "select", 
        required: true, 
        label: "projectFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "roles",
        field_type: "select", 
        required: false, 
        label: "roleFormFieldLabel",
        multiple: true,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "expires_at",
        field_type: "datetime", 
        required: false, 
        label: "expiresAtFormFieldLabel"
    },
    {
        field_key: "unrestricted",
        field_type: "bool", 
        required: false,
        label: "unrestrictedFormFieldLabel"
    }
]

export const userAccessRuleDataForm = [
    {
        field_key: "path",
        field_type: "string", 
        required: true, 
        label: "pathFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "method",
        field_type: "select", 
        required: true, 
        label: "methodFormFieldLabel", 
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true,
        items: [
            {
                keyword: "GET",
                value: "GET",
                default: false
            },
            {
                keyword: "POST",
                value: "POST",
                default: false
            },
            {
                keyword: "PUT",
                value: "PUT",
                default: false
            },
            {
                keyword: "PATCH",
                value: "PATCH",
                default: false
            },
            {
                keyword: "DELETE",
                value: "DELETE",
                default: false
            },
        ]
    },
    {
        field_key: "service",
        field_type: "select", 
        required: true, 
        label: "serviceFormFieldLabel", 
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
]