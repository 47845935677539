import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import CustomText from '../../_common/CustomText'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import constants from '../../../config/constants'
import { openStackServices } from '../../../config/openStackConstants'
import {
  computeNovaConstants,
  networkNeutronConstants,
  blockStorageCinderConstants,
  imagesGlanceConstants
} from '../../../config/openStackConstants'
import { limitsUpdateDataForm } from '../../../_data/openstack/neutron/limits/v2.0';
import { computeLimitsUpdateDataForm } from '../../../_data/openstack/compute/limits/v2.1';
import { cinderLimitsUpdateDataForm } from '../../../_data/openstack/cinder/limits/v3';
import { openstackRequest, computeNovaRequest, volumeCinderRequest, billingRequest } from '../../../_network/openstack_request'
import { projectsUrl as projectsUrlResponses} from '../../../_api_responses/openstack/identity/projects/v3';
import { resourcesSchema, resourceUsageCategoriesSchema } from '../helpers/homeData'
import { billingUrls } from '../../../_network/apiUrls'
import useWindowDimensions from '../../_common/WindowDimensions'
import HomeResourcesItem from './HomeResourcesItem'
import HomeResourceUsageCategory from './HomeResourceUsageCategory'
import HomeInstanceStates from './HomeInstanceStates'

const COMPUTE_SERVICE_NAME = openStackServices.computeService
const NETWORK_SERVICE_NAME = openStackServices.networkService
const VOLUME_SERVICE_NAME = openStackServices.volumeService
const IMAGE_SERVICE_NAME = openStackServices.imageService

const HomeContent = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const langTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const accessToken = useSelector(state => state.profile.access_token)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const purchasedServices = useSelector(state => state.openstack.purchasedServices)
  const mode = useSelector(state => state.settings.uiMode)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const hasBilling = useSelector(state => state.profile.hasBilling)

  let currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? billingAccountConfig.currency.name : ''
  currencyName = currencyName ? currencyName.toUpperCase() : ''
  
  const [resources, setResources] = useState([...resourcesSchema])

  const [servers, setServers] = useState([])
  const [keypairs, setKeypairs] = useState([])
  const [routers, setRouters] = useState([])
  const [volumes, setVolumes] = useState([])
  const [floatingIps, setFloatingIps] = useState([])
  const [snapshots, setSnapshots] = useState([])
  const [images, setImages] = useState([])
  const [backups, setBackups] = useState([])
  const [vpns, setVpns] = useState([])
  const [firewallRules, setFirewallRules] = useState([])

  const [serverLimits, setServerLimits] = useState({})
  const [volumeLimits, setVolumeLimits] = useState({})
  const [networkLimits, setNetworkLimits] = useState({})
  const [limits, setLimits] = useState([])

  const [billingBlockDiff, setBillingBlockDiff] = useState(0)

  const [chargesSummary, setChargesSummary] = useState('')
  const [monthToDateCost, setMonthToDateCost] = useState(0)
  const [forcastedCost, setForcastedCost] = useState(0)
  const [prevMonthCost, setPrevMonthCost] = useState(0)
  const [comparedCostPrecent, setComparedCostPercent] = useState(0)
  
  const computeServiceDomain = useSelector(
    state => state.openstack.purchasedServices.filter(
      service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
  const computeServiceVersion = useSelector(
    state => state.openstack.purchasedServices.filter(
      service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
  const serversUrl = useSelector(
    state => state.computeNova.computeNovaApiUrls.filter(
      version => version.api_version === "v2.1")[0].urls.filter(
        url => url.keyword === computeNovaConstants.serversUrl)[0].url)
  const keypairsUrl = useSelector(
    state => state.computeNova.computeNovaApiUrls.filter(
        version => version.api_version === "v2.1")[0].urls.filter(
            url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)
  const computeLimitsUrl = useSelector(
    state => state.computeNova.computeNovaApiUrls.filter(
        version => version.api_version === "v2.1")[0].urls.filter(
            url => url.keyword === computeNovaConstants.limitsUrl)[0].url)

  const neutronServiceDomain = useSelector(
    state => state.openstack.purchasedServices.filter(
    service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
    state => state.openstack.purchasedServices.filter(
    service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
  const routersUrl = useSelector(
    state => state.networkNeutron.networkNeutronApiUrls.filter(
        version => version.api_version === "v2.0")[0].urls.filter(
            url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
  const floatingIPsUrl = useSelector(
    state => state.networkNeutron.networkNeutronApiUrls.filter(
        version => version.api_version === "v2.0")[0].urls.filter(
            url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)
  const vpnSiteConnectionsUrl = useSelector(
    state => state.networkNeutron.networkNeutronApiUrls.filter(
        version => version.api_version === "v2.0")[0].urls.filter(
            url => url.keyword === networkNeutronConstants.vpnSiteConnectionsUrl)[0].url)
  const firewallRulesUrl = useSelector(
    state => state.networkNeutron.networkNeutronApiUrls.filter(
        version => version.api_version === "v2.0")[0].urls.filter(
            url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)
  const networkLimitsUrl = useSelector(
    state => state.networkNeutron.networkNeutronApiUrls.filter(
        version => version.api_version === "v2.0")[0].urls.filter(
            url => url.keyword === networkNeutronConstants.limitsUrl)[0].url)

  const cinderServiceDomain = useSelector(
    state => state.openstack.purchasedServices.filter(
    service => service.service === VOLUME_SERVICE_NAME)[0].config_params.service_domain)
  const cinderServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === VOLUME_SERVICE_NAME)[0].config_params.api_version)
  const volumesUrl = useSelector(
      state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
          version => version.api_version === "v3")[0].urls.filter(
              url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
  const snapshotsUrl = useSelector(
    state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
        version => version.api_version === "v3")[0].urls.filter(
            url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
  const backupsUrl = useSelector(
    state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
        version => version.api_version === "v3")[0].urls.filter(
            url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
  const cinderLimitsUrl = useSelector(
    state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
        version => version.api_version === "v3")[0].urls.filter(
            url => url.keyword === blockStorageCinderConstants.limitsUrl)[0].url)

  const imageServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
  const imageServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)
  const imagesUrl = useSelector(
      state => state.imageGlance.imageGlanceApiUrls.filter(
          version => version.api_version === "v2")[0].urls.filter(
              url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)

  const sxContainer = {
    background: mode === 'light' ? theme.palette.customWhite : undefined,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
    borderRadius: '0px',
  }

  const sxContainerLabel = {
    height: '44px',
    color: theme.palette.customBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: `${theme.palette.customGray} 1px solid`,
    paddingLeft: '20px',
  }

  const sxResourceUsedIcon = {
    width: '10px',
    height: '10px',
    background: alpha(theme.palette.primary.main, 0.3),
    border: `${theme.palette.primary.main} 1px solid`,
    marginRight: '5px',
  }

  const sxResourceFreeIcon = {
    width: '10px',
    height: '10px',
    background: theme.palette.customWhite,
    border: `${theme.palette.primary.main} 1px solid`,
    marginRight: '5px',
  }

  const sxResourceConditionTitle = {
    color: theme.palette.customBlack,
    fontSize: '14px',
    marginRight: '10px',
    textTransform: 'uppercase',
  }

  const sxBillingTitle = {
    color: theme.palette.customBlack,
    marginBottom: '5px',
  }

  const sxBillingAmount = {
    color: theme.palette.primary.main,
    marginBottom: '5px',
  }

  const sxBillingDescription = {
    color: theme.palette.customGrayDark,
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }

  const convertMBtoGB = (v) => {
    if (v < 1024) {
      return v
    } else {
      return Math.round(v / 1024)
    }
  }

  useEffect(() => {
    const fetchServersData = async () => {
      const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail`
      const method = 'GET'

      const response = await computeNovaRequest({ url, method, token })

      if (response && response.data && response.data.servers && response.data.servers.length > 0) {
        setServers(response.data.servers)
      } else {
        setServers([])
      }
    }

    fetchServersData()
  }, [computeServiceDomain, computeServiceVersion, serversUrl, token])

  useEffect(() => {
    const fetchKeypairsData = async () => {
      const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
      const method = 'GET'

      const response = await openstackRequest({ url, method, token })

      if (response && response.data && response.data.keypairs && response.data.keypairs.length > 0) {
        setKeypairs(response.data.keypairs)
      } else {
        setKeypairs([])
      }
    }

    fetchKeypairsData()
  }, [computeServiceDomain, computeServiceVersion, keypairsUrl, token])

  useEffect(() => {
    const fetchRoutersData = async () => {
      const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}?`
      const method = 'GET'

      const response = await openstackRequest({url:url, method:method, token, })

      if (response && response.data && response.data.routers && response.data.routers.length > 0) {
        setRouters(response.data.routers)
      } else {
        setRouters([])
      }
    }

    fetchRoutersData()
  }, [neutronServiceDomain, neutronServiceVersion, routersUrl, token])

  useEffect(() => {
    const fetchFloatingIpsData = async () => {
      const url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}?`
      const method = 'GET'

      const response = await openstackRequest({url:url, method:method, token, })

      if (response && response.data && response.data.floatingips && response.data.floatingips.length > 0) {
        setFloatingIps(response.data.floatingips)
      } else {
        setFloatingIps([])
      }
    }

    fetchFloatingIpsData()
  }, [neutronServiceDomain, neutronServiceVersion, floatingIPsUrl, token])

  useEffect(() => {
    const fetchVpnsData = async () => {
      const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnSiteConnectionsUrl}`
      const method = 'GET'

      const response = await openstackRequest({url:url, method:method, token, })

      if (response && response.data && response.data.ipsec_site_connections && response.data.ipsec_site_connections.length > 0) {
        setVpns(response.data.ipsec_site_connections)
      } else {
        setVpns([])
      }
    }

    fetchVpnsData()
  }, [neutronServiceDomain, neutronServiceVersion, vpnSiteConnectionsUrl, token])

  useEffect(() => {
    const fetchFirewallRulesData = async () => {
      const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}`
      const method = 'GET'

      const response = await openstackRequest({url:url, method:method, token, })

      if (response && response.data && response.data.firewall_rules && response.data.firewall_rules.length > 0) {
        setFirewallRules(response.data.firewall_rules)
      } else {
        setFirewallRules([])
      }
    }

    fetchFirewallRulesData()
  }, [neutronServiceDomain, neutronServiceVersion, firewallRulesUrl, token])

  useEffect(() => {
    const fetchVolumesData = async () => {
      const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail`
      const method = 'GET'

      const response = await volumeCinderRequest({ url, method, token })

      if (response && response.data && response.data.volumes && response.data.volumes.length > 0) {
        setVolumes(response.data.volumes)
      } else {
        setVolumes([])
      }
    }

    fetchVolumesData()
  }, [cinderServiceDomain, cinderServiceVersion, volumesUrl, defaultAdminProject, token])

  useEffect(() => {
    const fetchSnapshotsData = async () => {
      const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail`
      const method = 'GET'

      const response = await volumeCinderRequest({ url, method, token })

      if (response && response.data && response.data.snapshots && response.data.snapshots.length > 0) {
        setSnapshots(response.data.snapshots)
      } else {
        setSnapshots([])
      }
    }

    fetchSnapshotsData()
  }, [cinderServiceDomain, cinderServiceVersion, snapshotsUrl, defaultAdminProject, token])

  useEffect(() => {
    const fetchBackupsData = async () => {
      const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail`
      const method = 'GET'

      const response = await volumeCinderRequest({ url, method, token })

      if (response && response.data && response.data.backups && response.data.backups.length > 0) {
        setBackups(response.data.backups)
      } else {
        setBackups([])
      }
    }

    fetchBackupsData()
  }, [cinderServiceDomain, cinderServiceVersion, backupsUrl, defaultAdminProject, token])
  
  useEffect(() => {
    const fetchImagesData = async () => {
      const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
      const method = 'GET'

      const response = await openstackRequest({ url, method, token })

      if (response && response.data && response.data.images && response.data.images.length > 0) {
        setImages(response.data.images.filter(item => item.visibility === 'private' && item.owner === defaultAdminProject))
      } else {
        setImages([])
      }
    }

    fetchImagesData()
  }, [imageServiceDomain, imageServiceVersion, imagesUrl, token, defaultAdminProject])
  
  useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${computeServiceDomain}/${computeServiceVersion}/${computeLimitsUrl}`
        const method = "GET"
        const limits_response = await openstackRequest({url:url, method:method, token, })

        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.limits && limits_response.data.limits.absolute) {
          const absoulteItem = limits_response.data.limits.absolute

          updated_data.cpu_cores = { limit: absoulteItem.maxTotalCores, used: absoulteItem.totalCoresUsed, reserved: 0, }
          updated_data.ram = { limit: absoulteItem.maxTotalRAMSize, used: absoulteItem.totalRAMUsed, reserved: 0, }
          updated_data.server_instances = { limit: absoulteItem.maxTotalInstances, used: absoulteItem.totalInstancesUsed, reserved: 0, }
          updated_data.total_allowed_keypairs = { limit: absoulteItem.maxTotalKeypairs, used: keypairs.length, reserved: 0, }
        }

        setServerLimits(updated_data)        
    })()
  },[
    computeServiceDomain,
    computeServiceVersion,
    computeLimitsUrl,
    defaultAdminProject,
    token,
    keypairs,
  ])

  useEffect(() => {        
    (async () => {
        let updated_data = {}
        
        const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${cinderLimitsUrl}/${defaultAdminProject}?usage=true`
        const method = "GET"
        const limits_response = await volumeCinderRequest({url:url, method:method, token, })
        
        if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code && limits_response.data && limits_response.data.quota_set) {
          updated_data = limits_response.data.quota_set
        }
        
        setVolumeLimits(updated_data)        
    })()
  },[
    cinderServiceDomain,
    cinderServiceVersion,
    cinderLimitsUrl,
    defaultAdminProject,
    token,
  ])

  useEffect(() => {
    (async () => {        
      const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networkLimitsUrl}/${defaultAdminProject}/details.json`
      const method = "GET"
      const limits_response = await openstackRequest({url:url, method:method, token, })
      if ( limits_response.status_code === projectsUrlResponses.get.success_response.status_code) {
        setNetworkLimits(limits_response.data.quota)
      }        
    })()
  },[
    neutronServiceDomain,
    neutronServiceVersion,
    networkLimitsUrl,
    token,
    defaultAdminProject,
  ])

  useEffect(() => {
    const hasService = (service) => {
      const findService = purchasedServices.find(item => item.service === service)
      return findService ? true : false
    }
    
    let resources_initial = [...resourcesSchema]
    let resources_updated = []

    if (!hasService('compute_nova')) {
      resources_initial = resources_initial.filter(item => item.keyword !== 'instances')
    }

    if (!hasService('volumes_cinder')) {
      resources_initial = resources_initial.filter(item => item.keyword !== 'volumes' && item.keyword !== 'backups' && item.keyword !== 'snapshots')
    }

    if (!hasService('images_glance')) {
      resources_initial = resources_initial.filter(item => item.keyword !== 'private-images')
    }

    if (!hasService('networks_neutron')) {
      resources_initial = resources_initial.filter(item => item.keyword !== 'routers' && item.keyword !== 'public-ip-addresses' && item.keyword !== 'vpns' && item.keyword !== 'firewall-rules')
    }

    resources_initial.forEach(item => {
      if (item.keyword === 'instances') {
        resources_updated.push({
          ...item,
          count: servers.length,
          navigation: '/compute/instances',
        })
      } else if (item.keyword === 'volumes') {
        resources_updated.push({
          ...item,
          count: volumes.length,
          navigation: '/storage/volumes',
        })
      } else if (item.keyword === 'backups') {
        resources_updated.push({
          ...item,
          count: backups.length,
          navigation: '/storage/backups',
        })
      } else if (item.keyword === 'routers') {
        resources_updated.push({
          ...item,
          count: routers.length,
          navigation: '/networks/l3-networking/routers',
        })
      } else if (item.keyword === 'public-ip-addresses') {
        resources_updated.push({
          ...item,
          count: floatingIps.length,
          navigation: '/networks/l3-networking/floating-ips',
        })
      } else if (item.keyword === 'vpns') {
        resources_updated.push({
          ...item,
          count: vpns.length,
          navigation: '/networks/network-vpnaas/site-connections',
        })
      } else if (item.keyword === 'firewall-rules') {
        resources_updated.push({
          ...item,
          count: firewallRules.length,
          navigation: '/networks/network-fwaas/firewall-rules',
        })
      } else if (item.keyword === 'snapshots') {
        resources_updated.push({
          ...item,
          count: snapshots.length,
          navigation: '/storage/snapshots',
        })
      } else if (item.keyword === 'private-images') {
        resources_updated.push({
          ...item,
          count: images.length,
          navigation: '/images',
        })
      } else {
        resources_updated.push(item)
      }
    })
    
    setResources(resources_updated)
  }, [
    servers,
    volumes,
    routers,
    floatingIps,
    snapshots,
    images,
    backups,
    vpns,
    firewallRules,
    purchasedServices,
  ])
  
  useEffect(() => {
    const keys_network = limitsUpdateDataForm.map(field => field.field_key)
    const keys_compute = computeLimitsUpdateDataForm.map(field => field.field_key)
    const keys_cinder = cinderLimitsUpdateDataForm.map(field => field.field_key)

    let network_formatted_data = []
    let compute_formatted_data = []
    let cinder_formatted_data = []
    
    if (Object.keys(networkLimits).length > 0) {
      network_formatted_data = keys_network.map((item) => {
        let new_item = {...networkLimits[item]}
        if (new_item.limit) {
          new_item["available"] = networkLimits[item].limit === -1 ? langTexts.unlimitedValueText : new_item.limit - new_item.used
          new_item.limit = networkLimits[item].limit === -1 ? langTexts.unlimitedValueText : networkLimits[item].limit
          new_item["resource_name"] = item
        }        
        return new_item
      })
    }    

    if (Object.keys(serverLimits).length > 0) {
      compute_formatted_data = keys_compute.map((item) => {
        let new_item = {...serverLimits[item]}
        new_item["available"] = serverLimits[item].limit === -1 ? langTexts.unlimitedValueText : new_item.limit - new_item.used
        if (!new_item.used && new_item.used !== 0) {
          new_item["available"] = ''
        }
        new_item.limit = serverLimits[item].limit === -1 ? langTexts.unlimitedValueText : serverLimits[item].limit
        new_item["resource_name"] = item
  
        if (item === 'ram') {
          new_item.limit = convertMBtoGB(new_item.limit)
          new_item.used = convertMBtoGB(new_item.used)
          new_item.available = convertMBtoGB(new_item.available)
        }
  
        return new_item
      })
    }
    
    if (Object.keys(volumeLimits).length > 0) {
      cinder_formatted_data = keys_cinder.map((item) => {
        let new_item = {...volumeLimits[item]}
        new_item["used"] = new_item.in_use
        new_item["available"] = volumeLimits[item].limit === -1 ? langTexts.unlimitedValueText : new_item.limit - new_item.in_use
        new_item.limit = volumeLimits[item].limit === -1 ? langTexts.unlimitedValueText : volumeLimits[item].limit
        new_item["resource_name"] = item
        return new_item
      })
    }    

    setLimits([...compute_formatted_data, ...cinder_formatted_data, ...network_formatted_data])
  }, [serverLimits, volumeLimits, networkLimits, langTexts])

  useEffect(() => {
    const fetchChargesSummaryData = async () => {
      const url = constants.billing_url + '/' + billingUrls.getResourceChargesSummary + '/' + defaultAdminProject

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken: token,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        setChargesSummary(response.data)
      } else {
        setChargesSummary('')
      }
    }

    fetchChargesSummaryData()
  }, [accessToken, token, clientAccountID, defaultAdminProject])

  useEffect(() => {
    if (chargesSummary) {
      setMonthToDateCost(chargesSummary.month_to_date_cost ? chargesSummary.month_to_date_cost : 0)
      setForcastedCost(chargesSummary.current_month_total_forcast ? chargesSummary.current_month_total_forcast : 0)
      setPrevMonthCost(chargesSummary.previous_month_cost ? chargesSummary.previous_month_cost : 0)
    } else {
      setMonthToDateCost(0)
      setForcastedCost(0)
      setPrevMonthCost(0)
    }
  }, [chargesSummary])

  useEffect(() => {
    if (forcastedCost === prevMonthCost) {
      setComparedCostPercent(0)
    } else if (forcastedCost > prevMonthCost) {
      const diff = forcastedCost - prevMonthCost
      setComparedCostPercent( (prevMonthCost !== 0 ? (diff * 100) / prevMonthCost : 0).toFixed(2) )
    }
    else if (forcastedCost < prevMonthCost) {
      const diff = prevMonthCost - forcastedCost
      setComparedCostPercent( (prevMonthCost !== 0 ? (diff * 100) / prevMonthCost : 0).toFixed(2) )
    }
  }, [forcastedCost, prevMonthCost])

  useEffect(() => {
    if (width < 1280 && width >= 900) {
      setBillingBlockDiff(30)
    } else {
      setBillingBlockDiff(0)
    }
  }, [width])
  
  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={4}>
        <Grid xs={12} lg={9}>
          <Box>

            <Paper sx={sxContainer}>
              <Box sx={sxContainerLabel}>
                <CustomText size='h6'>{langTexts.resources}</CustomText>
              </Box>
              <Box sx={{ padding: '20px' }}>
                <Grid container spacing={4}>
                  {
                    resources.map((item, index) => (
                      <HomeResourcesItem key={index} {...item} />
                    ))
                  }
                </Grid>
              </Box>
            </Paper>

            <Box>
              <Grid container spacing={4}>
                {
                  hasBilling &&
                  <Grid xs={12} md={4} lg={5}>
                    <Paper sx={{ ...sxContainer, height: `${320 + billingBlockDiff}px` }}>
                      <Box sx={sxContainerLabel}>
                        <CustomText size='h6'>{langTexts.costSummary}</CustomText>
                      </Box>
                      <Box sx={{ padding: '20px 30px' }}>
                        <Box sx={sxBillingTitle}>{langTexts.monthToDateCost}</Box>
                        <Box sx={sxBillingAmount}>
                          <CustomText size='h6'>{monthToDateCost} {currencyName}</CustomText>
                        </Box>
                        <Box sx={sxBillingTitle}>{langTexts.totalForcastedCostForCurrentMonth}</Box>
                        <Box sx={sxBillingAmount}>
                          <CustomText size='h6'>{forcastedCost} {currencyName}</CustomText>
                        </Box>
                        {
                          (forcastedCost !==0 && prevMonthCost !== 0) &&
                          <Box sx={sxBillingDescription}>
                            { forcastedCost > prevMonthCost && <ArrowUpwardIcon /> }
                            { forcastedCost < prevMonthCost && <ArrowDownwardIcon /> }                          
                            <Box>{comparedCostPrecent}% {langTexts.comparedToLastMonthTotalCost}</Box>
                          </Box>
                        }                        
                        <Box sx={sxBillingTitle}>{langTexts.totalCostForLastMonth}</Box>
                        <Box sx={sxBillingAmount}>
                          <CustomText size='h6'>{prevMonthCost} {currencyName}</CustomText>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                }
                
                <Grid xs={12} md={hasBilling ? 8 : 12} lg={hasBilling ? 7 : 12}>
                  <Paper sx={{ ...sxContainer }}>
                    <Box sx={sxContainerLabel}>
                      <CustomText size='h6'>{langTexts.instancesState}</CustomText>
                    </Box>
                    <Box
                      sx={{
                        height: width >= 900 ? `${276 + billingBlockDiff}px` : 'auto',
                        overflowY: 'auto'
                      }}
                    >
                      {
                        servers.map((item, index) => (
                          <HomeInstanceStates
                            key={index}
                            {...item}
                            state={item['OS-EXT-STS:power_state']}
                          />
                        ))
                      }
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

          </Box>
        </Grid>
        <Grid xs={12} lg={3}>

          <Paper sx={{ ...sxContainer, }}>
            <Box sx={sxContainerLabel}>
              <CustomText size='h6'>{langTexts.resourceUsage}</CustomText>
            </Box>
            <Box
              sx={{
                padding: '20px',
                height: width >= 1200 ? `${588 + billingBlockDiff}px` : 'auto',
                overflowY: 'auto',
              }}
            >
              {
                resourceUsageCategoriesSchema.map((item, index) => (
                  <HomeResourceUsageCategory key={index} {...item} limits={limits} />
                ))
              }

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box sx={sxResourceUsedIcon}></Box>
                <Box sx={sxResourceConditionTitle}>{langTexts.used}</Box>
                <Box sx={sxResourceFreeIcon}></Box>
                <Box sx={sxResourceConditionTitle}>{langTexts.available}</Box>
              </Box>
            </Box>
          </Paper>

        </Grid>
      </Grid>
    </Box>
  )
}

export default HomeContent