import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { setBillingDeposit } from '../../../../store/reducers/profileSlice'

const PaypalCheckout = (props) => {
  const [{ isPending }] = usePayPalScriptReducer()
  const dispatch = useDispatch()
  const {
    amount,
    handleOpenNotificationDialog,
    setFetchDataReqiured,
    selectedInvoicesData,
    separatePayments,
    invoiceComment,
  } = props
  
  const [paymentCompelted, setPaymentCompleted] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: `${amount}`,
          },
        },
      ],
    })
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      if (details.status && details.status === 'COMPLETED') {
        setPaymentCompleted(true)
        setPaymentDetails(details)
      } else {
        handleOpenNotificationDialog(defaultTexts.paymentFailedLabel)
      }
    })
  }

  useEffect(() => {
    const fetchProjectDeposits = async () => {
      const url = constants.billing_url + '/' + billingUrls.getProjectDeposits + '/' + defaultAdminProject

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        dispatch(setBillingDeposit({ billingDeposit: response.data }))
      } else {
        dispatch(setBillingDeposit({ billingDeposit: null }))
      }
    }

    const sendDepositRefillRequest = async () => {
      const url = constants.billing_url + '/' + billingUrls.getDepositRefill + '/' + defaultAdminProject

      const request_data = {
        url,
        method: 'POST',
        accessToken,
        xAuthToken,
        clientAccountID,
        data: {
          amount,
          currency: paymentDetails.purchase_units[0].amount.currency_code,
          payment_method: 'paypal',
          transaction_data: invoiceComment ? { ...paymentDetails, comment: invoiceComment } : paymentDetails,
        },
      }
      
      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        handleOpenNotificationDialog(defaultTexts.paymentSucceededLabel)
        setFetchDataReqiured((prev) => prev + 1)
        fetchProjectDeposits()
      }
    }

    const sendSeparateDepositRefillRequest = async () => {
      const url = constants.billing_url + '/' + billingUrls.getDepositRefill + '/' + defaultAdminProject

      let count = 0

      for (let index = 0; index < selectedInvoicesData.length; index++) {
        const request_data = {
          url,
          method: 'POST',
          accessToken,
          xAuthToken,
          clientAccountID,
          data: {
            amount: selectedInvoicesData[index].amount,
            currency: selectedInvoicesData[index].currency_name,
            payment_method: 'paypal',
            transaction_data: paymentDetails,
            invoice_id: selectedInvoicesData[index].invoice_id,
          },
        }

        const response = await billingRequest(request_data)
      
        if (response.status_code && response.status_code < 400 && response.data) {
          count ++
        }
      }
      
      if (count === selectedInvoicesData.length) {
        handleOpenNotificationDialog(defaultTexts.paymentSucceededLabel)
        setFetchDataReqiured((prev) => prev + 1)
        fetchProjectDeposits()
      }
    }

    if (paymentCompelted && paymentDetails) {
      if (separatePayments) {
        sendSeparateDepositRefillRequest()
      } else {
        sendDepositRefillRequest()
      }
      
      setPaymentCompleted(false)
      setPaymentDetails(null)
    }
  }, [
    paymentCompelted,
    paymentDetails,
    accessToken,
    xAuthToken,
    clientAccountID,
    defaultTexts,
    defaultAdminProject,
    handleOpenNotificationDialog,
    setFetchDataReqiured,
    amount,
    separatePayments,
    selectedInvoicesData,
    invoiceComment,
    dispatch,
  ])
  
  return (
    <>
      {
        isPending ? (
          <Box>Loading...</Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <PayPalButtons 
              style={{
                shape: "rect",
                layout: "horizontal",
                color: "gold",
                label: "pay",
                tagline: false,
              }}
              createOrder={(data, actions) => onCreateOrder(data, actions)}
              onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
          </Box>
        )
      }
    </>
  )
}

export default PaypalCheckout