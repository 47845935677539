import React from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'

const HomeContact = (props) => {
  const theme = useTheme()
  const { contactUs } = props

  const sxRow = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '5px',
    color: theme.palette.primary.main,
  }

  const sxIcon = {
    margin: '4px 10px 0px 0px',
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Box sx={sxRow}>
        <Box sx={sxIcon}>
          <PhoneIcon />
        </Box>
        <Box>{contactUs.phone.join(', ')}</Box>
      </Box>
      <Box sx={sxRow}>
        <Box sx={sxIcon}>
          <EmailIcon />
        </Box>
        <Box>{contactUs.email.join(', ')}</Box>
      </Box>
    </Box>
  )
}

export default HomeContact