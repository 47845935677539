
import { createSlice } from '@reduxjs/toolkit'
import { networkDataSchema as  networkDataSchemaV2_0 } from 
"../../_data/openstack/neutron/networks/v2.0";
import {segmentDataSchema as segmentDataSchemaV2_0 } from 
"../../_data/openstack/neutron/segments/v2.0";
import { trunkDataSchema as trunkDataSchemaV2_0 } from 
"../../_data/openstack/neutron/trunks/v2.0";
import { portDataSchema as portDataSchemaV2_0 } from 
"../../_data/openstack/neutron/ports/v2.0";
import { subnetDataSchema as subnetDataSchemaV2_0 } from 
"../../_data/openstack/neutron/subnets/v2.0";
import { floatingIPDataSchema as floatingIPDataSchemaV2_0 } from 
"../../_data/openstack/neutron/floating_ips/v2.0";
import { routerDataSchema as routerDataSchemaV2_0 } from 
"../../_data/openstack/neutron/routers/v2.0";
import { securityGroupDataSchema as securityGroupDataSchemaV2_0 } from 
"../../_data/openstack/neutron/security/v2.0";
import { addressGroupDataSchema as addressGroupDataSchemaV2_0 } from 
"../../_data/openstack/neutron/security/v2.0";
import { defaultRuleDataSchema as defaultRuleDataSchemaV2_0 } from 
"../../_data/openstack/neutron/security/v2.0";
import { firewallGroupDataSchema as firewallGroupDataSchemaV2_0 } from 
"../../_data/openstack/neutron/fwaas/v2.0";
import { firewallPolicyDataSchema as firewallPolicyDataSchemaV2_0 } from 
"../../_data/openstack/neutron/fwaas/v2.0";
import { firewallRuleDataSchema as firewallRuleDataSchemaV2_0 } from 
"../../_data/openstack/neutron/fwaas/v2.0";
import { vpnIKEPolicyDataSchema as vpnIKEPolicyDataSchemaV2_0 } from 
"../../_data/openstack/neutron/vpn/v2.0";
import { vpnIPsecPolicyDataSchema as vpnIPsecPolicyDataSchemaV2_0 } from 
"../../_data/openstack/neutron/vpn/v2.0";
import { vpnServiceDataSchema as vpnServiceDataSchemaV2_0 } from 
"../../_data/openstack/neutron/vpn/v2.0";
import { vpnEndpointGroupDataSchema as vpnEndpointGroupDataSchemaV2_0 } from 
"../../_data/openstack/neutron/vpn/v2.0";
import { vpnSiteConnectionDataSchema as vpnSiteConnectionDataSchemaV2_0 } from 
"../../_data/openstack/neutron/vpn/v2.0";
import { virtualIPDataSchema as virtualIPDataSchemaV2_0 } from 
'../../_data/openstack/neutron/virtual_ips/v2.0';

const initialState = {
    networkNeutronApiUrls: [
      {
        api_version: "v2.0",
        urls: [
          {id: "", keyword: 'subnetsUrl', url: "subnets", api_group: 'subnetsApiUrls'},
          {id: "", keyword: 'networksUrl', url: "networks", api_group: 'networksApiUrls'},
          {id: "", keyword: 'portsUrl', url: "ports", api_group: 'portsApiUrls'},
          {id: "", keyword: 'secGroupsUrl', url: "security-groups", api_group: 'secGroupsApiUrls'},
          {id: "", keyword: 'segmentsUrl', url: "segments", api_group: 'l2networksApiUrls'},
          {id: "", keyword: 'trunksUrl', url: "trunks", api_group: 'l2networksApiUrls'},
          {id: "", keyword: 'floatingIPsUrl', url: "floatingips", api_group: 'l3networksApiUrls'},
          {id: "", keyword: 'floatingIPPoolsUrl', url: "floatingip_pools", api_group: 'l3networksApiUrls'},
          {id: "", keyword: 'routersUrl', url: "routers", api_group: 'l3networksApiUrls'},
          {id: "", keyword: 'virtualIPsUrl', url: "local_ips", api_group: 'l3networksApiUrls'},
          {id: "", keyword: 'securityGroupsUrl', url: "security-groups", api_group: 'securityGroupsApiUrls'},
          {id: "", keyword: 'securityGroupRulesUrl', url: "security-group-rules", api_group: 'securityGroupsApiUrls'},
          {id: "", keyword: 'defaultSecGroupRulesUrl', url: "default-security-group-rules", api_group: 'securityGroupsApiUrls'},
          {id: "", keyword: 'addressGroupsUrl', url: "address-groups", api_group: 'securityGroupsApiUrls'},
          {id: "", keyword: 'firewallGroupsUrl', url: "fwaas/firewall_groups", api_group: 'fwaasGroupsApiUrls'},
          {id: "", keyword: 'firewallPoliciesUrl', url: "fwaas/firewall_policies", api_group: 'fwaasGroupsApiUrls'},
          {id: "", keyword: 'firewallRulesUrl', url: "fwaas/firewall_rules", api_group: 'fwaasGroupsApiUrls'},
          {id: "", keyword: 'vpnIKEPoliciesUrl', url: "vpn/ikepolicies", api_group: 'vpnApiUrls'},
          {id: "", keyword: 'vpnIPsecPoliciesUrl', url: "vpn/ipsecpolicies", api_group: 'vpnApiUrls'},
          {id: "", keyword: 'vpnSiteConnectionsUrl', url: "vpn/ipsec-site-connections", api_group: 'vpnApiUrls'},
          {id: "", keyword: 'vpnEndpointGroupsUrl', url: "vpn/endpoint-groups", api_group: 'vpnApiUrls'},
          {id: "", keyword: 'vpnServicesUrl', url: "vpn/vpnservices", api_group: 'vpnApiUrls'},
          {id: "", keyword: 'limitsUrl', url: "quotas", api_group: 'limitsApiUrls'},
        ]
      }
    ],
    networkSubnetsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...subnetDataSchemaV2_0]
      }
    ],
    networksTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...networkDataSchemaV2_0]
      }
    ],
    segmentsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...segmentDataSchemaV2_0]
      }
    ],
    portsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...portDataSchemaV2_0]
      }
    ],
    trunksTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...trunkDataSchemaV2_0]
      }
    ],
    floatingIPsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...floatingIPDataSchemaV2_0]
      }
    ],
    virtualIPsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...virtualIPDataSchemaV2_0]
      }
    ],
    routersTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...routerDataSchemaV2_0]
      }
    ],
    securityGroupsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...securityGroupDataSchemaV2_0]
      }
    ],
    addressGroupsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...addressGroupDataSchemaV2_0]
      }
    ],
    defaultRulesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...defaultRuleDataSchemaV2_0]
      }
    ],
    firewallGroupsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...firewallGroupDataSchemaV2_0]
      }
    ],
    firewallPoliciesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...firewallPolicyDataSchemaV2_0]
      }
    ],
    firewallRulesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...firewallRuleDataSchemaV2_0]
      }
    ],
    vpnIKEPoliciesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...vpnIKEPolicyDataSchemaV2_0]
      }
    ],
    vpnIPsecPoliciesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...vpnIPsecPolicyDataSchemaV2_0]
      }
    ],
    vpnServicesTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...vpnServiceDataSchemaV2_0]
      }
    ],
    vpnEndpointGroupsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...vpnEndpointGroupDataSchemaV2_0]
      }
    ],
    vpnSiteConnectionsTableConfig: [
      {
        api_version: "v2.0",
        rows_per_page: 10,
        columns: [...vpnSiteConnectionDataSchemaV2_0]
      }
    ],
  }
  
  const networkNeutronSlice = createSlice({
    name: 'networkNeutron',
    initialState,
    reducers: {
      setNetworkNeutronApiUrls: (state, action) => {
          state.networkNeutronApiUrls = action.payload.networkNeutronApiUrls
      },
      setNetworkSubnetsTableConfig: (state, action) => {
        state.networkSubnetsTableConfig = action.payload.networkSubnetsTableConfig
      },
      setNetworksTableConfig: (state, action) => {
        state.networksTableConfig = action.payload.networksTableConfig
      },
      setSegmentsTableConfig: (state, action) => {
        state.segmentsTableConfig = action.payload.segmentsTableConfig
      },
      setPortsTableConfig: (state, action) => {
        state.portsTableConfig = action.payload.portsTableConfig
      },
      setTrunksTableConfig: (state, action) => {
        state.trunksTableConfig = action.payload.trunksTableConfig
      },
      setFloatingIPsTableConfig: (state, action) => {
        state.floatingIPsTableConfig = action.payload.floatingIPsTableConfig
      },
      setVirtualIPsTableConfig: (state, action) => {
        state.virtualIPsTableConfig = action.payload.virtualIPsTableConfig
      },
      setRoutersTableConfig: (state, action) => {
        state.routersTableConfig = action.payload.routersTableConfig
      },
      setSecurityGroupsTableConfig: (state, action) => {
        state.securityGroupsTableConfig = action.payload.securityGroupsTableConfig
      },
      setAddressGroupsTableConfig: (state, action) => {
        state.addressGroupsTableConfig = action.payload.addressGroupsTableConfig
      },
      setDefaultRulesTableConfig: (state, action) => {
        state.defaultRulesTableConfig = action.payload.defaultRulesTableConfig
      },
      setFirewallGroupsTableConfig: (state, action) => {
        state.firewallGroupsTableConfig = action.payload.firewallGroupsTableConfig
      },
      setFirewallPoliciesTableConfig: (state, action) => {
        state.firewallPoliciesTableConfig = action.payload.firewallPoliciesTableConfig
      },
      setFirewallRulesTableConfig: (state, action) => {
        state.firewallRulesTableConfig = action.payload.firewallRulesTableConfig
      },
      setVpnIKEPoliciesTableConfig: (state, action) => {
        state.vpnIKEPoliciesTableConfig = action.payload.vpnIKEPoliciesTableConfig
      },
      setVpnIPsecPoliciesTableConfig: (state, action) => {
        state.vpnIPsecPoliciesTableConfig = action.payload.vpnIPsecPoliciesTableConfig
      },
      setVpnServicesTableConfig: (state, action) => {
        state.vpnServicesTableConfig = action.payload.vpnServicesTableConfig
      },
      setVpnEndpointGroupsTableConfig: (state, action) => {
        state.vpnEndpointGroupsTableConfig = action.payload.vpnEndpointGroupsTableConfig
      },
      setVpnSiteConnectionsTableConfig: (state, action) => {
        state.vpnSiteConnectionsTableConfig = action.payload.vpnSiteConnectionsTableConfig
      },
      networkNeutronApiUrlsReset: () => {
        return {...initialState}
      }
    }
  })
  
  export const { 
        setNetworkNeutronApiUrls,
        setNetworkSubnetsTableConfig,
        setNetworksTableConfig,
        setSegmentsTableConfig,
        setPortsTableConfig,
        setTrunksTableConfig,
        setFloatingIPsTableConfig,
        setVirtualIPsTableConfig,
        setRoutersTableConfig,
        setSecurityGroupsTableConfig,
        setAddressGroupsTableConfig,
        setDefaultRulesTableConfig,
        setFirewallGroupsTableConfig,
        setFirewallPoliciesTableConfig,
        setFirewallRulesTableConfig,
        setVpnIKEPoliciesTableConfig,
        setVpnIPsecPoliciesTableConfig,
        setVpnServicesTableConfig,
        setVpnEndpointGroupsTableConfig,
        setVpnSiteConnectionsTableConfig,
        networkNeutronApiUrlsReset
  } = networkNeutronSlice.actions
  
  export default networkNeutronSlice.reducer