import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { vpnServiceDataForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const VPNServiceAddV20 = (props) => {
	const { handleDataFetch } = props
	const { routers, subnets } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)
	const [error, setError] = useState()
	const [successVPNServiceAdd, setSuccessVPNServiceAdd] = useState()
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [formData, setFormData] = useState({})
	const [formDataOptions, setFormDataOptions] = useState({});

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const vpnServicesUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.vpnServicesUrl)[0].url)

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleSuccessDialogClose = () => {
			setSuccessVPNServiceAdd(null);
			setSuccessDialogOpen(false);
	}

	const handleAddVPNServiceFormReset = () => {
			setFormDataOptions({})
			setFormData({})
	}

	const handleFormDataChange = (event,field_key) => {
			setFormDataOptions({})
			let new_form_data = {...formData}
			if (vpnServiceDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (vpnServiceDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setFormData(new_form_data)
	}

	const getDataForm = () => {
			let form = [...vpnServiceDataForm]
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {...formDataOptions[field.field_key]}
									form_field_options = {...form_field_options, ...field}
									delete form_field_options["label"]
									form_field_options["item_titles"] = defaultTexts
									if (field.field_key === "router_id") {
											
											const router_list = routers.map(r => {
													return {keyword: r.name, value: r.id, default: false}
											})
											form_field_options["items"] = [...router_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									} else if (field.field_key === "subnet_id") {											
											const project_sub_ids = subnets.map(s => s.id)
											const total_subnets = [...new Set([...project_sub_ids])]
											const total_sub_objects = subnets.filter(s => total_subnets.includes(s.id))
											const sub_list = total_sub_objects.map(s => {
													return {keyword: `${s.name}: ${s.cidr}`, value: s.id, default: false}
											})
											form_field_options["items"] = [...sub_list]
											form_field_options["self_item_titles"] = true
											form_field_options["empty"] = false
									}
									return (
											getFormFieldComponent(
													field,
													formData,
													handleFormDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleFormDataValidation = () => {
			let validation_faild = true
			let updatedDataFormOptions = {...formDataOptions}
			for (let n in vpnServiceDataForm) {
					if (vpnServiceDataForm[n].required && !formData[vpnServiceDataForm[n].field_key]) {
							validation_faild = false
							updatedDataFormOptions[vpnServiceDataForm[n].field_key] = {}
							updatedDataFormOptions[vpnServiceDataForm[n].field_key]["error"] = true
							updatedDataFormOptions[vpnServiceDataForm[n].field_key]["errorText"] = defaultTexts[vpnServiceDataForm[n].error_label]
					}
			}

			setFormDataOptions(updatedDataFormOptions)
			return validation_faild
	}

	const formatRuleData = () => {
			let updated_data = {...formData}
			if (formData.subnet_id.length === 0) {
				delete updated_data.subnet_id
			}
			return updated_data
	}

	const onAddVPNService = async () => {
			const data_is_valid = handleFormDataValidation()
			if (data_is_valid) {
					const formatted_data = formatRuleData()
					
					const method = "POST"
					const vpnGroup_response = await openstackRequest({
							url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}`, 
							method: method, 
							data: {vpnservice: formatted_data},
							token,
					})
					if (vpnGroup_response.status_code === vpnUrlResponses.post.success_response.status_code) {
							handleAddVPNServiceFormReset()
							handleDataFetch()
					} else {
							const error_response = vpnUrlResponses.post.error_response.filter(
									error_item => error_item.status_code === vpnGroup_response.status_code)
							if (error_response.length > 0) {
									const errorObject = {
											error_title: error_response[0].response_title, 
											error_message: error_response[0].response_message,
											error_details: vpnGroup_response.error
									}
									setError(errorObject)
							} else {
									const error_response = vpnUrlResponses.post.error_response.filter(
											error_item => error_item.status_code === "unknown")
									const errorObject = {
											error_title: error_response[0].response_title, 
											error_message: error_response[0].response_message,
											error_details: vpnGroup_response.error
									}
									setError(errorObject)
							}
					}
					
			} 
			return data_is_valid
	}

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setSuccessDialogOpen(true)
	},[successVPNServiceAdd]);

	useEffect(() => {
			if (Object.keys(formData).length === 0) {
					let new_form_data = {}
					for (const n in vpnServiceDataForm) {
							if (
									vpnServiceDataForm[n].field_type === "string" || 
									vpnServiceDataForm[n].field_type === "select"
									) {
									new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
									vpnServiceDataForm[n].default_value : 
									""
							} else if (vpnServiceDataForm[n].field_type === "integer") {
									new_form_data[vpnServiceDataForm[n].field_key] = 3600
							} else if (vpnServiceDataForm[n].field_type === "bool") {
									new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
									vpnServiceDataForm[n].default_value : 
									false
							}
					}
					setFormData(new_form_data)
			}
	},[
			formData
	]);

	return (
		<>
			<AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddVPNService}
        formReset={handleAddVPNServiceFormReset}
        customTexts={{
          title: defaultTexts.addVPNServiceDialogTitle
        }}
      />

			{
				successVPNServiceAdd &&
				<CustomDialog
					open={successDialogOpen}
					onClose={handleSuccessDialogClose}
					dialogTitle={{
							title: defaultTexts[successVPNServiceAdd.success_title], 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: defaultTexts[successVPNServiceAdd.success_message], 
							sx: {color: 'text.primary'}}}
				/>
			}

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
}

export default VPNServiceAddV20