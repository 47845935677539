import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import CustomDrawer from '../../_common/CustomDrawer'
import LimitsContent from './LimitsContent'

const LimitsWrapper = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)

  if (!isAuthenticated) {
    navigate('/')
  }

  return (
    <CustomDrawer>
      <Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
        <LimitsContent />
      </Box>      
    </CustomDrawer>
  )
}

export default LimitsWrapper