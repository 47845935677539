import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { routerDataForm, routerStaticRouteForm } from '../../../../../../_data/openstack/neutron/routers/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { routersUrl as routerUrlResponses } from '../../../../../../_api_responses/openstack/neutron/routers/v2.0';
import CustomText from '../../../../../_common/CustomText';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const RouterAddV20 = (props) => {
  const { handleDataFetch } = props
  const { externalNetworks } = props 
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successRouterAdd, setSuccessRouterAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [staticRoutes, setStaticRoutes] = useState([]);

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const routersUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.routersUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessRouterAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddRouterFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in routerDataForm) {
          if (
              routerDataForm[n].field_type === "string" || 
              routerDataForm[n].field_type === "select"
              ) {
              new_form_data[routerDataForm[n].field_key] = routerDataForm[n].default_value ? 
              routerDataForm[n].default_value : 
              ""
          } else if (routerDataForm[n].field_type === "bool") {
              new_form_data[routerDataForm[n].field_key] = routerDataForm[n].default_value ? 
              routerDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
      setStaticRoutes([])
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (routerDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (routerDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  const handleStaticRouteFormDataChange = (event,field_key,index) => {
      let new_form_data = [...staticRoutes]
      if (routerStaticRouteForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[index][field_key] = event.target.checked
      } else if (routerStaticRouteForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[index][field_key] = event.trim()
      } else {
          new_form_data[index][field_key] = event.target.value.trim()
      }
      setStaticRoutes(new_form_data)
  }

  const getDataForm = () => {
      let form = [...routerDataForm]
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "network_id") {
                      form_field_options["items"] = [...externalNetworks]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  } 
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
      {staticRoutes.map((route,index) => {
          return (
              <div key={index}>
              <CustomText>{defaultTexts.staticRouteFormFieldLabel} {index + 1}</CustomText>
                  {routerStaticRouteForm.map(item => {
                      return (
                          getFormFieldComponent(
                              item,
                              route,
                              handleStaticRouteFormDataChange,
                              defaultTexts[item.label],
                              {index: index}
                          )
                      )
                  })}
              </div>
          )
          })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in routerDataForm) {
          if (routerDataForm[n].required && !formData[routerDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[routerDataForm[n].field_key] = {}
              updatedDataFormOptions[routerDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[routerDataForm[n].field_key]["errorText"] = defaultTexts[routerDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const formatData = () => {
      let updated_data = {...formData}
      if (formData.network_id.length > 0) {
          updated_data["external_gateway_info"] = {}
          updated_data["external_gateway_info"]["network_id"] = formData.network_id
        //   if (formData.enable_snat) {
        //       updated_data["external_gateway_info"]["enable_snat"] = formData.enable_snat
        //   } else {
        //       updated_data["external_gateway_info"]["enable_snat"] = false
        //   }
      }
      delete updated_data.network_id
      delete updated_data.enable_snat

      return updated_data
  }

  const onAddRouter = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = formatData()
          
          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}`
          const method = "POST"
          
          const router_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {router: data},
              token,
          })
          if (router_response.status_code === routerUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddRouterFormReset()
              setFormDataOptions({})
          } else {
              const error_response = routerUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === router_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: router_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = routerUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: router_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successRouterAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in routerDataForm) {
              if (
                  routerDataForm[n].field_type === "string" || 
                  routerDataForm[n].field_type === "select"
                  ) {
                  new_form_data[routerDataForm[n].field_key] = routerDataForm[n].default_value ? 
                  routerDataForm[n].default_value : 
                  ""
              } else if (routerDataForm[n].field_type === "bool") {
                  new_form_data[routerDataForm[n].field_key] = routerDataForm[n].default_value ? 
                  routerDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddRouter}
        formReset={handleAddRouterFormReset}
        preDialogClose={true}
        customTexts={{
          title: defaultTexts.addRouterDialogTitle
        }}
      />

      {
        successRouterAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successRouterAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successRouterAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }
      
      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default RouterAddV20