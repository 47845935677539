import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import Checkbox from '@mui/material/Checkbox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { BsSegmentedNav } from "react-icons/bs";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
//import { openStackServices } from 
//'../../../../../../config/openStackConstants';
//import { networkNeutronConstants } 
//from '../../../../../../config/openStackConstants';
//import { 
//    getXAuthTokenProjectScope, 
//    openstackRequest
//} from '../../../../../../_network/openstack_request';

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    width: "100%",
    alignItems: "start",
    pl: 3
}

//const SERVICE_NAME = openStackServices.networkService

const NetworkSegmentsV20 = (props) => {
    const { networkSegments } = props
    //const { selectedRow, handleDataFetch } = props
    //const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    //const [selectedSegmentsToDelete, setSelectedSegmentsToDelete] = useState([])
    const theme = useTheme();

    //const neutronServiceDomain = useSelector(
    //    state => state.openstack.purchasedServices.filter(
    //    service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    //const neutronServiceVersion = useSelector(
    //    state => state.openstack.purchasedServices.filter(
    //    service => service.service === SERVICE_NAME)[0].config_params.api_version)
    //const networksUrl = useSelector(
    //    state => state.networkNeutron.networkNeutronApiUrls.filter(
    //        version => version.api_version === "v2.0")[0].urls.filter(
    //            url => url.keyword === networkNeutronConstants.networksUrl)[0].url)

    //const handleSelectedSegmentToDeleteChange = (event,row_id) => {
    //    if (event.target.checked) {
    //        const updated_list = [...selectedSegmentsToDelete, row_id]
    //        setSelectedSegmentsToDelete(updated_list)
    //    } else {
    //        const updated_list = selectedSegmentsToDelete.filter(i => i !== row_id)
    //        setSelectedSegmentsToDelete(updated_list)
    //    }
    //}

    //const onSegmentRemove = async () => {
    //    let updated_data = {}
    //    let updated_segment_list = [...networkSegments]
    //    for (let i in selectedSegmentsToDelete) {
    //        console.log(selectedSegmentsToDelete[i])
    //        const popped = updated_segment_list.splice(selectedSegmentsToDelete[i],1)
    //        
    //    }
    //    updated_data["segments"] = updated_segment_list
    //    console.log(updated_data)
    //    if (selectedSegmentsToDelete.length > 0) {
    //        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
    //        if (project_token) {
    //            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${selectedRow.id}`
    //            const method = "PUT"
    //            try {
    //                const response = await openstackRequest({
    //                    url:url, 
    //                    method:method, 
    //                    data: {network: updated_data},
    //                    token: project_token
    //                })
    //                console.log(response)
    //            } catch {
    //                console.log("")
    //            }
    //            handleDataFetch()
    //        }
    //    }
    //}

    return (
        <React.Fragment>
            <WrapperBox
                sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <BsSegmentedNav
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.networkSegmentsTitleTexts}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {networkSegments.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    {false && <TableCell align="left">
                                    {networkSegments.length > 0 && 
                                        <IconButton 
                                            disabled={true}
                                        >
                                            <DeleteIcon 
                                                color="primary"
                                            />
                                        </IconButton>}
                                    </TableCell>}
                                    <TableCell align="left">{defaultTexts.networkTypeFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.physicalNetworkFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.segmentationIDFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {networkSegments.map((row,index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {false && <TableCell>
                                        <Checkbox
                                            color="primary"
                                            //checked={selectedSegmentsToDelete.includes(index)}
                                            //onChange={(event) => handleSelectedSegmentToDeleteChange(event,index)}
                                        />
                                    </TableCell>}
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                        sx={{color: "primary.main"}}
                                    >
                                        {row["provider:network_type"]}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row["provider:physical_network"] ? row["provider:physical_network"] : "-"}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row["provider:segmentation_id"]}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
    </React.Fragment>
    )
};

export default NetworkSegmentsV20;