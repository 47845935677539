import React from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import SettingsIcon from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

const HeaderButton = (props) => {
  const theme = useTheme()

  const { onClick } = props
  const { buttontype } = props
  const { color } = props
  const { size } = props
  const { sx } = props
  
  const sxBox = {
    ...sx,
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    border: `${theme.palette.customGray} 1px solid`,
    borderRadius: '12px',
    cursor: onClick ? 'pointer' : 'default',

    '&:hover': {
      background: onClick ? alpha(theme.palette.customGray, 0.5) : theme.palette.customWhite,
    }
  }

  const sxIcon = {
    fontSize: '24px',
  }

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <Box
      onClick={handleClick}
      color={color ? color : 'primary'}
      size={size ? size : 'small'}
      sx={sxBox}
    >
      { buttontype === 'settings' && <SettingsIcon sx={sxIcon} /> }
      { buttontype === 'logout' && <Logout sx={sxIcon} /> }
      { buttontype === 'profile' && <PersonIcon sx={sxIcon} /> }
      { buttontype === 'alerts' && <NotificationsNoneIcon sx={sxIcon} /> }
      { buttontype === 'burger' && <MenuIcon sx={sxIcon} /> }
    </Box>
  )
}

export default HeaderButton