import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FaChartPie } from 'react-icons/fa6'
import { setChargesSummaryOpen } from '../../../../store/reducers/settingsSlice'
import useWindowDimensions from '../../../_common/WindowDimensions'
import BillingMenu from '../BillingMenu'
import BillingSummary from './BillingSummary'
import BillingTable from './BillingTable'
import ResourceDetails from './ResourceDetails'

const Billing = (props) => {
  const theme = useTheme()
  const { billingMenu, changeMenuActiveTab } = props
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()
  const resourceDetailsHeight = height / 2

  const chargesSummaryOpen = useSelector(state => state.settings.chargesSummaryOpen)

  const [selectedResource, setSelectedResource] = useState('')

  const pieChartIconStyle = {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    border: `${theme.palette.customGray} 1px solid`,
    borderRadius: '12px',
    cursor: 'pointer',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',

    '&:hover': {
      background: alpha(theme.palette.customGray, 0.5),
    }
  }

  const handleChargesSummaryOpen = () => {
    dispatch(setChargesSummaryOpen({ chargesSummaryOpen: true }))
  }

  const handleChargesSummaryClose = () => {
    dispatch(setChargesSummaryOpen({ chargesSummaryOpen: false }))
  }

  const handleClickResource = (props) => {
    setSelectedResource(props)
  }
  
  const getHeader = () => {
    return (
      <Stack
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{
          padding: '20px 20px 10px 20px',
          width: '100%',
          height: '60px',
        }}
      >
        <BillingMenu
          menu={billingMenu}
          onClick={changeMenuActiveTab}
        />

        {
          !chargesSummaryOpen &&
          <Box sx={pieChartIconStyle} onClick={handleChargesSummaryOpen}>
            <FaChartPie size={32} />
          </Box>
        }        
      </Stack>
    )
  }
  
  return (
    <>
      { getHeader() }

      {
        chargesSummaryOpen &&
        <BillingSummary
          handleChargesSummaryClose={handleChargesSummaryClose}
        />
      }

      <BillingTable
        handleClickResource={handleClickResource}
      />

      {
        selectedResource &&
        <>
          <Box sx={{ width: '1px', height: `${resourceDetailsHeight + 10}px` }}></Box>
          <ResourceDetails
            { ...selectedResource }
            setSelectedResource={setSelectedResource}
            resourceDetailsHeight={resourceDetailsHeight}            
          />
        </>        
      }      
    </>
  )
}

export default Billing