import { identityKeystonConstants } from '../../../config/openStackConstants';
import { identityRoleAssignmentApiQueryParamsV3 } from '../../../_data/openstack/identity/roles/v3';
import { store } from '../../../store/configStore' ;
import { openstackRequest, getIdentityProvider } from '../../../_network/openstack_request';
import Constants from '../../../config/constants';
import { 
    setAdminProjects
} from '../../../store/reducers/profileSlice';

export const getAssignedRoles = async (user_id, identity_service) => {
    const state = store.getState()
    const purchasedServices = state.openstack.purchasedServices
    const identityProviderState = getIdentityProvider(purchasedServices) 
    const identityProvider = identity_service ? identity_service : identityProviderState
    const identityKeystoneApiUrls = state.identityKeystone.identityKeystoneApiUrls
    const identityKeystoneApiUrlsList = identityKeystoneApiUrls.filter(item => 
        item.api_version === identity_service.config_params.api_version
    )[0].urls
    
    if (identityProvider.config_params.api_version === "v3") {
        const role_assign_url = identityKeystoneApiUrlsList.filter(item => 
            item.api_group === identityKeystonConstants.rolesApiUrls && 
            item.keyword === identityKeystonConstants.roleAssignmentsUrl)[0].url
        const query_params = identityRoleAssignmentApiQueryParamsV3.replace("{missingUserID}",user_id)
        const roles_url = `${identity_service.config_params.service_domain}/${identity_service.config_params.api_version}/${role_assign_url}/?${query_params}`
        const response = await openstackRequest({url:roles_url, method: "GET", headers: {}})
        return response
    }

    return null
}

export const handleProjectScopeRoleAssignment = async (project_id, user_id, role_name) => {
    const state = store.getState()
    const purchasedServices = state.openstack.purchasedServices
    const identityProvider = getIdentityProvider(purchasedServices) 
    const identityKeystoneApiUrls = state.identityKeystone.identityKeystoneApiUrls
    const identityKeystoneApiUrlsList = identityKeystoneApiUrls.filter(item => 
        item.api_version === identityProvider.config_params.api_version
    )[0].urls
    const rolesUrl = identityKeystoneApiUrlsList.filter(item => 
        item.api_group === identityKeystonConstants.rolesApiUrls && 
        item.keyword === identityKeystonConstants.rolesUrl)[0].url

    const projectsUrl = identityKeystoneApiUrlsList.filter(item => 
        item.api_group === identityKeystonConstants.identityProjectsApiUrls && 
        item.keyword === identityKeystonConstants.projectsUrl)[0].url
    
    const identityServiceDomain = identityProvider.config_params.service_domain
    const identityServiceVersion = identityProvider.config_params.api_version

    let role_id = null
    let url = `${identityServiceDomain}/${identityServiceVersion}/${rolesUrl}?name=${role_name}`
    const method = "GET"

    const roles_response = await openstackRequest({url:url, method:method})
    if (roles_response.status_code === 200) {
        role_id = roles_response.data.roles[0].id
    }

    if (project_id && user_id && role_id) {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${project_id}/users/${user_id}/roles/${role_id}`
        const method = "PUT"

        const role_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {}
        })
        if (role_response.status_code === 204) {
            const response = await getAssignedRoles(user_id, identityProvider)
            if (response && response.status_code < 400) {
                const admin_projects = response.data.role_assignments.filter(item => 
                    item.scope.project &&
                    item.role.name === Constants.admin_role_name
                ).map(item => {
                    return {id: item.scope.project.id, name: item.scope.project.name}
                })
                store.dispatch(setAdminProjects({adminProjects: admin_projects}))
            }
            return true
        } else {
            return false
        }
    } 
    return false
}

export const handleProjectScopeRoleIdAssignment = async (baseUrl, project_id, user_id, role_id) => {
    if (project_id && user_id && role_id) {
        const url = `${baseUrl}/${project_id}/users/${user_id}/roles/${role_id}`
        const method = "PUT"

        await openstackRequest({
            url: url, 
            method: method, 
            data: {}
        })
    } 
    return false
}