import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Divider from '@mui/material/Divider'
import CustomText from '../CustomText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper"
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { getFormComponent } from '../_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomFieldsCard from './CustomFieldsCard';
import CustomHeader from './CardHeader';
import CustomAccordion from './CustomAccordion';
import { deepCopy } from './helpers';

const fieldTypeToDefaultValueMapping = {
    string: "",
    boolean: false,
    array: "",
    integer: 0,
    unknown: ""
}

const boxStyle = {
    position: "relative",
    overflow: "hidden",
    borderRadius: 1,
    minHeight: "49vh",
    maxHeight: "49vh",
    width: "100%",
    border: 0.2,
    backgroundColor: "background.paper"
}

const innerBoxStyle = {
    position: "absolute",
    right: -9,
    overflowY: "scroll",
    width: "102%",
    height: "100%", 
    pb: 10
}

const CustomTransferList = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { withCustomList } = props
    const { withInstructions } = props
    const { catalogList, appliedFieldList, onDataUpdate } = props
    const { customCardTitle, catalogCardTitle, selectedCardTitle } = props
    const [instructionsOpen, setInstructionsOpen] = useState(true)
    const [selectedItemInstruction, setSelectedItemInstruction] = useState({description: defaultTexts.instructionBoxNoteText})
    const [selectedLeftItems, setSelectedLeftItems] = useState([])
    const [selectedRightItems, setSelectedRightItems] = useState([])
    const [currentCatalogFilter, setCurrentCatalogFilter] = useState("")
    const [currentAppliedFilter, setCurrentAppliedFilter] = useState("")
    const [catalog, setCatalog] = useState(deepCopy(catalogList))
    const [filteredCatalog, setFilteredCatalog] = useState([])
    const [selectedCustomFields, setSelectedCustomFields] = useState({1: ""})
    const [appliedFields, setAppliedFields] = useState([])
    const [appliedFilteredFields, setAppliedFilteredFields] = useState([...appliedFields])
    const [appliedFilteredFormFields, setAppliedFilteredFormFields] = useState({...appliedFieldList})
    const [rerenderRequired, setRerenderRequired] = useState(false)

    const theme = useTheme()
    
    const handleFormSubmit = useCallback(() => {
        onDataUpdate(appliedFilteredFormFields)
    },[appliedFilteredFormFields, onDataUpdate])

    const handleInstructionsClose = () => {
        setInstructionsOpen(false)
    }

    const handleInstructionsOpen = () => {
        setInstructionsOpen(true)
    }

    const handleInstructionSelection = (item) => {
        setSelectedItemInstruction({
            value: item.value, 
            title: item.title, 
            description: item.description
        })
    }

    const handleLeftItemSelection = (item,ns,obj) => {
        let updated_list = [...selectedLeftItems]
        if (selectedLeftItems.includes(item)) {
            updated_list = updated_list.filter(i => i !== item)
            const top_list = selectedTopLevelLeftItems.filter(n => n !== ns.value)
            setSelectedTopLevelLeftItems(top_list)
            if (obj) {
                const sec_list = selectedSecondLevelLeftItems.filter(s => s !== obj.value)
                setSelectedSecondLevelLeftItems(sec_list)
            }
        } else {
            updated_list.push(item)
        }
        setSelectedLeftItems(updated_list)
    }

    const handleCatalogSearch = useCallback((value) => {
        if (value) {
            const originalCatalog = deepCopy(catalog)
            const new_list = originalCatalog.filter(item => 
                item.value.toLowerCase().includes(value.toLowerCase()) || 
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.objects.filter(object => 
                    object.title.toLowerCase().includes(value.toLowerCase()) ||
                    object.value.toLowerCase().includes(value.toLowerCase())
                ).length > 0 ||
                item.properties.filter(prop => 
                    prop.title.toLowerCase().includes(value.toLowerCase()) ||
                    prop.value.toLowerCase().includes(value.toLowerCase())
                ).length > 0
            )
            setFilteredCatalog(new_list)
        } else {
            setFilteredCatalog(deepCopy(catalog))
        }
        
    },[catalog])

    const handleAppliedSearch = useCallback((value) => {
        if (value) {
            const new_list = appliedFields.filter(item => 
                JSON.stringify(appliedFilteredFormFields[item.field_key]).toLowerCase().includes(value.toLowerCase()) || 
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.field_key.toLowerCase().includes(value.toLowerCase()) ||
                (Object.keys(item).includes("enum") && item.enum.filter(
                    c => c.keyword === value.toLowerCase() || 
                    JSON.stringify(c.value) === value.toLowerCase()).length > 0)
            )
            setAppliedFilteredFields(new_list)
        } else {
            setAppliedFilteredFields([...appliedFields])
        }
    },[
        appliedFields,
        appliedFilteredFormFields
    ])

    const handleCatalogFilterChange = (e) => {
        setCurrentCatalogFilter(e.target.value)
    }

    const handleAppliedFilterChange = (e) => {
        setCurrentAppliedFilter(e.target.value)
    }

    const handleCustomFieldChange = (event,index) => {
        let new_list = {...selectedCustomFields}
        new_list[index] = event.target.value
        setSelectedCustomFields(new_list)
    }

    const handleSelectedCustomFieldsRemove = (index) => {
        let new_list = {...selectedCustomFields}
        delete new_list[index]
        setSelectedCustomFields(new_list)
    }

    const handleRightItemSelection = (item) => {
        let updated_list = [...selectedRightItems]
        if (selectedRightItems.includes(item.field_key)) {
            updated_list = updated_list.filter(i => i !== item.field_key)
        } else {
            updated_list.push(item.field_key)
        }
        setSelectedRightItems(updated_list)
        setSelectedItemInstruction({
            value: item.field_key, 
            title: item.title, 
            description: item.description
        })
    }

    const handleSelectedRightItemChange = (event,field_key,index) => {
        let new_form_data = {...appliedFilteredFormFields}
        if (appliedFields.filter(item => item.field_key === field_key)[0].type === "boolean") {
            new_form_data[field_key] = event.target.checked
        } else if (appliedFields.filter(item => item.field_key === field_key)[0].type === "array") {
            if (appliedFilteredFormFields[field_key].length > 0) {
                if (index === "enum") {
                    const regex = /(<.+>)/;
                    const operator = appliedFilteredFormFields[field_key].match(regex) ? 
                    appliedFilteredFormFields[field_key].match(regex)[1] : ""
                    new_form_data[field_key] = `${operator} ${event}`
                } else {
                    const regex = /(<.+>)/;
                    const old_operator = appliedFilteredFormFields[field_key].match(regex) ? 
                    appliedFilteredFormFields[field_key].match(regex)[1]  : ""
                    let enums = ""
                    if (old_operator.length > 0)  {
                        enums = appliedFilteredFormFields[field_key].split(old_operator)[1]
                    } else {
                        enums = appliedFilteredFormFields[field_key]
                    }
                    new_form_data[field_key] = `${event}${enums}`
                }
            } else {
                new_form_data[field_key] = `${event}`
            }
        } else if (Object.keys(appliedFields.filter(item => item.field_key === field_key)[0]).includes("enum")) {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setAppliedFilteredFormFields(new_form_data)
    }

    const getSelectedFieldsForm = () => {
        return (
            <FormGroup sx={{ml: 2, mr: 3}}>
                {appliedFilteredFields.map((field, index) => {
                    let form_field_options = {}
                    let field_form = {...field}
                    let filed_data = appliedFilteredFormFields[field_form.field_key]
                    if (field_form.type === "array") {
                        const enums = field_form.enum.map(c => {
                            return {keyword: c, value: c, default: false}
                        })
                        const operators = field_form.operators.map(o => {
                            return {keyword: o, value: o, default: false}
                        })
                        form_field_options["array_items"] = [...enums]
                        form_field_options["operator_items"] = [...operators]
                        form_field_options["empty"] = true
                        form_field_options["self_item_titles"] = true
                        let op_regexp = /(<.+>)/;
                        if (filed_data.length > 0) {
                            const operator = filed_data.match(op_regexp) ? 
                            filed_data.match(op_regexp)[1] : ""
                            form_field_options["operator_value"] = operator
                            if (operator.length > 0) {
                                form_field_options["choice_value"] = filed_data.split(operator)[1].trim().split(",")
                            } else {
                                form_field_options["choice_value"] = filed_data.trim().split(",")
                            }
                            
                        } else {
                            form_field_options["operator_value"] = ""
                            form_field_options["choice_value"] = []
                        }
                    } else if (Object.keys(field_form).includes("enum")) {
                        const enums = field_form.enum.map(c => {
                            return {keyword: c, value: c, default: false}
                        })
                        form_field_options["items"] = [...enums]
                        form_field_options["empty"] = true
                        form_field_options["self_item_titles"] = true
                        field_form["type"] = "select"
                    } else if (field_form.type === "boolean" && typeof filed_data === "string") {
                        filed_data = filed_data.toLowerCase() === "true"
                    }
                    return (
                        <Stack 
                            key={index} 
                            direction="row" 
                            spacing={2} 
                            alignItems="center" 
                            justifyContent="space-between" 
                            sx={{my: 1}}
                        >
                        <Stack 
                            direction="row" 
                            spacing={0} 
                            alignItems="center" 
                            onClick={() => handleRightItemSelection(field)}
                        >
                            <IconButton>
                                {selectedRightItems.includes(field_form.field_key) ?
                                    <CheckBoxIcon color="primary" />
                                    :
                                    <CheckBoxOutlineBlankIcon />
                                }
                            </IconButton>
                            <CustomText
                                sx={{
                                    color: selectedItemInstruction.value === field_form.field_key ? 
                                    "primary.main" : 
                                    undefined
                                }}
                            >
                                {field_form.title.length > 18 ? 
                                    `${field_form.title.slice(0,19)}...` : 
                                    field_form.title
                                }
                            </CustomText>
                        </Stack>
                        
                        {getFormComponent({
                            field_key: field_form.field_key,
                            type: field_form.type,
                            currentValue: filed_data,
                            setCurrentValue: handleSelectedRightItemChange,
                            size: "small",
                            withoutLabel: true,
                            sx: {width: 200},
                            ...form_field_options
                        })}
                        </Stack>
                    )
                })}
            </FormGroup>
        )
    }

    useEffect(() => {
        if (appliedFields.length > 0 || rerenderRequired) {
            let new_list = deepCopy(catalogList)
            for (let key in appliedFields) {
                new_list = new_list.map(ns => {
                    let new_ns = {...ns}
                    let new_ns_objects = [...ns.objects]
                    for (let i in new_ns_objects) {
                        let new_obj_properties = [...new_ns_objects[i].properties.filter(
                            pr => pr.value !== appliedFields[key].field_key)]
                        new_ns_objects[i].properties = new_obj_properties
                    }
                    new_ns.objects = new_ns_objects.filter(obj => obj.properties.length > 0)
                    let new_ns_properties = [...ns.properties.filter(
                        pr => pr.value !== appliedFields[key].field_key)]
                    new_ns.properties = new_ns_properties
                    return new_ns
                })
            }
            new_list = new_list.filter(
                item => item.properties.length > 0 || 
                item.objects.length > 0)
            setCatalog(new_list)
            setRerenderRequired(false)
        }
    },[
        appliedFields,
        rerenderRequired,
        catalogList
    ])

    const handleMoveItemsFromCatalog = useCallback((key,value,full_catalog) => {
        const obj_prop_parent = full_catalog.filter(
            item => item.objects.filter(
                obj => obj.properties.filter(
                    pr => pr.value === key).length > 0).length > 0
            )
        const prop_parent = full_catalog.filter(
            item => item.properties.filter(
                pr => pr.value === key).length > 0)
        if (obj_prop_parent.length > 0) {
            const obj = obj_prop_parent[0].objects.filter(
                item => item.properties.filter(
                    pr => pr.value === key).length > 0)
            const prop = obj[0].properties.filter(
                item => item.value === key)
            let default_value = Object.keys(prop[0]).includes("default") ? 
                prop[0].default : undefined
            if (default_value === undefined) {
                default_value = Object.keys(fieldTypeToDefaultValueMapping).includes(prop[0].type) ? 
                fieldTypeToDefaultValueMapping[prop[0].type] :
                fieldTypeToDefaultValueMapping.unknown
            }
            let new_item = {}
            new_item["title"] = prop[0].title
            new_item["field_key"] = prop[0].value
            if (prop[0].type === "boolean" && typeof value === "string") {
                new_item["value"] = value.toLowerCase() === 'true'
            } else {
                new_item["value"] = value ? value : default_value
            }
            new_item["type"] = prop[0].type
            if (prop[0].type === "array") {
                new_item["operators"] = prop[0].operators
            }
            if (Object.keys(prop[0]).includes("enum")) {
                new_item["enum"] = prop[0].enum
                new_item["type"] = "select"
            } 
            new_item["description"] = prop[0].description
            return new_item
        } else if (prop_parent.length > 0) {
            const prop = prop_parent[0].properties.filter(
                item => item.value === key)
            
            let default_value = Object.keys(prop[0]).includes("default") ? 
            prop[0].default : undefined
            if (default_value === undefined) {
                default_value = Object.keys(fieldTypeToDefaultValueMapping).includes(prop[0].type) ? 
                fieldTypeToDefaultValueMapping[prop[0].type] :
                fieldTypeToDefaultValueMapping.unknown
            }
            let new_item = {}
            new_item["title"] = prop[0].title
            new_item["field_key"] = prop[0].value
            if (prop[0].type === "boolean" && typeof value === "string") {
                new_item["value"] = value.toLowerCase() === 'true'
            } else {
                new_item["value"] = value ? value : default_value
            }
            new_item["type"] = prop[0].type 
            if (prop[0].type === "array") {
                new_item["operators"] = prop[0].operators
            }
            if (Object.keys(prop[0]).includes("enum")) {
                new_item["enum"] = prop[0].enum
            } 
            new_item["description"] = prop[0].description
            return new_item
        } 

        let new_item = {}
        new_item["title"] = key
        new_item["field_key"] = key
        new_item["value"] = value ? value : fieldTypeToDefaultValueMapping.unknown
        new_item["type"] = "string"
        new_item["description"] = defaultTexts.noDescriptionText
        return new_item
        
    },[defaultTexts])

    const handleLeftToRightTransfer = () => {
        let new_applied_list = []
        let new_applied_form_list = {}
        const originalCatalog = deepCopy(catalogList)
        for (let i in selectedLeftItems) {
            const key = selectedLeftItems[i]
            const value = undefined
            const new_item = handleMoveItemsFromCatalog(key,value,originalCatalog)
            new_applied_list.push(new_item)
            new_applied_form_list[new_item.field_key] = new_item.value
        }
        const valid_custom_fields = Object.keys(selectedCustomFields).filter(
            key => selectedCustomFields[key].length > 0 && 
            appliedFields.filter(item => item.field_key === selectedCustomFields[key]).length === 0
        )
        if (valid_custom_fields.length > 0) {
            const new_custom_fields = valid_custom_fields.map(item => {
                let new_item = {}
                new_item["title"] = selectedCustomFields[item]
                new_item["field_key"] = selectedCustomFields[item]
                new_item["value"] = ""
                new_item["type"] = "string"
                new_item["description"] = defaultTexts.noDescriptionText
                return new_item
            })
            new_applied_list = [...new_applied_list, ...new_custom_fields]
            for (let c in new_custom_fields) {
                new_applied_form_list[new_custom_fields[c].field_key] = new_custom_fields[c].value
            }
        }
        setSelectedLeftItems([])
        setSelectedCustomFields({1: ""})
        setAppliedFilteredFormFields({...new_applied_form_list, ...appliedFilteredFormFields})
        setAppliedFields([...new_applied_list, ...appliedFields])
    }

    const handleRightToLeftTransfer = () => {
        const  new_applied_list = appliedFields.filter(
            item => !selectedRightItems.includes(item.field_key))
        setSelectedRightItems([])
        setAppliedFields(new_applied_list)
        setAppliedFilteredFields(new_applied_list)
        const remaining_fields = new_applied_list.map(item => item.field_key)
        let updated_form_list = {}
        for (let k in remaining_fields) {
            updated_form_list[remaining_fields[k]] = appliedFilteredFormFields[remaining_fields[k]]
        }
        setAppliedFilteredFormFields(updated_form_list)
    }

    const handleFieldTransferValidation = () => {
        const empty_list = selectedLeftItems.length === 0 && Object.keys(selectedCustomFields).filter(key => selectedCustomFields[key].length > 0).length === 0
        const custom_fields = Object.keys(selectedCustomFields).map(item => selectedCustomFields[item]).filter(e => e)
        const duplicate_fields = appliedFields.filter(
            item => custom_fields.includes(item.field_key)).length > 0 ||
           new Set(custom_fields).size !== custom_fields.length
        return empty_list || duplicate_fields
    }

    useEffect(() => {
        if (Object.keys(appliedFieldList).length > 0) {
            let new_list = []
            const originalCatalog = deepCopy(catalogList)
            for (let key in appliedFieldList) {
                const new_item = handleMoveItemsFromCatalog(key,appliedFieldList[key],originalCatalog)
                new_list.push(new_item)
            }
            setAppliedFields(new_list)
        }
    },[
        handleMoveItemsFromCatalog,
        appliedFieldList,
        catalogList
    ]);

    useEffect(() => {
        handleAppliedSearch(currentAppliedFilter)
    },[
        appliedFields,
        currentAppliedFilter,
        handleAppliedSearch
    ])

    useEffect(() => {
        handleCatalogSearch(currentCatalogFilter)
    },[
        currentCatalogFilter,
        handleCatalogSearch,
        catalog
    ])

    useEffect(() => {
        handleFormSubmit()
    },[
        appliedFilteredFormFields,
        handleFormSubmit
    ])

    const [selectedTopLevelLeftItems, setSelectedTopLevelLeftItems] = useState([])
    const [selectedSecondLevelLeftItems, setSelectedSecondLevelLeftItems] = useState([])

    const handleSecondLevelLeftItemSelection = (object, item) => {
        let object_props_values = []
        const pr_values = object.properties.map(pr => pr.value)
        object_props_values = [...object_props_values, ...pr_values]
        if (selectedSecondLevelLeftItems.includes(object.value)) {
            const new_selected = selectedSecondLevelLeftItems.filter(i => i !== object.value)
            const new_selected_top = selectedTopLevelLeftItems.filter(i => i !== item.value)
            const new_left_items = selectedLeftItems.filter(s => !object_props_values.includes(s))
            setSelectedSecondLevelLeftItems(new_selected)
            setSelectedTopLevelLeftItems(new_selected_top)
            setSelectedLeftItems(new_left_items)
        } else {
            const new_selected = [...new Set([...selectedSecondLevelLeftItems, object.value])]
            const new_left_items = [...new Set([...selectedLeftItems, ...object_props_values])]
            setSelectedSecondLevelLeftItems(new_selected)
            setSelectedLeftItems(new_left_items)
        }
    }

    const handleTopLevelLeftItemSelection = (item) => {
        let item_props_values = []
        const pr_values = item.properties.map(pr => pr.value)
        item_props_values = [...item_props_values, ...pr_values]
        
        if (selectedTopLevelLeftItems.includes(item.value)) {
            let object_props_values = []
            let object_values = []
            for (let o in item.objects) {
                const pr_values = item.objects[o].properties.map(pr => pr.value)
                object_props_values = [...object_props_values, ...pr_values]
                object_values.push(item.objects[o].value)
            }
            item_props_values = [...item_props_values, ...object_props_values]
            const new_selected = selectedTopLevelLeftItems.filter(i => i !== item.value)
            const new_second_selected = selectedSecondLevelLeftItems.filter(o => !object_values.includes(o))
            const new_left_items = selectedLeftItems.filter(s => !item_props_values.includes(s))
            setSelectedTopLevelLeftItems(new_selected)
            setSelectedSecondLevelLeftItems(new_second_selected)
            setSelectedLeftItems(new_left_items)
        } else {
            let object_props_values = []
            let object_values = []
            for (let o in item.objects) {
                const pr_values = item.objects[o].properties.map(pr => pr.value)
                object_props_values = [...object_props_values, ...pr_values]
                object_values.push(item.objects[o].value)
            }
            item_props_values = [...item_props_values, ...object_props_values]
            item_props_values = [...new Set(item_props_values)]
            const new_selected = [...new Set([...selectedTopLevelLeftItems, item.value])]
            const new_second_selected = [...new Set([...selectedSecondLevelLeftItems, ...object_values])]
            const new_left_items = [...new Set([...selectedLeftItems, ...item_props_values])]
            setSelectedTopLevelLeftItems(new_selected)
            setSelectedSecondLevelLeftItems(new_second_selected)
            setSelectedLeftItems(new_left_items)
        }
    }

    const handleDeselectAllLeftItems = () => {
        setSelectedTopLevelLeftItems([])
        setSelectedSecondLevelLeftItems([])
        setSelectedLeftItems([])
    }

    const handleDeselectAllRightItems = () => {
        setSelectedRightItems([])
    }
    
    return (
        <Grid 
            container
            spacing={1}
            alignItems="center"
        >
            <Grid item xs={12} md={5}>
                <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                >
                    {withCustomList && 
                        <CustomFieldsCard 
                            customCardTitle={customCardTitle}
                            selectedCustomFields={selectedCustomFields}
                            setSelectedCustomFields={setSelectedCustomFields}
                            handleCustomFieldChange={handleCustomFieldChange}
                            handleSelectedCustomFieldsRemove={handleSelectedCustomFieldsRemove}
                            appliedFields={appliedFields}
                        />
                    }
                    <Box 
                        sx={{
                            ...boxStyle, 
                            borderColor: "darkGray"
                        }}
                    >
                        <CustomHeader
                            cardHeaderTitle={
                                catalogCardTitle ? 
                                catalogCardTitle : 
                                defaultTexts.catalogHeaderTitle
                            }
                            items={selectedLeftItems}
                            handleDeselectAllItems={handleDeselectAllLeftItems}
                            currentFilter={currentCatalogFilter}
                            handleFilterChange={handleCatalogFilterChange}
                        />
                        <Divider />
                        {catalogList.length === 0 && 
                            <Box sx={{position: "absolute", left: "50%", top: "50%"}}>
                                    <CircularProgress sx={{alignSelf: "center"}}/>
                            </Box>}
                        {catalogList.length > 0 && <Box sx={{...innerBoxStyle}}>
                        {filteredCatalog.map((item, index) => {
                            return (
                                <CustomAccordion 
                                    key={index}
                                    item={item}
                                    selectedItemInstruction={selectedItemInstruction}
                                    handleInstructionSelection={handleInstructionSelection}
                                    handleCategorySelection={handleTopLevelLeftItemSelection}
                                    handleGroupSelection={handleSecondLevelLeftItemSelection}
                                    selectedCategoryItems={selectedTopLevelLeftItems}
                                    selectedGroupItems={selectedSecondLevelLeftItems}
                                    selectedItems={selectedLeftItems}
                                    handleItemSelection={handleLeftItemSelection}
                                />
                            )
                        })}
                        </Box>}
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
                <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={handleFieldTransferValidation()}
                        onClick={handleLeftToRightTransfer}
                        sx={{p:1, px: 4, backgroundColor: "background.paper"}}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={selectedRightItems.length === 0}
                        onClick={handleRightToLeftTransfer}
                        sx={{p:1, px: 4, backgroundColor: "background.paper"}}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                >   
                {withInstructions && instructionsOpen &&
                    <Paper 
                        elevation={0} 
                        sx={{
                            ...boxStyle, 
                            borderColor: "darkGray",
                            minHeight: "21vh", 
                            maxHeight: "21vh",
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                "background.paper" : 
                                "vLightGray"
                        }}
                    >
                        <Box sx={{...innerBoxStyle, pb: 1}}>
                            <IconButton
                                onClick={handleInstructionsClose}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: "text.primary"
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Stack sx={{p: 2}} spacing={2}>
                                <CustomText sx={{fontSize: 16, fontWeight: "bold"}}>
                                    {selectedItemInstruction.title}
                                </CustomText>
                                <CustomText sx={{fontSize: 14}}>
                                    {selectedItemInstruction.description}
                                </CustomText>
                            </Stack>
                        </Box>
                    </Paper>
                }                
                    <Box
                        sx={{
                            ...boxStyle, 
                            borderColor: "darkGray",
                            minHeight: 
                            instructionsOpen ? "49vh" : "71vh",
                            position: "relative"
                        }}
                    >
                        {withInstructions && !instructionsOpen &&
                            <IconButton 
                                onClick={handleInstructionsOpen} 
                                color="info" 
                                sx={{
                                    position: "absolute", 
                                    top: 0, 
                                    right: 0
                                }}
                            >
                                <HelpCenterIcon />
                            </IconButton>
                        }
                        <CustomHeader
                            cardHeaderTitle={
                                selectedCardTitle ? 
                                selectedCardTitle : 
                                defaultTexts.existingFieldsHeaderTitle
                            }
                            items={selectedRightItems}
                            handleDeselectAllItems={handleDeselectAllRightItems}
                            currentFilter={currentAppliedFilter}
                            handleFilterChange={handleAppliedFilterChange}
                            search_field_sx={{
                                width: !instructionsOpen || 
                                selectedRightItems.length > 0 ? 
                                "40%" : "50%", 
                                m: 1, 
                                mr: !instructionsOpen ? 5 : 1
                            }}
                        />
                        <Divider />
                        <Box sx={{...innerBoxStyle, pb: 10}}>
                            {getSelectedFieldsForm()}
                        </Box>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    )
};

export default CustomTransferList