import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import CustomDrawer from '../_common/CustomDrawer'
import CustomerSupportContent from './CustomerSupportContent'

const CustomerSupportWrapper = () => {
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)

  if (!isAuthenticated) {
    navigate('/')
  }

  return (
    <CustomDrawer>
      <Box sx={{ height: 'calc(100vh - 64px)' }}>
        <CustomerSupportContent />
      </Box>
    </CustomDrawer>
  )
}

export default CustomerSupportWrapper