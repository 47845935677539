import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import { openStackServices, blockStorageCinderConstants, computeNovaConstants } from '../../../../../config/openStackConstants';
import VolumesTableV3 from './volumesTableV3';
import { volumesFilterMenu } from '../../../../../_data/openstack/cinder/volumes/v3';
import { volumeCinderRequest, openstackRequest } from '../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import VolumeDetailV3 from './volumeDetailV3';
import FormGroup from '@mui/material/FormGroup'
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';
import VolumeAddV3 from './VolumeAddV3';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import CustomPopover from '../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.volumeService
const COMPUTE_SERVICE_NAME = openStackServices.computeService

const VolumesWrapperV3 = (props) => {
	const theme = useTheme()

	const { navigate, location, changeMenuActiveTab } = props
	const { width } = useWindowDimensions()

	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)

	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const [volumesData, setVolumesData] = useState([])
	const [volumes, setVolumes] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedVolume, setSelectedVolume] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [volumeDeleteConfirmDialogOpen, setVolumeDeleteConfirmDialogOpen] = useState(false);
	const [selectedVolumes, setSelectedVolumes] = useState([])
	const [volumesSortParams, setVolumesSortParams] = useState("")
	
	const [volumeFilterQueryParams, setVolumeFilterQueryParams] = useState('')

	const [serversList, setServersList] = useState([])
	const [volumeTypes, setVolumeTypes] = useState([])
	const [volumeTypesList, setVolumeTypesList] = useState([])
	const [volumeGroups, setVolumeGroups] = useState([])
	const [volumeGroupsList, setVolumeGroupsList] = useState([])

	const [changeBootableDialogOpen, setChangeBootableDialogOpen] = useState(false)
	const [selectedBootableStatus, setSelectedBootableStatus] = useState(true)

	const [volumeDeleteCascade, setVolumeDeleteCascade] = useState(true)

	const [selectedVolumeIds, setSelectedVolumeIds] = useState([])
	const [searchBy, setSearchBy] = useState(volumesFilterMenu.length > 0 ? volumesFilterMenu[0].value : '')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)
	
	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	const volumeTypesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
	const volumeGroupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	const groupsUrl = useSelector(
		state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
				version => version.api_version === "v3")[0].urls.filter(
						url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
	
	const getSearchType = () => {
		const filterObject = volumesFilterMenu.find(item => item.value === searchBy)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		if (searchBy === 'volume_type') {
			return volumeTypesList
		} else {
			const filterObject = volumesFilterMenu.find(item => item.value === searchBy)
			return filterObject ? filterObject.items : []
		}		
	}

	const isValidSelectValue = (value, list) => {
		const findObject = list.find(item => item.value === value)
		return findObject ? true : false
	}

	const handleFilteredSearch = () => {
		if (searchBy && searchKeyword) {
			setVolumeFilterQueryParams(`?${searchBy}=${searchKeyword}`)
		} else {
			setVolumeFilterQueryParams('')
		}

		handleDataFetch()
	}
	
	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const handleVolumesDataFormatting = useCallback(() => {
			const formatted_data = volumesData.map((item) => {
					let new_item = {...item}
					new_item.size = `${item.size}GB`
					new_item.bootable = Boolean(item.bootable === "true")

					// const project = projects.filter(p => p.id === item[blockStorageCinderConstants.volumeProjectIdField])
					// if (project.length > 0) {
					// 		new_item[blockStorageCinderConstants.volumeProjectIdField] = project[0].name
					// }

					const vol_group = volumeGroups.filter(p => p.id === item.group_id)
					if (vol_group.length > 0) {
							new_item.group_id = vol_group[0].name ? vol_group[0].name : item.group_id
					}

					if (item.attachments.length > 0) {
							let attachments = item.attachments.map(atch => {
									const server = serversList.filter(s => s.id === atch.server_id)
									if (server.length > 0) {
											return `${server[0].name} -> ${atch.device}`
									} else {
											return `${atch.server_id} -> ${atch.device}`
									}
									
							})
							new_item.attachments = attachments
					}
					return new_item
			})
			
			setVolumes(formatted_data)
	},[
			volumesData,
			volumeGroups,
			serversList
	])
	
	const handleVolumeFilterReset = () => {
		setSearchKeyword('')
		setSearchBy(volumesFilterMenu[0].value)
		setVolumeFilterQueryParams('')
		setVolumesSortParams('')
		handleDataFetch()
	}

	const handleNavigateToServer = (server_id) => {
		navigate("/compute/instances", {state: {server_id: server_id}})
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedVolume(volumesData[index].id)
			setSelectedRow(volumesData[index])
	},[volumesData]);

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleVolumesSorting = (field,direction) => {
			const sort_param = `sort=${field}:${direction}`
			setVolumesSortParams(sort_param)
			handleDataFetch()
	}

	const handleChangeBootableDialogClose = () => {
			setChangeBootableDialogOpen(false)
	}

	const handleChangeBootableDialogOpen = (volume_list) => {
			const selected_v_list = volumesData.filter(v => 
					volume_list.includes(v.id))
			setSelectedVolumes([...selected_v_list])
			setChangeBootableDialogOpen(true)
	}

	const handleBootableStatusChange = (event) => {
			setSelectedBootableStatus(event.target.checked)
	}

	const handleNavigateToSnapshot = (snapshot_id, tab_name) => {
		changeMenuActiveTab(tab_name)
		navigate("/storage/snapshots", {state: {snapshot_id}})
	}

	const handleNavigateToBackup = (backup_id, tab_name) => {
		changeMenuActiveTab(tab_name)
		navigate("/storage/backups", {state: {backup_id}})
	}

	const handleNavigateToVolumeGroup = (group_id,tab_name) => {
		changeMenuActiveTab(tab_name)
		navigate("/storage/volume-groups", {state: {group_id}})
	}

	const handleBootableStatusUpdate = async (volume_id) => {
			let data = {}
			data["os-set_bootable"] = {bootable: selectedBootableStatus}
			
			
			const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${volume_id}/action`
			const method = "POST"
			
			const v_response = await volumeCinderRequest({
					url:url, 
					method:method,
					data: data,
					token,
					has_response: false
			})
			if (v_response.status_code === volumeUrlResponses.post.success_response.status_code) {
					return null
			} else {
					return v_response.error
			}
			
	}

	const onBootableStatusUpdate = async () => {
		handleChangeBootableDialogClose()

		let err = []

		for (let v in selectedVolumes) {
			const resp = await handleBootableStatusUpdate(selectedVolumes[v].id)
			if (resp !== null) {
				err = [...err, resp]
			}
		}

		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const handleVolumeDelete = async (v_id) => {
			let cascade = ""
			
			if (volumeDeleteCascade) {
					cascade = "cascade=true&&"
			}
			
			const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${v_id}?${cascade}`
			const method = "DELETE"
			
			const vt_response = await volumeCinderRequest({
					url:url, 
					method:method,
					token,
			})
			
			if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
					return null
			} else {
					return vt_response.error
			}			
	}

	const onVolumeDelete = async () => {
		handleVolumeDeleteConfirmDialogClose()

		let err = []

		for (let v in selectedVolumes) {
			const resp = await handleVolumeDelete(selectedVolumes[v].id)
			if (resp !== null) {
				err = [...err, resp]
			}
		}

		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const onVolumeDeleteConfirm = (v_list) => {
			const selected_v_list = volumesData.filter(v => 
					v_list.includes(v.id))
			setSelectedVolumes([...selected_v_list])
			setVolumeDeleteConfirmDialogOpen(true)
	}

	const handleVolumeDeleteConfirmDialogClose = () => {
			setVolumeDeleteCascade(false)
			setVolumeDeleteConfirmDialogOpen(false)
	}

	const handleVolumeDeleteCascadeChange = (event) => {
			setVolumeDeleteCascade(event.target.checked)
	}

	const getVolumesActionsList = () => {
			let volume_actions = []
			let new_action = {}
			new_action["value"] = "update_bootable_status"
			new_action["action"] = handleChangeBootableDialogOpen
			new_action["keyword"] = "updateBootableStatusActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			volume_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "volume_delete"
			new_action["action"] = onVolumeDeleteConfirm
			new_action["keyword"] = "volumeDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			volume_actions.push({...new_action})
			
			return volume_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedVolume(null)
		setSelectedRow(null)
	}, [defaultAdminProject, token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
							handleLoading(true)
							const firstSymbol = volumeFilterQueryParams ? '&&' : '?'
							const sort_params = volumesSortParams ? firstSymbol + volumesSortParams : ''				
							
							let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail${volumeFilterQueryParams}${sort_params}`
							const method = "GET"
	
							const volume_response = await volumeCinderRequest({url:url, method:method, token, })
							
							if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
									setVolumesData(volume_response.data.volumes)
									if (selectedVolume) {
											const selected_volume = volume_response.data.volumes.filter(item => item.id === selectedVolume)
											if (selected_volume.length > 0) {
													setSelectedRow(selected_volume[0])
											}
									}
							} else {
								setVolumesData([])
							}

							
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumesUrl, 
			volumeFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			volumesSortParams,
			selectedVolume,
			token,
	]);

	useEffect(() => {
			(async () => {
					let all_volume_types = []
					let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
					const method = "GET"

					const volume_types_response = await volumeCinderRequest({url:url, method:method, token, })
					if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
							all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
					}
					url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
					const response = await volumeCinderRequest({url:url, method:method, token, })
					if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
							const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
							all_volume_types = [...private_vt, ...all_volume_types]
					}
					setVolumeTypes([...all_volume_types])
					const volume_types_list = all_volume_types.map(item => {
							return {keyword: item.name, value: item.id, default: false}
					})
					setVolumeTypesList(volume_types_list)          
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			volumeTypesUrl,
			token,
	]);

	useEffect(() => {
			(async () => {            
					let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeGroupsUrl}`
					const method = "GET"

					const volume_groups_response = await volumeCinderRequest({url:url, method:method, token, })
					if (volume_groups_response.status_code === volumeUrlResponses.get.success_response.status_code) {
							const volume_groups_list = volume_groups_response.data.groups.map(item => {
									return {keyword: item.name, value: item.id, default: false}
							})
							setVolumeGroupsList(volume_groups_list)
					}            
			})();
	},[
			cinderServiceDomain,
			cinderServiceVersion,
			defaultAdminProject,
			volumeGroupsUrl,
			token,
	]);

	useEffect(() => {
			(async () => {
					const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?`
					const method = "GET"
					
					const servers_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})
					setServersList(servers_response.data.servers)            
			})();
	},[
			computeServiceDomain,
			computeServiceVersion,
			serversUrl,
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
		if (volumesData.length > 0) {
			handleVolumesDataFormatting()
		} else {
			setVolumes([])
		}
	},[
		volumesData,
		handleVolumesDataFormatting
	])

	useEffect(() => {
		(async () => {
				
			let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail?`
			const method = "GET"
			const groups_response = await volumeCinderRequest({url:url, method:method, token, })
			if (groups_response.status_code === volumeUrlResponses.get.success_response.status_code) {
					setVolumeGroups(groups_response.data.groups)
			}
				
		})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			groupsUrl, 
			dataFetchingRequired,
			defaultAdminProject,
			token,
	]);
	
	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		if (location.state && location.state.volume_id && volumesData.length >0) {
			const findVolume = volumesData.find(item => item.id === location.state.volume_id)
			setSelectedVolume(findVolume ? findVolume : null)
			setSelectedRow(findVolume ? findVolume : null)
			navigate(location.path, {})
		}
	}, [location, navigate, volumesData])
	
	const handleActionRun = () => {
		const selected_volume_list = volumesData.filter(item => selectedVolumeIds.includes(item.id))
		setSelectedVolumes([...selected_volume_list])
		
		if (currentAction === 'volume_delete') {
			setVolumeDeleteConfirmDialogOpen(true)
		}

		if (currentAction === 'update_bootable_status') {
			setChangeBootableDialogOpen(true)
		}
	}

	const handleDeselectAll = () => {
		setSelectedVolumeIds([])
		setSelectedVolumes([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedVolumeIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getVolumesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<VolumeAddV3
					handleDataFetch={handleDataFetch}
					volumeTypes={volumeTypesList}
					volumeGroups={volumeGroupsList}
					volumes={volumesData}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'select' &&
						<CustomSelectField
							label={defaultTexts.statusFormFieldLabel}
							items={getSearchSelectItems()} 
							currentValue={ isValidSelectValue(searchKeyword, getSearchSelectItems()) ? searchKeyword : '' }
							setCurrentValue={setSearchKeyword}
							item_titles={defaultTexts}
							self_item_titles={ searchBy === 'volume_type' ? true : false }
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleVolumeFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getVolumesHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 5px 20px',
						width: "100%"
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuVolumes} ({selectedVolumeIds.length}/{volumesData.length})
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>				

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 5px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedVolumeIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getVolumesTable = () => {
		return (
			<VolumesTableV3 
				volumesData={volumes}
				setVolumeTypesData={setVolumes}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getVolumesActionsList()}
				sortParams={volumesSortParams}
				sortHandler={handleVolumesSorting}
				setSelectedVolumeIds={setSelectedVolumeIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getVolumesContent = () => {
		return (
			<>
				{ getVolumesHeader() }
				{ getVolumesTable() }
			</>
		)
	}

	const getVolumeDetails = () => {
		return (
			<VolumeDetailV3
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setSelectedVolume={setSelectedVolume}
				handleDataFetch={handleDataFetch}
				handleNavigateToServer={handleNavigateToServer}
				handleVolumeDelete={onVolumeDeleteConfirm}
				serversList={serversList}
				volumeTypes={volumeTypes}
				volumeGroups={volumeGroups}
				handleChangeBootableDialogOpen={handleChangeBootableDialogOpen}
				handleNavigateToSnapshot={handleNavigateToSnapshot}
				handleNavigateToBackup={handleNavigateToBackup}
				handleNavigateToVolumeGroup={handleNavigateToVolumeGroup}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}
	
	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getVolumesContent()} contentBottom={getVolumeDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getVolumesHeader() }

				{ (selectedRow === null && !isLoading) && getVolumesTable() }

				<CustomDialog
					open={changeBootableDialogOpen}
					onClose={handleChangeBootableDialogClose}
					dialogTitle={{
							title: defaultTexts.updateBootableStatusActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onBootableStatusUpdate, 
							sx: {color: 'primary.main'}}]}
				>
					<FormGroup sx={{m:3}}>
						<FormLabel component="legend">
							{defaultTexts.bootableStatusFormFieldLabel}
						</FormLabel>
						<FormControlLabel
							control={
								<Switch 
									checked={selectedBootableStatus} 
									onChange={handleBootableStatusChange} 
									name="bootableStatus" 
								/>
							}
							label={
								selectedBootableStatus ? 
								defaultTexts.enableFormFieldLabel : 
								defaultTexts.disableFormFieldLabel 
							}
						/>
					</FormGroup>
				</CustomDialog>

				<CustomDialog
					open={volumeDeleteConfirmDialogOpen}
					onClose={handleVolumeDeleteConfirmDialogClose}
					dialogTitle={{
						title: defaultTexts.volumeDeleteConfirmTitle, 
						sx: {color: 'primary.main'}}}
					dialogBody={{
						text: `${defaultTexts.volumeDeleteConfirmText}: [${selectedVolumes.map(v => v.name).toString()}]`, 
						sx: {color: 'text.primary'}}}
					actionButtons={[{
						title: defaultTexts.confirmButtonText, 
						onClick: onVolumeDelete, 
						sx: {color: 'primary.main'}}]}
				>
					<Box>
						<CustomCheckboxField
							currentValue={volumeDeleteCascade}
							setCurrentValue={handleVolumeDeleteCascadeChange}
							label={defaultTexts.withSnapshotsFormFieldLabel}
							sx={{my: 1}}
						/>
					</Box>
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default VolumesWrapperV3