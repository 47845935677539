import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import { MdSettingsPower } from "react-icons/md";
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { VscDebugStart } from "react-icons/vsc";
import { FaRegStopCircle } from "react-icons/fa";
import { BsBootstrapReboot } from "react-icons/bs";
import { FaPauseCircle } from "react-icons/fa";
import { SiStopstalk } from "react-icons/si";
import { styled } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import { serversUrl as serversUrlResponses } from 
'../../../../../_api_responses/openstack/compute/servers/v2.1';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import useWindowDimensions from '../../../../_common/WindowDimensions';

const SERVICE_NAME = openStackServices.computeService
const SUCCESS_STATUS_CODE = serversUrlResponses.delete.success_response.status_code

const StyledTooltip = styled(MuiTooltip)(({ theme }) => ({
    fontSize: 20
  }));


const ComputeServerStateActionsV21 = (props) => {
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const { width } = useWindowDimensions()
	const [error, setError] = useState();
	const [successResponse, setSuccessResponse] = useState();
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const { 
			serverData, 
			selectedRow,
			handleDataFetch,
			handleServerStatusChange,
			isSuspended,
			setSuspendedDialogOpen,
	} = props
	const [isLoading, setIsLoading] = useState(false)

	const [actions, setActions] = useState([])

	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)

	const theme = useTheme()

	const handleSelectedAction = useCallback(async (data,has_response) => {
			setIsLoading(true)
			const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/action`
			const method = "POST"

			const project_token = await getXAuthTokenProjectScope(serverData.tenant_id)
			if (project_token) {
					const server_response = await openstackRequest({
							url:url, 
							method:method, 
							data: data,
							token: project_token,
							has_response: has_response
					})
					setIsLoading(false)
					if (server_response.status_code === SUCCESS_STATUS_CODE) {
							handleServerStatusChange(selectedRow.status, selectedRow.id)
							if (has_response) {
									setSuccessResponse(JSON.stringify(server_response.data))
							}
							handleSuccessDialogOpen()
							handleDataFetch()
					} else {
							setError(server_response.error)
					}
			}
	},[
			computeServiceDomain,
			computeServiceVersion,
			serversUrl,
			serverData,
			handleDataFetch,
			handleServerStatusChange,
			selectedRow
	])

	const handleStartAction = useCallback(async () => {
			let data = {}
			data["os-start"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleStopAction = useCallback(async () => {
			let data = {}
			data["os-stop"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleSoftRebootAction = useCallback(async () => {
			let data = {}
			data["reboot"] = {type: "SOFT"}
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleHardRebootAction = useCallback(async () => {
			let data = {}
			data["reboot"] = {type: "HARD"}
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handlePauseAction = useCallback(async () => {
			let data = {}
			data["pause"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleSuspendAction = useCallback(async () => {
			let data = {}
			data["suspend"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleUnpauseAction = useCallback(async () => {
			let data = {}
			data["unpause"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleResumeAction = useCallback(async () => {
			let data = {}
			data["resume"] = null
			await handleSelectedAction(data, false)
	},[
			handleSelectedAction
	])

	const handleSuccessDialogOpen = () => {
			setSuccessDialogOpen(true);
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}
	const handleSuccessDialogClose = () => {
			setSuccessResponse(null);
			setSuccessDialogOpen(false);
	}

	const handleApplyAction = (action) => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			action()
		}
	}

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error,serverData]);

	useEffect(() => {
			const action_list = []
			if (["shutoff"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <VscDebugStart size={28} />, name: 'Start', action: handleStartAction })
			}
			if (["active","error"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <FaRegStopCircle size={28} />, name: 'Stop', action: handleStopAction })
			}
			if (["active"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <BsBootstrapReboot size={28} />, name: 'Soft Reboot', action: handleSoftRebootAction})
			}
			if (["active","error","hard_reboot","paused","reboot","shutoff","suspended"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <BsBootstrapReboot size={28} />, name: 'Hard Reboot', action: handleHardRebootAction})
			}
			if (!["paused","shutoff","suspended"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <FaPauseCircle size={28} />, name: 'Pause', action: handlePauseAction })
			}
			if (!["suspended","shutoff", "paused"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <SiStopstalk size={28} />, name: 'Suspend', action: handleSuspendAction})
			}
			if (["paused"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <FaPauseCircle size={28} />, name: 'Unpause', action: handleUnpauseAction })
			}
			if (["suspended"].includes(selectedRow.status.toLowerCase())) {
					action_list.push({ icon: <SiStopstalk size={28} />, name: 'Resume Suspended', action: handleResumeAction})
			}
			setActions(action_list)
	},[
			serverData,
			selectedRow,
			handleStartAction,
			handleStopAction,
			handleSoftRebootAction,
			handleHardRebootAction,
			handlePauseAction,
			handleSuspendAction,
			handleUnpauseAction,
			handleResumeAction
	]);
	
	return (
		<Box 
			sx={{ 
					height: width >= 1024 ? 120 : 20, 
					transform: 'translateZ(0px)', 
					flexGrow: 1, 
					position: "relative",
					paddingRight: '80px',
			}}
		>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				<SpeedDial
					direction={width >= 1024 ? 'up' : 'down'}
					ariaLabel=""
					sx={{
						position: 'absolute',
						bottom: width >= 1024 ? 64 : 'auto',
						top: width >= 1024 ? 'auto' : -45,
						left: width >= 1024 ? 10 : 60,
					}}
					icon={<MdSettingsPower size={24} />}
					FabProps={{
						size: 'small',
						sx: {
							background: 'none',
							color: theme.palette.primary.main,
							border: `${theme.palette.customGray} 1px solid`,
    					borderRadius: '12px',	

							'&:hover': {
								background: alpha(theme.palette.customGray, 0.5)
							}
						}
					}}
				>
						{
							actions.map((action) => (
								<SpeedDialAction
									key={action.name}
									icon={action.icon}
									tooltipTitle={action.name}
									tooltipPlacement="right"
									FabProps={{
										size: "small",
										sx: {
											color: theme.palette.primary.main,
											border: `${theme.palette.customGray} 1px solid`,
    									borderRadius: '12px',

											'&:hover': {
												background: alpha(theme.palette.customGray, 0.5)
											}
										}
									}}
									TooltipClasses={StyledTooltip}
									onClick={() => handleApplyAction(action.action)}
								/>
							))
						}
				</SpeedDial>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
										<br>
										<br>
										<span>Details:</span> 
										<span style="color: orange">${error}</span>`,
							sx: {color: 'text.primary'}}}
					/>
				}

				<CustomDialog
					open={successDialogOpen}
					onClose={handleSuccessDialogClose}
					dialogTitle={{
						title: defaultTexts.successActionDialogTitle, 
						sx: {color: 'primary.main'}}}
					dialogBody={{
						text: successResponse ?
							`<span>${defaultTexts.successActionDialogMessage}</span>
									<br>
									<br>
									<span>${defaultTexts.responseNoteDialogText}:</span> 
									<span style="width: 100px; color: orange; word-wrap: break-word;">${successResponse}</span>` :
							`<span>${defaultTexts.successActionDialogMessage}</span>`, 
						sx: {color: 'text.primary'}
					}}
				/>
		</Box>
	)
}

export default ComputeServerStateActionsV21