import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useWindowDimensions from './WindowDimensions'
import CustomText from './CustomText'
import CustomSelectField from './_form_fields/CustomSelectField'

const ServiceSubmenu = (props) => {
  const { service, serviceSubmenu, currentTab } = props
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const navigate = useNavigate()

  const defaultTexts = useSelector(state => state.texts.langTexts)

  const getServiceSubmenuList = () => {
    let service_submenu_list = []
    serviceSubmenu.forEach(item => service_submenu_list.push({
      value: item.navigation,
      keyword: item.keyword,
    }))
    return service_submenu_list
  }

  const handleClick = (item) => {
    navigate(`${service}${item.parent}${item.navigation}`)
  }

  const handleChangeSubmenuItem = (value) => {
    const this_object = serviceSubmenu.find(item => item.navigation === value)
    if (this_object) {
      handleClick(this_object)
    }
  }
  
  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="center"      
    >
      {
        width >= 900 ?
        <>
          {
            serviceSubmenu.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'inline-block',
                  color: theme.palette.customGrayDark,
                }}
              >
                <Box
                  sx={{
                    display: 'inline-block',
                    color: currentTab === item.navigation ? theme.palette.primary.main : theme.palette.customGrayDark,
                    cursor: currentTab === item.navigation ? 'auto' : 'pointer',
                    marginRight: '10px',
                    '&:hover': {
                      opacity: currentTab === item.navigation ? 1 : 0.7,
                    }
                  }}
                  onClick={() => handleClick(item)}
                >
                  <CustomText sx={{ fontSize: '17px' }}>
                    {defaultTexts[item.keyword]}
                  </CustomText>
                </Box>

                {
                  index < serviceSubmenu.length - 1 &&
                  <Box
                    sx={{
                      display: 'inline-block',
                      fontSize: '17px',
                      marginRight: '10px',
                    }}
                  >|</Box>
                }										
              </Box>
            ))
          }
        </> :
        <>
          <CustomSelectField
            items={getServiceSubmenuList()} 
            currentValue={currentTab}
            setCurrentValue={handleChangeSubmenuItem}
            item_titles={defaultTexts}
            empty={true}
            size="small"
            sx={{ width: '240px' }}
          />
        </>
      }
    </Stack>
  )
}

export default ServiceSubmenu