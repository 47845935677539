import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { floatingIPDataForm } from '../../../../../../_data/openstack/neutron/floating_ips/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { floatingIPsUrl as floatingIPUrlResponses } from '../../../../../../_api_responses/openstack/neutron/floating_ips/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const ip_checker = require('ip-address');

const SERVICE_NAME = openStackServices.networkService

const FloatingIPAddV20 = (props) => {
  const { handleDataFetch } = props
  const { networks, floatingIPPools } = props 
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successFloatingIPAdd, setSuccessFloatingIPAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const floatingIPsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessFloatingIPAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddFloatingIPFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in floatingIPDataForm) {
          if (
              floatingIPDataForm[n].field_type === "string" || 
              floatingIPDataForm[n].field_type === "select"
              ) {
              new_form_data[floatingIPDataForm[n].field_key] = floatingIPDataForm[n].default_value ? 
              floatingIPDataForm[n].default_value : 
              ""
          } else if (floatingIPDataForm[n].field_type === "bool") {
              new_form_data[floatingIPDataForm[n].field_key] = floatingIPDataForm[n].default_value ? 
              floatingIPDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (floatingIPDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (floatingIPDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  const getDataForm = () => {
      let form = [...floatingIPDataForm]
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "floating_network_id") {
                      const network_list = networks.filter(n => n["router:external"])
                      const networks_filter = network_list.map(p => {
                          return {keyword: p.name, value: p.id, default: false}
                      })
                      form_field_options["items"] = [...networks_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  } else if (field.field_key === "subnet_id") {
                      const subnet_list = floatingIPPools.filter(s => s.network_id === formData.floating_network_id)
                      const subnets_filter = subnet_list.map(s => {
                          return {keyword: `${s.subnet_name}: ${s.cidr}`, value: s.subnet_id, default: false}
                      })
                      form_field_options["items"] = [...subnets_filter]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true

                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in floatingIPDataForm) {
          if (floatingIPDataForm[n].required && !formData[floatingIPDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[floatingIPDataForm[n].field_key] = {}
              updatedDataFormOptions[floatingIPDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[floatingIPDataForm[n].field_key]["errorText"] = defaultTexts[floatingIPDataForm[n].error_label]
          }
      }

      if (formData.floating_ip_address && formData.floating_ip_address.length > 0) {
          let parsev4 = null

          try {
              parsev4 = new ip_checker.Address4(formData.floating_ip_address)
          } catch {
              parsev4 = null
          }

          if (!parsev4) {
              validation_faild = false
              updatedDataFormOptions["floating_ip_address"] = {}
              updatedDataFormOptions["floating_ip_address"]["error"] = true
              updatedDataFormOptions["floating_ip_address"]["errorText"] = defaultTexts.invalidIPAddressErrorText
          }
      } else {
          delete formData.floating_ip_address
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddFloatingIP = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = {...formData}
           
          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}`
          const method = "POST"
          const fip_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {floatingip: data},
              token,
          })
          
          if (fip_response.status_code === floatingIPUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddFloatingIPFormReset()
              setFormDataOptions({})
          } else {
              const error_response = floatingIPUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === fip_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: fip_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = floatingIPUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: fip_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successFloatingIPAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in floatingIPDataForm) {
              if (
                  floatingIPDataForm[n].field_type === "string" || 
                  floatingIPDataForm[n].field_type === "select"
                  ) {
                  new_form_data[floatingIPDataForm[n].field_key] = floatingIPDataForm[n].default_value ? 
                  floatingIPDataForm[n].default_value : 
                  ""
              } else if (floatingIPDataForm[n].field_type === "bool") {
                  new_form_data[floatingIPDataForm[n].field_key] = floatingIPDataForm[n].default_value ? 
                  floatingIPDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddFloatingIP}
        formReset={handleAddFloatingIPFormReset}
        customTexts={{
          title: defaultTexts.addFloatingIPDialogTitle
        }}
      />

      {
        successFloatingIPAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successFloatingIPAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successFloatingIPAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }
          
      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default FloatingIPAddV20