import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box'
import CustomDialog from './CustomDialog';
import useWindowDimensions from './WindowDimensions';
import SuspendedAlertDialog from './SuspendedAlertDialog';

const AddButtonWithText = (props) => {
  const { onSubmit, formReset, preDialogClose } = props;
  const { getDataForm } = props
  const { customTexts } = props
  const { width } = useWindowDimensions()

  const theme = useTheme()

  const [dialogOpen, setDialogOpen] = useState(false)
  const defaultTexts = useSelector(state => state.texts.langTexts)

  const billingDeposit = useSelector(state => state.profile.billingDeposit)
	const isSuspended = (billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

  const sxButton = {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    background: theme.palette.success.main,
    border: `${theme.palette.customGrayDark} 1px solid`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0px 10px',
    marginLeft: width >= 900 ? '10px' : '0px',

    '&:hover': {
      background: alpha(theme.palette.success.main, 0.7),
    }
  }

  const formSubmit = async () => {
    if (preDialogClose) {
      setDialogOpen(false)
    }

    const result = await onSubmit()

    if (result) {
        setDialogOpen(false)
    } else {
        setDialogOpen(true)
    }
  }

  const onClick = () => {
    if (isSuspended) {
      setSuspendedDialogOpen(true)
    } else {
      setDialogOpen(true)
    }    
  }

  const onClose = () => {
      formReset()
      setDialogOpen(false)
  }

  return (
    <>
      <Box
        onClick={onClick}
        sx={sxButton}
      >
        {customTexts.title}
      </Box>

      <CustomDialog
        open={dialogOpen}
        onClose={onClose}
        dialogTitle={{
            title: customTexts.title, 
            sx: {color: 'primary.main'}}}
        dialogBody={{
            text: customTexts.description ? customTexts.description : "", 
            sx: {color: 'text.primary', mb: 3}}}
        actionButtons={[{
            title: defaultTexts.submitButtonText, 
            onClick: formSubmit, 
            variant: "contained",
            size: "medium"
        }]}
      >
        {getDataForm()}
        {props.children}
      </CustomDialog>

      <SuspendedAlertDialog
        isOpen={suspendedDialogOpen}
        setIsOpen={setSuspendedDialogOpen}
      />
    </>
  )
}

export default AddButtonWithText