import React, { useState, useCallback } from 'react'
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box'
import Payments from './payments/Payments';
import Invoices from './invoices/Invoices';

const PaymentsContent = () => {
  const theme = useTheme()
  
  const [billingMenu, setBillingMenu] = useState([
		{ keyword: "paymentsLabel", navigation: "payments", is_active: true, component: Payments },
		{ keyword: "invoicesLabel", navigation: "invoices", is_active: false, component: Invoices },
	])

  const getCurrentTab = () => {
		const currentItem = billingMenu.filter(item => item.is_active)[0]
		return currentItem
	}

  const [currentTab, setCurrentTab] = useState(getCurrentTab())

  const changeMenuActiveTab = useCallback((navigation) => {
		const updated_menu = billingMenu.map(item => {
			if (item.navigation === navigation) {
				item.is_active = true
				setCurrentTab(item)
			} else {
				item.is_active = false
			}

			return item
		})

		setBillingMenu(updated_menu)
	}, [billingMenu])

  return (
    <Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
      <currentTab.component 
				billingMenu={billingMenu}
				changeMenuActiveTab={changeMenuActiveTab}
			/>
    </Box>
  )
}

export default PaymentsContent