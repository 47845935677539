export const billingPaymentsDataSchema = [
  {
      field_key: "amount",
      is_id_field: false,
      field_type: "string", 
      label: "amountLabel",
      sort_key: 'amount',
  },
  {
      field_key: "time_stamp",
      is_id_field: false,
      field_type: "string", 
      label: "dateLabel",
      sort_key: 'time_stamp',
  },
  {
      field_key: "payment_method",
      is_id_field: false,
      field_type: "string", 
      label: "paymentMethodLabel",
  },
  {
    field_key: "transaction_data",
    is_id_field: false,
    field_type: "string", 
    label: "transactionDetailsLabel",
  },
  {
    field_key: "invoice_id",
    is_id_field: false,
    field_type: "string", 
    label: "invoiceIdLabel",
  },
]

export const billingInvoicesDataSchema = [
  {
      field_key: "amount",
      is_id_field: false,
      field_type: "string", 
      label: "amountLabel",
      sort_key: 'amount',
  },
  {
      field_key: "time_stamp",
      is_id_field: false,
      field_type: "string", 
      label: "issueDateLabel",
      sort_key: 'time_stamp',
  },
  {
    field_key: "due_date",
    is_id_field: false,
    field_type: "string", 
    label: "dueDateLabel",
    sort_key: 'due_date',
  },
  {
    field_key: "status",
    is_id_field: false,
    field_type: "string", 
    label: "statusFormFieldLabel",
  },
  {
    field_key: "id",
    is_id_field: true,
    field_type: "string", 
    label: "invoiceIdLabel",
  },
]