import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import { Button } from '@mui/material';
import NoDataNote from '../../../../_common/NoDataNote';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { volumeGroupsUrl as groupsUrlResponses } from '../../../../../_api_responses/openstack/cinder/groups/v3';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import CustomDialog from '../../../../_common/CustomDialog';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { volumeGroupDataForm, volumeGroupUpdateDataForm, volumeGroupDataSchema } from '../../../../../_data/openstack/cinder/groups/v3';
import { getDetailDataComponent } from '../../../../_common/common_helpers';
import ServiceCardContentHeader from '../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { groupDataUpdateForm } from '../../../../../_data/openstack/identity/groups/v3';
import { handleProjectScopeRoleAssignment } from '../../../../identity_keystone/helpers/rolesHelpers';

const SERVICE_NAME = openStackServices.volumeService
const VOLUME_LIST_MANIPULATION_API_VERSION = "3.25"
const VOLUME_TYPE_ID_MIN_API = "3.63"
const ALLOWED_ROLE = "admin"

const GroupDetailV3 = (props) => {
	const theme = useTheme()
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const user = useSelector(state => state.profile.userID)
	const AdminProjectId = useSelector(state => state.profile.defaultAdminProject)
	const adminProjects = useSelector(state => state.profile.adminProjects)
	const token = useSelector(state => state.profile.x_auth_token)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const mode = useSelector(state => state.settings.uiMode)
	const { selectedRow, selectedGroup, setSelectedRow, setSelectedGroup } = props
	const defaultAdminProject = AdminProjectId
	const { widthWeight } = props
	const { width } = useWindowDimensions();
	const { handleNavigateToVolume } = props
	const { volumes, volumeTypes } = props
	const { handleFetchData, handleDelete} = props
	const { isSuspended, setSuspendedDialogOpen }  = props

	const drawerWidth = drawerOpened ? 270 : 65
	
	const [editMode, setEditMode] = useState(false);
	
	const [error, setError] = useState()
	const [successGroupUpdate, setSuccessGroupUpdate] = useState()
	const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
	
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

	const [updateFormData, setUpdateFormData] = useState(
			{...selectedGroup}
	)
	const [groupActions, setGroupActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");
	
	const [volumesInGroup, setVolumesInGroup] = useState([]);
	const [volumesList, setVolumesList] = useState([]);
	const [selectedVolumes, setSelectedVolumes] = useState([]);
	const [volumesToRemove, setVolumesToRemove] = useState([]);
	const [updateVolumesListDialogOpen, setUpdateVolumesListDialogOpen] = useState(false);
	const [createSnapshotDialogOpen, setCreateSnapshotDialogOpen] = useState(false)
	const [snapshotData, setSnapshotData] = useState({})
	const [snapshotDataOptions, setSnapshotDataOptions] = useState({})
	const [warningDialogOpen, setWarningDialogOpen] = useState(false)
	const [warning, setWarning] = useState("")
	const [warningMessage, setWarningMessage] = useState("")

	const [groupSubMenu, setGroupSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/gr-details", is_active: true},
			{keyword: "submenuVolumeTypes", navigation: "/group-volume-types", is_active: false},
			{keyword: "submenuVolumes", navigation: "/group-volumes", is_active: false},
	])
	
	const [currentTab, setCurrentTab] = useState("/gr-details")

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		justifyContent: 'flex-start',
	}

	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const groupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
	const groupSnapshotsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.groupSnapshotsUrl)[0].url)
	const maxAPIVersion = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].max_api_version)
	
	const handleFormDataChange = (event,field_key) => {
			let new_form_data = {...updateFormData}
			if (volumeGroupDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (volumeGroupDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUpdateFormData(new_form_data)
	}

	const getSnapshotDataForm = (form,form_options,data,onDataChange) => {
			return (
					<FormGroup>
							{form.map(field => {
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_options[field.field_key]}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getDataForm = () => {
			return (
					<FormGroup>
							{volumeGroupUpdateDataForm.map(field => {
									let form_field_options = {}
							return (
									getFormFieldComponent(
											field,
											updateFormData,
											handleFormDataChange,
											defaultTexts[field.label],
											{
													size:"medium",
													withHelp: field.with_help_text,
													helpText: defaultTexts[field.help_text],
													sx: {
															my: 1, 
															width: width * widthWeight * 0.8
													},
											...form_field_options
											}
									)
							)
					})}
			</FormGroup>)
	}

	const handleCreateSnapshotDialogClose = () => {
			setSnapshotData({})
			setSnapshotDataOptions({})
			setCreateSnapshotDialogOpen(false)
	}

	const handleSnapshotDataChange = (event,field_key) => {
			setSnapshotDataOptions({})
			let new_form_data = {...snapshotData}
			if (groupDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else {
					new_form_data[field_key] = event.target.value
			}
			setSnapshotData(new_form_data)
	}

	const onSnapshotCreate = async () => {
			if (snapshotData.name.length === 0) {
					let updatedDataFormOptions = {...snapshotDataOptions}
					if (snapshotData.name.length === 0) {
							updatedDataFormOptions.name = {}
							updatedDataFormOptions.name["error"] = true
							updatedDataFormOptions.name["errorText"] = defaultTexts.requiredFormFieldError
					}
					setSnapshotDataOptions(updatedDataFormOptions)
					return false
			} else {
					
				const method = "POST"
				const snapshot_response = await volumeCinderRequest({
						url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupSnapshotsUrl}`, 
						method: method, 
						data: {group_snapshot: {
								...snapshotData,
								group_id: selectedRow.id
						}},
						token,
				})
				if (snapshot_response.status_code === groupsUrlResponses.post.success_response.status_code) {
						setCurrentAction("")
						handleCreateSnapshotDialogClose()
						handleFetchData()
				} else {
						const error_response = groupsUrlResponses.put.error_response.filter(
								error_item => error_item.status_code === snapshot_response.status_code)
						if (error_response.length > 0) {
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: snapshot_response.error
								}
								setError(errorObject)
						} else {
								const error_response = groupsUrlResponses.put.error_response.filter(
										error_item => error_item.status_code === "unknown")
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: snapshot_response.error
								}
								setError(errorObject)
						}
				}
					
			}
	}

	const handleVolumesInGroupReset = () => {
			const volume_list = volumes.filter(v => 
					selectedRow && 
					Object.keys(selectedRow).includes("volumes") && 
					selectedRow.volumes.includes(v.id))
			setVolumesInGroup(volume_list)
			const v_ids = volume_list.map(v => v.id)
			let filtered_volumes = []
			if (parseFloat(maxAPIVersion) >= parseFloat(VOLUME_TYPE_ID_MIN_API)) {
					filtered_volumes = volumes.filter(
							v => !v_ids.includes(v.id) && 
							selectedRow.volume_types.includes(v.volume_type_id))
			} else {
					filtered_volumes = volumes.filter(
							v => !v_ids.includes(v.id) && 
							selectedRow.volume_types.includes(volumeTypes.filter(vt => vt.name === v.volume_type)[0].id)
					)
			}
			const volumes_select = filtered_volumes.map(v => {
					return {keyword: `${v.name} (${v.id})`, value: v.id, default: false}
			})
			setVolumesList(volumes_select)
	}

	const handleAddVolumesToList = () => {
			const selected_volumes = volumes.filter(v => selectedVolumes.includes(v.id))
			const new_list = [...volumesInGroup, ...selected_volumes]
			setVolumesInGroup(new_list)
			const new_list_ids = new_list.map(v => v.id)
			const new_select_list = volumes.filter(v => !new_list_ids.includes(v.id))
			const new_select_volumes = new_select_list.map(v => {
					return {keyword: `${v.name} (${v.id})`, value: v.id, default: false}
			})
			setVolumesList(new_select_volumes)
			setSelectedVolumes([])
	}

	const handleVolumeRemoveFromGroup = (v_id) => {
			const new_list = volumesInGroup.filter(v => v.id !== v_id)
			setVolumesInGroup(new_list)
			const new_remove_list = [...volumesToRemove, v_id]
			setVolumesToRemove(new_remove_list)
			const volume = volumes.filter(v => v.id === v_id)
			setVolumesList([
					...volumesList, {keyword: `${volume[0].name} (${volume[0].id})`, 
					value: volume[0].id, default: false}
			])
	}

	const handleUpdateVolumesListDialogOpen = useCallback(() => {
			setUpdateVolumesListDialogOpen(true)
	},[])

	const handleUpdateVolumesListDialogClose = () => {
			setSelectedVolumes([])
			setVolumesToRemove([])
			handleVolumesInGroupReset()
			setUpdateVolumesListDialogOpen(false)
	}

	const handleCreateSnapshotDialogOpen = () => {
			setCreateSnapshotDialogOpen(true)
	}

	const handleRoleAssignment = useCallback(async () => {
			const project_id = selectedRow.project_id
			const response = await handleProjectScopeRoleAssignment(project_id,user,ALLOWED_ROLE)
			if (response) {
					setWarningDialogOpen(false)
			}
	},[
			selectedRow,
			user
	])

	const handleWarningDialogClose = () => {
			setWarningDialogOpen(false)
	}

	const handleSnapshotWarningMessageOpen = useCallback(() => {
			const warning_message = `<span>${defaultTexts.nonAllowedActionExecutionWarning}: <strong>${selectedGroup.project_id}</strong></span>
																	<br>
																	<br> 
															<span>${defaultTexts.notRequiredRole}: <strong>${ALLOWED_ROLE}</strong></span>`
			
			const warning = <Box 
					onClick={handleRoleAssignment} 
					sx={{
							cursor: "pointer",
							color: "info.main",
							textAlign: "start"
					}}
					>
					{`${defaultTexts.getAdminRoleToGivenProject}: ${ALLOWED_ROLE} -> ${selectedGroup.project_id}`}
					</Box>
			setWarningMessage(warning_message)
			setWarning(warning)
			setWarningDialogOpen(true)
	},[
			defaultTexts,
			handleRoleAssignment,
			selectedGroup
	])

	const handleVolumesListUpdate = async () => {
		const volumes_to_remove = volumesToRemove.filter(vr => selectedRow.volumes.includes(vr))
		const volumes_to_add = volumesInGroup.filter(vs => !selectedRow.volumes.includes(vs.id))
		
		if (volumes_to_remove.length > 0 || volumes_to_add.length > 0) {
			let data = {}
			data["group"] = {}

			if (volumes_to_remove.length > 0) {
				data["group"]["remove_volumes"] = volumes_to_remove.toString()
			}

			if (volumes_to_add.length > 0) {
				const volume_ids = volumes_to_add.map(v => v.id)
				data["group"]["add_volumes"] = volume_ids.toString()
			}
			
			const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/${selectedRow.id}`
			const method = "PUT"

			const vt_response = await volumeCinderRequest({
				url: url, 
				method: method, 
				data: data,
				token,
				has_response: false
			})

			if (vt_response.status_code === groupsUrlResponses.put.success_response.status_code) {
				handleFetchData()
				handleUpdateVolumesListDialogClose()
				setCurrentAction("")
				setSuccessGroupUpdate({
						success_title: groupsUrlResponses.put.success_response.response_title, 
						success_message: groupsUrlResponses.put.success_response.response_message
				})
			} else {
				const error_response = groupsUrlResponses.put.error_response.filter(error_item => error_item.status_code === vt_response.status_code)

				if (error_response.length > 0) {
					const errorObject = {
						error_title: error_response[0].response_title, 
						error_message: error_response[0].response_message,
						error_details: vt_response.error
					}
					setError(errorObject)
				} else {
					const error_response = groupsUrlResponses.put.error_response.filter(error_item => error_item.status_code === "unknown")
					const errorObject = {
						error_title: error_response[0].response_title, 
						error_message: error_response[0].response_message,
						error_details: vt_response.error
					}
					setError(errorObject)
				}
			}				
		} else {
			handleUpdateVolumesListDialogClose()
			setCurrentAction("")
		}
	}

	const handleEditModeChange = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleGroupDetailTabChange("/gr-details")
			setEditMode(true)
		}
	}

	const handleEditModeReset = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setUpdateFormData({...selectedRow})
			handleGroupDetailTabChange("/gr-details")
			setEditMode(false)
		}			
	}
	
	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}
	
	const handleSuccessUpdateDialogClose = () => {
			setSuccessGroupUpdate(null)
			setUpdateFormData({...selectedRow})
			setSuccessUpdateDialogOpen(false);
	}
	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}
	}
	const handleConfirmDeleteDialogClose = () => {
			setConfirmDeleteDialogOpen(false)
	}

	const dataFormatting = (data) => {
			let update_data = {}
			for (let i in volumeGroupUpdateDataForm) {
					if (selectedGroup[volumeGroupUpdateDataForm[i].field_key] !== data[volumeGroupUpdateDataForm[i].field_key]) {
							update_data[volumeGroupUpdateDataForm[i].field_key] = data[volumeGroupUpdateDataForm[i].field_key]
					}
			}
			if (Object.keys(update_data).length > 0) {
					return update_data
			} else {
					return null
			}
			
	}

	const handleEditGroup = async () => {
			let updated_data = {...updateFormData}
			updated_data = dataFormatting(updated_data)
			if (updated_data) {
					
				const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/${selectedRow.id}`
				const method = "PUT"

				const vt_response = await volumeCinderRequest({
						url: url, 
						method: method, 
						data: {group: updated_data},
						token,
						has_response: false
				})
				if (vt_response.status_code === groupsUrlResponses.put.success_response.status_code) {
						handleFetchData()
						handleEditModeReset()
						setSuccessGroupUpdate({
								success_title: groupsUrlResponses.put.success_response.response_title, 
								success_message: groupsUrlResponses.put.success_response.response_message
						})
				} else {
						const error_response = groupsUrlResponses.put.error_response.filter(
								error_item => error_item.status_code === vt_response.status_code)
						if (error_response.length > 0) {
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: vt_response.error
								}
								setError(errorObject)
						} else {
								const error_response = groupsUrlResponses.put.error_response.filter(
										error_item => error_item.status_code === "unknown")
								const errorObject = {
										error_title: error_response[0].response_title, 
										error_message: error_response[0].response_message,
										error_details: vt_response.error
								}
								setError(errorObject)
						}
				}
					
			} else {
					handleEditModeReset()
			}

	}

	const handleGroupDetailTabChange = useCallback((navigation) => {
			let newVTSubmenuData = groupSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setGroupSubMenu(newVTSubmenuData)
			setCurrentTab(navigation)
	},[
			groupSubMenu,
			setGroupSubMenu
	])

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			groupActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			if (volumes.length > 0) {
					const volume_list = volumes.filter(v => 
							selectedRow && 
							Object.keys(selectedRow).includes("volumes") && 
							selectedRow.volumes.includes(v.id))
					setVolumesInGroup(volume_list)
					const v_ids = volume_list.map(v => v.id)
					let filtered_volumes = []
					if (parseFloat(maxAPIVersion) >= parseFloat(VOLUME_TYPE_ID_MIN_API)) {
							filtered_volumes = volumes.filter(
									v => !v_ids.includes(v.id) && 
									selectedRow.volume_types.includes(v.volume_type_id))
					} else {
							filtered_volumes = volumes.filter(
									v => !v_ids.includes(v.id) && 
									selectedRow.volume_types.includes(volumeTypes.filter(vt => vt.name === v.volume_type)[0].id)
							)
					}
					const volumes_select = filtered_volumes.map(v => {
							return {keyword: `${v.name} (${v.id})`, value: v.id, default: false}
					})
					setVolumesList(volumes_select)
			} else {
					setVolumesInGroup([])
					setVolumesList([])
			}
	},[
			volumes,
			selectedRow,
			maxAPIVersion,
			volumeTypes
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setSuccessUpdateDialogOpen(true)
	},[successGroupUpdate]);

	useEffect(() => {
			if (selectedRow !== null) {
					// const scoped_actions_allowed = adminProjects.map(p => p.id).includes(selectedRow.project_id)
					const scoped_actions_allowed = true
					let gr_actions = []
					let new_action = {}
					if (parseFloat(maxAPIVersion) >= parseFloat(VOLUME_LIST_MANIPULATION_API_VERSION)) {
							new_action["value"] = "update_volumes_list"
							new_action["action"] = handleUpdateVolumesListDialogOpen
							new_action["keyword"] = "updateVolumesListActionTitle"
							new_action["button_text"] = "selectButtonTitleText"
							gr_actions.push({...new_action})
							new_action = {}
					}
					new_action["value"] = "create_snapshot"
					new_action["keyword"] = "createGroupSnapshotActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					if (scoped_actions_allowed) {
							new_action["action"] = handleCreateSnapshotDialogOpen
					} else {
							new_action["action"] = handleSnapshotWarningMessageOpen
					}
					gr_actions.push({...new_action})
					
					setGroupActions(gr_actions)
			}
	},[
			selectedRow,
			defaultTexts,
			maxAPIVersion,
			adminProjects,
			handleSnapshotWarningMessageOpen,
			handleUpdateVolumesListDialogOpen
	]);

	useEffect(() => {
			if (Object.keys(snapshotData).length === 0) {
					let new_form_data = {}
					for (const n in groupDataUpdateForm) {
							new_form_data[groupDataUpdateForm[n].field_key] = ""
					}
					setSnapshotData(new_form_data)
			}
	},[snapshotData]);

	useEffect(() => {
		setCurrentTab('/gr-details')
		setGroupSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/gr-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={groupSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleGroupDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedGroup}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/gr-details" &&
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
						<Grid item xs={12} lg={6}>
							<WrapperBox sx={detailTabCardConfig}>
									{
										!editMode ? 
										<Stack
											spacing={2} 
											sx={{ p: 2, mt: 1, flexWrap: "wrap", width: "90%"}}
										>
											{volumeGroupDataSchema.map((field) => {
													let new_field = {...field}
													let value = selectedGroup ? 
													selectedGroup[field.field_key] : ""
													
													return (
															getDetailDataComponent({
																	fieldType: new_field.field_type,
																	fieldKey: new_field.field_key,
																	label: defaultTexts[new_field.label],
																	value: value,
																	textOnTrue: defaultTexts[new_field.value_on_true] ? 
																	defaultTexts[new_field.value_on_true] : 
																	defaultTexts.formValueYes,
																	textOnFalse: defaultTexts[new_field.value_on_false] ? 
																	defaultTexts[new_field.value_on_false] : 
																	defaultTexts.formValueNo,
																	defaultTexts: defaultTexts,
																	textColor: new_field.text_color,
																	onClick: new_field.onClick
															})
													)
											})}
											</Stack> : 
											<Stack 
													sx={{
															m: 2, 
															alignItems: 'start'
													}}>
											<CustomText 
													size="h6" 
													sx={{
															color: "primary.main", 
															mb: 2
													}}>
													{defaultTexts.updateGroupFormTitle}
											</CustomText>
											{getDataForm()}
											<Button 
													variant="contained"
													color="secondary"
													sx={{mt: 5}}
													onClick={handleEditGroup}
											>
													{defaultTexts.saveButtonText}
											</Button>
									</Stack>}
							</WrapperBox>
						</Grid>
					</Grid>
				}
							
				{
					currentTab === "/group-volume-types" && 
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
						<Grid item xs={12} lg={6}>
							<WrapperBox sx={detailTabCardConfig}>
								<Stack spacing={2} sx={{ p: 2, mt: 1, flexWrap: "wrap", width: "90%"}}>
									{
										selectedRow.volume_types.map((vt_id, index) => {
											const vt = volumeTypes.filter(v => v.id === vt_id)
											return (
													<Box 
															key={index}
															sx={{
																	display: "flex", 
																	flexDirection: "column",
																	color: "primary.main", 
																	justifyContent: "flex-start"
															}}
													>
																	<CustomText 
																			sx={{p: 1, textAlign: "start"}}
																	>
																					{vt[0].name}
																	</CustomText>
															<Divider />
													</Box>
											)
										})
									}
								</Stack>
							</WrapperBox>
						</Grid>
					</Grid>
				}

				{
					currentTab === "/group-volumes" && 
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
						<Grid item xs={12} lg={6}>
							<WrapperBox sx={detailTabCardConfig}>
								<Stack spacing={2} sx={{ p: 2, mt: 1, flexWrap: "wrap", width: "90%"}}>									
									{
										selectedRow.volumes.length > 0 && <Stack sx={{mt: 2}} spacing={1}>
											{
												selectedRow.volumes.map(v_id => {
													const vol = volumes.filter(v => v.id === v_id)
													return (
														<Box 
															key={vol[0].id}
															onClick={() => handleNavigateToVolume(vol[0].id, "/volumes")}
															sx={{
																	display: "flex", 
																	flexDirection: "column",
																	color: "primary.main", 
																	cursor: "pointer", 
																	justifyContent: "flex-end"
															}}
														>
															<CustomText 
																sx={{p: 1, textAlign: "start"}}
															>
																{vol[0].name}
															</CustomText>
															<Divider />
														</Box>
													)
												})
											}
										</Stack>
									}
									
									{selectedRow.volumes.length === 0 && <NoDataNote text={defaultTexts.noVolumesNoteText} />}
								</Stack>
							</WrapperBox>
						</Grid>
					</Grid>
				}
			</Box>
			
			<Paper sx={{ 
					position: 'fixed',
					width: `calc(100% - ${drawerWidth}px)`,
					bottom: 0, 
					height: '60px',
					left: `${drawerWidth}px`,
					borderTop: `${theme.palette.customGrayDark} 2px solid`,
					borderRadius: '0px',
					padding: '0px 20px 0px 12px',
				}}
			>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={groupActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[groupActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{!editMode ? <IconButton onClick={handleEditModeChange}>
													<EditIcon color="warning" />
											</IconButton> :
											<IconButton onClick={handleEditModeReset}>
													<EditOffIcon color="warning"/>
											</IconButton>
									}
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
			</Paper>

					<CustomDialog
							open={updateVolumesListDialogOpen}
							onClose={handleUpdateVolumesListDialogClose}
							dialogTitle={{
									title: defaultTexts.updateVolumesListDialogTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: "", 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.submitButtonText, 
									onClick: handleVolumesListUpdate, 
									variant: "contained",
									size: "small"
									}]}
					>
							<Stack direction="row" spacing={2} sx={{mb: 3}}>
											<CustomSelectField 
													items={volumesList}
													required={false}
													currentValue={selectedVolumes}
													setCurrentValue={setSelectedVolumes}
													label={defaultTexts.volumeFormFieldLabel}
													self_item_titles={true}
													empty={true}
													multiple={true}
													size="small"
													sx={{my: 1, width: "70%"}}
											/>
											<Button onClick={handleAddVolumesToList}>
													{defaultTexts.addToListButtonTitle}
											</Button>
							</Stack>
							{volumesInGroup.length > 0 && volumesInGroup.map(vol => {
									return (
											<Stack key={vol.id} direction="row" spacing={2} alignItems="center" sx={{mt: 1}}>
													<IconButton onClick={() => handleVolumeRemoveFromGroup(vol.id)}>
															<DeleteIcon 
																	color="primary"
															/>
													</IconButton>
													<CustomText>{`${vol.name} (${vol.id})`}</CustomText>
											</Stack>
									)
							})}
							{volumesInGroup.length === 0 &&
									<CustomText sx={{m: 1}}>{defaultTexts.noVolumesAddedToGroupNoteText}</CustomText>
							}              
					</CustomDialog>
					<CustomDialog
							open={createSnapshotDialogOpen}
							onClose={handleCreateSnapshotDialogClose}
							dialogTitle={{
									title: defaultTexts.createGroupSnapshotActionTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: "", 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.submitButtonText, 
									onClick: onSnapshotCreate,
									variant: "contained",
									size: "small"}]}
					>
							{getSnapshotDataForm(
									volumeGroupUpdateDataForm,
									snapshotDataOptions,
									snapshotData,
									handleSnapshotDataChange
							)}
					</CustomDialog>
					{successGroupUpdate && <CustomDialog
							open={successUpdateDialogOpen}
							onClose={handleSuccessUpdateDialogClose}
							dialogTitle={{
									title: defaultTexts[successGroupUpdate.success_title], 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: successGroupUpdate.success_details ?
											`<span>${defaultTexts[successGroupUpdate.success_message]}</span>
													<br>
													<br>
													<span>Secret Key:</span> 
													<span style="width: 100px; color: orange; word-wrap: break-word;">
															${successGroupUpdate.success_details}
													</span>` :
											`<span>${defaultTexts[successGroupUpdate.success_message]}</span>`, 
									sx: {color: 'text.primary'}}}
					/>}
					{error && <CustomDialog
							open={errorDialogOpen}
							onClose={handleErrorDialogClose}
							dialogTitle={{
									title: defaultTexts[error.error_title], 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: `<span>${defaultTexts[error.error_message]}</span>
													<br>
													<br>
													<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
													<span style="color: orange">
															${error.error_details}
													</span>`, 
									sx: {color: 'text.primary'}}}
					/>}
					{warningDialogOpen && <CustomDialog
							open={warningDialogOpen}
							onClose={handleWarningDialogClose}
							dialogTitle={{
									title: defaultTexts.warningDialogTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: `<span>${warningMessage}</span>
									<br>
									<br>`, 
									sx: {color: 'text.primary'}}}
					>
							{warning}
					</CustomDialog>
					}
					{confirmDeleteDialogOpen && <CustomDialog
							open={confirmDeleteDialogOpen}
							onClose={handleConfirmDeleteDialogClose}
							dialogTitle={{
									title: defaultTexts.deleteConfirmationDialogTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: defaultTexts.deleteVTConfirmationDialogMessage, 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.confirmButtonText, 
									onClick: () => {}, 
									sx: {color: 'primary.main'}}]}
					/>}
		</>
	)
}

export default GroupDetailV3