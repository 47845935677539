export const keypairDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    //{
    //    field_key: "type",
    //    is_id_field: false,
    //    field_type: "string", 
    //    label: "typeFormFieldLabel"
    //},
    {
        field_key: "fingerprint",
        is_id_field: false,
        field_type: "string", 
        label: "fingerprintFormFieldLabel"
    },
    {
        field_key: "public_key_details",
        is_id_field: false,
        field_type: "string", 
        label: "publicKeyFormFieldLabel"
    }
]

export const keypairDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    //{
    //    field_key: "type",
    //    field_type: "select", 
    //    required: false, 
    //    label: "typeFormFieldLabel",
    //    self_item_titles: true,
    //    default_empty: true,
    //    items: [
    //        {
    //            keyword: "SSH",
    //            value: "ssh",
    //            default: false
    //        },
    //        {
    //            keyword: "x509",
    //            value: "x509",
    //            default: false
    //        }
    //    ]
    //},
    //{
    //    field_key: "user_id",
    //    field_type: "select", 
    //    required: false, 
    //    label: "userFormFieldLabel",
    //    on_empty_null: true,
    //    self_item_titles: true,
    //    default_empty: true
    //},
    {
        field_key: "public_key",
        field_type: "string", 
        required: true, 
        multiline: true,
        minRows: 8,
        label: "publicKeyFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
]

export const keypairsFilterMenu = [
    {
        keyword: "userFormFieldLabel", 
        value: "user_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    }
]