import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../_common/WrapperBox';
import ImagesTableV2 from './imagesTableV2';
import CustomText from '../../../../_common/CustomText';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import SearchIcon from '@mui/icons-material/Search';
import { imagesGlanceConstants } from '../../../../../config/openStackConstants';
import { openstackRequest } from '../../../../../_network/openstack_request';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';
import { openStackServices } from '../../../../../config/openStackConstants';
import { imageFilterMenu, imageMeasureMenu } from '../../../../../_data/openstack/glance/images/v2';
import CustomDialog from '../../../../_common/CustomDialog';
import ImageDetailV2 from './imageDetailV2';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ImageAddV2 from './imageAddV2';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import CustomPopover from '../../../../_common/CustomPopover'
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.imageService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.8
    } else {
        return 0.8
    }
}

const ImagesWrapperV2 = (props) => {
	const theme = useTheme()

	const imageRef = useRef();
	const [isLoading, setIsLoading] = useState(true)
	const [imagesData, setImagesData] = useState([])
	const [imagesFormattedData, setImagesFormattedData] = useState([])
	const filterMenu = imageFilterMenu
	const [error, setError] = useState()
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const { width } = useWindowDimensions();
	const WIDTH_WEIGHT = getWidthWeight(width)
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null)
	const [selectedImageForMetadata, setSelectedImageForMetadata] = useState(null)

	const [filterQueryParams, setFilterQueryParams] = useState("")
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const token = useSelector(state => state.profile.x_auth_token)
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	
	const [fetchDataRequired, setFetchDataRequired] = useState(true);
	const [imageFile, setImageFile] = useState(null)
	const [fileUploadRequired, setFileUploadRequired] = useState(false);
	const [imageRequiredFileUpload, setImageRequiredFileUpload] = useState(null);
	const [imageDeleteConfirmDialogOpen, setImageDeleteConfirmDialogOpen] = useState(false);
	const [selectedImages, setSelectedImages] = useState([])
	const [currentAction, setCurrentAction] = useState("");
	const [selectedImageIds, setSelectedImageIds] = useState([])
	const [searchBy, setSearchBy] = useState(filterMenu.length > 0 ? filterMenu[0].value : '')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [sortParams, setSortParams] = useState("")
	const [deselectAll, setDeselectAll] = useState(false)
	const [publicImageIds, setPublicImageIds] = useState([])

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const imageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const imageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const imagesUrl = useSelector(
			state => state.imageGlance.imageGlanceApiUrls.filter(
					version => version.api_version === "v2")[0].urls.filter(
							url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)
	
	const getSearchType = () => {
		const filterObject = filterMenu.find(item => item.value === searchBy)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		const filterObject = filterMenu.find(item => item.value === searchBy)
		return filterObject ? filterObject.items : []
	}

	const isValidSelectValue = (value, list) => {
		const findObject = list.find(item => item.value === value)
		return findObject ? true : false
	}

	const handleImageSorting = (field, direction) => {
		const sort_param = `sort_key=${field}&&sort_dir=${direction}`
		setSortParams(sort_param)
		handleDataFetch()
	}
	
	const handleDataFetch = () => {
		setFetchDataRequired(true)
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handleFilteredSearch = () => {
		if (searchBy && searchKeyword) {
			if (searchBy === 'size') {
				let sizeMin = searchKeyword.min ? parseInt(searchKeyword.min) : 0
				let sizeMax = searchKeyword.max ? parseInt(searchKeyword.max) : 0

				if (searchKeyword.measure && searchKeyword.measure === 'kb') {
					sizeMin = sizeMin * 1024
					sizeMax = sizeMax * 1024
				}

				if (searchKeyword.measure && searchKeyword.measure === 'mb') {
					sizeMin = sizeMin * 1024 * 1024
					sizeMax = sizeMax * 1024 * 1024
				}

				if (sizeMin && sizeMax) {
					setFilterQueryParams(`?size_min=${sizeMin}&size_max=${sizeMax}`)
				} else if (sizeMin) {
					setFilterQueryParams(`?size_min=${sizeMin}`)
				} else if (sizeMax) {
					setFilterQueryParams(`?size_max=${sizeMax}`)
				}				
			} else {
				setFilterQueryParams(`?${searchBy}=${searchKeyword}`)
			}
		} else {
			setFilterQueryParams('')
		}
	}
	
	const handleFilterReset = () => {
			setSearchKeyword('')
			setSearchBy(filterMenu[0].value)
			setFilterQueryParams("")
			setSortParams('')
			handleDataFetch()
	}
	
	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleDetailCardOpen = (index) => {
		setSelectedRow(imagesData[index])
		setSelectedImage(imagesFormattedData.filter(item => item.id === imagesData[index].id)[0])
	}

	const handleDetailCardClose = () => {
		setSelectedRow(null)
	}

	const handleImageDelete = async (image) => {
		const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}/${image.id}`
		const method = "DELETE"			
		
		const image_response = await openstackRequest({ url, method, token, })

		if (image_response.status_code === imagesUrlResponses.delete.success_response.status_code) {
			return null
		} else {
			return image_response.error
		}
	}

	const onImageDelete = async () => {
		handleImageDeleteConfirmDialogClose()
		setIsLoading(true)

		let err = []
		
		for (let i in selectedImages) {
			if (selectedImages[i].owner === defaultAdminProject) {
				const resp = await handleImageDelete(selectedImages[i])
				if (resp !== null) {
					err = [...err, resp]
				}
			}
		}

		setIsLoading(false)
		handleDataFetch()

		if (err.length > 0) {
			let error_object = {}
			error_object["error_title"] = "errorDeleteRecordTitle"
			error_object["error_message"] = "errorDeleteRecordMessage"
			error_object["error_details"] = err.toString()
			setError(error_object)
			setErrorDialogOpen(true)
		}
	}

	const onImageDeleteConfirm = (image_list) => {
		const selected_image_list = imagesData.filter(img => image_list.includes(img.id))
		setSelectedImages([...selected_image_list])
		setImageDeleteConfirmDialogOpen(true)
	}

	const handleImageDeleteConfirmDialogClose = () => {
			setImageDeleteConfirmDialogOpen(false)
	}

	const getImagesActionsList = () => {
		let image_actions = []
		let new_action = {}
		new_action["value"] = "image_delete"
		new_action["action"] = onImageDeleteConfirm
		new_action["keyword"] = "imageDeleteActionTitle"
		new_action["button_text"] = "applyButtonTitleText"
		image_actions.push({...new_action})
		
		return image_actions
	}

	const handleActionRun = () => {
		const selected_image_list = imagesData.filter(item => selectedImageIds.includes(item.id))
		setSelectedImages([...selected_image_list])
		setImageDeleteConfirmDialogOpen(true)
	}

	const getTextedDateTime = (date) => {
		const jsDate = new Date(date)	
		return ('0' + jsDate.getDate()).slice(-2) + '.' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '.' + jsDate.getFullYear() + ' ' + ('0' + jsDate.getHours()).slice(-2) + ':' + ('0' + jsDate.getMinutes()).slice(-2) + ':' + ('0' + jsDate.getSeconds()).slice(-2)
	}

	useEffect(() => {
		setFetchDataRequired(true)
		setSelectedImage(null)
		setSelectedRow(null)
	},[filterQueryParams, defaultAdminProject, token]);

	useEffect(() => {
		if (fetchDataRequired) {
		(async () => {
				handleLoading(true)
				const firstSymbol = filterQueryParams ? '&&' : '?'
				const sort_params = sortParams ? firstSymbol + sortParams : ''

				const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}${filterQueryParams}${sort_params}`
				const method = "GET"
				
				const images_response = await openstackRequest({ url, method, token })
				
				if (images_response.status_code === imagesUrlResponses.get.success_response.status_code) {
					if (selectedRow) {
						const not_hidden = images_response.data.images.filter(image => image.id === selectedRow.id)

						if (not_hidden.length === 0) {
							handleDetailCardClose()
						}

						setSelectedImageForMetadata(images_response.data.images.filter(image => image.id === selectedRow.id)[0])
					}

					setImagesData(images_response.data.images)
				} else {
					const error_response = imagesUrlResponses.get.error_response.filter(
						error_item => error_item.status_code === images_response.status_code)
					if (error_response.length > 0) {
						const errorObject = {
							error_title: error_response[0].response_title, 
							error_message: error_response[0].response_message,
							error_details: images_response.error
						}
						setError(errorObject)
					} else {
						const error_response = imagesUrlResponses.get.error_response.filter(
							error_item => error_item.status_code === "unknown")
						const errorObject = {
							error_title: error_response[0].response_title, 
							error_message: error_response[0].response_message,
							error_details: images_response.error
						}
						setError(errorObject)
					}
				}
				
				
		})();

		
		handleLoading(false)
		}
		
		setTimeout(()=>{setFetchDataRequired(false)},700)
		setTimeout(()=>{handleLoading(false)}, 700)
	},[
			imageServiceDomain, 
			imageServiceVersion, 
			imagesUrl, 
			filterQueryParams,
			fetchDataRequired,
			defaultAdminProject,
			selectedRow,
			sortParams,
			token,
	])

	useEffect(() => {
		let data_to_update = [...imagesData]

		if (imagesData.length > 0) {
			const handleImagesDataFormatting = (data) => {
				const updated_data = data.map(item => {
					let new_item = {...item}
					
					if (item.status === "active") {
						new_item["status_icon"] = <TaskAltIcon color="success" />
					} else {
						new_item["status_icon"] = <ErrorOutlineIcon color="warning" />
					}

					if (item.size < 1024) {
						new_item.size = item.size
					} else if (item.size < 1048576) {
						new_item.size = `${parseInt(item.size / 1024)} KB`
					} else if (item.size < 1073741824) {
						new_item.size = `${parseInt(item.size / (1024 * 1024))} MB`
					} else {
						new_item.size = `${parseInt(item.size / (1024 * 1024 * 1024))} GB`
					}

					new_item.status = item.status.charAt(0).toUpperCase() + item.status.slice(1)
					new_item.visibility = item.visibility.charAt(0).toUpperCase() + item.visibility.slice(1)
					new_item.created_at = getTextedDateTime(new_item.created_at)
					new_item.updated_at = getTextedDateTime(new_item.updated_at)

					if (Object.keys(item).includes("block_device_mapping") && item.block_device_mapping.length > 0) {
						const mapping = JSON.parse(item.block_device_mapping)
						new_item.type = mapping[0].source_type === "snapshot" ? mapping[0].source_type.toUpperCase() : "IMAGE"
					} else {
						new_item.type = "IMAGE"
					}

					return new_item
				})

				return updated_data
			}

			data_to_update = handleImagesDataFormatting(data_to_update)				
		}

		if (imageRequiredFileUpload) {
			data_to_update = data_to_update.map(image => {
				if (image.id === imageRequiredFileUpload.id && image.status !== "active") {
					let new_item = {...image}
					new_item.status_icon = <WrapperBox>
							<CircularProgress color="info" style={{ padding: '10px', margin: '-10px 0px -10px -35px' }} />
						</WrapperBox>
					return new_item
				} else {
					return image
				}
			})
		}
		
		setImagesFormattedData(data_to_update)
		
		if (selectedRow !== null) {
				setSelectedImage(data_to_update.filter(item => item.id === selectedRow.id)[0])
		}
	},[
		imagesData,
		imageRequiredFileUpload,
		defaultTexts,
		selectedRow
	])

	useEffect(() => {
		let public_images_ids = []

		imagesData.forEach(item => {
			if (item.visibility !== 'private' || item.owner !== defaultAdminProject) {
				public_images_ids.push(item.id)
			}
		})

		setPublicImageIds(public_images_ids)
	}, [imagesData, defaultAdminProject])

	useEffect(() => {
		setErrorDialogOpen(true)
	}, [error])

	const handleImageFileRemove = () => {
			setImageFile(null)
			setImageRequiredFileUpload(null)
	}

	useEffect(() => {
		if (fileUploadRequired && imageRequiredFileUpload && imageFile) {
			(async () => {
				const file_url = `${imageServiceDomain}${imageRequiredFileUpload.file}`
				const file_method = "PUT"
				const file_headers = {}
				file_headers["Content-Type"] = "application/octet-stream"
				const fileFormData = new FormData();
				fileFormData.append('file', imageFile);

				openstackRequest({
					url:file_url, 
					method:file_method, 
					headers: file_headers,
					token,
					form_data: fileFormData}).then(response => {
						handleDataFetch()
						handleImageFileRemove()
				})
			})()
		}
	},[
		fileUploadRequired,
		defaultAdminProject,
		imageServiceDomain,
		imageRequiredFileUpload,
		imageFile,
		token,
	])

	useEffect(() => {
		setSearchKeyword('')
	}, [searchBy])

	useEffect(() => {
		setSelectedImageForMetadata(selectedRow)
	}, [selectedRow])

	const handleDeselectAll = () => {
		setSelectedImageIds([])
		setSelectedImages([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedImageIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getImagesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}
				
				<ImageAddV2
					handleFetchData={handleDataFetch}
					imageFile={imageFile}
					setImageFile={setImageFile}
					fileUploadRequired={fileUploadRequired}
					setFileUploadRequired={setFileUploadRequired}
					imageRequiredFileUpload={imageRequiredFileUpload}
					setImageRequiredFileUpload={setImageRequiredFileUpload}
					handleImageFileRemove={handleImageFileRemove}
					ref={imageRef}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={searchKeyword}
							setCurrentValue={e => setSearchKeyword(e.target.value)}
							sx={{ width: width >= 900 ? '430px' : '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={ isValidSelectValue(searchKeyword, getSearchSelectItems()) ? searchKeyword : '' }
							setCurrentValue={setSearchKeyword}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							sx={{ width: width >= 900 ? '430px' : '240px' }}
						/>
					}

					{
						getSearchType() === 'size-integer' &&
						<Stack
							direction={ width >= 900 ? 'row' : 'column' }
							justifyContent="flex-start"
							alignItems="flex-start"
						>
							<CustomTextField
								type='number'
								size="small"
								label={defaultTexts.minLabel}
								variant="outlined"
								currentValue={ searchKeyword.min ? searchKeyword.min : '' }
								setCurrentValue={e => setSearchKeyword(prev => { return { ...prev, min: e.target.value } })}
								sx={{
									width: width >= 900 ? '120px' : '240px',
									marginBottom: width >= 900 ? '0px' : '10px',
								}}
							/>
							<CustomTextField
								type='number'
								size="small"
								label={defaultTexts.maxLabel}
								variant="outlined"
								currentValue={ searchKeyword.max ? searchKeyword.max : '' }
								setCurrentValue={e => setSearchKeyword(prev => { return { ...prev, max: e.target.value } })}
								sx={{
									width: width >= 900 ? '120px' : '240px',
									marginLeft: width >= 900 ? '5px' : '0px',
									marginBottom: width >= 900 ? '0px' : '10px',
								}}
							/>
							<CustomSelectField
								items={imageMeasureMenu}
								currentValue={ searchKeyword.measure ? searchKeyword.measure : imageMeasureMenu[0].value }
								setCurrentValue={e => setSearchKeyword(prev => { return { ...prev, measure: e } })}
								item_titles={defaultTexts}
								empty={true}
								size="small"
								sx={{
									width: width >= 900 ? '180px' : '240px',
									marginLeft: width >= 900 ? '5px' : '0px',
									marginBottom: width >= 900 ? '0px' : '10px',
								}}
							/>
						</Stack>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={filterMenu} 
						currentValue={searchBy}
						setCurrentValue={setSearchBy}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}
	
	const getImagesHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 10px 20px',
						width: "100%"
					}}
				>
					<CustomText sx={{ color: theme.palette.primary.main, fontSize: '17px' }}>
						{defaultTexts.submenuImages} ({selectedImageIds.length}/{imagesFormattedData.length})
					</CustomText>

					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedImageIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getImagesTable = () => {
		return (
			<ImagesTableV2 
				imagesData={imagesFormattedData}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getImagesActionsList()}
				setSelectedImageIds={setSelectedImageIds}
				setImagesData={setImagesFormattedData}
				sortParams={sortParams}
				sortHandler={handleImageSorting}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				publicImageIds={publicImageIds}
				isSuspended={isSuspended}
			/>
		)
	}

	const getImagesContent = () => {
		return (
			<>
				{ getImagesHeader() }
				{ getImagesTable() }
			</>
		)
	}

	const getImageDetails = () => {
		return (
			<ImageDetailV2
				selectedRow={selectedRow}
				selectedImage={selectedImage}
				widthWeight={WIDTH_WEIGHT}
				handleDataFetch={handleDataFetch}
				handleDrawerClose={handleDetailCardClose}
				imagesData={imagesData}
				setImageRequiredFileUpload={setImageRequiredFileUpload}
				setImageFile={setImageFile}
				setFileUploadRequired={setFileUploadRequired}
				ref={imageRef}
				onImageDeleteConfirm={onImageDeleteConfirm}
				setSelectedRow={setSelectedRow}
				setSelectedImage={setSelectedImage}
				selectedImageForMetadata={selectedImageForMetadata}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getImagesContent()} contentBottom={getImageDetails()} />
			}

			<Box>
				{isLoading && <CustomBackdrop open={isLoading} />}

				{ selectedRow === null && getImagesHeader() }

				{ (selectedRow === null && !isLoading) && getImagesTable() }

				<CustomDialog
					open={imageDeleteConfirmDialogOpen}
					onClose={handleImageDeleteConfirmDialogClose}
					dialogTitle={{
						title: defaultTexts.imageDeleteConfirmTitle, 
						sx: {color: 'primary.main'}}}
					dialogBody={{
						text: `${defaultTexts.imageDeleteConfirmText}: [${selectedImages.filter(s => s.owner === defaultAdminProject).map(s => s.name).toString()}]`, 
						sx: {color: 'text.primary'}}}
					actionButtons={[{
						title: defaultTexts.confirmButtonText, 
						onClick: onImageDelete, 
						sx: {color: 'primary.main'}}]}
				/>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
							title: defaultTexts[error.error_title], 
							sx: {color: 'primary.main'}}}
						dialogBody={{
							text: `<span>${defaultTexts[error.error_message]}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default ImagesWrapperV2