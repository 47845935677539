export const computeLimitsUpdateDataForm = [
  {
      field_key: "cpu_cores",
      field_type: "integer", 
      required: false,
      min: -1,
      label: "cpuCoresTitleText"
  },
  {
      field_key: "ram",
      field_type: "integer", 
      required: false,
      min: -1,
      label: "ramTitleText"
  },
  {
    field_key: "server_instances",
    field_type: "integer", 
    required: false,
    min: -1,
      label: "serverInstancesTitleText"
  },
  {
    field_key: "total_allowed_keypairs",
    field_type: "integer", 
    required: false,
    min: -1,
      label: "totalAllowedKeypairsTitleText"
  },
]