import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import WrapperBox from '../../_common/WrapperBox';
import NotPurchasedService from '../../_common/NotPurchasedService';
import { computeNovaConstants } from '../../../config/openStackConstants';
import ComputeNovaContentV21 from './v2_1/ComputeNovaContentV2_1';
import { openStackServices } from '../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.computeService
const SERVICE_TITLE = 'Compute (Nova)'

const ComputeNovaWrapper = (props) => {
	const { section } = props
	
	const isAuthenticated = useSelector(state => state.profile.isAuthenticated)

	const navigate = useNavigate()
	const location = useLocation()

	if (!isAuthenticated) {
			navigate('/');
	}

	const purchasedServices = useSelector(state => 
			state.openstack.purchasedServices);
	
	const purchasedService = purchasedServices.filter(
			item => item.service === SERVICE_NAME)
	if (purchasedService.length === 0) {
			return (
					<CustomDrawer>
							<NotPurchasedService service={SERVICE_TITLE} />
					</CustomDrawer>
			)
	}

	if (!computeNovaConstants.supported_api_versions.includes(
			purchasedService[0].config_params.api_version)) {
			return (
					<CustomDrawer>
							<WrapperBox>
									Configured API Version for {SERVICE_TITLE} is not supported!
							</WrapperBox>
					</CustomDrawer>
			)
	}

	if (purchasedService[0].config_params.api_version === "v2.1") {
			return (
					<CustomDrawer>
							<ComputeNovaContentV21 
									serviceTitle={SERVICE_TITLE} 
									serviceName={SERVICE_NAME}
									navigate={navigate}
									location={location}
									section={section}
							/>
					</CustomDrawer>
			)
	}
}

export default ComputeNovaWrapper