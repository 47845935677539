import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { GoCopy } from "react-icons/go";
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import CustomDialog from '../../../../_common/CustomDialog';

const detailTabCardConfig = {
	border: 0,
	borderBottom: 0.3,
	borderStyle: "dashed",
	borderBottomColor: "text.secondary",
	height: 100,
	minWidth: 150,
	alignItems: "start",
	pl: 3
}

const SERVICE_NAME = openStackServices.volumeService

const VolumSnapshotsV3 = (props) => {
	const { volumeSnapshots, handleDataFetch, handleNavigateToSnapshot } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const token = useSelector(state => state.profile.x_auth_token)
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const theme = useTheme();

	const [selectedSnapshots, setSelectedSnapshots] = useState([])
	const [snapshotDeleteConfirmDialogOpen, setSnapshotDeleteConfirmDialogOpen] = useState(false);

	const cinderServiceDomain = useSelector(
		state => state.openstack.purchasedServices.filter(
		service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
		state => state.openstack.purchasedServices.filter(
		service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const snapshotsUrl = useSelector(
		state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
				version => version.api_version === "v3")[0].urls.filter(
						url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)

	const handleSnapshotDelete = async (s_id) => {			
		const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${s_id}`
		const method = "DELETE"
		
		const vt_response = await volumeCinderRequest({
				url:url, 
				method:method,
				token,
		})

		if (vt_response.status_code === snapshotUrlResponses.delete.success_response.status_code) {
				return true
		} else {
				return false
		}			
	}

	const handleSnapshotDeleteConfirmDialogOpen = (snapshot) => {
		setSnapshotDeleteConfirmDialogOpen(true)
		setSelectedSnapshots([snapshot])
	}

	const handleSnapshotDeleteConfirmDialogClose = () => {
		setSnapshotDeleteConfirmDialogOpen(false)
		setSelectedSnapshots([])
	}

	const onSnapshotDelete = async () => {
		handleSnapshotDeleteConfirmDialogClose()
		if (selectedSnapshots.length > 0) {
			await handleSnapshotDelete(selectedSnapshots[0].id)
		}			
		handleDataFetch()
	}

	return (
			<React.Fragment>
					<WrapperBox
							sx={detailTabCardConfig}
					>
							<Stack
									direction="row" 
									alignItems="center" 
									justifyContent="center"
									spacing={4}
							>
							<GoCopy
									size={28}
							/> 
									<CustomText 
											size="p" 
											sx={{fontSize: {xs: 16, lg: 18}}}
									>
											{defaultTexts.volumeSnapshotsTitleText}
									</CustomText>
									<CustomText 
											size="h4" 
											sx={{
													color: theme.palette.dashboardPink
											}}
									>
											{volumeSnapshots.length}
									</CustomText>
							</Stack>
					</WrapperBox>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} md={12}>
									<TableContainer>
											<Table sx={{ width: "100%" }}>
													<TableHead>
															<TableRow>
																	<TableCell></TableCell>
																	<TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.sizeFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.statusFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.createdFormFieldLabel}</TableCell>
																	<TableCell align="left">{defaultTexts.updatedAtFormFieldLabel}</TableCell>
															</TableRow>
													</TableHead>
													<TableBody>
													{volumeSnapshots.map((row, index) => (
															<TableRow
																	key={index}
																	hover
																	sx={{ cursor: 'pointer' }}
															>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																	>
																			<IconButton onClick={() => handleSnapshotDeleteConfirmDialogOpen(row)}>
																					<DeleteIcon color="error" />
																			</IconButton>
																	</TableCell>                                    
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																			onClick={() => handleNavigateToSnapshot(row.id, '/snapshots')}
																	>
																			{row.name}
																	</TableCell>
																	<TableCell 
																			align="left"
																			onClick={() => handleNavigateToSnapshot(row.id, '/snapshots')}
																	>
																			{`${row.size} GB`}
																	</TableCell>
																	<TableCell 
																			component="th" 
																			align="left"
																			scope="row"
																			onClick={() => handleNavigateToSnapshot(row.id, '/snapshots')}
																	>
																			{row.status}
																	</TableCell>
																	<TableCell 
																			align="left"
																			onClick={() => handleNavigateToSnapshot(row.id, '/snapshots')}
																	>
																			{new Date(row.created_at).toLocaleString()}
																	</TableCell>
																	<TableCell 
																			align="left"
																			onClick={() => handleNavigateToSnapshot(row.id, '/snapshots')}
																	>
																			{new Date(row.updated_at).toLocaleString()}
																	</TableCell>
															</TableRow>
													))}
													</TableBody>
											</Table>
									</TableContainer>
					</Grid>
			</Grid>

			<CustomDialog
				open={snapshotDeleteConfirmDialogOpen}
				onClose={handleSnapshotDeleteConfirmDialogClose}
				dialogTitle={{
						title: defaultTexts.snapshotDeleteConfirmTitle, 
						sx: {color: 'primary.main'}}}
				dialogBody={{
						text: `${defaultTexts.snapshotDeleteConfirmText}: [${selectedSnapshots.map(v => v.name).toString()}]`, 
						sx: {color: 'text.primary'}}}
				actionButtons={[{
						title: defaultTexts.confirmButtonText, 
						onClick: onSnapshotDelete, 
						sx: {color: 'primary.main'}}]}
			/>
	</React.Fragment>
	)
}

export default VolumSnapshotsV3