
import { createSlice } from '@reduxjs/toolkit'
import { volumeTypeDataSchema as volumeTypeDataSchemaV3 } from 
"../../_data/openstack/cinder/volume_types/v3";
import {qosSpecsDataSchema as qosSpecsDataSchemaV3} from 
"../../_data/openstack/cinder/volume_types/v3";
import { defaultVolumeTypeSchema as defaultVolumeTypeSchemaV3} from
"../../_data/openstack/cinder/volume_types/v3";
import { volumeDataSchema as volumeDataSchemaV3 } from 
'../../_data/openstack/cinder/volumes/v3';
import { volumeTransfersSchema as volumeTransfersSchemaV3 } from 
'../../_data/openstack/cinder/volumes/v3';
import { snapshotDataSchema as snapshotDataSchemaV3 } from 
'../../_data/openstack/cinder/snapshots/v3';
import { backupDataSchema as backupDataSchemaV3 } from 
'../../_data/openstack/cinder/backups/v3';
import { volumeGroupDataSchema as volumeGroupDataSchemaV3 } from 
'../../_data/openstack/cinder/groups/v3';
import { groupSnapshotDataSchema as groupSnapshotDataSchemaV3 } from 
'../../_data/openstack/cinder/group_snapshots/v3';
import { groupTypeDataSchema as groupTypeDataSchemaV3 } from 
'../../_data/openstack/cinder/group_types/v3';

const initialState = {
    blockStorageCinderApiUrls: [
      {
        api_version: "v3",
        max_api_version: null,
        urls: [
          {id: "", keyword: 'volumesUrl', url: "volumes", api_group: 'volumesApiUrls'},
          {id: "", keyword: 'attachmentsUrl', url: "attachments", api_group: 'volumesApiUrls'},
          {id: "", keyword: 'volumeTypesUrl', url: "types", api_group: 'volumeTypesApiUrls'},
          {id: "", keyword: "volumeTransfersUrl", url: "volume-transfers", api_group: 'volumesApiUrls'},
          {id: "", keyword: 'defaultVolumeTypesUrl', url: "default-types", api_group: 'volumeTypesApiUrls'},
          {id: "", keyword: 'snapshotsUrl', url: "snapshots", api_group: 'snapshotsApiUrls'},
          {id: "", keyword: 'backupsUrl', url: "backups", api_group: 'backupsApiUrls'},
          {id: "", keyword: 'clustersUrl', url: "clusters", api_group: 'clustersApiUrls'},
          {id: "", keyword: 'groupsUrl', url: "groups", api_group: 'groupsApiUrls'},
          {id: "", keyword: 'groupSnapshotsUrl', url: "group_snapshots", api_group: 'groupsApiUrls'},
          {id: "", keyword: 'groupTypesUrl', url: "group_types", api_group: 'groupsApiUrls'},
          {id: "", keyword: 'limitsUrl', url: "os-quota-sets", api_group: 'limitsApiUrls'},
          {id: "", keyword: 'qosSpecsUrl', url: "qos-specs", api_group: 'qosSpecsApiUrls'},
          {id: "", keyword: 'quotasUrl', url: "os-quota-sets", api_group: 'quotasApiUrls'}
        ]
      }
    ],
    volumesTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...volumeDataSchemaV3]
      }
    ],
    volumeTypesTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...volumeTypeDataSchemaV3]
      }
    ],
    qosSpecsTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...qosSpecsDataSchemaV3]
      }
    ],
    defaultVolumeTypesTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...defaultVolumeTypeSchemaV3]
      }
    ],
    volumeTransfersTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...volumeTransfersSchemaV3]
      }
    ],
    snapshotsTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...snapshotDataSchemaV3]
      }
    ],
    backupsTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...backupDataSchemaV3]
      }
    ],
    volumeGroupsTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...volumeGroupDataSchemaV3]
      }
    ],
    groupSnapshotsTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...groupSnapshotDataSchemaV3]
      }
    ],
    groupTypesTableConfig: [
      {
        api_version: "v3",
        rows_per_page: 10,
        columns: [...groupTypeDataSchemaV3]
      }
    ],
  }
  
  const blockStorageCinderSlice = createSlice({
    name: 'blockStorageCinder',
    initialState,
    reducers: {
      setBlockStorageCinderApiUrls: (state, action) => {
          state.blockStorageCinderApiUrls = action.payload.blockStorageCinderApiUrls
      },
      setVolumesTableConfig: (state, action) => {
        state.volumesTableConfig = action.payload.volumesTableConfig
      },
      setVolumeTypesTableConfig: (state, action) => {
        state.volumeTypesTableConfig = action.payload.volumeTypesTableConfig
      },
      setQoSSpecsTableConfig: (state, action) => {
        state.qosSpecsTableConfig = action.payload.qosSpecsTableConfig
      },
      setDefaultVolumeTypesTableConfig: (state, action) => {
        state.defaultVolumeTypesTableConfig = action.payload.defaultVolumeTypesTableConfig
      },
      setVolumeTransfersTableConfig: (state, action) => {
        state.volumeTransfersTableConfig = action.payload.volumeTransfersTableConfig
      },
      setSnapshotsTableConfig: (state, action) => {
        state.snapshotsTableConfig = action.payload.snapshotsTableConfig
      },
      setBackupsTableConfig: (state, action) => {
        state.backupsTableConfig = action.payload.backupsTableConfig
      },
      setVolumeGroupsTableConfig: (state, action) => {
        state.volumeGroupsTableConfig = action.payload.volumeGroupsTableConfig
      },
      setGroupSnapshotsTableConfig: (state, action) => {
        state.groupSnapshotsTableConfig = action.payload.groupSnapshotsTableConfig
      },
      setGroupTypesTableConfig: (state, action) => {
        state.groupTypesTableConfig = action.payload.groupTypesTableConfig
      },
      blockStorageCinderApiUrlsReset: () => {
        return {...initialState}
      }
    }
  })
  
  export const { 
        setBlockStorageCinderApiUrls,
        setVolumesTableConfig,
        setVolumeTypesTableConfig,
        setQoSSpecsTableConfig,
        setDefaultVolumeTypesTableConfig,
        setVolumeTransfersTableConfig,
        setSnapshotsTableConfig,
        setBackupsTableConfig,
        setVolumeGroupsTableConfig,
        setGroupSnapshotsTableConfig,
        setGroupTypesTableConfig,
        blockStorageCinderApiUrlsReset
  } = blockStorageCinderSlice.actions
  
  export default blockStorageCinderSlice.reducer