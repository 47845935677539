import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { networkDataForm, networkTypeFieldsForm } from '../../../../../../_data/openstack/neutron/networks/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } from '../../../../../../_api_responses/openstack/neutron/networks/v2.0';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';
import CustomText from '../../../../../_common/CustomText';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const NetworkAdd = (props) => {
  const { handleDataFetch } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successNetworkAdd, setSuccessNetworkAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [supportSegments, setSupportSegments] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState([])

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const networksUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.networksUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessNetworkAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleSupportSegmentsChange = (event) => {
      setSupportSegments(event.target.checked)
  }

  const handleAddNetworkFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in networkDataForm) {
          if (
              networkDataForm[n].field_type === "string" || 
              networkDataForm[n].field_type === "select"
              ) {
              new_form_data[networkDataForm[n].field_key] = ""
          } else if (networkDataForm[n].field_type === "bool") {
              new_form_data[networkDataForm[n].field_key] = networkDataForm[n].default_value ? 
              networkDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
      setSupportSegments(false)
      setSelectedSegments([])
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (networkDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (networkDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  const getDataForm = () => {
      let form = [...networkDataForm]
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }


  const handleNetTypeFormDataChange = (event,field_key,index) => {
      let new_form_data = [...selectedSegments]
      if (networkTypeFieldsForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[index][field_key] = event.target.checked
      } else if (networkTypeFieldsForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[index][field_key] = event
      } else {
          new_form_data[index][field_key] = event.target.value
      }
      setSelectedSegments(new_form_data)
  }

  const handleSelectedSegmentAdd = () => {
      let new_form_data = {}
      for (const n in networkTypeFieldsForm) {
          if (
              networkTypeFieldsForm[n].field_type === "string" || 
              networkTypeFieldsForm[n].field_type === "select"
              ) {
              new_form_data[networkTypeFieldsForm[n].field_key] = ""
          } else if (networkTypeFieldsForm[n].field_type === "bool") {
              new_form_data[networkTypeFieldsForm[n].field_key] = networkTypeFieldsForm[n].default_value ? 
              networkTypeFieldsForm[n].default_value : 
              false
          }
      }
      const updated_data = [...selectedSegments]
      updated_data.push(new_form_data)
      setSelectedSegments(updated_data)
  }

  const handleSelectedSegmentRemove = () => {
      let new_data = [...selectedSegments]
      new_data.pop()
      setSelectedSegments(new_data)
  }

  const getNetTypeForm = (data,index) => {
          const item = selectedSegments[index]
          let form = []
          if (item["provider:network_type"] === "vlan") {
              form = [...networkTypeFieldsForm]
          } else if (item["provider:network_type"] === "gre" ||
              item["provider:network_type"] === "vxlan" ||
              item["provider:network_type"] === "geneve"
          ) {
              form = networkTypeFieldsForm.filter(item => item.field_key !== "provider:physical_network")
          }  else if (item["provider:network_type"] === "flat") {
              form = networkTypeFieldsForm.filter(item => item.field_key !== "provider:segmentation_id")
          } else {
              form = networkTypeFieldsForm.filter(item => item.field_key === "provider:network_type")
          }

          return (
              <FormGroup>
              <CustomText sx={{color: "text.secondary"}}>{defaultTexts.segmentFormFieldTitle} {index + 1}</CustomText>
                  {form.map((field) => {
                      let form_field_options = {}
                      if (field.field_key === "provider:network_type") {
                          form_field_options["items"] = [...field.items]
                          form_field_options["self_item_titles"] = false
                          form_field_options["item_titles"] = defaultTexts
                      }
                      form_field_options["index"] = index
                      return (
                          getFormFieldComponent(
                              field,
                              data,
                              handleNetTypeFormDataChange,
                              defaultTexts[field.label],
                              {...form_field_options}
                          )
                      )
                  })}
              </FormGroup>
          )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in networkDataForm) {
          if (networkDataForm[n].required && !formData[networkDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[networkDataForm[n].field_key] = {}
              updatedDataFormOptions[networkDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[networkDataForm[n].field_key]["errorText"] = defaultTexts[networkDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddNetwork = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          let data = {...formData}
          if (supportSegments) {
              data["segments"] = selectedSegments
          }

          const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
          const method = "POST"
          const network_response = await openstackRequest({
              url: url, 
              method: method, 
              data: {network: data},
              token,
          })
          if (network_response.status_code === networkUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddNetworkFormReset()
              setFormDataOptions({})
          } else {
              const error_response = networkUrlResponses.post_async.error_response.filter(
                  error_item => error_item.status_code === network_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: network_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = networkUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: network_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successNetworkAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in networkDataForm) {
              if (
                  networkDataForm[n].field_type === "string" || 
                  networkDataForm[n].field_type === "select"
                  ) {
                  new_form_data[networkDataForm[n].field_key] = ""
              } else if (networkDataForm[n].field_type === "bool") {
                  new_form_data[networkDataForm[n].field_key] = networkDataForm[n].default_value ? 
                  networkDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);


  useEffect(() => {
      if (Object.keys(selectedSegments).length === 0) {
          let new_form_data = {}
          for (const n in networkTypeFieldsForm) {
              if (
                  networkTypeFieldsForm[n].field_type === "string" || 
                  networkTypeFieldsForm[n].field_type === "select"
                  ) {
                  new_form_data[networkTypeFieldsForm[n].field_key] = ""
              } else if (networkTypeFieldsForm[n].field_type === "bool") {
                  new_form_data[networkTypeFieldsForm[n].field_key] = networkTypeFieldsForm[n].default_value ? 
                  networkTypeFieldsForm[n].default_value : 
                  false
              }
          }
          setSelectedSegments([new_form_data])
      }
  },[selectedSegments]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddNetwork}
        formReset={handleAddNetworkFormReset}
        customTexts={{
          title: defaultTexts.createNetworkLabel
        }}
      >
        <CustomCheckboxField
          currentValue={supportSegments}
          setCurrentValue={handleSupportSegmentsChange}
          label={defaultTexts.supportSegmentationCheckbox}
        />
        {
          supportSegments && 
          selectedSegments.map((item,index) => {
            const form = getNetTypeForm(item,index)
            return form
          })
        }
        {
          supportSegments && selectedSegments.length > 1 &&
          <MinusButton 
            style={{ transform: 'scale(0.7)' }}
            sx={{height: 5}}
            onClick={handleSelectedSegmentRemove}
          />
        }
        {
          supportSegments &&
          <PlusButton 
            style={{ transform: 'scale(0.7)' }}
            sx={{height: 5}}
            onClick={handleSelectedSegmentAdd}
          />
        }
      </AddButtonWithText>

      {
        successNetworkAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successNetworkAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successNetworkAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default NetworkAdd