import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button'
import { FormGroup } from '@mui/material';
import { imageDataForm } from '../../../../../_data/openstack/glance/images/v2';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { openStackServices } from '../../../../../config/openStackConstants';
import { imagesGlanceConstants } from '../../../../../config/openStackConstants';
import { openstackRequest } from '../../../../../_network/openstack_request';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomText from '../../../../_common/CustomText';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import { handleEmptyFields } from '../../../helpers/glance_helpers';
import AddButtonWithText from '../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.imageService

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const imageAddV2 = forwardRef((props, ref) => {
  const [error, setError] = useState()
  const [successImageAdd, setSuccessImageAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { handleFetchData } = props
  const defaultTexts = useSelector(state => state.texts.langTexts);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const {imageFile, setImageFile} = props
  const {setFileUploadRequired} = props
  const {setImageRequiredFileUpload, handleImageFileRemove} = props
  const token = useSelector(state => state.profile.x_auth_token)

  const imageServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const imageServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const imagesUrl = useSelector(
      state => state.imageGlance.imageGlanceApiUrls.filter(
          version => version.api_version === "v2")[0].urls.filter(
              url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in imageDataForm) {
          if (imageDataForm[n].required && !formData[imageDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[imageDataForm[n].field_key] = {}
              updatedDataFormOptions[imageDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[imageDataForm[n].field_key]["errorText"] = defaultTexts[imageDataForm[n].error_label]
          }
      }
      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const handleFormDataFormatting = () => {
    let updated_data = {...formData}

    if (formData.min_disk) {
        updated_data.min_disk = parseInt(formData.min_disk)
    }

    if (formData.min_ram) {
        updated_data.min_ram = parseInt(formData.min_ram)
    }
    
    updated_data = handleEmptyFields(imageDataForm,updated_data)

    updated_data = { ...updated_data, visibility: 'private' }

    return updated_data
  }

  const onAddImage = async () => {
    const validateFormData = handleFormDataValidation()

    if (validateFormData) {
      const formatted_data = handleFormDataFormatting()
      const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
      const method = "POST"
        
      const image_response = await openstackRequest({
        url, 
        method, 
        token, 
        data: formatted_data
      })

      if (image_response.status_code === imagesUrlResponses.post.success_response.status_code) {
        if (imageFile) {
          setImageRequiredFileUpload(image_response.data)
          setFileUploadRequired(true)
        }

        handleAddImageFormReset()
        setFormDataOptions({})
        handleFetchData()

        setSuccessImageAdd({
          success_title: imagesUrlResponses.post.success_response.response_title, 
          success_message: imagesUrlResponses.post.success_response.response_message
        })
      } else {
        const error_response = imagesUrlResponses.post.error_response.filter(
          error_item => error_item.status_code === image_response.status_code)
        if (error_response.length > 0) {
          const errorObject = {
            error_title: error_response[0].response_title, 
            error_message: error_response[0].response_message,
            error_details: image_response.error
          }
          setError(errorObject)
        } else {
          const error_response = imagesUrlResponses.post.error_response.filter(
            error_item => error_item.status_code === "unknown")
          const errorObject = {
            error_title: error_response[0].response_title, 
            error_message: error_response[0].response_message,
            error_details: image_response.error
          }
          setError(errorObject)
        }
      }
        
    }

    return validateFormData
  }

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessImageAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddImageFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in imageDataForm) {
          if (imageDataForm[n].field_type === "string" || 
              imageDataForm[n].field_type === "select"
          ) {
              new_form_data[imageDataForm[n].field_key] = ""
          } else if (imageDataForm[n].field_type === "bool") {
              new_form_data[imageDataForm[n].field_key] = imageDataForm[n].default_value ? 
              imageDataForm[n].default_value : 
              false
          } else if (imageDataForm[n].field_type === "integer") {
              new_form_data[imageDataForm[n].field_key] = imageDataForm[n].default_value ? 
              imageDataForm[n].default_value : 
              0
          }
      }
      setFormData(new_form_data)
  }

  const handleAddImageFormResetAll = () => {
      handleAddImageFormReset()
      imageFileRemoveHandler()
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (imageDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (imageDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successImageAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in imageDataForm) {
              if (
                  imageDataForm[n].field_type === "string" || 
                  imageDataForm[n].field_type === "select" ||
                  imageDataForm[n].field_type === "list"
                  ) {
                  new_form_data[imageDataForm[n].field_key] = imageDataForm[n].default_value ? 
                  imageDataForm[n].default_value : ""
              } else if (imageDataForm[n].field_type === "bool") {
                  new_form_data[imageDataForm[n].field_key] = imageDataForm[n].default_value ? 
                  imageDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  const handleImageUpload = (event) => {
      setImageFile(event.target.files[0])
  }

  const imageFileRemoveHandler = () => {
      handleImageFileRemove()
  }

  const getDataForm = () => {
      return (
          <FormGroup>
              {imageDataForm.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_type === "select") {
                      form_field_options["items"] = [...field.items]
                      form_field_options["item_titles"] = defaultTexts
                      form_field_options["empty"] = field.default_empty
                      form_field_options["self_item_titles"] = field.self_item_titles
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {
                              sx: {m: 1, width: "90%"},
                              ...form_field_options
                          }
                      )
                  )
              })}
              <Button 
                  component="label"
                  role={undefined}
                  variant="contained"
                  disabled={imageFile !== null}
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />} 
                  color="secondary"
                  sx={{alignSelf: "flex-start", mt: 2}}
              >
                  {defaultTexts.chooseFileButtonText}
                  <VisuallyHiddenInput type="file" onChange={handleImageUpload} ref={ref} />
              </Button>
              <Stack direction="row" spacing={2} alignItems="center">
                  <CustomText size="p">
                      {imageFile ? imageFile.name : ""}
                  </CustomText>
                  {imageFile && <IconButton onClick={imageFileRemoveHandler} >
                      <DeleteIcon color="error" />
                  </IconButton>}
              </Stack>
          </FormGroup>
      )
  }

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddImage}
        formReset={handleAddImageFormResetAll}
        customTexts={{
          title: defaultTexts.createImageActionTitle,
          description: defaultTexts.addImageDialogDescriptionExtendedText,
          }}
      />

      {
        successImageAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
            title: defaultTexts[successImageAdd.success_title], 
            sx: {color: 'primary.main'}}}
          dialogBody={{
            text: defaultTexts[successImageAdd.success_message], 
            sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
            title: defaultTexts[error.error_title], 
            sx: {color: 'primary.main'}}}
          dialogBody={{
            text: `<span>${defaultTexts[error.error_message]}</span>
                    <br>
                    <br>
                    <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                    <span style="color: orange">
                      ${error.error_details}
                    </span>`,
            sx: {color: 'text.primary'}}}
        />
      }
    </>    
  )
})

export default imageAddV2