import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import SubnetSpecsV20 from './subnetSpecsV2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { subnetsUrl as subnetUrlResponses } from '../../../../../../_api_responses/openstack/neutron/subnets/v2.0';
import { openStackServices } from '../../../../../../config/openStackConstants';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    subnetDataUpdateForm,
    subnetAllocationPoolForm,
    dnsNameserversForm,
    subnetHostRouteForm
} from '../../../../../../_data/openstack/neutron/subnets/v2.0';
import CustomText from '../../../../../_common/CustomText';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.networkService

const SubnetDetailV20 = (props) => {
	const theme = useTheme()
	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const { selectedRow, handleDataFetch, setSelectedRow, setSelectedSubnet } = props
	const { handleDelete } = props
	const { networks } = props
	const { handleNavigateToNetwork } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const token = useSelector(state => state.profile.x_auth_token)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);

	const [subnetActions, setSubnetActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [subnetUpdateData, setSubnetUpdateData] = useState({})
	const [updateSubnetDialogOpen, setUpdateSubnetDialogOpen] = useState(false)
	const [allocationPools, setAllocationPools] = useState([...selectedRow.allocation_pools]);
	const [updateAllocationPoolsDialogOpen, setUpdateAllocationPoolsDialogOpen] = useState(false);
	const [dnsNameservers, setDnsNameservers] = useState([...selectedRow.dns_nameservers.map(item => {
			return {dns_nameserver: item}
	})]);
	const [updateDNSNameserversDialogOpen, setUpdateDNSNameserversDialogOpen] = useState(false)

	const [hostRoutes, setHostRoutes] = useState([...selectedRow.host_routes]);
	const [updateHostRoutesDialogOpen, setUpdateHostRoutesDialogOpen] = useState(false);
	const [gatewayIP, setGatewayIP] = useState(selectedRow.gateway_ip ? selectedRow.gateway_ip : "")
	const [updateGatewayIPDialogOpen, setUpdateGatewayIPDialogOpen] = useState(false)
	const [disableGatewayIP, setDisableGatewayIP] = useState(selectedRow.gateway_ip === null)
	const [gatewayIPFieldDisabled, setGatewayIPFieldDisabled] = useState(selectedRow.gateway_ip === null)
		
	const [subnetSubMenu, setSubnetSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/subnet-details", is_active: true}
	])

	const [currentTab, setCurrentTab] = useState("/subnet-details")
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)

	const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}/${selectedRow.id}`

	const getFormattedSubnetData = useCallback((data) => {
			let formatted_data = {...data}
			const network = networks.filter(n => n.id === data.network_id)[0]
			formatted_data.network_id = network ? 
			network.name : 
			data.network_id
			return formatted_data
	},[networks])


	const handleSubnetDetailTabChange = useCallback((navigation) => {
			let newSubnetSubmenuData = subnetSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setSubnetSubMenu(newSubnetSubmenuData)
			setCurrentTab(navigation)
	},[
			subnetSubMenu
	])

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.id])
		}			
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleAllocationPoolFormDataChange = (event,field_key,index) => {
			let new_form_data = [...allocationPools]
			if (subnetAllocationPoolForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[index][field_key] = event.target.checked
			} else if (subnetAllocationPoolForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[index][field_key] = event.trim()
			} else {
					new_form_data[index][field_key] = event.target.value.trim()
			}
			setAllocationPools(new_form_data)
	}

	const handleAllocationPoolAdd = () => {
			let new_form_data = {}
			for (const n in subnetAllocationPoolForm) {
					if (
							subnetAllocationPoolForm[n].field_type === "string" || 
							subnetAllocationPoolForm[n].field_type === "select"
							) {
							new_form_data[subnetAllocationPoolForm[n].field_key] = ""
					} else if (subnetAllocationPoolForm[n].field_type === "bool") {
							new_form_data[subnetAllocationPoolForm[n].field_key] = subnetAllocationPoolForm[n].default_value ? 
							subnetAllocationPoolForm[n].default_value : 
							false
					}
			}
			const updated_data = [...allocationPools]
			updated_data.push(new_form_data)
			setAllocationPools(updated_data)
	}

	const handleAllocationPoolRemove = () => {
			let new_data = [...allocationPools]
			new_data.pop()
			setAllocationPools(new_data)
	}

	const handleHostRouteFormDataChange = (event,field_key,index) => {
			let new_form_data = [...hostRoutes]
			if (subnetHostRouteForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[index][field_key] = event.target.checked
			} else if (subnetHostRouteForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[index][field_key] = event.trim()
			} else {
					new_form_data[index][field_key] = event.target.value.trim()
			}
			setHostRoutes(new_form_data)
	}

	const handleHostRouteAdd = () => {
			let new_form_data = {}
			for (const n in subnetHostRouteForm) {
					if (
							subnetHostRouteForm[n].field_type === "string" || 
							subnetHostRouteForm[n].field_type === "select"
							) {
							new_form_data[subnetHostRouteForm[n].field_key] = ""
					} else if (subnetHostRouteForm[n].field_type === "bool") {
							new_form_data[subnetHostRouteForm[n].field_key] = subnetHostRouteForm[n].default_value ? 
							subnetHostRouteForm[n].default_value : 
							false
					}
			}
			const updated_data = [...hostRoutes]
			updated_data.push(new_form_data)
			setHostRoutes(updated_data)
	}

	const handleHostRouteRemove = () => {
			let new_data = [...hostRoutes]
			new_data.pop()
			setHostRoutes(new_data)
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			let form_data = [...form]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getAllocationPoolsForm = () => {
			return (
					<FormGroup>
							{allocationPools.map((pool,index) => {
							return (
									<div key={index}>
									<CustomText>{defaultTexts.allocationPoolFormFieldLabel} {index + 1}</CustomText>
											{subnetAllocationPoolForm.map(item => {
													return (
															getFormFieldComponent(
																	item,
																	pool,
																	handleAllocationPoolFormDataChange,
																	defaultTexts[item.label],
																	{index: index}
															)
													)
											})}
									</div>
							)
							})}
							{allocationPools.length > 0 && <MinusButton 
									style={{ transform: 'scale(0.7)' }}
									sx={{height: 5}}
									onClick={handleAllocationPoolRemove}
							/>}
							{<Stack 
									direction="row" 
									spacing={2} 
									alignItems="center"
									onClick={handleAllocationPoolAdd}
									sx={{cursor: "pointer"}}
							>
									<PlusButton 
											style={{ transform: 'scale(0.7)' }}
											sx={{height: 5}}
											onClick={handleAllocationPoolAdd}
									/>
									<CustomText>
											{defaultTexts.addAllocationPoolFormFieldLabel}
									</CustomText>
							</Stack>}
					</FormGroup>
			)
	}

	const getDNSNameserversForm = () => {
			return (
					<FormGroup>
							{dnsNameservers.map((serv,index) => {
									return (
											<div key={index}>
											<CustomText>{defaultTexts.dnsNameserverFormFieldLabel} {index + 1}</CustomText>
													{dnsNameserversForm.map(item => {
															return (
																	getFormFieldComponent(
																			item,
																			serv,
																			handleDNSNameserverFormDataChange,
																			defaultTexts[item.label],
																			{index: index}
																	)
															)
													})}
											</div>
									)
									})}
									{dnsNameservers.length > 0 && <MinusButton 
											style={{ transform: 'scale(0.7)' }}
											sx={{height: 5}}
											onClick={handleDNSNameserverRemove}
									/>}
									{<Stack 
											direction="row" 
											spacing={2} 
											alignItems="center"
											onClick={handleDNSNameserverAdd}
											sx={{cursor: "pointer"}}
									>
											<PlusButton 
													style={{ transform: 'scale(0.7)' }}
													sx={{height: 5}}
													onClick={handleDNSNameserverAdd}
											/>
											<CustomText>
													{defaultTexts.addDnsNameserverFormFieldLabel}
											</CustomText>
									</Stack>}
					</FormGroup>
			)
	}

	const getHostRoutesForm = () => {
			return (
					<FormGroup>
							{hostRoutes.map((serv,index) => {
									return (
											<div key={index}>
											<CustomText>{defaultTexts.hostRoutesFormFieldLabel} {index + 1}</CustomText>
													{subnetHostRouteForm.map(item => {
															return (
																	getFormFieldComponent(
																			item,
																			serv,
																			handleHostRouteFormDataChange,
																			defaultTexts[item.label],
																			{index: index}
																	)
															)
													})}
											</div>
									)
									})}
									{hostRoutes.length > 0 && <MinusButton 
											style={{ transform: 'scale(0.7)' }}
											sx={{height: 5}}
											onClick={handleHostRouteRemove}
									/>}
									{<Stack 
											direction="row" 
											spacing={2} 
											alignItems="center"
											onClick={handleHostRouteAdd}
											sx={{cursor: "pointer"}}
									>
											<PlusButton 
													style={{ transform: 'scale(0.7)' }}
													sx={{height: 5}}
													onClick={handleHostRouteAdd}
											/>
											<CustomText>
													{defaultTexts.addHostRouteFormFieldLabel}
											</CustomText>
									</Stack>}
					</FormGroup>
			)
	}

	const handleGatewayIPChange = (event) => {
			setGatewayIP(event.target.value.trim())
	}

	const handleDisableGatewayIPChange = (event) => {
			if (event.target.checked) {
					setGatewayIP("")
					setGatewayIPFieldDisabled(true)
			} else {
					setGatewayIP(selectedRow.gateway_ip ? selectedRow.gateway_ip : "")
					setGatewayIPFieldDisabled(false)
			}
			setDisableGatewayIP(event.target.checked)
	}

	const handleUpdateGatewayIPDialogOpen = () => {
			setUpdateGatewayIPDialogOpen(true)
	}

	const handleUpdateGatewayIPDialogClose = () => {
			setGatewayIP(selectedRow.gateway_ip ? selectedRow.gateway_ip : "")
			setDisableGatewayIP(selectedRow.gateway_ip === null)
			setUpdateGatewayIPDialogOpen(false)
	}

	const handleDNSNameserverFormDataChange = (event, field_key, index) => {
			let new_form_data = [...dnsNameservers]
			new_form_data[index][field_key] = event.target.value.trim()
			setDnsNameservers(new_form_data)
	}

	const handleDNSNameserverAdd = () => {
			let new_form_data = {}
			new_form_data["dns_nameserver"] = ""
			const updated_data = [...dnsNameservers]
			updated_data.push(new_form_data)
			setDnsNameservers(updated_data)
	}

	const handleDNSNameserverRemove = () => {
			let new_data = [...dnsNameservers]
			new_data.pop()
			setDnsNameservers(new_data)
	}

	const handleSubnetUpdateDataChange = (event,field_key) => {
			let new_form_data = {...subnetUpdateData}
			if (subnetDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (subnetDataUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setSubnetUpdateData(new_form_data)
	}

	const handleUpdateSubnetDialogOpen = () => {
			setUpdateSubnetDialogOpen(true)
	}

	const handleUpdateSubnetDialogClose = () => {
			setUpdateSubnetDialogOpen(false)
	}

	const onGatewayIPUpdate = async () => {
			let data = {}
			if (disableGatewayIP) {
					data["gateway_ip"] = null
			} else {
					data["gateway_ip"] = gatewayIP.length > 0 ? gatewayIP : selectedRow.gateway_ip
			}
			await onSubnetUpdate(undefined,data)
			handleUpdateGatewayIPDialogClose()
	}

	const onSubnetUpdate = useCallback(async (event,data) => {
			console.log(data)
			let updated_data = {...subnetUpdateData}
			if (data) {
					updated_data = data
			}
			
			const method = "PUT"
			const subnet_response = await openstackRequest({
					url: common_url, 
					method: method, 
					data: {subnet: updated_data},
					token,
			})
			if (subnet_response.status_code === subnetUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleUpdateSubnetDialogClose()
					handleDataFetch()
			} else {
					const error_response = subnetUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === subnet_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: subnet_response.error
							}
							setError(errorObject)
					} else {
							const error_response = subnetUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: subnet_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			subnetUpdateData,
			handleDataFetch,
			token,
	])

	const handleUpdateAllocationPoolsDialogOpen = useCallback(() => {
			setAllocationPools([...selectedRow.allocation_pools])
			setUpdateAllocationPoolsDialogOpen(true)
	},[selectedRow])

	const handleUpdateAllocationPoolsDialogClose = () => {
			setUpdateAllocationPoolsDialogOpen(false)
	}

	const handleUpdateDNSNameServersDialogOpen = () => {
			setUpdateDNSNameserversDialogOpen(true)
	}
	
	const handleUpdateDNSNameserversDialogClose = () => {
			setDnsNameservers([...selectedRow.dns_nameservers.map(item => {
					return {dns_nameserver: item}
			})])
			setUpdateDNSNameserversDialogOpen(false)
	}

	const handleUpdateHostRoutesDialogOpen = () => {
			setUpdateHostRoutesDialogOpen(true)
	}

	const handleUpdateHostRoutesDialogClose = () => {
			setHostRoutes([...selectedRow.host_routes])
			setUpdateHostRoutesDialogOpen(false)
	}

	const handleSubnetDisableDhcp = useCallback(async () => {
			const data = {}
			data["enable_dhcp"] = false
			await onSubnetUpdate(undefined,data)
	},[onSubnetUpdate])

	const handleSubnetEnableDhcp = useCallback(async () => {
			const data = {}
			data["enable_dhcp"] = true
			await onSubnetUpdate(undefined,data)
	},[onSubnetUpdate])

	const onAllocationPoolsUpdate = async () => {
			const data = {}
			data["allocation_pools"] = [
					...allocationPools.filter(
							item => 
									item.start.length > 0 && 
									item.end.length > 0)]
			await onSubnetUpdate(undefined,data)
			handleUpdateAllocationPoolsDialogClose()
	}

	const onDNSNameserversUpdate = async () => {
			const data = {}
			const dns_nameservers = dnsNameservers.filter(
					item => item.dns_nameserver.length > 0)
			data["dns_nameservers"] = [
					...dns_nameservers.map(
							item => item.dns_nameserver
					)]
			await onSubnetUpdate(undefined,data)
			handleUpdateDNSNameserversDialogClose()
	}

	const onHostRoutesUpdate = async () => {
			const data = {}
			data["host_routes"] = [...hostRoutes.filter(
					item => item.destination.length > 0 &&
					item.nexthop.length > 0
			)]
			await onSubnetUpdate(undefined,data)
			handleUpdateHostRoutesDialogClose()
	}

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			subnetActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			let subnet_actions = []
			let new_action = {}
			new_action["value"] = "update_subnet"
			new_action["action"] = handleUpdateSubnetDialogOpen
			new_action["keyword"] = "subnetUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			subnet_actions.push({...new_action})
			if (selectedRow.enable_dhcp) {
					new_action = {}
					new_action["value"] = "update_allocation_pools"
					new_action["action"] = handleUpdateAllocationPoolsDialogOpen
					new_action["keyword"] = "subnetUpdateAllocationPoolsActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					subnet_actions.push({...new_action})
					new_action = {}
					new_action["value"] = "update_dns_nameservers"
					new_action["action"] = handleUpdateDNSNameServersDialogOpen
					new_action["keyword"] = "subnetUpdateDNSNameserversActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					subnet_actions.push({...new_action})
					new_action = {}
					new_action["value"] = "update_host_routes"
					new_action["action"] = handleUpdateHostRoutesDialogOpen
					new_action["keyword"] = "subnetUpdateHostRoutesActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					subnet_actions.push({...new_action})
					new_action = {}
					new_action["value"] = "disable_dhcp"
					new_action["action"] = handleSubnetDisableDhcp
					new_action["keyword"] = "subnetDisableDhcpActionTitle"
					new_action["button_text"] = "applyButtonTitleText"
					subnet_actions.push({...new_action})
			} else {
					new_action = {}
					new_action["value"] = "enable_dhcp"
					new_action["action"] = handleSubnetEnableDhcp
					new_action["keyword"] = "subnetEnableDhcpActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					subnet_actions.push({...new_action})
			}
			new_action = {}
			new_action["value"] = "update_gateway_ip"
			new_action["action"] = handleUpdateGatewayIPDialogOpen
			new_action["keyword"] = "subnetUpdateGatewayIPActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			subnet_actions.push({...new_action})
			
			setSubnetActions(subnet_actions)
	},[
			selectedRow,
			handleUpdateAllocationPoolsDialogOpen,
			handleSubnetDisableDhcp,
			handleSubnetEnableDhcp
	])

	useEffect(() => {
			if (Object.keys(subnetUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in subnetDataUpdateForm) {
							new_form_data[subnetDataUpdateForm[n].field_key] = selectedRow[subnetDataUpdateForm[n].field_key]
					}
					setSubnetUpdateData(new_form_data)
			}
	},[subnetUpdateData, selectedRow]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])

	useEffect(() => {
		setCurrentAction('')
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={subnetSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleSubnetDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedSubnet}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/subnet-details" &&
					<SubnetSpecsV20
						subnetData={getFormattedSubnetData(selectedRow)}
						selectedRow={selectedRow}
						handleNavigateToNetwork={handleNavigateToNetwork}
					/>
				}
			</Box>
			
			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
						padding: '0px 20px 0px 12px',
					}}
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={subnetActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[subnetActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			} 

			<CustomDialog
					open={updateSubnetDialogOpen}
					onClose={handleUpdateSubnetDialogClose}
					dialogTitle={{
							title: defaultTexts.updateSubnetActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onSubnetUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							subnetDataUpdateForm,
							{},
							subnetUpdateData,
							handleSubnetUpdateDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={updateGatewayIPDialogOpen}
					onClose={handleUpdateGatewayIPDialogClose}
					dialogTitle={{
							title: defaultTexts.subnetUpdateGatewayIPActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onGatewayIPUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomTextField 
							currentValue={gatewayIP}
							setCurrentValue={handleGatewayIPChange}
							label={defaultTexts.gatewayIPFormFieldLabel}
							disabled={gatewayIPFieldDisabled}
					/>
					<CustomCheckboxField
							currentValue={disableGatewayIP}
							setCurrentValue={handleDisableGatewayIPChange}
							label={defaultTexts.disableGatewayIPFormFieldLabel}
							required={false}
					/>
			</CustomDialog>

			<CustomDialog
					open={updateAllocationPoolsDialogOpen}
					onClose={handleUpdateAllocationPoolsDialogClose}
					dialogTitle={{
							title: defaultTexts.updateAllocationPoolsActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onAllocationPoolsUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getAllocationPoolsForm()}
			</CustomDialog>

			<CustomDialog
					open={updateDNSNameserversDialogOpen}
					onClose={handleUpdateDNSNameserversDialogClose}
					dialogTitle={{
							title: defaultTexts.subnetUpdateDNSNameserversActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onDNSNameserversUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDNSNameserversForm()}
			</CustomDialog>

			<CustomDialog
					open={updateHostRoutesDialogOpen}
					onClose={handleUpdateHostRoutesDialogClose}
					dialogTitle={{
							title: defaultTexts.subnetUpdateHostRoutesActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onHostRoutesUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getHostRoutesForm()}
			</CustomDialog>

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
}

export default SubnetDetailV20