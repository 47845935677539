import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const CustomBackdrop = (props) => {
  const { open } = props;

  return (
    <Backdrop
      sx={{ 
        color: '#fff', 
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default CustomBackdrop