import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataNote from '../../../../../_common/NoDataNote';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FloatingIPSpecsV20 from './floatingIPSpecsV2.0';
import FloatingIPPortForwardingsV20 from './floatingIPPortForwardingsV2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { floatingIPsUrl as floatingIPUrlResponses } from '../../../../../../_api_responses/openstack/neutron/floating_ips/v2.0';
import { openStackServices } from '../../../../../../config/openStackConstants';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from '../../../../../_common/CustomDialog';
import { portForwardingForm } from '../../../../../../_data/openstack/neutron/floating_ips/v2.0';

const SERVICE_NAME = openStackServices.networkService

const FloatingIPDetailV20 = (props) => {
	const theme = useTheme()
	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const { selectedRow, setSelectedRow, setSelectedFloatingIP, handleDataFetch } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	
	const { handleDelete } = props
	const { networks, servers } = props
	const { handleNavigateToNetwork } = props
	const { handleNavigateToServer } = props
	const { handleNavigateToRouter } = props
	const token = useSelector(state => state.profile.x_auth_token)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const drawerWidth = drawerOpened ? 270 : 65
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);

	const [floatingIPActions, setFloatingIPActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [portForwardingData, setPortForwardingData] = useState({})
	const [updatePortForwardingDialogOpen, setUpdatePortForwardingDialogOpen] = useState(false)
	const [selectedPort, setSelectedPort] = useState("")
	const [selectedPortError, setSelectedPortError] = useState(false)
	const [projectPorts, setProjectPorts] = useState([])
	const [associateFloatingIPDialogOpen, setAssociateFloatingIPDialogOpen] = useState(false)
		
	const [floatingIPSubMenu, setFloatingIPSubMenu] = useState([
			{keyword: "submenuDetails", navigation: "/fip-details", is_active: true},
			{keyword: "submenuPortForwardings", navigation: "/fip-port-forwardings", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/fip-details")
	
	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const floatingIPsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)
	const portsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.portsUrl)[0].url)

	const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}/${selectedRow.id}`

	const getFormattedFloatingIPData = useCallback((data) => {
			let new_item = {...data}
			
			const network = networks.filter(n => n.id === data.floating_network_id)
			if (network.length > 0) {
					new_item.floating_network_id = network[0].name
			} else {
					new_item.floating_network_id = "-"
			}
			if (data.port_details) {
					if (data.port_details.device_owner === networkNeutronConstants.computeNovaOwner) {
							const server = servers.filter(srv => srv.id === data.port_details.device_id)
							if (server.length > 0) {
									new_item.port_details["device_name"] = server[0].name
							}
					}
			}
			return new_item
	},[networks, servers])


	const handleFloatingIPDetailTabChange = useCallback((navigation) => {
			let newFloatingIPSubmenuData = floatingIPSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setFloatingIPSubMenu(newFloatingIPSubmenuData)
			setCurrentTab(navigation)
	},[
			floatingIPSubMenu
	])

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleDelete([selectedRow.floating_ip_address])
		}			
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			let form_data = [...form]
			return (
					<FormGroup>
							{form_data.map(field => {
									let form_field_options = {}
									form_field_options["items"] = field.items ? [...field.items] : []
									form_field_options["item_titles"] = defaultTexts
									form_field_options["self_items_titles"] = false
									form_field_options["empty"] = false
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleAssociateFloatingIPDialogOpen = () => {
			setAssociateFloatingIPDialogOpen(true)
	}

	const handleAssociateFloatingIPDialogClose = () => {
			setSelectedPort("")
			setAssociateFloatingIPDialogOpen(false)
	}

	const handleUpdatePortForwardingDialogClose = () => {
			setUpdatePortForwardingDialogOpen(false)
	}

	const handleUpdatePortForwardingDialogOpen = () => {
			setUpdatePortForwardingDialogOpen(true)
	}

	const handlePortForwardongUpdateDataChange = (event, field_key) => {
			let new_form_data = {...portForwardingData}
			if (portForwardingForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (portForwardingForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
							new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setPortForwardingData(new_form_data)
	}

	const onFloatingIPUpdate = useCallback(async (event,data) => {
			let updated_data = {}
			if (data) {
					updated_data = data
			}
			
			const method = "PUT"
			const fip_response = await openstackRequest({
					url: common_url, 
					method: method, 
					data: {floatingip: updated_data},
					token,
			})
			if (fip_response.status_code === floatingIPUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleDataFetch()
			} else {
					const error_response = floatingIPUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === fip_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fip_response.error
							}
							setError(errorObject)
					} else {
							const error_response = floatingIPUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fip_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			token,
			handleDataFetch
	])

	const handleDisassociateFloatingIPDialogOpen = useCallback(async () => {
			const data = {port_id: null}
			await onFloatingIPUpdate(undefined, data)
	},[onFloatingIPUpdate])

	const onFloatingIPAssociate = useCallback(async () => {
			if (selectedPort.length === 0) {
					setSelectedPortError(true)
					return false
			}
			const data = {port_id: selectedPort}
			await onFloatingIPUpdate(undefined, data)
			handleAssociateFloatingIPDialogClose()
			setSelectedPort("")
	},[selectedPort, onFloatingIPUpdate])

	const formatPortForwardData = (data) => {
			console.log(data)
			let new_data = {}
			new_data["internal_ip_address"] = data.internal_ip_address
			new_data["protocol"] = data.protocol
			if (data.start_internal_port > 0 && data.end_internal_port > 0) {
					new_data["internal_port_range"] = `${data.start_internal_port}:${data.end_internal_port}`
			} else if (data.start_internal_port > 0) {
					new_data["internal_port"] = data.start_internal_port
			}

			if (data.start_external_port > 0 && data.end_external_port > 0) {
					new_data["external_port_range"] = `${data.start_external_port}:${data.end_external_port}`
			} else if (data.start_external_port > 0) {
					new_data["external_port"] = data.start_external_port
			}
			if (data.description.length > 0) {
					new_data["description"] = data.description
			}    
			return new_data
	}

	const onPortForwardingCreate = useCallback(async () => {
			let updated_data = {...portForwardingData}
			updated_data = formatPortForwardData(updated_data)
			
			const method = "POST"
			const fip_response = await openstackRequest({
					url: `${common_url}/port_forwardings/`, 
					method: method, 
					data: {port_forwarding: updated_data},
					token,
			})
			if (fip_response.status_code === floatingIPUrlResponses.put.success_response.status_code) {
					setCurrentAction("")
					handleUpdatePortForwardingDialogClose()
					handleDataFetch()
			} else {
					const error_response = floatingIPUrlResponses.put.error_response.filter(
							error_item => error_item.status_code === fip_response.status_code)
					if (error_response.length > 0) {
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fip_response.error
							}
							setError(errorObject)
					} else {
							const error_response = floatingIPUrlResponses.put.error_response.filter(
									error_item => error_item.status_code === "unknown")
							const errorObject = {
									error_title: error_response[0].response_title, 
									error_message: error_response[0].response_message,
									error_details: fip_response.error
							}
							setError(errorObject)
					}
			}
			
	},[
			common_url,
			token,
			handleDataFetch,
			portForwardingData
	])

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			floatingIPActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			let fip_actions = []
			let new_action = {}
			if (selectedRow.port_details) {
					new_action["value"] = "disassociate_floating_ip"
					new_action["action"] = handleDisassociateFloatingIPDialogOpen
					new_action["keyword"] = "disassociateFloatingIPActionTitle"
					new_action["button_text"] = "applyButtonTitleText"
					fip_actions.push({...new_action})
			}
			if (!selectedRow.port_details) {
					new_action["value"] = "associate_floating_ip"
					new_action["action"] = handleAssociateFloatingIPDialogOpen
					new_action["keyword"] = "associateFloatingIPActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					fip_actions.push({...new_action})
			}
			new_action = {}
			new_action["value"] = "create_port_forwarding"
			new_action["action"] = handleUpdatePortForwardingDialogOpen
			new_action["keyword"] = "createPortForwardingActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			fip_actions.push({...new_action})
			
			setFloatingIPActions(fip_actions)
	},[
			selectedRow,
			handleDisassociateFloatingIPDialogOpen
	])

	useEffect(() => {
			(async () => {
					
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}?project_id=${selectedRow.project_id}`
				const method = "GET"
				const port_response = await openstackRequest({url:url, method:method, token, })
				if (port_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
						const filtered_ports = port_response.data.ports.filter(p => p.device_owner === networkNeutronConstants.computeNovaOwner ||
								p.device_owner === ""
						)
						const formatted_list = filtered_ports.map(item => {
								let server = ""
								if (item.device_id) {
										const server_object = servers.filter(s => s.id === item.device_id)
										server = server_object.length > 0 ? `${server_object[0].name}: ` : server
								}
								return {
										keyword: item.fixed_ips.length > 0 ? `${server}${item.fixed_ips[0].ip_address}` : `${server}`,
										value: item.id, default: false
								}
						})
						setProjectPorts(formatted_list)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			portsUrl, 
			selectedRow,
			servers,
			token,
	]);

	useEffect(() => {
			if (Object.keys(portForwardingData).length === 0) {
					let new_form_data = {}
					for (const n in portForwardingForm) {
							if (portForwardingForm[n].field_type === "select" ||
									portForwardingForm[n].field_type === "string"
							) {
									new_form_data[portForwardingForm[n].field_key] = ""
							} else if (portForwardingForm[n].field_type === "bool") {
									new_form_data[portForwardingForm[n].field_key] = portForwardingForm[n].default_value ? 
									portForwardingForm[n].default_value : 
									false
							}
					}
					setPortForwardingData(new_form_data)
			}
	},[portForwardingData]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])

	useEffect(() => {
		setCurrentTab('/fip-details')
		setFloatingIPSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/fip-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])

	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={floatingIPSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleFloatingIPDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedFloatingIP}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/fip-details" &&
					<FloatingIPSpecsV20
						floatingIPData={getFormattedFloatingIPData(selectedRow)}
						selectedRow={selectedRow}
						handleNavigateToNetwork={handleNavigateToNetwork}
						handleNavigateToServer={handleNavigateToServer}
						handleNavigateToRouter={handleNavigateToRouter}
					/>
				}

				{
					currentTab === "/fip-port-forwardings" && selectedRow.portForwardings &&
					<FloatingIPPortForwardingsV20
						selectedRow={selectedRow}
						portForwardings={selectedRow.portForwardings}
					/>
				}

				{
					currentTab === "/fip-port-forwardings" && !selectedRow.portForwardings &&
					<NoDataNote text={defaultTexts.noPortForwardingsNoteText} />
				}
			</Box>
			
			{
				!isCardLoading &&
				<Paper
					sx={{ 
						position: 'fixed',
						width: `calc(100% - ${drawerWidth}px)`,
						bottom: 0, 
						height: '60px',
						left: `${drawerWidth}px`,
						borderTop: `${theme.palette.customGrayDark} 2px solid`,
						borderRadius: '0px',
						padding: '0px 20px 0px 12px',
					}} 
				>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={floatingIPActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[floatingIPActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			}

			<CustomDialog
					open={updatePortForwardingDialogOpen}
					onClose={handleUpdatePortForwardingDialogClose}
					dialogTitle={{
							title: defaultTexts.createPortForwardingActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onPortForwardingCreate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							portForwardingForm,
							{},
							portForwardingData,
							handlePortForwardongUpdateDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={associateFloatingIPDialogOpen}
					onClose={handleAssociateFloatingIPDialogClose}
					dialogTitle={{
							title: defaultTexts.associateFloatingIPActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onFloatingIPAssociate, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomSelectField
							currentValue={selectedPort}
							setCurrentValue={setSelectedPort}
							label={defaultTexts.portFormFieldLabel}
							items={projectPorts}
							self_item_titles={true}
							empty={false}
							required={true}
							error={selectedPortError}
							errorText={defaultTexts.requiredFormFieldError}
							sx={{width: "80%", m: 1}}
					/>
			</CustomDialog>

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error.error_details}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}
		</>
	)
};

export default FloatingIPDetailV20;