import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { setDefaultAdminProject, setXAuthToken } from '../../store/reducers/profileSlice'
import { setSavedProjectId } from '../../store/reducers/settingsSlice'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Tooltip from '@mui/material/Tooltip'
import { getXAuthTokenProjectScope } from '../../_network/openstack_request'

const AdminProjectSelector = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { logoutHandler } = props
  
  const dispatch = useDispatch()

  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const adminProjects = useSelector(state => state.profile.adminProjects)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const [anchorElProject, setAnchorElProject] = useState(null)
  
  const handleOpenProjectMenu = (event) => {
    setAnchorElProject(event.currentTarget)
  }
  
  const handleCloseProjectsMenu = () => {
    setAnchorElProject(null)
  }
  
  const handleProjectChange = async (value) => {
    const authResponse = await getXAuthTokenProjectScope(value)

    if (authResponse) {
      dispatch(setXAuthToken({ x_auth_token: authResponse }))
      dispatch(setDefaultAdminProject({ defaultAdminProject: value }))
      dispatch(setSavedProjectId({ savedProjectId: value }))
      handleCloseProjectsMenu()
      navigate('/')
    } else {
      logoutHandler()
    }
  }

  return (
    <Box sx={{ display: 'inline-flex' }}>
      <Tooltip title={defaultTexts.selectProjectText}>
        <Button 
          onClick={handleOpenProjectMenu} 
          sx={{           
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            margin: '0px 10px 0px 20px',
            border: `${theme.palette.primary.main} 1px solid`,
            color: theme.palette.customBlack,
          }}
        >
          <Typography textAlign="center">
            { adminProjects.filter(item => item.id === defaultAdminProject)[0].name }
          </Typography>
          <ArrowDropDownIcon />
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: '48px' }}
        anchorEl={anchorElProject}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElProject)}
        onClose={handleCloseProjectsMenu}
      >
        {
          adminProjects.map((item) => {
            return (
              <MenuItem 
                onClick={() => {handleProjectChange(item.id)}} 
                key={item.id} 
                value={item.id}
              >
                {item.name.toUpperCase()}
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default AdminProjectSelector