import React from 'react';
import Box from '@mui/material/Box';
import IKEPoliciesWrapperV20 from './vpn_ike_policies/ikePoliciesWrapperV2.0';
import IPsecPoliciesWrapperV20 from './vpn_ipsec_policies/ipsecPoliciesWrapperV2.0';
import VPNServicesWrapperV20 from './vpn_services/vpnServicesWrapperV2.0';
import EndpointGroupsWrapperV20 from './vpn_endpoint_groups/vpnEndpointGroupsWrapperV2.0';
import SiteConnectionsWrapperV20 from './vpn_site_connections/siteConnectionsWrapperV2.0';

const VPNaaSWrapperV20 = (props) => {
	const { navigate, location, changeMenuActiveTab, serviceMenu, serviceSubmenu, subsection } = props
	
	return (
		<Box>
				{subsection === "/ike-policies" && 
						<IKEPoliciesWrapperV20 
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/ipsec-policies" && 
						<IPsecPoliciesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/vpn-services" &&
						<VPNServicesWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/endpoint-groups" &&
						<EndpointGroupsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
				{subsection === "/site-connections" &&
						<SiteConnectionsWrapperV20
								navigate={navigate}
								location={location}
								changeMenuActiveTab={changeMenuActiveTab}
								currentTab={subsection}
								serviceMenu={serviceMenu}
								serviceSubmenu={serviceSubmenu}
						/>
				}
		</Box>
	)
}

export default VPNaaSWrapperV20