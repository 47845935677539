import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { BsEthernet } from "react-icons/bs";

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const ComputeServerInterfacesV21 = (props) => {
    const { attachedInterfaces } = props
    const { subnets } = props
    const { networks } = props
    const { handleNavigateToSubnet, handleNavigateToPort, handleNavigateToNetwork } = props

    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();
    
    return (
        <React.Fragment>
            <WrapperBox
            sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <BsEthernet
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.attachedInterfacesTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {attachedInterfaces.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{defaultTexts.ipAddressFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.subnetFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.macAddressFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.portFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.networkFormFieldLabel}</TableCell>
                                    <TableCell align="right">{defaultTexts.stateFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {attachedInterfaces.map((row) => (
                                <TableRow
                                    key={row.mac_addr}
                                >
                                    <TableCell 
                                        component="th" 
                                        scope="row"
                                    >
                                        {row.fixed_ips.map(ip => 
                                            <div key={ip.ip_address}>{ip.ip_address}</div>
                                        )}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'primary.main',
                                        }}
                                    >
                                    {row.fixed_ips.map(ip => 
                                        <div key={ip.ip_address} onClick={() => handleNavigateToSubnet(ip)}>
                                            {
                                                subnets.length > 0 ?
                                                subnets.filter(sub => sub.id === ip.subnet_id)[0].name :
                                                `${ip.subnet_id.slice(0,10)}...`
                                            }
                                        </div>
                                    )}
                                    </TableCell>
                                    <TableCell 
                                        align="center"
                                    >
                                        {row.mac_addr}
                                    </TableCell>
                                    <TableCell 
                                        align="center"
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'primary.main',
                                        }}
                                        onClick={() => handleNavigateToPort(row.port_id)}
                                    >
                                        {row.port_id.slice(0,10)}...
                                    </TableCell>
                                    <TableCell 
                                        align="center"
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'primary.main',
                                        }}
                                        onClick={() => handleNavigateToNetwork(row.net_id)}
                                    >
                                        {
                                            networks.length > 0 ? 
                                            networks.filter(
                                                net => net.id === row.net_id)[0].name :
                                                `${row.net_id.slice(0,10)}...`
                                        }
                                    </TableCell>
                                    <TableCell 
                                        align="right"
                                    >
                                        {row.port_state}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default ComputeServerInterfacesV21;