import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import CustomText from '../CustomText';
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CustomInnerAccordion from './CustomInnerAccordion';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&::before': {
      display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    paddingRight: 0,
    borderTop: `1px solid ${theme.palette.divider}`
}));

const CustomAccordion = (props) => {
    const { item } = props
    const { handleInstructionSelection } = props
    const { selectedItemInstruction } = props
    const { handleCategorySelection, handleGroupSelection } = props
    const { selectedCategoryItems, selectedGroupItems } = props
    const { selectedItems, handleItemSelection } = props

    const [expanded, setExpanded] = useState("");
    const [innerExpanded, setInnerExpanded] = useState([]);

    const theme = useTheme()

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleInnerChange = (panel) => (event, newExpanded) => {
        let updated_list = [...innerExpanded]
        if (newExpanded) {
            if (!innerExpanded.includes(panel)) {
                updated_list.push(panel)
            }
        } else {
            updated_list = updated_list.filter(item => item !== panel)
        }
        setInnerExpanded(updated_list);
    };

    return (
        <Accordion 
            expanded={expanded === item.value} 
            onChange={handleChange(item.value)}
        >
            <Stack direction="row" justifyContent="space-between">
                <AccordionSummary 
                    onClick={() => {handleInstructionSelection(item)}}
                >
                    <CustomText
                        sx={{
                            color: selectedItemInstruction.value === item.value ? 
                            "primary.main" : 
                            undefined
                        }}
                    >
                        {item.title}
                    </CustomText>
                </AccordionSummary>
                <IconButton onClick={() => handleCategorySelection(item)}>
                    {selectedCategoryItems.includes(item.value) ?
                        <CheckBoxIcon color="primary" />
                        :
                        <CheckBoxOutlineBlankIcon />
                    }
                </IconButton>
            </Stack>
            <AccordionDetails>
                {item.objects.map((object, index) => {
                    return (
                        <CustomInnerAccordion
                            key={index}
                            item={object}
                            parent={item}
                            innerExpanded={innerExpanded}
                            handleInnerChange={handleInnerChange}
                            handleInstructionSelection={handleInstructionSelection}
                            selectedItemInstruction={selectedItemInstruction}
                            handleGroupSelection={handleGroupSelection}
                            selectedGroupItems={selectedGroupItems}
                            handleItemSelection={handleItemSelection}
                            selectedItems={selectedItems}
                        />
                    )
                })}
                <Stack direction="column" spacing={2}>
                    {item.properties.map((property, index) => {
                        return (
                            <Stack 
                                key={index} 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                                onClick={() => {handleInstructionSelection(property)}}
                                sx={{
                                    pl: 2,
                                    '&:first-of-type': {
                                        pt: item.objects.length > 0 ? 1 : 0,
                                        borderTop: item.objects.length > 0 ? 
                                        `1px solid ${theme.palette.divider}` : 0
                                    },
                                }}
                            >
                                <CustomText
                                    sx={{
                                        color: selectedItemInstruction.value === property.value ? 
                                        "primary.main" : 
                                        undefined
                                    }}
                                >
                                    {property.title}
                                </CustomText>
                                <IconButton onClick={() => handleItemSelection(property.value,item)}>
                                    {selectedItems.includes(property.value)  ?
                                        <CheckBoxIcon color="primary" />
                                        :
                                        <CheckBoxOutlineBlankIcon />
                                    }
                                </IconButton>
                            </Stack>
                        )
                    })}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
};

export default CustomAccordion;