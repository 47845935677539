export const serviceDataSchema = [
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "binary",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "disabled_reason",
        is_id_field: false,
        field_type: "string", 
        label: "disabledReasonFormFieldLabel"
    },
    {
        field_key: "host",
        is_id_field: false,
        field_type: "string", 
        label: "hostFormFieldLabel"
    },
    {
        field_key: "state",
        is_id_field: false,
        field_type: "string", 
        label: "stateFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    },
    {
        field_key: "forced_down",
        is_id_field: false,
        field_type: "bool", 
        label: "forcedDownFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "zone",
        is_id_field: false,
        field_type: "string", 
        label: "zoneFormFieldLabel"
    }
]