import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest, computeNovaRequest } from '../../../../../_network/openstack_request';
import { openStackServices } from '../../../../../config/openStackConstants';
import { computeNovaConstants, networkNeutronConstants, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../_common/ServiceCardContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import ComputeServerSpecsV21 from './computeServerSpecsV2_1';
import ComputeServerVolumesV21 from './computeServerVolumesV2_1';
import ComputeServerInterfacesV21 from './computeServerInterfacesV2_1';
import ComputeServerSecGroupsV21 from './computeServerSecGroupsV2_1'
import ComputeServerMetadataV21 from './computeServerMetadataV2_1';
import ComputeServerStateActionsV21 from './computeServerStateActionsV2_1';
import ComputeServerActionsV21 from './computeServerActionsV2_1';
import Box from '@mui/material/Box';
import { flavorsUrl as flavorsUrlResponses } from '../../../../../_api_responses/openstack/compute/flavors/v2.1';
import useWindowDimensions from '../../../../_common/WindowDimensions';

const SERVICE_NAME = openStackServices.computeService
const NETWORK_SERVICE_NAME = openStackServices.networkService
const VOLUMES_SERVICE_NAME = openStackServices.volumeService
const flavors_default_url_query = "?is_public=none"

const ComputeServerDetailV21 = (props) => {
	const { selectedRow, selectedServer, handleDataFetch, setSelectedRow, setSelectedServer } = props
	const { serverGroups } = props
	const { handleServerStatusChange } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const { handleNavigateToVolume, handleNavigateToSubnet, handleNavigateToPort, handleNavigateToNetwork, handleNavigateToSecurityGroup } = props
	const { width } = useWindowDimensions()
	
	const theme = useTheme()

	// const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
	const [serverData, setServerData] = useState({...selectedRow})
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const token = useSelector(state => state.profile.x_auth_token)
	const mode = useSelector(state => state.settings.uiMode)

	const drawerWidth = drawerOpened ? 270 : 65
	
	const [attachedVolumes, setAttachedVolumes] = useState([]);
	const [attachedInterfaces, setAttachedInterfaces] = useState([]);
	const [serverSecGroups, setServerSecGroups] = useState([]);
	const [subnets, setSubnets] = useState([]);
	const [networks, setNetworks] = useState([]);
	const [secGroups, setSecGroups] = useState([]);
	const [volumes, setVolumes] = useState([]);
	const [flavors, setFlavors] = useState([]);
	const [selectedServerWithFlavorId, setSelectedServerWithFlavorId] = useState({})

	const [serverSubMenu, setServerSubMenu] = useState(serverData.fault ? [
			{keyword: "submenuDetails", navigation: "/server-details", is_active: true},
			{keyword: "submenuVolumes", navigation: "/server-volumes", is_active: false},
			{keyword: "submenuInterfaces", navigation: "/server-interfaces", is_active: false},
			{keyword: "submenuSecurityGroups", navigation: "/server-security-groups", is_active: false},
			{keyword: "submenuMetadata", navigation: "/server-metadata", is_active: false},
			{keyword: "submenuUserData", navigation: "/server-user-data", is_active: false},
			{keyword: "submenuFault", navigation: "/server-fault", is_active: false}
	] : [
			{keyword: "submenuDetails", navigation: "/server-details", is_active: true},
			{keyword: "submenuVolumes", navigation: "/server-volumes", is_active: false},
			{keyword: "submenuInterfaces", navigation: "/server-interfaces", is_active: false},
			{keyword: "submenuSecurityGroups", navigation: "/server-security-groups", is_active: false},
			{keyword: "submenuMetadata", navigation: "/server-metadata", is_active: false},
			{keyword: "submenuUserData", navigation: "/server-user-data", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/server-details")
	
	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	const flavorsUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
	const networkServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
	const networkServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
	const secGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.secGroupsUrl)[0].url)
	const blockStorageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === VOLUMES_SERVICE_NAME)[0].config_params.service_domain)
	const blockStorageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === VOLUMES_SERVICE_NAME)[0].config_params.api_version)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)

	const common_url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${selectedRow.id}`

	const getEmptyListNoteComponent = (text) => {
			return (
					<WrapperBox>
							<CustomCard 
									overflow="hidden" 
									cardHeight={220}
									cardWidth={300}
									card_sx={{
											mt: 15, 
											opacity: 0.5, 
											borderRadius: 50,
									}}
									box_sx={{
											display: "flex", 
											alignItems: "center", 
											justifyContent: "center",
											overflow: "hidden",
											right: 0
									}}
							>
									<CustomText 
											size="h5" 
											sx={{textAlign: "center"}}
									>
											{text}
									</CustomText>
							</CustomCard>
					</WrapperBox>
			)
	}

	const getFormattedServerData = (data) => {
			let formatted_data = {...data}
			return formatted_data
	}

	const handleServerDetailTabChange = useCallback((navigation) => {
			let newServerSubmenuData = serverSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setServerSubMenu(newServerSubmenuData)
			setCurrentTab(navigation)
	},[
			serverSubMenu
	])

	useEffect(() => {
			(async () => {
					const url = common_url
					const method = "GET"
					
							const server_response = await computeNovaRequest({
									url:url, 
									method:method, 
									token,
							})
							setServerData((server_response.data && server_response.data.server) ? server_response.data.server : null)
					
			})();
	},[
			common_url,
			token,
			selectedRow,
			selectedServer
	]);

	useEffect(() => {
			(async () => {
					const url = `${common_url}/os-volume_attachments`
					const method = "GET"
					
					const server_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})

					setAttachedVolumes(
						(server_response.data && server_response.data.volumeAttachments) ? server_response.data.volumeAttachments : ''
					)
					
			})();
	},[
			common_url,
			token,
			selectedRow,
			selectedServer
	]);

	useEffect(() => {
			(async () => {
					const url = `${networkServiceDomain}/${networkServiceVersion}/${subnetsUrl}`
					const method = "GET"
					
					try {
							const subnets_response = await openstackRequest({
									url:url, 
									method:method,
									token,
							})
							setSubnets(subnets_response.data.subnets)
					} catch {
							setSubnets([])
					}

			})();
	},[
			networkServiceDomain,
			networkServiceVersion,
			subnetsUrl,
			selectedServer,
			token,
	]);

	useEffect(() => {
			(async () => {
					const url = `${networkServiceDomain}/${networkServiceVersion}/${networksUrl}`
					const method = "GET"
					
					
					try {
							const networks_response = await openstackRequest({
									url:url, 
									method:method,
									token,
							})
							setNetworks(networks_response.data.networks)
					} catch {
							setNetworks([])
					}

			})();
	},[
			networkServiceDomain,
			networkServiceVersion,
			networksUrl,
			selectedServer,
			token
	]);

	useEffect(() => {
			(async () => {
					const url = `${networkServiceDomain}/${networkServiceVersion}/${secGroupsUrl}?tenant_id=${selectedServer.tenant_id}`
					const method = "GET"
					
					try {
							const sec_groups_response = await openstackRequest({
									url:url, 
									method:method,
									token,
							})
							setSecGroups(sec_groups_response.data.security_groups)
					} catch {
							setSecGroups([])
					}

			})();
	},[
			networkServiceDomain,
			networkServiceVersion,
			secGroupsUrl,
			serverSecGroups,
			selectedServer,
			token
	]);

	useEffect(() => {
			(async () => {
					const url = `${blockStorageServiceDomain}/${blockStorageServiceVersion}/${selectedServer.tenant_id}/${volumesUrl}/detail`
					const method = "GET"
					
					try {
							const volumes_response = await openstackRequest({
									url:url, 
									method:method,
									token,
							})
							setVolumes(volumes_response.data.volumes)
					} catch {
							setVolumes([])
					}

			})();
	},[
			blockStorageServiceDomain,
			blockStorageServiceVersion,
			volumesUrl,
			selectedServer,
			token
	]);

	useEffect(() => {
			(async () => {
					const url = `${common_url}/os-interface`
					const method = "GET"
					
					const server_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})
					setAttachedInterfaces(
							(server_response.data && server_response.data.interfaceAttachments) ? server_response.data.interfaceAttachments : ''
					)
					
			})();
	},[
			common_url,
			token,
			selectedRow,
			selectedServer
	]);

	useEffect(() => {
			(async () => {
					const url = `${common_url}/os-security-groups`
					const method = "GET"
					
					const server_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})
					let unique = []
					let sec_groups = []
					const sec_gr_list = (server_response.data && server_response.data.security_groups) ? server_response.data.security_groups : []
					for (let gr in sec_gr_list) {
							if (!unique.includes(sec_gr_list[gr].id)) {
									unique.push(sec_gr_list[gr].id)
									sec_groups.push(sec_gr_list[gr])
							}
					}
					setServerSecGroups(sec_groups)
					
			})();
	},[
			common_url,
			token,
			selectedRow,
			selectedServer
	]);

	useEffect(() => {
			(async () => {
					const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${flavors_default_url_query}`
					const method = "GET"

					const flavors_response = await openstackRequest({url:url, method:method})
					if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
							setFlavors(flavors_response.data.flavors)
					}
			})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			flavorsUrl
	]);

	useEffect(() => {
		const upatdeSelectedServer = async () => {
			const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${selectedServer.id}`
			const method = "GET"

			const response = await openstackRequest({ url, method, })
			
			if (response && response.data && response.data.server && response.data.server.flavor && response.data.server.flavor.id) {
				let currentServer = { ...selectedServer }
				currentServer.flavor = { ...selectedServer.flavor, id: response.data.server.flavor.id }
				setSelectedServerWithFlavorId(currentServer)
			}
		}

		if (selectedServer.id) {
			upatdeSelectedServer()
		} else {
			setSelectedServerWithFlavorId({})
		}
	}, [selectedServer, token, computeServiceDomain, computeServiceVersion, serversUrl])

	useEffect(() => {
		setCurrentTab('/server-details')
		setServerSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/server-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])
	
	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={serverSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleServerDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedServer}
					/>
				</WrapperBox>
			}

			{
				width < 1024 &&
				<Box>
					<ComputeServerStateActionsV21 
						handleDataFetch={handleDataFetch}
						serverData={selectedServer}
						selectedRow={selectedRow}
						handleServerStatusChange={handleServerStatusChange}
						isSuspended={isSuspended}
						setSuspendedDialogOpen={setSuspendedDialogOpen}
					/>
				</Box>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/server-details" &&
					<ComputeServerSpecsV21 
						serverData={getFormattedServerData(serverData)}
						selectedRow={selectedServerWithFlavorId}
						serverGroups={serverGroups}
					/>
				}

				{currentTab === "/server-volumes" &&
						<ComputeServerVolumesV21 
								attachedVolumes={attachedVolumes}
								handleNavigateToVolume={handleNavigateToVolume}
						/>
				}

				{currentTab === "/server-interfaces" && 
						<ComputeServerInterfacesV21 
								attachedInterfaces={attachedInterfaces}
								subnets={subnets}
								networks={networks}
								handleNavigateToSubnet={handleNavigateToSubnet}
								handleNavigateToPort={handleNavigateToPort}
								handleNavigateToNetwork={handleNavigateToNetwork}
						/>
				}

				{currentTab === "/server-security-groups" && 
						<ComputeServerSecGroupsV21 
								serverSecGroups={serverSecGroups}
								secGroups={secGroups}
								handleNavigateToSecurityGroup={handleNavigateToSecurityGroup}
						/>
				}

				{currentTab === "/server-metadata" && 
						<ComputeServerMetadataV21
								serverMetadata={serverData.metadata}
								serverData={selectedServer}
								handleDataFetch={handleDataFetch}
						/>
				}
				
				{currentTab === "/server-user-data" && 
						<div>
						{serverData["OS-EXT-SRV-ATTR:user_data"] ?
								serverData["OS-EXT-SRV-ATTR:user_data"]
								:
								getEmptyListNoteComponent(defaultTexts.noUserDataNoteText)}
						</div>
				}
				{currentTab === "/server-fault" && 
						<Stack
								direction="column"
								spacing={1}
								sx={{m: 1, mb: 5}}
						>
								<CustomText 
										size="p" 
								>
										{defaultTexts.faultCodeFormFieldLabel}: {serverData.fault.code}
								</CustomText>
								<Divider />
								<CustomText 
										size="p" 
								>
										{defaultTexts.createdFormFieldLabel}: {
												new Date(serverData.fault.created).toLocaleString()
										}
								</CustomText>
								<Divider />
								<CustomText 
										size="p" 
								>
								{defaultTexts.faultMessageFormFieldLabel}: {serverData.fault.message}
								</CustomText>
								<Divider />
								<CustomText 
										size="p" 
								>
										{defaultTexts.faultDetailsFormFieldLabel}:
								</CustomText>
								<Box
										sx={{
												border: 0.3,
												borderRadius: 2,
												borderStyle: "dashed",
												borderColor: "text.secondary",
												p: 2,
												my: 1
										}}
								>
								<CustomText 
										size="p" 
								>
										{serverData.fault.details}
								</CustomText>
								</Box>
						</Stack>
				}
			</Box>

			<Paper sx={{
				position: 'fixed',
				width: `calc(100% - ${drawerWidth}px)`,
				bottom: 0,
				height: width >= 900 ? '80px' : '110px',
				left: `${drawerWidth}px`,
				borderTop: `${theme.palette.customGrayDark} 2px solid`,
				borderRadius: '0px',
				overflowY: width >= 900 ? 'visible' : 'auto',
				}}
			>
				<Grid 
					container 
					alignItems="center"  
					justifyContent="flex-start"
				>
					{
						width >= 1024 &&
						<Grid item>
							<ComputeServerStateActionsV21 
								handleDataFetch={handleDataFetch}
								serverData={selectedServer}
								selectedRow={selectedRow}
								handleServerStatusChange={handleServerStatusChange}
								isSuspended={isSuspended}
								setSuspendedDialogOpen={setSuspendedDialogOpen}
							/>
						</Grid>
					}
					
					<Grid item>
						{
							selectedServerWithFlavorId.flavor &&
							<ComputeServerActionsV21 
								serverData={selectedServerWithFlavorId}
								serverSecGroups={serverSecGroups}
								handleDataFetch={handleDataFetch}
								secGroups={secGroups}
								networks={networks}
								subnets={subnets}
								attachedInterfaces={attachedInterfaces}
								attachedVolumes={attachedVolumes}
								volumes={volumes}
								flavors={flavors}
								isSuspended={isSuspended}
								setSuspendedDialogOpen={setSuspendedDialogOpen}
							/>
						}						
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default ComputeServerDetailV21