import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { LuFileJson2 } from "react-icons/lu";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import CustomBackdrop from '../../../../_common/CustomBackdrop';

const SERVICE_NAME = openStackServices.computeService

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 120
}

const ComputeServerMetadataV21 = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const { serverMetadata, serverData, handleDataFetch } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [selectedMetadata, setSelectedMetadata] = useState(null)
    const [deleteMetadataConfirmDialogOpen, setDeleteMetadataConfirmDialogOpen] = useState(false)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [error, setError] = useState();

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)

    const theme = useTheme();

    const onServerMetadataDelete = (key) => {
        setSelectedMetadata(key)
        setDeleteMetadataConfirmDialogOpen(true)
    }

    const handleDeleteMetadataConfirmDialogClose = () => {
        setSelectedMetadata(null)
        setDeleteMetadataConfirmDialogOpen(false)
    }

    const handleServerMetadataDelete = async () => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/metadata/${selectedMetadata}`
        const method = "DELETE"
        handleDeleteMetadataConfirmDialogClose()
        setIsLoading(true)
        const project_token = await getXAuthTokenProjectScope(serverData.tenant_id)
        if (project_token) {
            const server_response = await openstackRequest({
                url:url, 
                method:method, 
                token: project_token
            })
            setIsLoading(false)
            if (server_response.status_code === 204) {
                handleDataFetch()
            } else {
                setError(server_response.error)
            }
        }
        setIsLoading(false)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error,serverData]);

    return (
        <React.Fragment>
            {isLoading && <CustomBackdrop open={isLoading} />}
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={4}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack
                            direction="row" 
                            alignItems="start" 
                            justifyContent="center"
                            spacing={0}
                        >
                        <Stack
                            direction="column" 
                            alignItems="center" 
                            justifyContent="flex-start"
                            spacing={0}
                        >
                            <CustomText 
                                size="h4" 
                                sx={{
                                    color: theme.palette.dashboardPink
                                }}
                            >
                                {Object.keys(serverMetadata).length}
                            </CustomText>
                            <CustomText 
                                size="p" 
                                sx={{fontSize: {xs: 16, lg: 18}}}
                            >
                                {defaultTexts.appliedMetadataTitleText}
                            </CustomText>
                        </Stack>
                        <LuFileJson2
                            size={28}
                        />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {defaultTexts.keyKeywordText}
                                    </TableCell>
                                    <TableCell align="center">
                                        {defaultTexts.valueKeywordText}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {Object.keys(serverMetadata).map((key) => (
                                <TableRow
                                    key={key}
                                >
                                <TableCell 
                                    component="th" 
                                    scope="row"
                                    align="center"
                                >
                                    {key}
                                </TableCell>
                                <TableCell 
                                    align="center"
                                >
                                    {serverMetadata[key]}
                                    <IconButton
                                        sx={{
                                            color: "primary.main"
                                        }}
                                        onClick={() => onServerMetadataDelete(key)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        <CustomDialog
            open={deleteMetadataConfirmDialogOpen}
            onClose={handleDeleteMetadataConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.serverMetadataDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.serverMetadataDeleteConfirmText} [${selectedMetadata}: ${serverMetadata[selectedMetadata]}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: handleServerMetadataDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default ComputeServerMetadataV21;