import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box'
import request from '../../../_network/request';
import { billingRequest } from '../../../_network/openstack_request';
import { billingUrls } from '../../../_network/apiUrls';
import {
  getXAuthToken,
  openstackRequest,
  getXAuthTokenProjectScope,
  // getXAuthTokenApplicationCredential,
} from '../../../_network/openstack_request';
import constants from '../../../config/constants';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BigButton from '../../_common/BigButton';
import CustomDialog from '../../_common/CustomDialog';
import CustomBackdrop from '../../_common/CustomBackdrop';
import WrapperBox from '../../_common/WrapperBox';
import CustomTextField from '../../_common/_form_fields/CustomTextField';
import { FormGroup } from '@mui/material';
import { coreServiceURLs } from '../../../_network/apiUrls';
import { identityKeystonConstants } from '../../../config/openStackConstants'
import {
  setUserCredentials,
  setUserIsAuthenticated,
  setAdminProjects,
  setDefaultAdminProject,
  setUserID,
  setIdentityProvider,
  setXAuthToken,
  setSessionDatetime,
} from '../../../store/reducers/profileSlice';
import { setRegionName, setSavedProjectId } from '../../../store/reducers/settingsSlice'
import { setPurchasedServices, setPurchasedServicesAll, setSupportedRegions } from '../../../store/reducers/openStackSlice';
// import { handleProjectScopeRoleIdAssignment } from '../../identity_keystone/helpers/rolesHelpers';
import { GoogleReCapcha, googleCapchaValidation } from '../../_common/GoogleReCapcha'
import HomeContact from '../components/HomeContact'
import { getFormFieldComponent } from '../../_common/_form_fields/form_helpers'
import { isValidPassword, isValidEmail } from '../helpers/homeHelpers';
import PlanningCalculator from '../../billing/components/planning/PlanningCalculator';

const LoginForm = (props) => {
  const { promoHeight } = props

  const theme = useTheme()
  const dispatch = useDispatch()

  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const contactUs = useSelector(state => state.settings.contactUs)
  const openstackDomain = useSelector(state => state.settings.openstackDomain)
  // const openstackRole = useSelector(state => state.settings.openstackRole)
  const signupConfig = useSelector(state => state.settings.signupConfig)
  const regionName = useSelector(state => state.settings.regionName)
  const savedProjectId = useSelector(state => state.settings.savedProjectId)
  const textItems = useSelector(state => state.texts.langTexts)
  const identityProviderAuthUrls = useSelector(state => state.identityKeystone.identityKeystoneApiUrls)
  
  const [fieldUsername, setFieldUsername] = useState("");
  const [fieldUsernameError, setFieldUsernameError] = useState(false);
  const [fieldPasswordError, setFieldPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [fieldPassword, setFieldPassword] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [unauthorizedDialogVisible, setUnauthorizedDialogVisible] = useState(false);
  const [contactDialogVisible, setContactDialogVisible] = useState(false)
  const [signupDialogVisible, setSignupDialogVisible] = useState(false)
  const [signupDoneDialogVisible, setSignupDoneDialogVisible] = useState(false)
  const [calculatorDialogVisible, setCalculatorDialogVisible] = useState(false)

  const captchaRef = useRef("")
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [capchaValidationError, setCapchaValidationError] = useState(false)

  const signUpCaptchaRef = useRef("")
  const [signUpCaptchaValidated, setSignUpCaptchaValidated] = useState(false)
  const [signUpCapchaValidationError, setSignUpCapchaValidationError] = useState(false)

  const [signupFormData, setSignupFormData] = useState({})
  const [signupFormFields, setSignupFormFields] = useState([])
  const [signupFormOptions, setSignupFormOptions] = useState({})

  const [allowCalculator, setAllowCalculator] = useState(false)

  const sxWrapper = {
    margin: '-24px 0px -24px -24px',
    height: `${promoHeight}px`,
    position: 'relative',
    background: theme.palette.customWhite,
  }

  const sxTextButton = {
    color: theme.palette.primary.main,
    '&:hover': {
      color: alpha(theme.palette.primary.main, 0.7),
      background: 'none',
    }
  }

  // const sxForgotPasswordButton = {
  //   ...sxTextButton,
  //   textTransform: 'capitalize',
  // }

  const sxContactButton = {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: alpha(theme.palette.secondary.main, 0.7),
      background: 'none',
    }
  }

  const sxCalculatorButton = {
    color: theme.palette.primary.main,
    '&:hover': {
      color: alpha(theme.palette.primary.main, 0.7),
      background: 'none',
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const passwordInputHandle = (event) => {
    setFieldPasswordError(false)
    setFieldPassword(event.target.value);
  }
  
  const usernameInputHandle = (event) => {
    setFieldUsernameError(false)
    setFieldUsername(event.target.value);
  }

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  }

  const handleBackdropToggle = () => {
    setBackdropOpen(!backdropOpen)
  }

  const handleUnauthorizedDialogClose = () => {
    setUnauthorizedDialogVisible(false)
  }

  const handleContactDialogOpen = () => {
    setContactDialogVisible(true)
  }

  const handleContactDialogClose = () => {
    setContactDialogVisible(false)
  }

  const handleSignupDialogOpen = () => {
    setSignupDialogVisible(true)
  }

  const handleSignupDialogClose = () => {
    setSignupDialogVisible(false)
    handleSignupFormReset()
  }

  const handleSignupDoneDialogOpen = () => {
    setSignupDoneDialogVisible(true)
  }

  const handleSignupDoneDialogClose = () => {
    setSignupDoneDialogVisible(false)
  }

  const handleCalculatorDialogOpen = () => {
    setCalculatorDialogVisible(true)
  }

  const handleCalculatorDialogClose = () => {
    setCalculatorDialogVisible(false)
  }

  const getProjectsUsersUrl = (identityProvider, userId) => {
    let user_url = ""
    let projects_url = ""

    if (identityProvider.config_params.api_version === "v3") {
      const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
          item.api_version === identityProvider.config_params.api_version
      )[0].urls

      user_url = identityKeystoneApiUrlsList.filter(item => 
        item.api_group === identityKeystonConstants.usersApiUrls && 
        item.keyword === identityKeystonConstants.usersUrl)[0].url

      projects_url = identityKeystoneApiUrlsList.filter(item => 
        item.api_group === identityKeystonConstants.identityProjectsApiUrls && 
        item.keyword === identityKeystonConstants.projectsUrl)[0].url
    }

    return `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${user_url}/${userId}/${projects_url}`
  }

  // const getProjectsUrl = (identityProvider) => {
  //   let projects_url = ""

  //   if (identityProvider.config_params.api_version === "v3") {
  //     const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
  //         item.api_version === identityProvider.config_params.api_version
  //     )[0].urls

  //     projects_url = identityKeystoneApiUrlsList.filter(item => 
  //       item.api_group === identityKeystonConstants.identityProjectsApiUrls && 
  //       item.keyword === identityKeystonConstants.projectsUrl)[0].url
  //   }

  //   return `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${projects_url}`
  // }

  // const getUsersUrl = (identityProvider) => {
  //   let users_url = ""

  //   if (identityProvider.config_params.api_version === "v3") {
  //     const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
  //         item.api_version === identityProvider.config_params.api_version
  //     )[0].urls

  //     users_url = identityKeystoneApiUrlsList.filter(item => 
  //       item.api_group === identityKeystonConstants.usersApiUrls && 
  //       item.keyword === identityKeystonConstants.usersUrl)[0].url
  //   }

  //   return `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${users_url}`
  // }

  const getSignupPostData = () => {
    let result = {}
    
    if (signupConfig.form_fields && signupConfig.form_fields.length > 0) {
      signupConfig.form_fields.forEach(item => {
        if (item.openstack_field) {
          result[item.openstack_field] = signupFormData[item.field_key]
        }        
      })
    }

    return result
  }

  const loginHandler = async (username, password) => {
    const query_params = `?client_account_id=${clientAccountID}&subscription_package_group=${constants.subscription_package_group}`
      
    const url = `${constants.core_service_domain}/${coreServiceURLs.getClientConfig}/${query_params}`

    const request_data = {
      url: url,
      method: 'GET',
    }
    
    const response = await request(request_data)
    
    if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
      const client_account_config = response.data

      const identityService = client_account_config.find(item => item.config_params && item.config_params.is_identity_provider)
      const nonIdentityServices = client_account_config.filter(item => item.service_type === 'backend' && !item.config_params.is_identity_provider)

      const supported_regions = [...new Set(nonIdentityServices.map(item => item.config_params.region_name))]

      if (identityService) {
        const tokenResponse = await getXAuthToken(username, password, identityService, openstackDomain)
        
        if (tokenResponse && tokenResponse.user_id && tokenResponse.x_auth_token) {
          dispatch(setUserCredentials({ username, password }))

          dispatch(setUserID({ user_id: tokenResponse.user_id }))

          dispatch(setIdentityProvider({ identityProvider: identityService }))

          const projectsUrl = getProjectsUsersUrl(identityService, tokenResponse.user_id)
          
          const projectsResponse = await openstackRequest({
            url: projectsUrl,
            method: 'GET',
            token: tokenResponse.x_auth_token,
          })
          
          if (projectsResponse.status_code && projectsResponse.status_code < 400 && projectsResponse.data && projectsResponse.data.projects && projectsResponse.data.projects.length > 0) {
            let projectId = projectsResponse.data.projects[0].id

            if (savedProjectId) {
              const projectObject = projectsResponse.data.projects.find(item => item.id === savedProjectId)
              if (projectObject) {
                projectId = savedProjectId
              }
            } else {
              dispatch(setSavedProjectId({ savedProjectId: projectId }))
            }

            dispatch(setDefaultAdminProject({ defaultAdminProject: projectId }))

            const authResponse = await getXAuthTokenProjectScope(projectId)
            
            if (authResponse) {
              const nonBackendServices = client_account_config.filter(s => s.service_type !== "backend")
              let backendServices = client_account_config.filter(s => s.service_type === "backend")

              if (regionName) {
                backendServices = backendServices.filter(s => s.config_params.region_name === regionName)
                backendServices = [identityService, ...backendServices]
              } else if (identityService.config_params.default_region !== null) {
                backendServices = backendServices.filter(s => s.config_params.region_name === identityService.config_params.default_region)
                backendServices = [identityService, ...backendServices]
                dispatch(setRegionName({ regionName: identityService.config_params.default_region }))
              }

              const purchasedRegionServices = [...backendServices, ...nonBackendServices]

              dispatch(setXAuthToken({ x_auth_token: authResponse }))
              dispatch(setAdminProjects({ adminProjects: projectsResponse.data.projects }))
              dispatch(setUserIsAuthenticated({ isAuthenticated: true }))
              dispatch(setSessionDatetime({ sessionDatetime: new Date().toISOString() }))
              dispatch(setPurchasedServices({ purchasedServices: purchasedRegionServices }))
              dispatch(setPurchasedServicesAll({ purchasedServicesAll: client_account_config }))
              dispatch(setSupportedRegions({ supportedRegions: supported_regions }))              
              setFieldUsername("")
              setFieldPassword("")
            } else {
              setUnauthorizedDialogVisible(true)
              return false
            }            
          } else {
            setUnauthorizedDialogVisible(true)
            return false
          }
        } else {
          setUnauthorizedDialogVisible(true)
          return false
        }
      } else {
        setUnauthorizedDialogVisible(true)
        return false
      }
    } else {
      setUnauthorizedDialogVisible(true)
      return false
    }
  }

  const submitLoginForm = async () => {
    handleBackdropToggle()

    let formIsValid = true

    if (fieldUsername.length > 0) {
      formIsValid = formIsValid && true
    } else {
      setFieldUsernameError(true)
      formIsValid = false
    }

    if (fieldPassword.length > 0) {
      formIsValid = formIsValid && true
    } else {
      formIsValid = false
      setFieldPasswordError(true)
    }

    if (formIsValid) {
      const reCapchaResponse = captchaValidated ? true : await googleCapchaValidation(captchaRef.current.getValue())
      setCaptchaValidated(reCapchaResponse ? true : false)

      if (reCapchaResponse) {
        await loginHandler(fieldUsername, fieldPassword)
      } else {
        formIsValid = false
        setCapchaValidationError(true)
      }
    }
    
    handleBackdropClose()
  }

  const handleSignupFormReset = () => {
    setSignupFormOptions({})
    let new_form_data = {}

    for (const n in signupFormFields) {
      if (
        signupFormFields[n].field_type === "string" || 
        signupFormFields[n].field_type === "select" ||
        signupFormFields[n].field_type === "list"
        ) {
        new_form_data[signupFormFields[n].field_key] = ""
      } else if (signupFormFields[n].field_type === "bool") {
        new_form_data[signupFormFields[n].field_key] = signupFormFields[n].default_value ? 
        signupFormFields[n].default_value : 
        false
      } else if (signupFormFields[n].field_type === "integer") {
        new_form_data[signupFormFields[n].field_key] = signupFormFields[n].default_value ? 
        signupFormFields[n].default_value : 
        0
      } else if (signupFormFields[n].field_type === "float") {
        new_form_data[signupFormFields[n].field_key] = signupFormFields[n].default_value ? 
        signupFormFields[n].default_value : 
        0
      }
    }

    setSignupFormData(new_form_data)
  }

  const handleFormDataChange = (event, field_key) => {
    let new_form_data = { ...signupFormData }

    if (signupFormFields.filter(
      item => item.field_key === field_key)[0].field_type === "bool") {
      new_form_data[field_key] = event.target.checked
    } else if (signupFormFields.filter(
      item => item.field_key === field_key)[0].field_type === "select") {
      new_form_data[field_key] = event
    } else {
      new_form_data[field_key] = event.target.value
    }

    setSignupFormOptions({})
    setSignupFormData(new_form_data)
  }
  
  const getSignUpDataForm = () => {
    return (
      <FormGroup>
        {
          signupFormFields.map(field => {
            let form_field_options = { ...signupFormOptions[field.field_key] }
            form_field_options["withHelp"] = field.with_help
            form_field_options["helpText"] = field.help_text ? field.help_text : ''
            form_field_options["helpIsFile"] = field.help_is_file ? field.help_is_file : false
            form_field_options["helpIsUrl"] = field.help_is_url ? field.help_is_url : false
            form_field_options["helpTextWidth"] = 300
            form_field_options["zIndex"] = 1300
            if (field.with_help) {
                form_field_options["sx"] = {my: 1, width: {xs: "83%", lg: "90%"}}
            }

            return (
              getFormFieldComponent(
                field,
                signupFormData,
                handleFormDataChange,
                field.label,
                { ...form_field_options }
              )
            )
          })
        }

        <GoogleReCapcha 
          ref={signUpCaptchaRef}
          error={signUpCapchaValidationError}
        />
      </FormGroup>
    )
  }

  const handleFormDataValidation = () => {
    let validation_faild = true
    let updatedDataFormOptions = { ...signupFormOptions }

    for (let n in signupFormFields) {
      if (signupFormFields[n].required && !signupFormData[signupFormFields[n].field_key]) {
        validation_faild = false
        updatedDataFormOptions[signupFormFields[n].field_key] = {}
        updatedDataFormOptions[signupFormFields[n].field_key]["error"] = true
        updatedDataFormOptions[signupFormFields[n].field_key]["errorText"] = textItems.requiredFormFieldError
      }

      if (signupFormFields[n].field_key === 'password' && signupFormData[signupFormFields[n].field_key] && !isValidPassword(signupFormData[signupFormFields[n].field_key])) {
        validation_faild = false
        updatedDataFormOptions[signupFormFields[n].field_key] = {}
        updatedDataFormOptions[signupFormFields[n].field_key]["error"] = true
        updatedDataFormOptions[signupFormFields[n].field_key]["errorText"] = textItems.passwordRequirements
      }

      if (signupFormFields[n].field_key === 'confirm_password' && signupFormData[signupFormFields[n].field_key] && signupFormData[signupFormFields[n].field_key] !== signupFormData.password) {
        validation_faild = false
        updatedDataFormOptions[signupFormFields[n].field_key] = {}
        updatedDataFormOptions[signupFormFields[n].field_key]["error"] = true
        updatedDataFormOptions[signupFormFields[n].field_key]["errorText"] = textItems.passwordNotMatch
      }

      if (signupFormFields[n].field_key === 'email' && signupFormData[signupFormFields[n].field_key] && !isValidEmail(signupFormData[signupFormFields[n].field_key])) {
        validation_faild = false
        updatedDataFormOptions[signupFormFields[n].field_key] = {}
        updatedDataFormOptions[signupFormFields[n].field_key]["error"] = true
        updatedDataFormOptions[signupFormFields[n].field_key]["errorText"] = textItems.invalidEmailFormFieldError
      }
    }

    setSignupFormOptions(updatedDataFormOptions)
    return validation_faild
  }

  const signupHandler = async () => {
    handleBackdropToggle()

    let validateFormData = handleFormDataValidation()

    const reCapchaResponse = signUpCaptchaValidated ? true : await googleCapchaValidation(signUpCaptchaRef.current.getValue())
    setSignUpCaptchaValidated(reCapchaResponse ? true : false)

    if (!reCapchaResponse) {
      validateFormData = false
      setSignUpCapchaValidationError(true)
    }
    
    if (validateFormData) {
      const url = `${constants.core_service_domain}/${coreServiceURLs.getOpenStackUserSignUp}`

      const postData = getSignupPostData()
      
      const request_data = {
        url,
        method: 'POST',
        data: {
          user: postData,
        },
        headers: {
          'customer-account-id': clientAccountID,
        },
      }
      
      const response = await request(request_data)
      
      if (response.status_code && response.status_code < 400) {
        handleSignupFormReset()
        handleSignupDialogClose()
        handleSignupDoneDialogOpen()
      } else {
        setUnauthorizedDialogVisible(true)
        return false
      }
    }

    handleBackdropClose()
  }

  // const signupHandler = async () => {
  //   handleBackdropToggle()

  //   let validateFormData = handleFormDataValidation()

  //   const reCapchaResponse = signUpCaptchaValidated ? true : await googleCapchaValidation(signUpCaptchaRef.current.getValue())
  //   setSignUpCaptchaValidated(reCapchaResponse ? true : false)

  //   if (!reCapchaResponse) {
  //     validateFormData = false
  //     setSignUpCapchaValidationError(true)
  //   }
    
  //   if (validateFormData) {
  //     const query_params = `?client_account_id=${clientAccountID}&subscription_package_group=${constants.subscription_package_group}`
      
  //     const url = `${constants.core_service_domain}/${coreServiceURLs.getClientConfig}/${query_params}`

  //     const request_data = {
  //       url: url,
  //       method: 'GET',
  //     }
      
  //     const response = await request(request_data)
      
  //     if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
  //       const identityService = response.data.find(item => item.config_params && item.config_params.is_identity_provider)
        
  //       if (identityService) {
  //         const tokenResponse = await getXAuthTokenApplicationCredential(identityService)
          
  //         if (tokenResponse && tokenResponse.user_id && tokenResponse.x_auth_token) {
  //           const usersUrl = getUsersUrl(identityService)
            
  //           const postData = getSignupPostData()
            
  //           const signupResponse = await openstackRequest({
  //             url: usersUrl,
  //             method: 'POST',
  //             data: { user: postData },
  //             token: tokenResponse.x_auth_token,
  //           })
            
  //           if (signupResponse.status_code && signupResponse.status_code < 400) {
  //             handleSignupFormReset()
  //             handleSignupDialogClose()
  //             handleSignupDoneDialogOpen()

  //             const user_id = (signupResponse.data && signupResponse.data.user && signupResponse.data.user.id) ? signupResponse.data.user.id : null

  //             const project_id = await handleAddProject(identityService, postData.name)

  //             await handleProjectScopeRoleIdAssignment(getProjectsUrl(identityService), project_id, user_id, openstackRole)
  //           } else {
  //             setUnauthorizedDialogVisible(true)
  //             return false
  //           }
  //         } else {
  //           setUnauthorizedDialogVisible(true)
  //           return false
  //         }
  //       } else {
  //         setUnauthorizedDialogVisible(true)
  //         return false
  //       }
  //     } else {
  //       setUnauthorizedDialogVisible(true)
  //       return false
  //     }
  //   }

  //   handleBackdropClose()
  // }

  // const handleAddProject = async (identityProvider, username) => {
  //   const url = getProjectsUrl(identityProvider)

  //   const request_data = {
  //     name: username + '_1',
  //     domain_id: openstackDomain,
  //   }

  //   const method = "POST"
    
  //   const response = await openstackRequest({ url, method, data: { project: request_data } })

  //   if (response.status_code && response.status_code < 400 && response.data && response.data.project.id) {
  //     return response.data.project.id
  //   }
  // } 
  
  useEffect(() => {
    if (signupConfig.form_fields && signupConfig.form_fields.length > 0) {
      let result = []

      signupConfig.form_fields.forEach(item => {
        let fieldType = item.field_type
        let helpText = ''
        let helpIsFile = false
        let helpIsUrl = false

        if (fieldType === 'boolean') {
          fieldType = 'bool'
        }

        if (item.content_type === 'password') {
          fieldType = item.content_type
          helpText = textItems.passwordRequirements
        }

        if (item.field_key === 'tou' && signupConfig.documents) {
          const document = signupConfig.documents.find(item => item.label.toLowerCase().includes('terms'))
          helpText = document ? document.value : ''
          helpIsFile = document.type === 'file'
          helpIsUrl = document.type === 'url'
        }

        if (item.field_key === 'pp' && signupConfig.documents) {
          const document = signupConfig.documents.find(item => item.label.toLowerCase().includes('policy'))
          helpText = document ? document.value : ''
          helpIsFile = document.type === 'file'
          helpIsUrl = document.type === 'url'
        }

        result.push({
          ...item,
          field_type: fieldType,
          with_help: (item.field_key === 'tou' || item.field_key === 'pp' || item.field_key === 'password'),
          help_text: helpText,
          help_is_file: helpIsFile,
          help_is_url: helpIsUrl,
        })
      })

      setSignupFormFields(result)
    } else {
      setSignupFormFields([])
    }
  }, [signupConfig, textItems])

  useEffect(() => {
    const fetchAccountConfigsPublicData = async () => {
      const url = constants.billing_url + '/' + billingUrls.getAccountConfigsPublic

      const request_data = {
        url,
        method: 'GET',
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.allow_public_price_calculator) {
        setAllowCalculator(true)
      } else {
        setAllowCalculator(false)
      }
    }

    fetchAccountConfigsPublicData()
  }, [clientAccountID])

  return (
    <WrapperBox sx={sxWrapper}>
      
      <CustomTextField 
        required={true}
        size="medium"
        error={fieldUsernameError}
        errorText={textItems.missingUsernameError}
        variant="outlined"
        label={textItems.usernameLabel}
        currentValue={fieldUsername}
        setCurrentValue={usernameInputHandle}
        StartIcon={<AccountCircleIcon />}
        sx={{ 
          width: { xs: 300 },
          marginBottom: '20px',
        }}
      />

      <CustomTextField 
        required={true}
        size="medium"
        error={fieldPasswordError}
        errorText={textItems.missingPasswordError}
        variant="outlined"
        label={textItems.passwordLabel}
        currentValue={fieldPassword}
        setCurrentValue={passwordInputHandle}
        type={showPassword ? 'text' : 'password'}
        StartIcon={<LockIcon />}
        EndIcon={
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
          {
            showPassword ? 
            <VisibilityOff /> : 
            <Visibility />
          }
          </IconButton>
        }
        sx={{
          width: { xs: 300 },
          marginBottom : '10px',
        }}
      />

      {/* <Box sx={{ width: '300px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <Button variant="text" disableRipple sx={sxForgotPasswordButton}>{textItems.forgotPassword}</Button>
      </Box> */}

      <GoogleReCapcha 
        ref={captchaRef}
        error={capchaValidationError}
      />

      <BigButton 
        btnTitleText={textItems.loginButtonTitle}
        onClickHandler={submitLoginForm}
        sx={{ margin: '40px 0px 20px 0px' }}
      />
      
      {
        signupConfig.enabled &&
        <Box>
          <Button variant="text" disableRipple sx={sxTextButton} onClick={handleSignupDialogOpen}>{textItems.signUp}</Button>
        </Box>
      }
      
      {
        contactUs &&
        <Box sx={{ position: 'absolute', left: '12px', bottom: '12px', }}>
          <Button variant="text" disableRipple sx={sxContactButton} onClick={handleContactDialogOpen}>{textItems.contactUs}</Button>
        </Box>
      }

      {
        allowCalculator &&
        <Box sx={{ position: 'absolute', right: '12px', bottom: '12px', }}>
          <Button variant="text" disableRipple sx={sxCalculatorButton} onClick={handleCalculatorDialogOpen}>{textItems.priceCalculator}</Button>
        </Box>
      }     

      <CustomBackdrop open={backdropOpen} />

      <CustomDialog
        open={unauthorizedDialogVisible}
        onClose={handleUnauthorizedDialogClose}
        dialogTitle={{
          title: textItems.unauthorizedDialogTitle, 
          sx: {color: 'primary.main'}}}
        dialogBody={{
          text: textItems.unauthorizedDialogBody, 
          sx: {color: 'text.primary'}}}
        actionButtons={[{
          title: textItems.auauthorizedDialogButtonTitle, 
          onClick: handleUnauthorizedDialogClose, 
          sx: {color: 'primary.main'}}]}
      />

      <CustomDialog
        open={signupDoneDialogVisible}
        onClose={handleSignupDoneDialogClose}
        dialogTitle={{
          title: textItems.signUp, 
          sx: {color: 'primary.main'}}}
        dialogBody={{
          text: textItems.signUpSuccessfull, 
          sx: {color: 'text.primary'}}}
        actionButtons={[{
          title: textItems.auauthorizedDialogButtonTitle, 
          onClick: handleSignupDoneDialogClose, 
          sx: {color: 'primary.main'}}]}
      />

      <CustomDialog
        open={contactDialogVisible}
        onClose={handleContactDialogClose}
        dialogTitle={{
          title: textItems.contactUs, 
          sx: { color: theme.palette.secondary.main }
        }}
        dialogBody={{
          text: contactUs.company ? contactUs.company : '', 
          sx: {
            color: theme.palette.primary.main,
          }
        }}
      >
        <HomeContact contactUs={contactUs} />
      </CustomDialog>

      <CustomDialog
        open={signupDialogVisible}
        onClose={handleSignupDialogClose}
        dialogTitle={{
          title: textItems.signUp, 
          sx: { color: theme.palette.primary.main }
        }}
        dialogBody={{ text: '' }}
        actionButtons={[{
          title: textItems.submitButtonText, 
          onClick: signupHandler, 
          variant: "contained",
          size: "medium",
          sx: { color: 'white' },
        }]}
      >
        { getSignUpDataForm() }
      </CustomDialog>

      <CustomDialog
        open={calculatorDialogVisible}
        onClose={handleCalculatorDialogClose}
        dialogTitle={{
          title: textItems.priceCalculator, 
          sx: { color: theme.palette.primary.main }
        }}
        dialogBody={{ text: '' }}
        maxWidth='xl'
      >
        <PlanningCalculator />
      </CustomDialog>
      
    </WrapperBox>
  )
}

export default LoginForm