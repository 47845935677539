import Constants from '../config/constants';
import { store } from '../store/configStore';
import { getAccessToken } from './auth';
import { setAccessToken } from '../store/reducers/profileSlice';

const request = async ({url, method, headers, data, form_data, timeout}) => {
    const set_timeout = timeout ? timeout : 10000
    const state = store.getState() 
    let access_token = state.profile.access_token 
    if (!access_token) {
        const new_access_token = await getAccessToken()
        if (new_access_token) {
            access_token = new_access_token
            store.dispatch(setAccessToken({access_token: new_access_token}))
        }
    }
    
    let options = {
        method,
        headers: {
        ...headers
        }
    }

    options.headers["Authorization"] = `Bearer ${access_token}`
    
    if (data) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data)
    }

    if (form_data) {
        options.body = form_data
    }

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), set_timeout);
    
    let response = await fetch(url, {
        ...options,
        signal: controller.signal
    });
    
    clearTimeout(id);

    if (response.status === Constants.unAuthorizedStatusCode) {
        const new_access_token = await getAccessToken()
        if (new_access_token) {
            store.dispatch(setAccessToken({access_token: new_access_token}))
            options.headers["Authorization"] = `Bearer ${new_access_token}`
            response = await fetch(url, options)
        }
    }
    if (response.ok) {
        const status_code = response.status
        const res_data = await response.json()
        return {status_code: status_code, data: res_data}
    } 

    return { status_code: response.status, data: null }
}

export default request