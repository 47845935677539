import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { keypairDataSchema } from '../../../../../_data/openstack/compute/keypairs/v2.1';
import { setComputeKeypairsTableConfig } from '../../../../../store/reducers/computeNovaSlice';
import Box from '@mui/material/Box'
import useWindowDimensions from '../../../../_common/WindowDimensions'

const ComputeKeypairsTableV21 = (props) => {
	const { keypairsData, setKeypairsData, setSelectedIds, deselectAll, setDeselectAll, isSuspended } = props
	const { handleRowSelection, handleCellClick } = props
	const {currentAction} = props
	const {setCurrentAction}  = props
	const { actionsTexts }  = props
	const { actionsList } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
	
	const keypairsTableConfig = useSelector(
			state => state.computeNova.computeKeypairsTableConfig)
	const rowsPerPage = useSelector(
			state => state.computeNova.computeKeypairsTableConfig.filter(
			versions => versions.api_version === "v2.1")[0].rows_per_page)
	const keypairsTableColumns = useSelector(
			state => state.computeNova.computeKeypairsTableConfig.filter(
					versions => versions.api_version === "v2.1")[0].columns)
	
	const identityField = keypairsTableColumns.filter(
			item => item.is_id_field)[0].field_key

	const dispatch = useDispatch();

	const handleKeypairTableConfig = (value_list) => {
			let newTableConfig = keypairDataSchema.filter(
					n => value_list.includes(n.field_key))
			const new_data = keypairsTableConfig.map(version => {
					if (version.api_version === "v2.1") {
							let new_item = {...version}
							new_item.columns = [...newTableConfig]
							return new_item
					} else {
							return version
					}
			})
			dispatch(setComputeKeypairsTableConfig({
					computeKeypairsTableConfig: new_data
			}))
	}
	
	const handleRowsPerPageChange = (rows_num) => {
			const new_data = keypairsTableConfig.map(version => {
					if (version.api_version === "v2.1") {
							let new_item = {...version}
							new_item.rows_per_page = rows_num
							return new_item
					} else {
							return version
					}
			})
			dispatch(setComputeKeypairsTableConfig({
					computeKeypairsTableConfig: new_data
			}))
	}

	return (
		<Box
			sx={{
				width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
			}}
		>
			<CustomTable
				withPagination={false}
				withCheckbox={true}
				withActions={true}
				defaultDataTexts={{...defaultTexts}} 
				tableHeaders={keypairsTableColumns}
				tableHeadersConfigHandler={handleKeypairTableConfig}
				identityField={identityField}
				columns={keypairDataSchema}
				rowsPerPage={rowsPerPage}
				handleRowsPerPageChange={handleRowsPerPageChange}
				dataRows={keypairsData}
				setDataRows={setKeypairsData}
				handleRowSelection={handleRowSelection}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={actionsTexts}
				actionsList={actionsList}
				handleCellClick={handleCellClick}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		</Box>
	)
}

export default ComputeKeypairsTableV21