import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const SiteConnectionSpecsV20 = (props) => {
  const theme = useTheme()
	const { siteConnectionData, handleNavigateToIkePolicy, handleNavigateToIpsecPolicy, handleNavigateToVpnService, handleNavigateToEndpointGroup } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '350px',
		justifyContent: 'flex-start',
	}
  
	return (
			<React.Fragment>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.name ? siteConnectionData.name : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData ? siteConnectionData.id : ""}
															</CustomText>
													</Stack>
													<Divider />	
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.localIDFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.local_id ? siteConnectionData.local_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.peerAddressFormFieldLabel}
															</CustomText>
															<CustomText size="p">
                                  {siteConnectionData.peer_address ? siteConnectionData.peer_address : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.peerIDFormFieldLabel}
															</CustomText>
															<CustomText size="p">
                                  {siteConnectionData.peer_id ? siteConnectionData.peer_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.statusFormFieldLabel}
															</CustomText>
															<CustomText size="p">
                                  {siteConnectionData.status ? siteConnectionData.status : "-"}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.adminStateFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.admin_state_up ? 
																			defaultTexts.formValueYes :
																			defaultTexts.formValueNo
																	}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.localEndpointGroupFormFieldLabel}
															</CustomText>
															<CustomText size="p">
                                {
																	siteConnectionData.local_ep_group_id ?
																	<Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToEndpointGroup(siteConnectionData.local_ep_group_id)}>
																		{siteConnectionData.local_ep_group_id}
																	</Box> : "-"
																}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.peerEndpointGroupFormFieldLabel}
															</CustomText>
															<CustomText size="p">
                                {
																	siteConnectionData.peer_ep_group_id ?
																	<Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToEndpointGroup(siteConnectionData.peer_ep_group_id)}>
																		{siteConnectionData.peer_ep_group_id}
																	</Box> : "-"
																}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.peerCIDRsFormFieldLabel}
														</CustomText>
														<Stack alignItems="end">
																{
                                  siteConnectionData.peer_cidrs.map((ip, index) => 
																		<Box key={index}>
                                      <CustomText size="p">
                                        {ip}
                                      </CustomText>
                                    </Box>
                                  )
                                }
														</Stack>
												</Stack>
												<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ikePolicyFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																<Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToIkePolicy(siteConnectionData.ikepolicy_id)}>
																	{siteConnectionData.ikepolicy_id}
																</Box>																	
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipsecPolicyFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																<Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToIpsecPolicy(siteConnectionData.ipsecpolicy_id)}>
																	{siteConnectionData.ipsecpolicy_id}
																</Box>
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.vpnServiceFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																<Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToVpnService(siteConnectionData.vpnservice_id)}>
																	{siteConnectionData.vpnservice_id}
																</Box>																	
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.initiatorFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.initiator}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.dpdFormFieldLabel}
															</CustomText>
															<Stack alignItems="end">
                                <Box>
                                  <CustomText size="p">
                                    {defaultTexts.actionFormFieldLabel + ': ' + siteConnectionData.dpd.action}
                                  </CustomText>
                                </Box>
                                <Box>
                                  <CustomText size="p">
                                    {defaultTexts.dpdIntervalFormFieldLabel + ': ' + siteConnectionData.dpd.interval}
                                  </CustomText>
                                </Box>
                                <Box>
                                  <CustomText size="p">
                                    {defaultTexts.dpdTimeoutFormFieldLabel + ': ' + siteConnectionData.dpd.timeout}
                                  </CustomText>
                                </Box>
														</Stack>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.authModeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.auth_mode}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.routeModeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.route_mode}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.mtuFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.mtu}
															</CustomText>
													</Stack>
													<Divider />
                          <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{siteConnectionData.description}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
			</React.Fragment>
	)
}

export default SiteConnectionSpecsV20