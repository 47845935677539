import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import SegmentSpecsV20 from './segmentSpecsV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { segmentsUrl as segmentUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/segments/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    segmentDataUpdateForm
} from '../../../../../../_data/openstack/neutron/segments/v2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const SegmentDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { networks } = props
    const { handleNavigateToNetwork } = props
    const { handleNavigateToServer } = props
    const { handleNavigateToRouter } = props
    const { handleNavigateToSubnet } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [segmentActions, setSegmentActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [segmentUpdateData, setSegmentUpdateData] = useState({})
    const [updateSegmentDialogOpen, setUpdateSegmentDialogOpen] = useState(false)

    const [segmentSubMenu, setSegmentSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/segment-details", is_active: true}
    ])

    const [currentTab, setCurrentTab] = useState("/segment-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const segmentsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}/${selectedRow.id}`

    const getFormattedSegmentData = useCallback((data) => {
        let formatted_data = {...data}
        const network = networks.filter(n => n.id === data.network_id)[0]
        formatted_data.network_id = network ? 
        network.name : 
        data.network_id

        return formatted_data
    },[networks])

    const handleSegmentDetailTabChange = useCallback((navigation) => {
        let newSegmentSubmenuData = segmentSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setSegmentSubMenu(newSegmentSubmenuData)
        setCurrentTab(navigation)
    },[
        segmentSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleSegmentUpdateDataChange = (event,field_key) => {
        let new_form_data = {...segmentUpdateData}
        if (segmentDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (segmentDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setSegmentUpdateData(new_form_data)
    }

    const handleUpdateSegmentDialogOpen = () => {
        setUpdateSegmentDialogOpen(true)
    }

    const handleUpdateSegmentDialogClose = () => {
        setUpdateSegmentDialogOpen(false)
    }

    const onSegmentUpdate = async () => {
        let updated_data = {...segmentUpdateData}
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const segment_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {segment: updated_data},
                token: project_token
            })
            if (segment_response.status_code === segmentUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateSegmentDialogClose()
                handleDataFetch()
            } else {
                const error_response = segmentUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === segment_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: segment_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = segmentUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: segment_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    useEffect(() => {
        let segment_actions = []
        let new_action = {}
        new_action["value"] = "update_segment"
        new_action["action"] = handleUpdateSegmentDialogOpen
        new_action["keyword"] = "segmentUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        segment_actions.push({...new_action})
        setSegmentActions(segment_actions)
    },[
        selectedRow
    ])

    useEffect(() => {
        if (Object.keys(segmentUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in segmentDataUpdateForm) {
                new_form_data[segmentDataUpdateForm[n].field_key] = selectedRow[segmentDataUpdateForm[n].field_key]
            }
            setSegmentUpdateData(new_form_data)
        }
    },[segmentUpdateData, selectedRow]);


    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={segmentSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleSegmentDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/segment-details" &&
            <SegmentSpecsV20
                segmentData={getFormattedSegmentData(selectedRow)}
                selectedRow={selectedRow}
                handleNavigateToNetwork={handleNavigateToNetwork}
                handleNavigateToServer={handleNavigateToServer}
                handleNavigateToRouter={handleNavigateToRouter}
                handleNavigateToSubnet={handleNavigateToSubnet}
            />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={segmentActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={segmentActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[segmentActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateSegmentDialogOpen}
            onClose={handleUpdateSegmentDialogClose}
            dialogTitle={{
                title: defaultTexts.updateSegmentActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onSegmentUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                segmentDataUpdateForm,
                {},
                segmentUpdateData,
                handleSegmentUpdateDataChange
            )}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default SegmentDetailV20;