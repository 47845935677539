export const cinderLimitsUpdateDataForm = [
  {
      field_key: "volumes",
      field_type: "integer", 
      required: false,
      min: -1,
      label: "volumesLimitTitleText"
  },
  {
      field_key: "gigabytes",
      field_type: "integer", 
      required: false,
      min: -1,
      label: "storage"
  },
  {
    field_key: "snapshots",
    field_type: "integer", 
    required: false,
    min: -1,
    label: "submenuSnapshots"
  },
  {
    field_key: "backups",
    field_type: "integer", 
    required: false,
    min: -1,
    label: "submenuBackups"
  },
  {
    field_key: "backup_gigabytes",
    field_type: "integer", 
    required: false,
    min: -1,
    label: "backupStorageLabel"
  },
]