import React from 'react'
import { useSelector } from 'react-redux'
import getSymbolFromCurrency from 'currency-symbol-map'

const HeaderBalance = () => {
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)

  const currencySymbol = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? getSymbolFromCurrency(billingAccountConfig.currency.name) : ''

  return (
    <>{currencySymbol} { (billingDeposit && billingDeposit.amount) && billingDeposit.amount.toFixed(2) }</>
  )
}

export default HeaderBalance