import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const SubnetSpecsV20 = (props) => {
	const theme = useTheme()

	const { subnetData, selectedRow, handleNavigateToNetwork } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '300px',
		justifyContent: 'flex-start',
	}

	return (
			<React.Fragment>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.name ? subnetData.name : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData ? subnetData.id : ""}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
															onClick={() => handleNavigateToNetwork(selectedRow.network_id,"/l2-networks")}
															sx={{cursor: "pointer"}}
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.networkFormFieldLabel}
															</CustomText>
															<CustomText size="p" sx={{color: "primary.main"}}>
																	{subnetData.network_id ? 
																			subnetData.network_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.sharedFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.shared ? 
																			defaultTexts.formValueYes : 
																			defaultTexts.formValueNo}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.createTimeHeaderLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(subnetData.created_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.updatedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(subnetData.updated_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.description ? subnetData.description : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.revisionNumberFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.revision_number}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox sx={detailTabCardConfig}>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipVersionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	IPv{subnetData.ip_version}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.cidrFormFieldLabel}
															</CustomText>
															<Stack alignItems="end">
																	{subnetData.cidr}
															</Stack>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.gatewayIpFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.gateway_ip ? 
																			subnetData.gateway_ip : 
																			"-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.enableDhcpFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.enable_dhcp  ? 
																			defaultTexts.formValueYes : 
																			defaultTexts.formValueNo
																	}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.allocationPoolsFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.allocation_pools.map(pool => 
																					<Stack key={pool.start}>
																					<CustomText 
																							size="p" 
																					>
																					{pool.start} - {pool.end}
																					</CustomText>
																					</Stack>)
																	}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.dnsNameserversFormFieldLabel}
															</CustomText>
															<Stack>
																	{subnetData.dns_nameservers.length > 0 ? 
																			subnetData.dns_nameservers.map(dns => 
																					<CustomText 
																							size="p" 
																							key={dns}
																					>
																					{dns}
																			</CustomText>)
																			:
																			"-"
																	}
															</Stack>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.hostRoutesFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.host_routes.length > 0 ? 
																			subnetData.host_routes.map(route => 
																					<CustomText 
																							size="p" 
																							key={route.destination}
																					>
																					DST: {route.destination} - NH: {route.nexthop}
																			</CustomText>)
																			:
																			"-"
																	}
															</CustomText>
													</Stack>
													<Divider />
													{subnetData.ip_version === 6 && <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipv6AddressModeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.ipv6_address_mode ? subnetData.ipv6_address_mode : "-"}
															</CustomText>
													</Stack>}
													{subnetData.ip_version === 6 &&  <Divider />}
													{subnetData.ip_version === 6 && <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ipv6RouterModeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{subnetData.ipv6_ra_mode ? subnetData.ipv6_ra_mode : "-"}
															</CustomText>
													</Stack>}
													{subnetData.ip_version === 6 &&  <Divider />}
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
			</React.Fragment>
	)
}

export default SubnetSpecsV20