export const limitsDataSchema = [
    {
        field_key: "resource_name",
        is_id_field: true,
        field_type: "string", 
        label: "resourceNameFormFieldLabel"
    },
    {
        field_key: "used",
        is_id_field: false,
        field_type: "string", 
        label: "usedResourceQuotaFormFieldLabel"
    },
    {
        field_key: "available",
        is_id_field: false,
        field_type: "string", 
        label: "availableResourceQuotaFormFieldLabel"
    },
    {
        field_key: "limit",
        is_id_field: false,
        field_type: "string", 
        label: "maxResourceQuotaFormFieldLabel"
    }
]

export const limitsUpdateDataForm = [
    {
        field_key: "network",
        field_type: "integer", 
        required: false,
        label: "networkFormFieldLabel",
        min: -1
    },
    {
        field_key: "subnet",
        field_type: "integer", 
        required: false,
        label: "subnetFormFieldLabel",
        min: -1
    },
    {
        field_key: "port",
        field_type: "integer", 
        required: false,
        label: "portFormFieldLabel",
        min: -1
    },
    {
        field_key: "trunk",
        field_type: "integer", 
        required: false,
        label: "trunkFormFieldLabel",
        min: -1
    },
    {
        field_key: "router",
        field_type: "integer", 
        required: false,
        label: "routerFormFieldLabel",
        min: -1
    },
    {
        field_key: "floatingip",
        field_type: "integer", 
        required: false,
        label: "floatingIPFormFieldLabel",
        min: -1
    },
    {
        field_key: "security_group",
        field_type: "integer", 
        required: false,
        label: "securityGroupFormFieldLabel",
        min: -1
    },
    {
        field_key: "security_group_rule",
        field_type: "integer", 
        required: false,
        label: "securityGroupRuleFormFieldLabel",
        min: -1
    },
    {
        field_key: "firewall_group",
        field_type: "integer", 
        required: false,
        label: "firewallGroupFormFieldLabel",
        min: -1
    },
    {
        field_key: "firewall_policy",
        field_type: "integer", 
        required: false,
        label: "firewallPolicyFormFieldLabel",
        min: -1
    },
    {
        field_key: "firewall_rule",
        field_type: "integer", 
        required: false,
        label: "firewallRuleFormFieldLabel",
        min: -1
    },
    {
        field_key: "ikepolicy",
        field_type: "integer", 
        required: false,
        label: "ikePolicyFormFieldLabel",
        min: -1
    },
    {
        field_key: "ipsecpolicy",
        field_type: "integer", 
        required: false,
        label: "ipsecPolicyFormFieldLabel",
        min: -1
    },
    {
        field_key: "endpoint_group",
        field_type: "integer", 
        required: false,
        label: "endpointGroupFormFieldLabel",
        min: -1
    },
    {
        field_key: "vpnservice",
        field_type: "integer", 
        required: false,
        label: "vpnServiceFormFieldLabel",
        min: -1
    },
    {
        field_key: "ipsec_site_connection",
        field_type: "integer", 
        required: false,
        label: "ipsecSiteConnectionFormFieldLabel",
        min: -1
    }
]