import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import CustomTable from '../../../_common/CustomTable'
import { billingInvoicesDataSchema } from '../../../../_data/billing/payments/payments'
import useWindowDimensions from '../../../_common/WindowDimensions'

const InvoicesTable = (props) => {
  const { invoicesData, setInvoicesData, sortParams, sortHandler, setSelectedInvoices, deselectAll, setDeselectAll } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()

  const identityField = billingInvoicesDataSchema.filter(item => item.is_id_field)[0].field_key
  
  return (
    <Box
      sx={{
        width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
      }}
    >
      <CustomTable
        withPagination={false}
        withCheckbox={true}
        withActions={false}
        withSorting={false}
        defaultDataTexts={{ ...defaultTexts }} 
        tableHeaders={billingInvoicesDataSchema}      
        columns={[...billingInvoicesDataSchema]} 
        dataRows={invoicesData}
        setDataRows={setInvoicesData}      
        sortHandler={sortHandler}
        selfSorting={true}
        identityField={identityField}
        setSelectedIds={setSelectedInvoices}
        sortParams={sortParams}
        deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
        showObjectInCell={true}
      />
    </Box>
  )
}

export default InvoicesTable