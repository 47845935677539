import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import { openStackServices, networkNeutronConstants } from '../../../../../../config/openStackConstants';
import { vpnSiteConnectionDataForm } from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import AddButtonWithText from '../../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.networkService

const SiteConnectionAddV20 = (props) => {
  const { handleDataFetch } = props
  const { ikePolicies } = props
  const { ipsecPolicies } = props
  const { vpnServices } = props
  const { endpointGroups } = props
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successVPNSiteConnectionAdd, setSuccessVPNSiteConnectionAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});

  const neutronServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const neutronServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const vpnSiteConnectionsUrl = useSelector(
      state => state.networkNeutron.networkNeutronApiUrls.filter(
          version => version.api_version === "v2.0")[0].urls.filter(
              url => url.keyword === networkNeutronConstants.vpnSiteConnectionsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessVPNSiteConnectionAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddVPNSiteConnectionFormReset = () => {
      setFormDataOptions({})
      setFormData({})
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (vpnSiteConnectionDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (vpnSiteConnectionDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }

  const getDataForm = () => {
      let form = [...vpnSiteConnectionDataForm]
      return (
          <FormGroup>
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  form_field_options = {...form_field_options, ...field}
                  delete form_field_options["label"]
                  form_field_options["item_titles"] = defaultTexts
                  if (field.field_key === "ikepolicy_id") {
                      const policy_list = ikePolicies.map(r => {
                          return {keyword: r.name, value: r.id, default: false}
                      })
                      form_field_options["items"] = [...policy_list]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  } else if (field.field_key === "ipsecpolicy_id") {
                      const policy_list = ipsecPolicies.map(r => {
                          return {keyword: r.name, value: r.id, default: false}
                      })
                      form_field_options["items"] = [...policy_list]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  } else if (field.field_key === "vpnservice_id") {
                      const service_list = vpnServices.map(r => {
                          return {keyword: r.name, value: r.id, default: false}
                      })
                      form_field_options["items"] = [...service_list]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  } else if (field.field_key === "local_ep_group_id") {
                      const eg_list = endpointGroups.map(r => {
                          return {keyword: r.name, value: r.id, default: false}
                      })
                      form_field_options["items"] = [...eg_list]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  } else if (field.field_key === "peer_ep_group_id") {
                      const eg_list = endpointGroups.map(r => {
                          return {keyword: r.name, value: r.id, default: false}
                      })
                      form_field_options["items"] = [...eg_list]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = false
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in vpnSiteConnectionDataForm) {
          if (vpnSiteConnectionDataForm[n].required && !formData[vpnSiteConnectionDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[vpnSiteConnectionDataForm[n].field_key] = {}
              updatedDataFormOptions[vpnSiteConnectionDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[vpnSiteConnectionDataForm[n].field_key]["errorText"] = defaultTexts[vpnSiteConnectionDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const formatConnetionData = () => {
      let updated_data = {...formData}
      updated_data["dpd"] = {}
      updated_data["dpd"]["interval"] = formData.interval
      updated_data["dpd"]["timeout"] = formData.timeout
      updated_data["dpd"]["action"] = formData.action
      if (formData.peer_cidrs.length > 0) {
          updated_data.peer_cidrs = formData.peer_cidrs.split(",")
      } else {
          delete updated_data.peer_cidrs
      }
      
      delete updated_data.interval
      delete updated_data.timeput
      delete updated_data.action
      if (formData.local_ep_group_id.length === 0) {
          delete updated_data.local_ep_group_id
      }
      if (formData.peer_ep_group_id.length === 0) {
          delete updated_data.peer_ep_group_id
      }
      return updated_data
  }

  const onAddVPNSiteConnection = async () => {
      const data_is_valid = handleFormDataValidation()
      if (data_is_valid) {
          const formatted_data = formatConnetionData()
          
          const method = "POST"
          const vpnGroup_response = await openstackRequest({
              url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnSiteConnectionsUrl}`, 
              method: method, 
              data: {ipsec_site_connection: formatted_data},
              token,
          })
          if (vpnGroup_response.status_code === vpnUrlResponses.post.success_response.status_code) {
              handleAddVPNSiteConnectionFormReset()
              handleDataFetch()
          } else {
              const error_response = vpnUrlResponses.post.error_response.filter(
                  error_item => error_item.status_code === vpnGroup_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: vpnGroup_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = vpnUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: vpnGroup_response.error
                  }
                  setError(errorObject)
              }
          }          
      } 
      return data_is_valid
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successVPNSiteConnectionAdd]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in vpnSiteConnectionDataForm) {
              if (
                  vpnSiteConnectionDataForm[n].field_type === "string" || 
                  vpnSiteConnectionDataForm[n].field_type === "select"
                  ) {
                  new_form_data[vpnSiteConnectionDataForm[n].field_key] = vpnSiteConnectionDataForm[n].default_value ? 
                  vpnSiteConnectionDataForm[n].default_value : 
                  ""
              } else if (vpnSiteConnectionDataForm[n].field_type === "integer") {
                  new_form_data[vpnSiteConnectionDataForm[n].field_key] = vpnSiteConnectionDataForm[n].default_value
              } else if (vpnSiteConnectionDataForm[n].field_type === "bool") {
                  new_form_data[vpnSiteConnectionDataForm[n].field_key] = vpnSiteConnectionDataForm[n].default_value ? 
                  vpnSiteConnectionDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[
      formData
  ]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddVPNSiteConnection}
        formReset={handleAddVPNSiteConnectionFormReset}
        customTexts={{
          title: defaultTexts.addVPNSiteConnectionDialogTitle
        }}
      />

      {
        successVPNSiteConnectionAdd &&
        <CustomDialog
          open={successDialogOpen}
          onClose={handleSuccessDialogClose}
          dialogTitle={{
              title: defaultTexts[successVPNSiteConnectionAdd.success_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: defaultTexts[successVPNSiteConnectionAdd.success_message], 
              sx: {color: 'text.primary'}}}
        />
      }

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
              title: defaultTexts[error.error_title], 
              sx: {color: 'primary.main'}}}
          dialogBody={{
              text: `<span>${defaultTexts[error.error_message]}</span>
                      <br>
                      <br>
                      <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                      <span style="color: orange">
                          ${error.error_details}
                      </span>`, 
              sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default SiteConnectionAddV20