import React from 'react';
import Box from '@mui/material/Box';
import NetworksWrapperV20 from './networks/networksWrapperV2.0';
import PortsWrapperV20 from './ports/portsWrapperV2.0';
import SegmentsWrapperV20 from './segments/segmentsWrapperV2.0';
import TrunksWrapperV20 from './trunks/trunksWrapperV2.0';

const L2NetworkingWrapperV20 = (props) => {
	const { navigate, location, serviceMenu, serviceSubmenu, changeMenuActiveTab, segmentsSupported, trunksSupported, subsection } = props
	
	return (
		<Box>
			{subsection === "/networks" && 
					<NetworksWrapperV20 
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={subsection}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{subsection === "/ports" && 
					<PortsWrapperV20
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={subsection}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{subsection === "/segments" && segmentsSupported &&
					<SegmentsWrapperV20
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={subsection}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
			{subsection === "/trunks" && trunksSupported &&
					<TrunksWrapperV20 
							navigate={navigate}
							location={location}
							changeMenuActiveTab={changeMenuActiveTab}
							currentTab={subsection}
							serviceMenu={serviceMenu}
							serviceSubmenu={serviceSubmenu}
					/>
			}
		</Box>
	)
}

export default L2NetworkingWrapperV20