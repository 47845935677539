import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import useWindowDimensions from '../../../_common/WindowDimensions'
import BillingMenu from '../BillingMenu'
import PlanningCompare from './PlanningCompare'
import PlanningBreakdown from './PlanningBreakdown'
import PlanningCalculator from './PlanningCalculator'

const Planning = (props) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const { billingMenu, changeMenuActiveTab } = props

  const mode = useSelector(state => state.settings.uiMode)

  const chartContainerStyle = {
    background: mode === 'light' ? theme.palette.customWhite : theme.palette.customLightBlack,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    minHeight: width >=1400 ? '500px' : '620px',
    width: '100%',
    padding: '20px',
  }

  const getHeader = () => {
    return (
      <Stack
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{
          padding: '20px 20px 10px 20px',
          width: '100%',
          height: '60px',
        }}
      >
        <BillingMenu
          menu={billingMenu}
          onClick={changeMenuActiveTab}
        />
      </Stack>
    )
  }

  return (
    <>
      { getHeader() }

      <Box sx={{ margin: '0px 20px 20px 20px' }}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={6}>
            <Box sx={chartContainerStyle}>
              <PlanningCompare />
            </Box>
          </Grid>
          <Grid xs={12} lg={6}>
            <Box sx={chartContainerStyle}>
              <PlanningBreakdown />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ margin: '0px 20px 20px 20px' }}>
        <Box sx={chartContainerStyle}>
          <PlanningCalculator />
        </Box>
      </Box>      
    </>
  )
}

export default Planning