import React, { useEffect, useState, useCallback } from 'react';
//import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'; 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { openStackServices } from '../../../../../config/openStackConstants';
import { openstackRequest } from '../../../../../_network/openstack_request';
import { imagesGlanceConstants } from '../../../../../config/openStackConstants';
import CustomDialog from '../../../../_common/CustomDialog';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';
import { imageDataForm as imageForm } from '../../../../../_data/openstack/glance/images/v2';
import CustomTransferList from '../../../../_common/custom_transfer_list/CustomTransferList';

const SERVICE_NAME = openStackServices.imageService

const ImageActionsV2 = (props) => {
	const { imageData, onImageDeleteConfirm } = props
	const { handleDataFetch } = props
	const { imageMetadata, metadataCatalog } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	//const { imageMetadata } = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [currentAction, setCurrentAction] = useState("")
	const [imageActions, setImageActions] = useState([])
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [error, setError] = useState();
	const [successResponse, setSuccessResponse] = useState();
	const [formData, setFormData] = useState({})
	const [imageDataForm, setImageDataForm] = useState([...imageForm])

	const token = useSelector(state => state.profile.x_auth_token)

	//const theme = useTheme()

	const imageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const imageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const imagesUrl = useSelector(
			state => state.imageGlance.imageGlanceApiUrls.filter(
					version => version.api_version === "v2")[0].urls.filter(
							url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)

	const [updateImageSelectionDialogOpen, setUpdateImageSelectionDialogOpen] = useState(false);
	const [imageMetadataDialogOpen, setImageMetadataDialogOpen] = useState(false);
	const [updatedMetadata, setUpdatedMetadata] = useState({})

	const handleUpdateFormformatting = useCallback(() => {
			let form_data = {...imageData}
			if (imageData.status !== "queued") {
					delete form_data.architecture
					delete form_data.container_format
					delete form_data.disk_format
					let new_form = imageForm.filter(item => 
							item.field_key !== "architecture" &&
							item.field_key !== "container_format" &&
							item.field_key !== "disk_format"
					)

					setImageDataForm(new_form)
			}

			setFormData(form_data)
	},[
		imageData  
	])

	const handleUpdateImageSelectDialogOpen = useCallback(() => {
			handleUpdateFormformatting()
			setUpdateImageSelectionDialogOpen(true)
	},[
			handleUpdateFormformatting
	])

	const handleUpdateImageSelectionDialogClose = () => {
			setUpdateImageSelectionDialogOpen(false)
	}

	const handleImageMetadataDialogOpen = () => {
			setImageMetadataDialogOpen(true)
	}

	const handleImageMetadataSelectionDialogClose = () => {
			setUpdatedMetadata([])
			setImageMetadataDialogOpen(false)
	}

	useEffect(() => {
			let image_actions = []
			let new_action = {}
			new_action["value"] = "image_update"
			new_action["action"] = handleUpdateImageSelectDialogOpen
			new_action["keyword"] = "imageUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			image_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "image_delete"
			new_action["action"] = () => onImageDeleteConfirm([imageData.id])
			new_action["keyword"] = "imageDeleteActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			image_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "image_manage_metadata"
			new_action["action"] = handleImageMetadataDialogOpen
			new_action["keyword"] = "createUpdateMetadataActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			image_actions.push({...new_action})
			setImageActions(image_actions)
	},[
			imageData,
			onImageDeleteConfirm,
			handleUpdateImageSelectDialogOpen
	])

	const handleFormDataChange = (event,field_key) => {
			let new_form_data = {...formData}
			if (imageDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (imageDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setFormData(new_form_data)
	}

	const getDataForm = () => {
			return (
					<FormGroup>
							{imageDataForm.map(field => {
									let field_item = {...field}
									let form_field_options = {}
									if (field_item.field_type === "select") {
											form_field_options["items"] = [...field_item.items]
											form_field_options["item_titles"] = defaultTexts
											form_field_options["empty"] = field_item.default_empty
											form_field_options["self_item_titles"] = field_item.self_item_titles
									}
									if (field_item.field_key === "name" || field_item.field_key === "visibility") {
											field_item.required = false
									}
									return (
											getFormFieldComponent(
													field_item,
													formData,
													handleFormDataChange,
													defaultTexts[field.label],
													{
															sx: {m: 1, width: "90%"},
															...form_field_options
													}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleSuccessDialogOpen = () => {
			setSuccessDialogOpen(true);
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}
	const handleSuccessDialogClose = () => {
			setSuccessResponse(null);
			setSuccessDialogOpen(false);
	}

	const formatImageData = () => {
			let new_list = []
			for (let item in imageDataForm) {
					if (formData[imageDataForm[item].field_key] !== undefined) {
							let new_item = {}
							new_item["op"] = "replace"
							new_item["path"] = `/${imageDataForm[item].field_key}`
							new_item["value"] = (new_item["path"] === '/min_disk' || new_item["path"] === '/min_ram') ? parseInt(formData[imageDataForm[item].field_key]) : formData[imageDataForm[item].field_key]

							new_list.push(new_item)
					}
					
			}
			
			return new_list
	}

	const onImageUpdate = async () => {
		const data_to_send = formatImageData()
		
		const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}/${imageData.id}`
		const method = "PATCH"
		const headers = {"Content-Type":"application/openstack-images-v2.1-json-patch"}
		
		const image_response = await openstackRequest({
			url: url, 
			method: method, 
			headers: headers,
			form_data: JSON.stringify(data_to_send),
			token,
		})
		
		if (image_response.status_code === imagesUrlResponses.patch.success_response.status_code) {
			setSuccessResponse(defaultTexts.imageUpdateSuccessResponseMessage)
			setCurrentAction("")
			handleSuccessDialogOpen()
			handleDataFetch()
			handleUpdateImageSelectionDialogClose()
		} else {
			setError(image_response.error)
		}			
	}

	const onImageMetadataUpdate = async () => {
			let o_k = []
			let o_v = []
			let n_k = []
			let n_v = []
			for (const [old_k,old_v] of Object.entries(imageMetadata)) {
					o_k.push(old_k)
					o_v.push(old_v)
			}
			for (const [new_k,new_v] of Object.entries(updatedMetadata)) {
					n_k.push(new_k)
					n_v.push(new_v)
			}
			const added_keys = n_k.filter(item => !o_k.includes(item))
			const removed_keys = o_k.filter(item => !n_k.includes(item))
			const common_keys = o_k.filter(item => n_k.includes(item))

			let updated_metadata = []
			
			let updated_removed_items = removed_keys.map(item => {
					return {op: "remove", path: `/${item}`}
			})
			let updated_added_items = added_keys.map(item => {
					let formatted_value = typeof updatedMetadata[item] === "boolean" ? 
					JSON.stringify(updatedMetadata[item]).toLowerCase() : 
					updatedMetadata[item]
					return {op: "add", path: `/${item}`, value: formatted_value}
			})
			let updated_replaced_items = common_keys.map(item => {
					let formatted_value = typeof updatedMetadata[item] === "boolean" ? 
					JSON.stringify(updatedMetadata[item]).toLowerCase() : 
					updatedMetadata[item]
					return {op: "replace", path: `/${item}`, value: formatted_value}
			})
			updated_metadata = [...updated_removed_items, ...updated_added_items, ...updated_replaced_items]
			await handleImageMetadataUpdate(updated_metadata)
	}

	const handleImageMetadataUpdate = async (updated_metadata) => {
			const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}/${imageData.id}`
			const method = "PATCH"
			const headers = {"Content-Type":"application/openstack-images-v2.1-json-patch"}
			const image_response = await openstackRequest({
					url:url, 
					method:method, 
					headers: headers,
					form_data: JSON.stringify(updated_metadata),
					token,
			})
			if (image_response.status_code === imagesUrlResponses.patch.success_response.status_code) {
					setSuccessResponse(defaultTexts.imageUpdateSuccessResponseMessage)
					setCurrentAction("")
					handleSuccessDialogOpen()
					handleDataFetch()
					handleImageMetadataSelectionDialogClose()
			} else {
					setError(image_response.error)
			}        
	}

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			imageActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			handleUpdateFormformatting()
	},[
			imageData,
			handleUpdateFormformatting
	])

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error,imageData]);

	return (
			<Box>
					{imageActions && <CustomSelectField 
							items={imageActions}
							currentValue={currentAction}
							setCurrentValue={setCurrentAction}
							item_titles={defaultTexts}
							label={defaultTexts.actionsDropdownLabelText}
							empty={true}
							size="small"
							sx={{m: 1}}
					/>}
					{currentAction.length > 0 && 
							<Button 
											variant="contained"
											color="secondary"
											sx={{m: 1, height: '70%'}}
											onClick={handleApplyAction}
									>
									{defaultTexts[imageActions.filter(
											action => action.value === currentAction)[0].button_text]}
							</Button>
					}
					<CustomDialog
							open={updateImageSelectionDialogOpen}
							onClose={handleUpdateImageSelectionDialogClose}
							dialogTitle={{
									title: defaultTexts.imageUpdateActionTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: "", 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.submitButtonText, 
									onClick: onImageUpdate, 
									sx: {color: 'primary.main'}}]}
					>
							{getDataForm()}
					</CustomDialog>
					<CustomDialog
							open={imageMetadataDialogOpen}
							onClose={handleImageMetadataSelectionDialogClose}
							dialogTitle={{
									title: defaultTexts.imageMetadataUpdateActionTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: "", 
									sx: {color: 'text.primary'}}}
							actionButtons={[{
									title: defaultTexts.submitButtonText, 
									onClick: onImageMetadataUpdate, 
									sx: {color: 'primary.main'}}]}
							maxWidth="lg"
					>
							<CustomTransferList 
									withCustomList={true}
									withInstructions={true}
									catalogList={metadataCatalog}
									appliedFieldList={imageMetadata}
									onDataUpdate={setUpdatedMetadata}
							/>
					</CustomDialog>
					{error && <CustomDialog
							open={errorDialogOpen}
							onClose={handleErrorDialogClose}
							dialogTitle={{
									title: defaultTexts.failedActionErrorDialogTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
													<br>
													<br>
													<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
													<span style="color: orange">
															${error}
													</span>`, 
									sx: {color: 'text.primary'}}}
					/>}
					<CustomDialog
							open={successDialogOpen}
							onClose={handleSuccessDialogClose}
							dialogTitle={{
									title: defaultTexts.successActionDialogTitle, 
									sx: {color: 'primary.main'}}}
							dialogBody={{
									text: successResponse ?
											`<span>${defaultTexts.successActionDialogMessage}</span>
													<br>
													<br>
													<span>${defaultTexts.responseNoteDialogText}:</span> 
													<span style="width: 100px; color: orange; word-wrap: break-word;">
															${successResponse}
													</span>` :
											`<span>${defaultTexts.successActionDialogMessage}</span>`, 
									sx: {color: 'text.primary'}
							}}
					/>
			</Box>
	)
}

export default ImageActionsV2