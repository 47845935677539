import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CustomText from './CustomText'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const ServiceDoubleSubmenuDropdown = (props) => {
  const theme = useTheme()
  const { label, isActive, dropdownMenu, setCurrentTab, parentNavigation, onClick } = props
  
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const [anchorEl, setAnchorEl] = useState(null)
  
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  
  const handleClick = (navigation) => {
    setCurrentTab(navigation)
    onClick(parentNavigation)
  }

  return (
    <Box sx={{ display: 'inline-flex' }}>      
      <Button 
        onClick={handleOpenMenu} 
        sx={{           
          display: 'flex',
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          textTransform: 'capitalize',
          margin: '0px',
          padding: '0px',
          color: isActive ? theme.palette.primary.main : theme.palette.customGrayDark,
          '&:hover': {
            background: 'none',
          },
        }}
        disableRipple={true}
      >
        <CustomText sx={{ fontSize: '17px' }}>{defaultTexts[label]}</CustomText>
        <ArrowDropDownIcon />
      </Button>
      
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {
          dropdownMenu.map((item, index) => {
            return (
              <MenuItem 
                onClick={() => {handleClick(item.navigation)}} 
                key={index} 
                value={item.navigation}
              >
                { defaultTexts[item.keyword] }
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default ServiceDoubleSubmenuDropdown