import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import CustomText from './CustomText'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { setUIMode } from '../../store/reducers/settingsSlice'
import CustomSelectField from './_form_fields/CustomSelectField'
import { setCurrentLang } from '../../store/reducers/settingsSlice'
import { setLangTexts, textsReset } from '../../store/reducers/textsSlice'
import { getTranslatedText } from '../../lang/translator'

const SettingsContent = (props) => {
  const theme = useTheme()
  const { handleCloseSettingsDrawer } = props
  const dispatch = useDispatch()

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const currentLang = useSelector(state => state.settings.currentLang)
  const defaultLang = useSelector(state => state.settings.defaultLang)
  const supportedLangs = useSelector(state => state.settings.supportedLangs)
  const mode = useSelector(state => state.settings.uiMode)
  
  const [currentLanguage, setCurrentLanguage] = useState(currentLang)
  
  const sxHead = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
    borderBottom: `${theme.palette.customGray} 1px solid`,
    color: theme.palette.customBlack,
    padding: '0px 15px',
  }

  const sxIcon = {
    color: theme.palette.primary.main,
  }
  
  const sxLabel = {
    color: theme.palette.customBlack,
    textTransform: 'uppercase',
    padding: '15px',
  }

  const sxLightButton = {
    display: 'inline-block',
    position: 'relative',
    width: '50%',
    height: '50px',
    color: mode === 'light' ? theme.palette.primary.main : theme.palette.customBlack,
    border: mode === 'light' ? `${theme.palette.primary.main} 1px solid` : `${theme.palette.customGray} 1px solid`,
    background: mode === 'light' ? alpha(theme.palette.primary.main, 0.1) : 'none',
    zIndex: mode === 'light' ? '2' : '1',
    borderRadius: '12px 0px 0px 12px',
    cursor: 'pointer',
    '&:hover': {
      background: mode === 'light' ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.customGray, 0.1),
    }
  }

  const sxDarkButton = {
    display: 'inline-block',
    position: 'relative',
    width: '50%',
    height: '50px',
    color: mode === 'dark' ? theme.palette.primary.main : theme.palette.customBlack,
    border: mode === 'dark' ? `${theme.palette.primary.main} 1px solid` : `${theme.palette.customGray} 1px solid`,
    background: mode === 'dark' ? alpha(theme.palette.primary.main, 0.1) : 'none',
    zIndex: mode === 'dark' ? '2' : '1',
    borderRadius: '0px 12px 12px 0px',
    cursor: 'pointer',
    marginLeft: '-1px',
    '&:hover': {
      background: mode === 'dark' ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.customGray, 0.1),
    }
  }

  const sxButtonContent = {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }
  
  const getSelectFieldItems = (supportedLangs) => {
    let result = []

    supportedLangs.forEach(item => result.push({
      keyword: item.label,
      value: item.value,
      default: false,
    }))

    return result
  }

  const handleClickLightMode = () => {
    dispatch(setUIMode({ uiMode: 'light' }))
  }

  const handleClickDarkMode = () => {
    dispatch(setUIMode({ uiMode: 'dark' }))
  }

  useEffect(() => {
    const handleLangChange = async (currentLanguage) => {      
      if (defaultLang !== currentLanguage) {
        const translatedItems = await getTranslatedText(currentLanguage)
        const newData = { ...defaultTexts, ...translatedItems }
        dispatch(setLangTexts({ langTexts: newData }))
      } else {
        dispatch(textsReset())
      }
      
      dispatch(setCurrentLang({ currentLang: currentLanguage }))
    }

    if (currentLanguage !== currentLang) {
      handleLangChange(currentLanguage)
    }    
  }, [currentLanguage, currentLang, defaultLang, defaultTexts, dispatch])
  
  return (
    <>
      <Box sx={sxHead}>
        <CustomText size='h6'>{defaultTexts.settings}</CustomText>
        <IconButton sx={sxIcon} onClick={handleCloseSettingsDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={sxLabel}>{defaultTexts.mode}</Box>

      <Box sx={{ padding: '0px 30px', marginBottom: '20px' }}>
        <Box sx={sxLightButton} onClick={handleClickLightMode}>
          <Box sx={sxButtonContent}>
            <Box>
              <LightModeIcon sx={{ marginTop: '5px' }} />
            </Box>
            <Box>{defaultTexts.light}</Box>
          </Box>
        </Box>

        <Box sx={sxDarkButton} onClick={handleClickDarkMode}>
          <Box sx={sxButtonContent}>
            <Box>
              <DarkModeIcon sx={{ marginTop: '5px' }} />
            </Box>
            <Box>{defaultTexts.dark}</Box>
          </Box>
        </Box>
      </Box>

      <Box sx={sxLabel}>{defaultTexts.language}</Box>

      <Box sx={{ padding: '0px 30px' }}>
        <CustomSelectField 
          currentValue={currentLanguage}
          setCurrentValue={setCurrentLanguage}
          items={getSelectFieldItems(supportedLangs)}
          self_item_titles={true}
        />
      </Box>
    </>
  )
}

export default SettingsContent