import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, networkNeutronConstants, computeNovaConstants } from '../../../../../../config/openStackConstants';
import PortsTableV20 from './portsTableV2.0';
import PortDetailV20 from './portDetailV2.0';
import { portsFilterMenu, portDataUpdateForm } from '../../../../../../_data/openstack/neutron/ports/v2.0';
import { openstackRequest } from '../../../../../../_network/openstack_request';
import { portsUrl as portUrlResponses } from '../../../../../../_api_responses/openstack/neutron/ports/v2.0';
import { networksUrl as networkUrlResponses} from '../../../../../../_api_responses/openstack/neutron/networks/v2.0'
import { getFormFieldComponent } from '../../../../../_common/_form_fields/form_helpers';
// import { LiaNetworkWiredSolid } from "react-icons/lia";
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../../_common/_form_fields/CustomSelectField';
import ServiceSubmenu from '../../../../../_common/ServiceSubmenu';
import PortAdd from './PortAddV2_0';
import CustomSplitPane from '../../../../../_common/CustomSplitPane';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import CustomPopover from '../../../../../_common/CustomPopover';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SuspendedAlertDialog from '../../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService

const PortsWrapperV20 = (props) => {
	const theme = useTheme()
	const { navigate, location, currentTab, serviceSubmenu, changeMenuActiveTab } = props
	const { segmentsSupported, trunksSupported} = props
	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState();
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
	const token = useSelector(state => state.profile.x_auth_token)
	
	const { width } = useWindowDimensions()
	
	const [portsData, setPortsData] = useState([])
	const [ports, setPorts] = useState([])
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedPort, setSelectedPort] = useState(null);
	const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
	const [currentAction, setCurrentAction] = useState("");
	const [portDeleteConfirmDialogOpen, setPortDeleteConfirmDialogOpen] = useState(false);
	const [selectedPorts, setSelectedPorts] = useState([])
	const [portsSortParams, setPortsSortParams] = useState("")
	
	const [portFilterQueryParams, setPortFilterQueryParams] = useState("")
	const [selectedPortFilter, setSelectedPortFilter] = useState(portsFilterMenu[0].value)
	const [selectedPortFilterValue, setSelectedPortFilterValue] = useState("")
	const [portsFilter, setPortsFilter] = useState([...portsFilterMenu])
	const [portUpdateData, setPortUpdateData] = useState({})
	const [selectedUpdateField, setSelectedUpdateField] = useState("")
	const [portUpdateDialogOpen, setPortUpdateDialogOpen] = useState(false)

	const [networks, setNetworks] = useState([])
	const [subnets, setSubnets] = useState([])
	const [secGroups, setSecGroups] = useState([])
	const [servers, setServers] = useState([])

	const [filteredSubmenu, setFilteredSubmenu] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const neutronServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const neutronServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const portsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
	const networksUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
	const subnetsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
	const secGroupsUrl = useSelector(
			state => state.networkNeutron.networkNeutronApiUrls.filter(
					version => version.api_version === "v2.0")[0].urls.filter(
							url => url.keyword === networkNeutronConstants.secGroupsUrl)[0].url)
	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)

	const handleDataFetch = () => {
			setDataFetchingRequired(true)
	}

	const getSearchType = () => {
		const filterObject = portsFilterMenu.find(item => item.value === selectedPortFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		const filterObject = portsFilterMenu.find(item => item.value === selectedPortFilter)
		return filterObject ? filterObject.items : []
	}

	const handlePortFilteredSearch = () => {
			if (selectedPortFilter && selectedPortFilterValue) {
					setPortFilterQueryParams(`${selectedPortFilter}=${selectedPortFilterValue}`)
			} else {
					setPortFilterQueryParams("")
			}
			handleDataFetch()
	}

	const handlePortsDataFormatting = useCallback(() => {
			const formatted_data = portsData.map((item) => {
					let new_item = {...item}
					
					if (item.fixed_ips.length > 0) {
							let ip_addresses = []
							for (let ip in item.fixed_ips) {
									ip_addresses.push(item.fixed_ips[ip].ip_address)
							}
							new_item.fixed_ips = ip_addresses
					}
					const network = networks.filter(n => n.id === item.network_id)
					if (network.length > 0) {
							new_item.network_id = network[0].name
					}
					return new_item
			})

			setPorts(formatted_data)
	},[
			portsData,
			networks
	])

	const handlePortFilterReset = () => {
			setSelectedPortFilter(portsFilterMenu[0].value)
			setSelectedPortFilterValue("")
			setPortFilterQueryParams("")
			handleDataFetch()
	}

	const handleNavigateToServer = (server_id) => {
		handleDetailCardClose()
		navigate("/compute/instances",{state: {server_id: server_id}})
}

const handleNavigateToRouter = (router_id,tab_name) => {
		handleDetailCardClose()
		changeMenuActiveTab(tab_name)
		navigate("/networks/l3-networking/routers",{state: {router_id: router_id}})
}

const handleNavigateToSecGroup = (security_group_id,tab_name) => {
		handleDetailCardClose()
		changeMenuActiveTab(tab_name)
		navigate("/networks/network-security/security-groups",{state: { security_group_id }})
}

const handleNavigateToNetwork = (network_id,tab_name) => {
		handleDetailCardClose()
		changeMenuActiveTab(tab_name)
		
		navigate("/networks/l2-networking/networks",{state: {network_id: network_id}})
}

const handleNavigateToSubnet = (subnet_id,tab_name) => {
		handleDetailCardClose()
		changeMenuActiveTab(tab_name)
		navigate("/networks/l3-networking/subnets",{state: {subnet_id: subnet_id}})
}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedPort(portsData[index].id)
			setSelectedRow(portsData[index])
	},[portsData]);

	const handleDetailCardClose = () => {
			setSelectedRow(null)
			setSelectedPort(null)
			navigate(location.path,{})
	};

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	const handlePortsSorting = (field,direction) => {
			const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
			setPortsSortParams(sort_param)
			handleDataFetch()
	}

	const handlePortUpdate = async (p_id,data) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}/${p_id}`
		const method = "PUT"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				data: {port: data},
				token,
		})

		if (nt_response.status_code === portUrlResponses.put.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onPortUpdate = async () => {
			handlePortUpdateDialogClose()
			let err = []
			const data = {}
			data[selectedUpdateField] = portUpdateData[selectedUpdateField]
			for (let n in selectedPorts) {
					const resp = await handlePortUpdate(selectedPorts[n].id, data)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorUpdateRecordTitle"
					error_object["error_message"] = "errorUpdateRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handlePortDelete = async (p_id) => {
		const url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}/${p_id}`
		const method = "DELETE"
		
		const nt_response = await openstackRequest({
				url:url, 
				method:method,
				token,
		})

		if (nt_response.status_code === portUrlResponses.delete.success_response.status_code) {
				return null
		} else {
				return nt_response.error
		}
	}

	const onPortDelete = async () => {
			handlePortDeleteConfirmDialogClose()
			let err = []
			for (let n in selectedPorts) {
					const resp = await handlePortDelete(selectedPorts[n].id)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			handleDetailCardClose()
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const onPortDeleteConfirm = (n_list) => {
			const selected_n_list = portsData.filter(n => 
					n_list.includes(n.id))
			setSelectedPorts([...selected_n_list])
			setPortDeleteConfirmDialogOpen(true)
	}

	const handlePortDeleteConfirmDialogClose = () => {
			setPortDeleteConfirmDialogOpen(false)
	}

	const onPortUpdateAdminState = (n_list) => {
			const selected_n_list = portsData.filter(n => 
					n_list.includes(n.id))
			setSelectedPorts([...selected_n_list])
			setSelectedUpdateField("admin_state_up")
			setPortUpdateDialogOpen(true)
	}

	const onPortUpdatePortSecurity = (n_list) => {
			const selected_n_list = portsData.filter(n => 
					n_list.includes(n.id))
			setSelectedPorts([...selected_n_list])
			setSelectedUpdateField("port_security_enabled")
			setPortUpdateDialogOpen(true)
	}

	const handlePortUpdateDialogClose = () => {
			setPortUpdateDialogOpen(false)
	}

	const handlePortUpdateDataChange = (event,field_key) => {
			let updated_data = {...portUpdateData}
			const field_type = portDataUpdateForm.filter(field => field.field_key === field_key)[0].field_type
			if (field_type === "bool") {
					updated_data[field_key] = event.target.checked
			} else if (field_type === "select") {
					updated_data[field_key] = event
			} else {
					updated_data[field_key] = event.target.value
			}
			setPortUpdateData(updated_data)        
	}

	const getUpdateForm = () => {
			let form = portDataUpdateForm.filter(field => field.field_key === selectedUpdateField)
			return (
					<FormGroup>
							{form.map(field => {
									let form_field_options = {}
									return (
											getFormFieldComponent(
													field,
													portUpdateData,
													handlePortUpdateDataChange,
													defaultTexts[field.label],
													{...form_field_options}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getPortsActionsList = () => {
			let port_actions = []
			let new_action = {}
			new_action["value"] = "update_admin_state"
			new_action["action"] = onPortUpdateAdminState
			new_action["keyword"] = "portUpdateAdminStateActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			port_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "update_port_security"
			new_action["action"] = onPortUpdatePortSecurity
			new_action["keyword"] = "portUpdatePortSecurityActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			port_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "port_delete"
			new_action["action"] = onPortDeleteConfirm
			new_action["keyword"] = "portDeleteActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			port_actions.push({...new_action})
			
			return port_actions
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	useEffect(() => {
		setDataFetchingRequired(true)
		setSelectedPort(null)
		setSelectedRow(null)
	}, [token])

	useEffect(() => {
			if (dataFetchingRequired) {
					(async () => {
						handleLoading(true)
						
						let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}?${portFilterQueryParams}${portsSortParams}`
						const method = "GET"
						const port_response = await openstackRequest({url:url, method:method, token, })
						if (port_response.status_code === portUrlResponses.get.success_response.status_code) {
								setPortsData(port_response.data.ports)
								if (selectedPort) {
										const selected_port = port_response.data.ports.filter(item => item.id === selectedPort)
										if (selected_port.length > 0) {
												setSelectedRow(selected_port[0])
										}
								}
						} else {
							setPortsData([])
						}
						
						
					})();
			}
			setTimeout(()=>{setDataFetchingRequired(false)},700)
			setTimeout(()=>{handleLoading(false)},700)
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			portsUrl, 
			portFilterQueryParams,
			dataFetchingRequired,
			defaultAdminProject,
			portsSortParams,
			selectedPort,
			token,
	]);

	useEffect(() => {
			(async () => {
				handleLoading(true)
				
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
				const method = "GET"
				const subnets_response = await openstackRequest({url:url, method:method, token, })
				if (subnets_response.status_code === portUrlResponses.get.success_response.status_code) {
						setSubnets(subnets_response.data.subnets)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			subnetsUrl, 
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
			(async () => {
				handleLoading(true)

				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${secGroupsUrl}`
				const method = "GET"
				const sec_groups_response = await openstackRequest({url:url, method:method, token, })
				if (sec_groups_response.status_code === portUrlResponses.get.success_response.status_code) {
						setSecGroups(sec_groups_response.data.security_groups)
				}
					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			secGroupsUrl, 
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
			(async () => {
				let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
				const method = "GET"
				const network_response = await openstackRequest({url:url, method:method, token, })
				if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
						setNetworks(network_response.data.networks)
				}					
			})();
	},[
			neutronServiceDomain, 
			neutronServiceVersion, 
			networksUrl,
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
		(async () => {
			let url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?`
			const method = "GET"
			const servers_response = await openstackRequest({url:url, method:method, token, })
			if (servers_response.status_code === networkUrlResponses.get.success_response.status_code) {
					
					setServers(servers_response.data.servers)
			}
		})();
	},[
			computeServiceDomain, 
			computeServiceVersion, 
			serversUrl,
			token,
	]);
	
	useEffect(() => {
		if (portsData.length > 0) {
			handlePortsDataFormatting()
		} else {
			setPorts([])
		}
	},[
		portsData,
		handlePortsDataFormatting
	])

	useEffect(() => {						
			let port_filter_menu = portsFilterMenu.map(nt => {
					let new_item = {...nt}
					return new_item
			})
			setPortsFilter(port_filter_menu)
	},[
			portsData,
	])

	useEffect(() => {
			if (!dataFetchingRequired && location.state ) {
					const port_id = location.state ? location.state.port_id : null
							const port_index = portsData.findIndex(v => v.id === port_id);
							if (port_index !== -1) {
									setTimeout(() => handleDetailCardOpen(port_index), 600)
							}
			}
	},[
			dataFetchingRequired,
			portsData,
			handleDetailCardOpen,
			location
	])

	useEffect(() => {
			if (Object.keys(portUpdateData).length === 0) {
					let new_form_data = {}
					for (const n in portDataUpdateForm) {
							if (
									portDataUpdateForm[n].field_type === "string" || 
									portDataUpdateForm[n].field_type === "select"
									) {
									new_form_data[portDataUpdateForm[n].field_key] = ""
							} else if (portDataUpdateForm[n].field_type === "bool") {
									new_form_data[portDataUpdateForm[n].field_key] = portDataUpdateForm[n].default_value ? 
									portDataUpdateForm[n].default_value : 
									false
							}
					}
					setPortUpdateData(new_form_data)
			}
	},[
			portUpdateData
	]);

	useEffect(() => {
			setErrorDialogOpen(true)
	},[error]);

	useEffect(() => {
		const filtered_submenu = serviceSubmenu.filter(item => item.parent === '/l2-networking')
		setFilteredSubmenu(filtered_submenu)
	}, [serviceSubmenu, segmentsSupported, trunksSupported])

	useEffect(() => {
		setSelectedPortFilterValue('')
	}, [selectedPortFilter])

	const handleActionRun = () => {
		const actionsList = getPortsActionsList()
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedIds)
  }

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedPorts([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getPortsActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<PortAdd
					handleDataFetch={handleDataFetch}
					networks={networks}
					subnets={subnets}
					secGroups={secGroups}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedPortFilterValue}
							setCurrentValue={e => setSelectedPortFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedPortFilterValue}
							setCurrentValue={setSelectedPortFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={portsFilter} 
						currentValue={selectedPortFilter}
						setCurrentValue={setSelectedPortFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handlePortFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handlePortFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}
	
	const getNetworksHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems={ width >= 900 ? 'center' : 'flex-start' }
					sx={{
						margin: width >= 900 ? '0px 20px' : '0px 20px 10px 20px',
						padding: '20px 0px 10px 0px',
						width: 'calc(100% - 40px)',
						borderBottom: width >= 900 ? `${theme.palette.customGray} 1px solid` : 'none',
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuL2Networks}
					</Box>

					<ServiceSubmenu
						service='/networks'
						serviceSubmenu={filteredSubmenu}
						currentTab={currentTab}
					/>
				</Stack>

				{
					width >= 900 &&
					<Stack 
						direction="row" 
						justifyContent="space-between" 
						alignItems="center"
						sx={{
							margin: '0px 20px',
							padding: '10px 0px 0px 0px',
							width: 'calc(100% - 40px)',
						}}
					>
						<Box
							sx={{
								fontSize: '17px',
								color: theme.palette.primary.main,
								padding: '0px 0px 10px 0px',
							}}
						>
							{defaultTexts.selectedRowsLabel} ({selectedIds.length}/{portsData.length})
						</Box>

						<Box>
							{
								width >= 900 ?
								getActionButtons() :
								<CustomPopover
									type='menu'
									horizontalOrigin='right'
								>
									{getActionButtons()}
								</CustomPopover>
							}
						</Box>
					</Stack>
				}

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 20px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getNetworksTable = () => {
		return (
			<PortsTableV20 
				portsData={ports}
				setPortsData={setPorts}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getPortsActionsList()}
				sortHandler={handlePortsSorting}
				sortParams={portsSortParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getNetworkContent = () => {
		return (
			<>
				{ getNetworksHeader() }
				{ getNetworksTable() }
			</>
		)
	}

	const getNetworkDetails = () => {
		return (
			<PortDetailV20
				selectedRow={selectedRow}
				handleDataFetch={handleDataFetch}
				handleDelete={onPortDeleteConfirm}
				networks={networks}
				secGroups={secGroups}
				subnets={subnets}
				servers={servers}
				setSelectedRow={setSelectedRow}
				setSelectedPort={setSelectedPort}
				handleNavigateToNetwork={handleNavigateToNetwork}
				handleNavigateToSecGroup={handleNavigateToSecGroup}
				handleNavigateToServer={handleNavigateToServer}
				handleNavigateToRouter={handleNavigateToRouter}
				handleNavigateToSubnet={handleNavigateToSubnet}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}
	
	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getNetworkContent()} contentBottom={getNetworkDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getNetworksHeader() }

				{ (selectedRow === null && !isLoading) && getNetworksTable() }

				<CustomDialog
						open={portDeleteConfirmDialogOpen}
						onClose={handlePortDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.portDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.portDeleteConfirmText}: [${selectedPorts.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onPortDelete, 
								sx: {color: 'primary.main'}}]}
				/>

				<CustomDialog
						open={portUpdateDialogOpen}
						onClose={handlePortUpdateDialogClose}
						dialogTitle={{
								title: defaultTexts.portUpdateTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: "", 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: onPortUpdate, 
								sx: {color: 'primary.main'}}]}
				>
								{getUpdateForm()}
				</CustomDialog>

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
		</Box>
	</>
	)
}

export default PortsWrapperV20