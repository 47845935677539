import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { BarChart } from '@mui/x-charts/BarChart'
import CustomPopover from '../../../_common/CustomPopover'
import useWindowDimensions from '../../../_common/WindowDimensions'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import {
  getCurrentMonthFirstDate,
  getCurrentMonthLastDate,
  addTimeToDate,
  getMonthShortName,
} from '../../helpers/dates'

const ResourceDetailsGraph = (props) => {
  const { resource, id, resourceDetailsHeight } = props
  const { width } = useWindowDimensions()
  
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const defaultTexts = useSelector(state => state.texts.langTexts)

  const [chargesGraphData, setChargesGraphData] = useState([])
  const [chargesGraph, setChargesGraph] = useState([])
  const [startDate, setStartDate] = useState(getCurrentMonthFirstDate())
  const [endDate, setEndDate] = useState(getCurrentMonthLastDate())
  const [isLoading, setIsLoading] = useState(true)

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value)
  }

  const handleChargesGraphDataFormatting = useCallback(() => {
    let formatted_data = chargesGraphData.map((item) => {
      let new_item = { ...item }

      if (chargesGraphData.length === 12) {
        new_item.time = getMonthShortName(item.time, defaultTexts)        
      }

      return new_item
    })

    setChargesGraph(formatted_data)
  }, [chargesGraphData, defaultTexts])

  useEffect(() => {
    const fetchChargesGraphData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (resource === 'flavor') {
        filterArray = ['flavor_id=' + id]
      }
      
      if (resource === 'volume-type') {
        filterArray = ['volume_type_id=' + id]
      }

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }      

      const url = constants.billing_url + '/' + resource + billingUrls.getResourceGraph + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setChargesGraphData(response.data)
      } else {
        setChargesGraphData([])
      }

      setIsLoading(false)
    }

    fetchChargesGraphData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, resource, id, startDate, endDate])

  useEffect(() => {
		if (chargesGraphData.length > 0) {
			handleChargesGraphDataFormatting()
		} else {
			setChargesGraph([])
		}
	},[
		chargesGraphData,
		handleChargesGraphDataFormatting
	])
  
  const getFilter = () => {
    return (
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start" 
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        sx={{
          width: '100%',
          padding: '10px 10px 0px 10px',
        }}
      >
        <Box>
          <DatePicker
            label=''
            value={new Date(startDate)}
            onChange={e => handleChangeStartDate(new Date(e))}
            maxDate={new Date(endDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
        <Box sx={{ margin: '0px 10px' }}>{ width >= 900 ? '-' : <Box sx={{ width: '1px', height: '10px' }}></Box> }</Box>
        <Box>
          <DatePicker
            label=''
            value={new Date(endDate)}
            onChange={e => handleChangeEndDate(new Date(e))}
            minDate={new Date(startDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
      </Stack>
    )
  }
  
  return (
    <>
      <>
        {
          width >= 900 ?
          <Box sx={{ paddingBottom: '10px' }}>
            {getFilter()}
          </Box> :
          <Box sx={{ paddingLeft: '10px' }}>
            <CustomPopover
              type='filterlist'
              horizontalOrigin='left'
            >
              {getFilter()}
            </CustomPopover>
          </Box>          
        }
      </>

      {
        isLoading ?
        <Stack
          direction='row'
          spacing={6}
          sx={{ margin: '20px 20px 0px 20px' }}
        >
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
          <Skeleton variant="rectangular" width={40} height={resourceDetailsHeight - 200} />
        </Stack> :
        <Box sx={{ margin: '-20px 10px 0px 10px' }}>
          <BarChart
            dataset={chargesGraph}
            xAxis={[{ scaleType: 'band', dataKey: 'time' }]}
            series={[{ dataKey: 'total_cost' }]}
            height={resourceDetailsHeight - 140}
          />
        </Box>        
      }     
    </>
  )
}

export default ResourceDetailsGraph