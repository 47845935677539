import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { volumeGroupDataSchema, volumeGroupSortFields } from '../../../../../_data/openstack/cinder/groups/v3';
import { setVolumeGroupsTableConfig } from '../../../../../store/reducers/blockStorageCinderSlice';
import Box from '@mui/material/Box'
import useWindowDimensions from '../../../../_common/WindowDimensions'

const handleTableColumnsConfig = (columns_schema,api_version) => {
    let current_api_version = api_version
    if (!api_version) {
        current_api_version = "3.0"
    }

    let schema = columns_schema.filter(f => 
        (!f.min_api_version ||  
            parseFloat(f.min_api_version) <= parseFloat(current_api_version))
    )
    return schema
}

const GroupsTableV3 = (props) => {
    const { data, setData, sortParams, setSelectedGroupIds, deselectAll, setDeselectAll, isSuspended } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const { width } = useWindowDimensions()
    
    const tableConfig = useSelector(
        state => state.blockStorageCinder.volumeGroupsTableConfig)
    const rowsPerPage = useSelector(
        state => state.blockStorageCinder.volumeGroupsTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const tableHeaders = useSelector(
        state => state.blockStorageCinder.volumeGroupsTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    const cinderMaxApiVersion = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            versions => versions.api_version === "v3")[0].max_api_version)

    const tableColumns = handleTableColumnsConfig(volumeGroupDataSchema,cinderMaxApiVersion)
    const identityField = tableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleTableConfig = (value_list) => {
        let schema = []
        if (data.length > 0) {
            const v_keys = Object.keys(data[0])
            schema = volumeGroupDataSchema.filter(
                n => v_keys.includes(n.field_key))
        } else {
            schema = [...volumeGroupDataSchema]
        }
        let newTableConfig = schema.filter(
            n => value_list.includes(n.field_key))
        const new_data = tableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVolumeGroupsTableConfig({
            volumeGroupsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = tableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVolumeGroupsTableConfig({
            volumeGroupsTableConfig: new_data
        }))
    }

    return (
        <Box
			sx={{
				width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
				overflowX: 'auto',
			}}
		>
            <CustomTable
                withPagination={false}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={
                    tableHeaders.length > 0 ? 
                    tableHeaders : 
                    [...tableColumns]}
                tableHeadersConfigHandler={handleTableConfig}
                identityField={identityField}
                columns={[...tableColumns]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={data}
                setDataRows={setData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={volumeGroupSortFields}
                sortHandler={sortHandler}
                selfSorting={true}
                sortParams={sortParams}
                setSelectedIds={setSelectedGroupIds}
                deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
                isSuspended={isSuspended}
            />
        </Box>
    )
};

export default GroupsTableV3;