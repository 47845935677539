export const identityRoleAssignmentApiQueryParamsV3 = "include_names&&user.id={missingUserID}"

export const roleDataSchema = [
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "domain_id",
        is_id_field: false,
        field_type: "string", 
        label: "domainFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    }
]

export const roleDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "domain_id",
        field_type: "select", 
        required: false,
        label: "domainFormFieldLabel",
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    }
]


export const roleDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    }
]


export const rolesfilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterDomain", 
        value: "domain_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    }
]

const roleAssignDataForm = [
    {
        field_key: "scope",
        field_type: "select", 
        required: true, 
        label: "scopeFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: false,
        default_empty: true,
        items: [
            {
                keyword: "systemScopeFormFieldLabel", 
                value: "system_scope", 
                default: false
            },
            {
                keyword: "domainScopeFormFieldLabel", 
                value: "domain_scope", 
                default: false
            },
            {
                keyword: "projectScopeFormFieldLabel", 
                value: "project_scope", 
                default: false
            }
        ]
    },
    {
        field_key: "domain_id",
        field_type: "select", 
        required: true,
        label: "domainFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "project_id",
        field_type: "select", 
        required: true,
        label: "projectFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    }
]

export const roleAssignToUserDataForm = [
    ...roleAssignDataForm,
    {
        field_key: "user_id",
        field_type: "select", 
        required: true,
        label: "userFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    }
]

export const roleAssignToGroupDataForm = [
    ...roleAssignDataForm,
    {
        field_key: "group_id",
        field_type: "select", 
        required: true,
        label: "groupFormFieldLabel",
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true
    }
]