import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import SplitPane, { Pane, SashContent } from 'split-pane-react'
import useWindowDimensions from './WindowDimensions'
import 'split-pane-react/esm/themes/default.css'

const CustomSplitPane = (props) => {
  const { height } = useWindowDimensions()
  const theme = useTheme()

  const { contentTop, contentBottom, minSizePercentage } = props
  const mode = useSelector(state => state.settings.uiMode)

  const [sizes, setSizes] = useState([50, 50, 'auto'])
  const minSize = minSizePercentage ? minSizePercentage : 30

  const sxActionSashWrap = {
    width: '100%',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `${theme.palette.customGrayDark} 1px solid`,
    background: mode === 'dark' ? theme.palette.customLightBlack : theme.palette.customWhite,
  }

  const sxAction = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
  }

  const sxDraggableBar = {
    width: '30px',
    height: '4px',
    background: theme.palette.customGrayDark,
  }

  return (
    <Box sx={{ height: `${height - 64}px` }}>
      <SplitPane
        split='horizontal'
        sizes={sizes}
        onChange={(sizes) => setSizes(sizes)}
        sashRender={(index, active) => (
          <SashContent style={sxActionSashWrap}>
            <span
              style={sxAction}
              onClick={() => setSizes(sizes)}
            >
              <Box>
                <Box sx={{ ...sxDraggableBar, marginBottom: '4px' }}></Box>
                <Box sx={sxDraggableBar}></Box>
              </Box>
            </span>
          </SashContent>
        )}
      >
        <Pane minSize={`${minSize}%`} maxSize={`${100 - minSize}%`} style={{ overflowY: 'auto' }}>
          <Box>{contentTop}</Box>
        </Pane>
        <Pane minSize={`${minSize}%`} maxSize={`${100 - minSize}%`} style={{ overflowY: 'auto' }}>
          <Box sx={{ paddingTop: '30px' }}>{contentBottom}</Box>
        </Pane>
      </SplitPane>
    </Box>
  )
}

export default CustomSplitPane