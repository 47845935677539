import React, { useState, useEffect} from 'react';
import SideFilter from '../../../../_common/SideFilter';

const ComputeServerFilterV21 = (props) => {
	const { filters, filterGroups } = props
	const { flavors } = props
	const { currentValues, setCurrentValue } = props
	const { handleSearch, handleFilterReset } = props

	const [formattedFilters, setFormattedFilters] = useState({
			...filters,
			group1: filters.group1.map(f => {
					f["options"] = {}
					if (f.field_key === "flavor") {
							f["options"]["items"] = flavors.map(fl => {
									return {keyword: fl.name, value: fl.id, default: false}
							})
							f["options"]["self_item_titles"] = true
							f["options"]["default_empty"] = true
					}
					else if (f.field_key === "status") {
							f["options"]["items"] = [...f.items]
							f["options"]["self_item_titles"] = true
							f["options"]["default_empty"] = true
					}
					return f
			})
	})

	useEffect(() => {
			let formatted_filters = {...filters}
			formatted_filters.group1 = filters.group1.map(f => {
					f["options"] = {}
					f["options"]["sx"] = {width: "80%"}
					if (f.field_key === "flavor") {
							f["options"]["items"] = flavors.map(fl => {
									return {keyword: fl.name, value: fl.id, default: false}
							})
							f["options"]["self_item_titles"] = true
							f["options"]["default_empty"] = true
					}
					else if (f.field_key === "status") {
							f["options"]["items"] = [...f.items]
							f["options"]["self_item_titles"] = true
							f["options"]["default_empty"] = true
					}
					return f
			})
			
			setFormattedFilters(formatted_filters)
	},[
			filters,
			flavors
	]);
	
	return (
		<SideFilter 
			filters={formattedFilters}
			filterGroups={filterGroups}
			currentValues={currentValues}
			setCurrentValue={setCurrentValue}
			handleFilterReset={handleFilterReset}
			handleSearch={handleSearch}
			sx={{maxHeight: 500}}
		/>
	)
}

export default ComputeServerFilterV21