import Constants from '../config/constants';
import { identityKeystonConstants } from '../config/openStackConstants';
import { store } from '../store/configStore';
import { setUserID, setUserDomain, setXAuthToken } from '../store/reducers/profileSlice';
import { authTokenApiDataV3, authTokenApplicationCredentialApiDataV3, authTokenProjectScopeApiDataV3, } from '../_data/openstack/identity/authTokens/v3';
import { setComputeNovaApiUrls } from '../store/reducers/computeNovaSlice';
import { setBlockStorageCinderApiUrls } from '../store/reducers/blockStorageCinderSlice';
import { openStackServices, computeNovaConstants, blockStorageCinderConstants } from '../config/openStackConstants';
import { getAccessToken } from './auth';
import { setAccessToken } from '../store/reducers/profileSlice';

export const getIdentityProvider = (services) => {
    const identity_service = services.filter(service => service.config_params.is_identity_provider)
    if (identity_service.length > 0) {
        return identity_service[0]
    } else {
        return null
    }
}

export const getXAuthToken = async (user_name,pass,identity_provider,domain) => {
    const state = store.getState()
    const username = user_name ? user_name : state.profile.username
    const password = pass ? pass : state.profile.password
    const identityProvider = identity_provider ? identity_provider : ''
    const identityProviderAuthUrls = state.identityKeystone.identityKeystoneApiUrls
    const authDomain = domain ? domain : state.profile.userDomain.name

    if (identityProvider) {
        let authtokenApiDataStr = ""
        let identity_url = ""
        if (identityProvider.config_params.api_version === "v3") {
            const identityAuthTokenApiJson = {...authTokenApiDataV3}
            authtokenApiDataStr = JSON.stringify(identityAuthTokenApiJson)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingUsername}", username)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingPassword}", password)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingDomain}", authDomain)
            const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
                item.api_version === identityProvider.config_params.api_version
            )[0].urls
            const auth_url = identityKeystoneApiUrlsList.filter(item => 
                item.api_group === identityKeystonConstants.authTokensApiUrls && 
                item.keyword === identityKeystonConstants.authTokenUrl)[0].url
    
            identity_url = `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${auth_url}`
        }
        
        let request_data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: authtokenApiDataStr
        }
        
        const response = await fetch(identity_url, request_data)
        
        if (response.ok) {
            const x_auth_token = response.headers.get('X-Subject-Token')
            const res_data = await response.json()
            store.dispatch(setUserID({user_id: res_data.token.user.id}))
            store.dispatch(setUserDomain({domain_id: res_data.token.user.domain}))
            store.dispatch(setXAuthToken({x_auth_token: x_auth_token}))

            return {x_auth_token: x_auth_token, user_id: res_data.token.user.id}
        } 
        console.log(await response.json())    
    }

    return null
}

export const getXAuthTokenProjectScope = async (project_id) => {
    const state = store.getState()
    const user_id = state.profile.userID
    const password = state.profile.password
    const identityProvider = state.profile.identityProvider
    const identityProviderAuthUrls = state.identityKeystone.identityKeystoneApiUrls
    const projectId = project_id ? project_id : state.profile.defaultAdminProject

    if (identityProvider) {
        let authtokenApiDataStr = ""
        let identity_url = ""
        if (identityProvider.config_params.api_version === "v3") {
            const identityAuthTokenApiJson = {...authTokenProjectScopeApiDataV3}

            authtokenApiDataStr = JSON.stringify(identityAuthTokenApiJson)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingUserID}", user_id)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingPassword}", password)
            authtokenApiDataStr = authtokenApiDataStr.replace("{missingProjectID}", projectId)

            const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
                item.api_version === identityProvider.config_params.api_version
            )[0].urls
            
            const auth_url = identityKeystoneApiUrlsList.filter(item => 
                item.api_group === identityKeystonConstants.authTokensApiUrls && 
                item.keyword === identityKeystonConstants.authTokenUrl)[0].url
    
            identity_url = `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${auth_url}`
        }
        
        let request_data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: authtokenApiDataStr
        }

        const response = await fetch(identity_url, request_data)
        
        if (response.ok) {
            const x_auth_token = response.headers.get('X-Subject-Token')

            store.dispatch(setXAuthToken({x_auth_token: x_auth_token}))
    
            return x_auth_token
        }
    }

    return null
}

export const getXAuthTokenApplicationCredential = async (identity_provider) => {
    const state = store.getState()
    const identityProvider = identity_provider ? identity_provider : ''
    const identityProviderAuthUrls = state.identityKeystone.identityKeystoneApiUrls
    const credentialId = (identityProvider.config_params && identityProvider.config_params.app_credential_id) ? identityProvider.config_params.app_credential_id : ''
    const credentialSecret = (identityProvider.config_params && identityProvider.config_params.app_credential_secret) ? identityProvider.config_params.app_credential_secret : ''

    if (identityProvider) {
        let authtokenApiDataStr = ""
        let identity_url = ""
        if (identityProvider.config_params.api_version === "v3") {
            const identityAuthTokenApiJson = { ...authTokenApplicationCredentialApiDataV3 }
            authtokenApiDataStr = JSON.stringify(identityAuthTokenApiJson)
            authtokenApiDataStr = authtokenApiDataStr.replace("{id}", credentialId)
            authtokenApiDataStr = authtokenApiDataStr.replace("{secret}", credentialSecret)

            const identityKeystoneApiUrlsList = identityProviderAuthUrls.filter(item => 
                item.api_version === identityProvider.config_params.api_version
            )[0].urls

            const auth_url = identityKeystoneApiUrlsList.filter(item => 
                item.api_group === identityKeystonConstants.authTokensApiUrls && 
                item.keyword === identityKeystonConstants.authTokenUrl)[0].url
    
            identity_url = `${identityProvider.config_params.service_domain}/${identityProvider.config_params.api_version}/${auth_url}`
        }
        
        let request_data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: authtokenApiDataStr
        }
        
        const response = await fetch(identity_url, request_data)
        
        if (response.ok) {
            const x_auth_token = response.headers.get('X-Subject-Token')
            const res_data = await response.json()
            store.dispatch(setUserID({user_id: res_data.token.user.id}))
            store.dispatch(setUserDomain({domain_id: res_data.token.user.domain}))
            store.dispatch(setXAuthToken({x_auth_token: x_auth_token}))

            return {x_auth_token: x_auth_token, user_id: res_data.token.user.id}
        } 
        console.log(await response.json())    
    }

    return null
}

export const openstackRequest = async ({url, method, headers, data, form_data, token, has_response}) => {
    const state = store.getState()
    const x_auth_token = token ? token : state.profile.x_auth_token
    const has_response_data = has_response !== undefined ? has_response : true
    
    let options = {
        method,
        headers: {
        ...headers
        }
    }

    options.headers["X-Auth-Token"] = x_auth_token
    
    if (data) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data)
    } else if (form_data) {
        options.body = form_data
    }
    
    let response = await fetch(url, options)
    
    if (response.status === Constants.unAuthorizedStatusCode) {   
        for (let i=0; i < Constants.openstack_request_max_retries; i++) {
            
            const new_access_token = await getXAuthTokenProjectScope()

            if (new_access_token) {
                options.headers["X-Auth-Token"] = new_access_token
                response = await fetch(url, options)

                if (response.status !== Constants.unAuthorizedStatusCode) {
                    break;
                }
            }
        }        
    }

    if (response.ok) {
        const status_code = response.status
        let res_data = null
        if (method !== "DELETE" && status_code !== 204 && has_response_data) {
            res_data = await response.json()
        }
        return {status_code: status_code, data: res_data}
    }
    
    let res_data = await response.json()
    
    return { 
        status_code: response.status, 
        data: null, 
        error: JSON.stringify(res_data)
    }
}

export const computeNovaRequest = async ({url, method, headers, data, token, has_response}) => {
    if (!headers) {
        headers = {}
    }
    const state = store.getState() 
    const SERVICE_NAME = openStackServices.computeService
    const computeServiceVersion = state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version
    const maxAPIVersion = state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === computeServiceVersion)[0].max_api_version
    if (!maxAPIVersion) {
        const computeServiceDomain = state.openstack.purchasedServices.filter(
            service => service.service === SERVICE_NAME)[0].config_params.service_domain
        const url = `${computeServiceDomain}/${computeServiceVersion}/`
        const response = await openstackRequest({url:url,method:"GET"})
        if (response.status_code === 200) {
            const max_api_version = response.data.version.version
            const data_to_update = state.computeNova.computeNovaApiUrls.map(ver => {
                if (ver.api_version === computeServiceVersion) {
                    let new_item = {...ver}
                    new_item.max_api_version = max_api_version
                    return new_item
                } else {
                    return ver
                }
            })
            store.dispatch(setComputeNovaApiUrls({computeNovaApiUrls: data_to_update}))
            headers[computeNovaConstants.apiVersionHeaderOld] = max_api_version
            
        }
    } else {
        headers[computeNovaConstants.apiVersionHeaderOld] = maxAPIVersion
    }
    return await openstackRequest({url, method, headers: headers, data, token, has_response})
}

export const volumeCinderRequest = async ({url, method, headers, data, token, has_response}) => {
    if (!headers) {
        headers = {}
    }
    const state = store.getState() 
    const SERVICE_NAME = openStackServices.volumeService
    const volumeServiceVersion = state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version
    const maxAPIVersion = state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === volumeServiceVersion)[0].max_api_version
    if (!maxAPIVersion) {
        const volumeServiceDomain = state.openstack.purchasedServices.filter(
            service => service.service === SERVICE_NAME)[0].config_params.service_domain
        const url = `${volumeServiceDomain}/${volumeServiceVersion}/`
        const response = await openstackRequest({url:url,method:"GET"})  
        if (response.status_code === 200) {
            const max_api_version = response.data.versions.filter(item => item.id.includes(volumeServiceVersion))[0].version
            const data_to_update = state.blockStorageCinder.blockStorageCinderApiUrls.map(ver => {
                if (ver.api_version === volumeServiceVersion) {
                    let new_item = {...ver}
                    new_item.max_api_version = max_api_version
                    return new_item
                } else {
                    return ver
                }
            })
            store.dispatch(setBlockStorageCinderApiUrls({blockStorageCinderApiUrls: data_to_update}))
            headers[blockStorageCinderConstants.apiVersionHeaderNew] = `volume ${max_api_version}`
            
        }
    } else {
        headers[blockStorageCinderConstants.apiVersionHeaderNew] = `volume ${maxAPIVersion}`
    }
    return await openstackRequest({url, method, headers: headers, data, token, has_response})
}

export const billingRequest = async ({ url, method, headers, data, form_data, accessToken, xAuthToken, clientAccountID }) => {
    const state = store.getState()
    
    const access_token = accessToken ? accessToken : state.profile.access_token
    const x_auth_token = xAuthToken ? xAuthToken : state.profile.x_auth_token
    const customer_account_id = clientAccountID ? clientAccountID : state.settings.clientAccountID
    
    let options = {
        method,
        headers: {
        ...headers
        }
    }

    options.headers["Authorization"] = `Bearer ${access_token}`
    options.headers["X-Auth-Token"] = x_auth_token
    options.headers["customer-account-id"] = customer_account_id
    
    if (data) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data)
    }

    if (form_data) {
        options.body = form_data
    }
    
    let response = await fetch(url, options)
    
    if (response.status === Constants.unAuthorizedStatusCode || response.status === 403) {
        const new_access_token = await getAccessToken()
        
        const new_x_auth_token = await getXAuthTokenProjectScope()
        
        if (new_access_token) {
            store.dispatch(setAccessToken({ access_token: new_access_token }))
            options.headers["Authorization"] = `Bearer ${new_access_token}`
            options.headers["X-Auth-Token"] = new_x_auth_token
            response = await fetch(url, options)
        }
    }
    
    if (response.ok) {
        const status_code = response.status
        const res_data = await response.json()
        return {status_code: status_code, data: res_data}
    } 

    return { status_code: response.status, data: null }
}