import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../_common/CustomDialog';
import { openStackServices, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import { snapshotDataForm } from '../../../../../_data/openstack/cinder/group_snapshots/v3';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { volumeCinderRequest } from '../../../../../_network/openstack_request';
import { groupsUrl as groupUrlResponses } from '../../../../../_api_responses/openstack/cinder/group_snapshots/v3';
import AddButtonWithText from '../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.volumeService

const GroupSnapshotAddV3 = (props) => {
  const { handleDataFetch } = props
  const { groups } = props
  // const adminProjects = useSelector(state => state.profile.adminProjects)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const token = useSelector(state => state.profile.x_auth_token)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const [error, setError] = useState()
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});

  const cinderServiceDomain = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const cinderServiceVersion = useSelector(
      state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const snapshotsUrl = useSelector(
      state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
          version => version.api_version === "v3")[0].urls.filter(
              url => url.keyword === blockStorageCinderConstants.groupSnapshotsUrl)[0].url)

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleAddSnapshotFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in snapshotDataForm) {
          if (snapshotDataForm[n].field_type === "string" || 
          snapshotDataForm[n].field_type === "select") {
              new_form_data[snapshotDataForm[n].field_key] = ""
          } else if (snapshotDataForm[n].field_type === "bool") {
              new_form_data[snapshotDataForm[n].field_key] = snapshotDataForm[n].default_value ? 
              snapshotDataForm[n].default_value : 
              false
          }
      }
      setFormData(new_form_data)
  }

  const handleFormDataChange = (event,field_key) => {
      setFormDataOptions({})
      let new_form_data = {...formData}
      if (snapshotDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (snapshotDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormData(new_form_data)
  }


  const getDataForm = () => {
      return (
          <FormGroup>
              {snapshotDataForm.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  if (field.field_key === "group_id") {
                      
                      const group_selection = groups.map(gr => {
                          return {keyword: gr.name, value: gr.id, default: false}
                      })
                      form_field_options["items"] = [...group_selection]
                      form_field_options["self_item_titles"] = true
                      form_field_options["empty"] = true
                  }
                  return (
                      getFormFieldComponent(
                          field,
                          formData,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {...form_field_options}
                      )
                  )
              })}
          </FormGroup>
      )
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in snapshotDataForm) {
          if (snapshotDataForm[n].required && !formData[snapshotDataForm[n].field_key]) {
              validation_faild = false
              updatedDataFormOptions[snapshotDataForm[n].field_key] = {}
              updatedDataFormOptions[snapshotDataForm[n].field_key]["error"] = true
              updatedDataFormOptions[snapshotDataForm[n].field_key]["errorText"] = defaultTexts[snapshotDataForm[n].error_label]
          }
      }

      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const onAddSnapshot = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {          
          const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}`
          const method = "POST"
          const snapshot_response = await volumeCinderRequest({
              url: url, 
              method: method, 
              data: {group_snapshot: formData},
              token,
          })
          if (snapshot_response.status_code === groupUrlResponses.post.success_response.status_code) {
              handleDataFetch()
              handleAddSnapshotFormReset()
              setFormDataOptions({})
          } else {
              const error_response = groupUrlResponses.post.error_response.filter(
                  error_item => error_item.status_code === snapshot_response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: snapshot_response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = groupUrlResponses.post_async.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: snapshot_response.error
                  }
                  setError(errorObject)
              }
          }
          
      }
      return validateFormData
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in snapshotDataForm) {
              if (
                  snapshotDataForm[n].field_type === "string" || 
                  snapshotDataForm[n].field_type === "select"
                  ) {
                  new_form_data[snapshotDataForm[n].field_key] = ""
              } else if (snapshotDataForm[n].field_type === "bool") {
                  new_form_data[snapshotDataForm[n].field_key] = snapshotDataForm[n].default_value ? 
                  snapshotDataForm[n].default_value : 
                  false
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddSnapshot}
        formReset={handleAddSnapshotFormReset}
        customTexts={{
          title: defaultTexts.addSnapshotDialogTitle
        }}
      />

      {
        error &&
        <CustomDialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          dialogTitle={{
            title: defaultTexts[error.error_title], 
            sx: {color: 'primary.main'}}}
          dialogBody={{
            text: `<span>${defaultTexts[error.error_message]}</span>
                    <br>
                    <br>
                    <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                    <span style="color: orange">
                        ${error.error_details}
                    </span>`, 
            sx: {color: 'text.primary'}}}
        />
      }
    </>
  )
}

export default GroupSnapshotAddV3