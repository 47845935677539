import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { BsEthernet } from "react-icons/bs";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const RouterInterfacesV20 = (props) => {
    const { attachedInterfaces, onRouterInterfaceRemove, handleNavigateToNetwork, handleNavigateToSubnet } = props
    const { subnets } = props
    const { networks } = props

    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();
    
    return (
        <React.Fragment>
            <WrapperBox
            sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <BsEthernet
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.attachedInterfacesTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {attachedInterfaces.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{defaultTexts.ipAddressFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.subnetFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.networkFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.statusFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.adminStateFormFieldLabel}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {attachedInterfaces.map((row) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                    >
                                        {row.fixed_ips.map(ip => 
                                            <div key={ip.ip_address}>{ip.ip_address}</div>
                                        )}
                                    </TableCell>
                                    <TableCell align="left" sx={{ cursor: 'pointer', color: 'primary.main' }}>
                                    {row.fixed_ips.map(ip => 
                                        <div key={ip.ip_address} onClick={() => handleNavigateToSubnet(ip)}>
                                            {
                                                subnets.length > 0 ? 
                                                subnets.filter(
                                                    sub => sub.id === ip.subnet_id)[0].name :
                                                `${ip.subnet_id.slice(0,10)}...`
                                            }
                                        </div>
                                    )}
                                    </TableCell>
                                    <TableCell align="left" sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleNavigateToNetwork(row.network_id, 'id')}>
                                        {
                                            networks.length > 0 ? 
                                            networks.filter(
                                                net => net.id === row.network_id)[0].name :
                                                `${row.net_id.slice(0,10)}...`
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.status}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.admin_state_up ? defaultTexts.formValueYes : defaultTexts.formValueNo}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onRouterInterfaceRemove(row.id)}
                                        >
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default RouterInterfacesV20;