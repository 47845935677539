import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { networkNeutronConstants } from '../../../../../../config/openStackConstants';

const PortSpecsV20 = (props) => {
	const theme = useTheme()
	const { portData, selectedRow, handleNavigateToNetwork, handleNavigateToServer, handleNavigateToRouter, handleNavigateToSubnet } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
		background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
		boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
		padding: '20px 0px',
		minHeight: '300px',
		justifyContent: 'flex-start',
	}

	const handleNavigationToDevice = () => {
		if (portData.device_owner === networkNeutronConstants.computeNovaOwner) {
			handleNavigateToServer(selectedRow.device_id)
		} else if (portData.device_owner === networkNeutronConstants.routerInterfaceOwner) {
			handleNavigateToRouter(portData.device_id, "/l3-networks")
		}
	}

	return (
		<React.Fragment>
				<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
						<Grid item xs={12} lg={6}>
								<WrapperBox
										sx={detailTabCardConfig}
								>
										<Stack 
												direction="column" 
												spacing={1} 
												sx={{width: "90%"}}
										>
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.nameFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.name ? portData.name : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.idFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData ? portData.id : ""}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
														onClick={() => handleNavigateToNetwork(selectedRow.network_id, "/l2-networks")}
                            sx={{cursor: "pointer"}}
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.networkFormFieldLabel}
														</CustomText>
														<CustomText size="p" sx={{color: "primary.main"}}>
																{portData.network_id ? 
																		portData.network_id : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.statusFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.status.toUpperCase()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.adminStateUpFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.admin_state_up ? 
																		defaultTexts.formValueYes : 
																		defaultTexts.formValueNo}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
														onClick={handleNavigationToDevice}
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.attachedToFormFieldLabel}
														</CustomText>
														<CustomText 
																size="p" 
																sx={{color: portData.device_id ? "primary.main" : "text.primary",
																		cursor: portData.device_id ? "pointer" : undefined
																}}>
																{portData.device_id ? portData.device_id : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.deviceOwnerFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.device_owner ? portData.device_owner : "-"}
														</CustomText>
												</Stack>
												<Divider />
										</Stack>
								</WrapperBox>
						</Grid>
						<Grid item xs={12} lg={6}>
								<WrapperBox
										sx={detailTabCardConfig}
								>
										<Stack 
												direction="column" 
												spacing={1} 
												sx={{width: "90%"}}
										>
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.revisionNumberFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.revision_number}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.macAddressFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.mac_address}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.ipAddressFormFieldLabel}
														</CustomText>
														<Stack alignItems="end">
																{portData.fixed_ips.map(ip => 
																		<Box 
																				key={ip.ip_address}
																				sx={{cursor: "pointer"}}
																				onClick={() => handleNavigateToSubnet(ip.subnet_id,"/l3-networks")}
																		>
																		<CustomText 
																				size="p" 
																				sx={{color: "primary.main"}}
																		>
																		{ip.subnet_name}: {ip.ip_address} 
																</CustomText></Box>)}
														</Stack>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.portSecurityEnabledFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.port_security_enabled ? 
																		defaultTexts.formValueYes : 
																		defaultTexts.formValueNo}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.qosPolicyFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.qos_policy_id  ? portData.qos_policy_id : "-"}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.createTimeHeaderLabel}
														</CustomText>
														<CustomText size="p">
																{new Date(portData.created_at).toLocaleString()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.updatedAtFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{new Date(portData.updated_at).toLocaleString()}
														</CustomText>
												</Stack>
												<Divider />
												<Stack 
														direction="row" 
														justifyContent="space-between" 
														alignItems="center"
												>
														<CustomText 
																size="p" 
																sx={{color: "text.secondary"}}
														>
																{defaultTexts.descriptionFormFieldLabel}
														</CustomText>
														<CustomText size="p">
																{portData.description ? portData.description : "-"}
														</CustomText>
												</Stack>
												<Divider />
										</Stack>
								</WrapperBox>
						</Grid>
				</Grid>
		</React.Fragment>
  )
}

export default PortSpecsV20