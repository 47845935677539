import React, { useState, useEffect } from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'

const HomeResourceUsageItem = (props) => {
  const theme = useTheme()

  const { resource_name, used, available } = props

  const freeValue = available === 'Unlimited' ? used : available

  const [usedWidth, setUsedWidth] = useState(50)

  const sxLabel = {
    color: theme.palette.customBlack,
    fontSize: '14px',
  }

  const sxUsedValueContainer = {
    display: 'inline-block',
    height: '20px',
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.3),
    border: `${theme.palette.primary.main} 1px solid`,
    fontSize: '14px',
  }

  const sxFreeValueContainer = {
    display: 'inline-block',
    height: '20px',
    color: theme.palette.primary.main,
    background: theme.palette.customWhite,
    border: `${theme.palette.primary.main} 1px solid`,
    fontSize: '14px',
  }

  const sxValue = {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  useEffect(() => {
    let used_width = (used === 0 && freeValue === 0) ? 0 : `${ ((used)/(used + freeValue)) * 100 }`
    used_width = (used_width === 0 || used_width > 20) ? used_width : 20
    if (used_width > 80) {
      used_width = 80
    }
    setUsedWidth(used_width)
  }, [used, freeValue])

  return (
    <>
      <Box sx={sxLabel}>{resource_name}{available === 'Unlimited' && `: ${used}`}</Box>
      <Box sx={{ marginBottom: '8px' }}>

        {
          (used !== 0 && available !== 'Unlimited') &&
          <Box sx={{
            ...sxUsedValueContainer,
            width: `${usedWidth}%`,
            borderRight: freeValue === 0 ? `${theme.palette.secondary.main} 1px solid` : 'none',
            borderRadius: freeValue === 0 ? '4px' : '4px 0px 0px 4px',
          }}>
            <Box sx={sxValue}>{used}</Box>
          </Box>
        }

        {
          ((freeValue !== 0 || used === 0) && available !== 'Unlimited') &&
          <Box sx={{
            ...sxFreeValueContainer,
            width: used === 0 ? '100%' : `${100 - usedWidth}%`,
            borderRadius: used === 0 ? '4px' : '0px 4px 4px 0px',
          }}>
            <Box sx={sxValue}>{available}</Box>
          </Box>
        }        

      </Box>      
    </>
  )
}

export default HomeResourceUsageItem