
import { createSlice } from '@reduxjs/toolkit'
import { flavorDataSchema as  flavorDataSchemaV2_1 } from 
"../../_data/openstack/compute/flavors/v2.1";
import { serverDataSchema as  serverDataSchemaV2_1 } from 
"../../_data/openstack/compute/servers/v2.1";
import { serversFilterConfig as serversFilterConfigV2_1} from 
"../../_data/openstack/compute/servers/v2.1";
import { serviceDataSchema as  serviceDataSchemaV2_1 } from 
"../../_data/openstack/compute/infrastructure/v2.1";
import { keypairDataSchema as keypairDataSchemaV2_1} from 
"../../_data/openstack/compute/keypairs/v2.1";

const initialState = {
    computeNovaApiUrls: [
      {
        api_version: "v2.1",
        max_api_version: null,
        urls: [
          {id: "", keyword: 'serversUrl', url: "servers", api_group: 'serversApiUrls'},
          {id: "", keyword: 'serverGroupsUrl', url: "os-server-groups", api_group: 'serversApiUrls'},
          {id: "", keyword: 'usageUrl', url: "os-simple-tenant-usage", api_group: 'usageApiUrls'},
          {id: "", keyword: 'flavorsUrl', url: "flavors", api_group: 'flavorsApiUrls'},
          {id: "", keyword: 'keyPairsUrl', url: "os-keypairs", api_group: 'keyPairsApiUrls'},
          {id: "", keyword: 'limitsUrl', url: "limits", api_group: 'computeLimitsApiUrls'},
          {id: "", keyword: 'quotaSetsUrl', url: "os-quota-sets", api_group: 'computeLimitsApiUrls'},
          {id: "", keyword: 'aggregatesUrl', url: "os-aggregates", api_group: 'infrastructureApiUrls'},
          {id: "", keyword: 'availabilityZonesUrl', url: "os-availability-zone", api_group: 'infrastructureApiUrls'},
          {id: "", keyword: 'hypervisorsUrl', url: "os-hypervisors", api_group: 'infrastructureApiUrls'},
          {id: "", keyword: 'computeServicesUrl', url: "os-services", api_group: 'infrastructureApiUrls'},
        ]
      }
    ],
    computeFlavorsTableConfig: [
      {
        api_version: "v2.1",
        rows_per_page: 10,
        columns: [
          ...flavorDataSchemaV2_1
        ]
      }
    ],
    computeServersTableConfig: [
        {
          api_version: "v2.1",
          rows_per_page: 10,
          columns: [
            ...serverDataSchemaV2_1
          ]
        }
    ],
    computeServersFilterConfig: [
        {
          api_version: "v2.1",
          columns: [
            ...serversFilterConfigV2_1
          ]
        }
    ],
    computeServerGroupsTableConfig: [
        {
          api_version: "v2.1",
          rows_per_page: 10,
          columns: []
        }
    ],
    computeKeypairsTableConfig: [
      {
        api_version: "v2.1",
        rows_per_page: 10,
        columns: [
          ...keypairDataSchemaV2_1
        ]
      }
    ],
    computeServiceTableConfig: [
        {
          api_version: "v2.1",
          rows_per_page: 10,
          columns: [
            ...serviceDataSchemaV2_1
          ]
        }
    ],
    defaultStatsSubheaderOpenState: true
  }
  
  const computeNovaSlice = createSlice({
    name: 'computeNova',
    initialState,
    reducers: {
      setComputeNovaApiUrls: (state, action) => {
          state.computeNovaApiUrls = action.payload.computeNovaApiUrls
      },
      setComputeFlavorsTableConfig: (state, action) => {
        state.computeFlavorsTableConfig = action.payload.computeFlavorsTableConfig
      },
      setComputeServersTableConfig: (state, action) => {
        state.computeServersTableConfig = action.payload.computeServersTableConfig
      },
      setComputeServersFilterConfig: (state, action) => {
        state.computeServersFilterConfig = action.payload.computeServersFilterConfig
      },
      setComputeServerGroupsTableConfig: (state, action) => {
        state.computeServerGroupsTableConfig = action.payload.computeServerGroupsTableConfig
      },
      setComputeKeypairsTableConfig: (state, action) => {
        state.computeKeypairsTableConfig = action.payload.computeKeypairsTableConfig
      },
      setComputeServiceTableConfig: (state, action) => {
        state.computeServiceTableConfig = action.payload.computeServiceTableConfig
      },
      setDefaultStatsSubheaderOpenState: (state, action) => {
        state.defaultStatsSubheaderOpenState = action.payload.defaultStatsSubheaderOpenState
      },
      computeNovaApiUrlsReset: () => {
        return {...initialState}
      }
    }
  })
  
  export const { 
        setComputeNovaApiUrls,
        setComputeFlavorsTableConfig,
        setComputeServersTableConfig,
        setComputeServersFilterConfig,
        setComputeServerGroupsTableConfig,
        setComputeServiceTableConfig,
        setComputeKeypairsTableConfig,
        setDefaultStatsSubheaderOpenState,
        computeNovaApiUrlsReset
  } = computeNovaSlice.actions
  
  export default computeNovaSlice.reducer