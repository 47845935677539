import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import NoDataNote from '../../../../_common/NoDataNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import VolumeSpecsV3 from './volumeSpecsV3';
import VolumeAttachmentsV3 from './volumeAttachmentsV3';
import VolumeMetadataV3 from './volumeMetadataV3';
import VolumSnapshotsV3 from './volumeSnapshotsV3';
import VolumBackupsV3 from './volumeBackupsV3';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { Grid }  from '@mui/material';
import { openstackRequest, volumeCinderRequest, computeNovaRequest } from '../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import { volumesUrl as volumeUrlResponses } from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import { openStackServices } from '../../../../../config/openStackConstants';
import { computeNovaConstants, blockStorageCinderConstants } from '../../../../../config/openStackConstants';
import ServiceCardContentHeader from '../../../../_common/ServiceCardContentHeader';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomDialog from '../../../../_common/CustomDialog';
import { volumeUpdateForm, volumeStatusList, uploadImageDataForm } from '../../../../../_data/openstack/cinder/volumes/v3';
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';
import { imagesUrl as imagesUrlResponses } from '../../../../../_api_responses/openstack/glance/images/v2';
import CustomTransferList from '../../../../_common/custom_transfer_list/CustomTransferList';
import { handleProjectScopeRoleAssignment } from '../../../../identity_keystone/helpers/rolesHelpers';

const SERVICE_NAME = openStackServices.volumeService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const IMAGE_SERVICE_NAME = openStackServices.imageService
const EXTEND_ACTION_API_VERSION = "3.42"
const RESOURCE_NAME = "OS::Cinder::Volume"
const ALLOWED_ROLE = "admin"

const migrationPolicies = [
    {
        keyword: "onDemandFormChoiceLabel",
        value: "on-demand",
        default: false
    },
    {
        keyword: "neverFormChoiceLabel",
        value: "never",
        default: true
    }
]

const VolumeDetailV3 = (props) => {
	const theme = useTheme()

	const user = useSelector(state => state.profile.userID)
	const drawerOpened = useSelector(state => state.drawer.drawerOpened)
	const token = useSelector(state => state.profile.x_auth_token)
	const mode = useSelector(state => state.settings.uiMode)

	const drawerWidth = drawerOpened ? 270 : 65

	const [isCardLoading, setIsCardLoading] = useState(true)
	const [error, setError] = useState();
	const [warning, setWarning] = useState("")
	const [warningMessage, setWarningMessage] = useState("")
	const [warningDialogOpen, setWarningDialogOpen] = useState(false)
	const [volumeTransferSuccess, setVolumeTransferSuccess] = useState("")
	const { selectedRow, setSelectedRow, setSelectedVolume, handleDataFetch } = props
	const { serversList, volumeGroups } = props
	const { handleVolumeDelete } = props
	const { handleNavigateToServer } = props
	const { handleChangeBootableDialogOpen, volumeTypes, handleNavigateToSnapshot, handleNavigateToBackup, handleNavigateToVolumeGroup } = props
	const { isSuspended, setSuspendedDialogOpen }  = props
	const AdminProjectId = useSelector(state => state.profile.defaultAdminProject)
	const adminProjects = useSelector(state => state.profile.adminProjects)
	const defaultAdminProject = AdminProjectId
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [metadataNamespaces, setMetadataNamespaces] = useState([])
	const [metadataCatalog, setMetadataCatalog] = useState([])
	
	const [attachedServers, setAttachedServers] = useState([]);
	const [volumeSnapshots, setVolumeSnapshots] = useState([]);
	const [volumeBackups, setVolumeBackups] = useState([]);

	const [volumeActions, setVolumeActions] = useState([]);
	const [currentAction, setCurrentAction] = useState("");

	const [volumeUpdateData, setVolumeUpdateData] = useState({})
	const [updateVolumeDialogOpen, setUpdateVolumeDialogOpen] = useState(false)
	const [selectedVolumeStatusError, setSelectedVolumeStatusError] = useState(false)
	const [selectedVolumeStatus, setSelectedVolumeStatus] = useState("")
	const [volumeStatusUpdateDialogOpen, setVolumeStatusUpdateDialogOpen] = useState(false)
	const [volumeStatusMenu, setVolumeStatusMenu] = useState([
			...volumeStatusList.filter(s => s.value !== selectedRow.status)])

	const [volumeSizeExtendDialogOpen, setVolumeSizeExtendDialogOpen] = useState(false)
	const [selectedVolumeSize, setSelectedVolumeSize] = useState(selectedRow.size)
	const [selectedVolumeSizeError, setSelectedVolumeSizeError] = useState(false)

	const [selectedVolumeInstance, setSelectedVolumeInstance] = useState("")
	const [selectedVolumeInstanceError, setSelectedVolumeInstanceError] = useState(false)
	const [volumeAttachmentDialogOpen, setVolumeAttachmentDialogOpen] = useState(false);
	const [instanceList, setInstanceList] = useState([])
	const [deleteOnTerminate, setDeleteOnTerminate] = useState(false)
	const [createSnapshotDialogOpen, setCreateSnapshotDialogOpen] = useState(false)
	const [snapshotData, setSnapshotData] = useState({})
	const [snapshotDataOptions, setSnapshotDataOptions] = useState({})

	const [createBackupDialogOpen, setCreateBackupDialogOpen] = useState(false)
	const [backupData, setBackupData] = useState({})

	const [uploadImageData, setUploadImageData] = useState({});
	const [uploadImageOptions, setUploadImageOptions] = useState({});
	const [uploadImageDialogOpen, setUploadImageDialogOpen] = useState(false)
	const [updatedMetadata, setUpdatedMetadata] = useState({})
	const [volumeMetadataDialogOpen, setVolumeMetadataDialogOpen] = useState(false)

	const [volumeTransferName, setVolumeTransferName] = useState("")
	const [volumeTransferNameError, setVolumeTransferNameError] = useState(false)
	const [volumeTransferDialogOpen, setVolumeTransferDialogOpen] = useState(false)

	const [volumeTransferSuccessDialogOpen, setVolumeTransferSuccessDialogOpen] = useState(false)
	const [volumeTypeUpdateDialogOpen, setVolumeTypeUpdateDialogOpen] = useState(false)
	const [selectedVolumeTypeError, setSelectedVolumeTypeError] = useState(false)
	const [selectedVolumeType, setSelectedVolumeType] = useState("")
	const [selectedMigrationPolicy, setSelectedMigrationPolicy] = useState("never")
	const [ volumeTypesList, setVolumeTypesList] = useState([])

	const [volumeSubMenu, setVolumeSubMenu] = useState([
		{keyword: "submenuDetails", navigation: "/volume-details", is_active: true},
		// {keyword: "submenuAttachments", navigation: "/volume-attachments", is_active: false},
		{keyword: "submenuMetadata", navigation: "/volume-metadata", is_active: false},
		{keyword: "submenuSnapshots", navigation: "/volume-snapshots", is_active: false},
		{keyword: "submenuBackups", navigation: "/volume-backups", is_active: false}
	])

	const [currentTab, setCurrentTab] = useState("/volume-details")
	
	const cinderServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const cinderServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const volumesUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
	const maxAPIVersion = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].max_api_version)
	const snapshotsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
	const backupsUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
	const volumeTransfersUrl = useSelector(
			state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
					version => version.api_version === "v3")[0].urls.filter(
							url => url.keyword === blockStorageCinderConstants.volumeTransfersUrl)[0].url)
	const computeServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
	const computeServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
	const serversUrl = useSelector(
			state => state.computeNova.computeNovaApiUrls.filter(
					version => version.api_version === "v2.1")[0].urls.filter(
							url => url.keyword === computeNovaConstants.serversUrl)[0].url)
	const imageServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
	const imageServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)
	

	const common_url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${selectedRow.id}`

	const getFormattedVolumeData = useCallback((data) => {
		const group = volumeGroups.filter(vg => vg.id === data.group_id)

		let formatted_data = {...data}
		
		formatted_data.bootable = data.bootable === "true" ? true : false
		formatted_data.size = `${data.size} GB`
		formatted_data.group_id = group.length > 0 ? group[0].name : data.group_id
		return formatted_data
	}, [volumeGroups])

	const handleWarningDialogClose = () => {
			setWarningDialogOpen(false)
	}

	const handleRoleAssignment = useCallback(async () => {
			const project_id = selectedRow[blockStorageCinderConstants.volumeProjectIdField]
			const response = await handleProjectScopeRoleAssignment(project_id,user,ALLOWED_ROLE)
			if (response) {
					setWarningDialogOpen(false)
			}
	},[
			selectedRow,
			user
	])

	const handleMetadataFormatting = useCallback((objs,props) => {
			const metadata_catalog = metadataNamespaces.map(ns => {
					let new_item = {...ns}
					const resource_type_association = ns.resource_type_associations.filter(rs => rs.name === RESOURCE_NAME)[0]
					new_item["value"] = ns.namespace
					new_item["title"] = ns.display_name
					const prefix = resource_type_association.prefix ? resource_type_association.prefix : ""
					new_item["prefix"] = prefix
					new_item["objects"] = objs[ns.namespace].map((obj,index) => {
							let new_object = {...obj}
							new_object["title"] = obj.name
							new_object["value"] = `${ns.namespace}_${prefix}object${index}`
							new_object["properties"] = []
							const obj_props = Object.keys(obj.properties).map(key => {
									let new_prop = {}
									new_prop["value"] = `${prefix}${key}`
									for (const [k,v] of Object.entries(obj.properties[key])) {
											if (Array.isArray(v)) {
													new_prop[k] = [...v]
											} else if (v instanceof Object) {
													new_prop[k] = {...v}
											} else {
													new_prop[k] = v
											}
									}
									return new_prop
							})
							new_object["properties"] = obj_props
							return new_object
					})
					new_item["properties"] = Object.keys(props[ns.namespace]).map(pr_key => {
							let new_pr = {}
							new_pr["value"] = `${prefix}${pr_key}`
							for (let k in props[ns.namespace][pr_key]) {
									if (k === "items") {
											new_pr["enum"] = props[ns.namespace][pr_key][k]["enum"]
									} else if (Array.isArray(props[ns.namespace][pr_key][k])) {
											new_pr[k] = [...props[ns.namespace][pr_key][k]]
									} else if (props[ns.namespace][pr_key][k] instanceof Object) {
											new_pr[k] = {...props[ns.namespace][pr_key][k]}
									} else {
											new_pr[k] = props[ns.namespace][pr_key][k]
									}
							}
							return  new_pr
					})
					return new_item
			})
			setMetadataCatalog(metadata_catalog)
	},[metadataNamespaces])

	const handleVolumeDetailTabChange = useCallback((navigation) => {
			let newVolumeSubmenuData = volumeSubMenu.map(item => {
					if (item.navigation === navigation) {
							item.is_active = true
					} else {
							item.is_active = false
					}
					return item
			})
			setVolumeSubMenu(newVolumeSubmenuData)
			setCurrentTab(navigation)
	},[
			volumeSubMenu
	])

	const handleCreateSnapshotDialogClose = () => {
			setSnapshotData({})
			setCreateSnapshotDialogOpen(false)
	}

	const handleCreateSnapshotDialogOpen = () => {
			setCreateSnapshotDialogOpen(true)
	}

	const handleCreateBackupDialogClose = () => {
			setBackupData({})
			setCreateBackupDialogOpen(false)
	}

	const handleCreateBackupDialogOpen = () => {
			setCreateBackupDialogOpen(true)
	}

	const handleUploadImageDialogClose = () => {
			setUploadImageData({})
			setUploadImageDialogOpen(false)
	}

	const handleUploadImageDialogOpen = () => {
			setUploadImageDialogOpen(true)
	}

	const handleVolumeTransferDialogOpen = () => {
			setVolumeTransferDialogOpen(true)
	}

	const handleVolumeTransferSuccessDialogClose = () => {
			setVolumeTransferSuccessDialogOpen(false)
	}

	const handleVolumeTransferSuccessDialogOpen = () => {
			setVolumeTransferSuccessDialogOpen(true)
	}

	const handleVolumeTransferDialogClose = () => {
			setVolumeTransferName("")
			setVolumeTransferDialogOpen(false)
	}

	const validateUploadImageFormData = () => {
			let validation_faild = true
			let updatedDataFormOptions = {...uploadImageOptions}
			for (let n in uploadImageDataForm) {
					if (uploadImageDataForm[n].required && !uploadImageData[uploadImageDataForm[n].field_key]) {
							validation_faild = false
							updatedDataFormOptions[uploadImageDataForm[n].field_key] = {}
							updatedDataFormOptions[uploadImageDataForm[n].field_key]["error"] = true
							updatedDataFormOptions[uploadImageDataForm[n].field_key]["errorText"] = defaultTexts[uploadImageDataForm[n].error_label]
					}
			}
			setUploadImageOptions(updatedDataFormOptions)
			return validation_faild
	}

	const handleConfirmDeleteDialogOpen = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			handleVolumeDelete([selectedRow.id])
		}			
	}

	const onChangeBootableDialogOpen = useCallback(() => {
			handleChangeBootableDialogOpen([selectedRow.id])
	},[handleChangeBootableDialogOpen,selectedRow])

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleVolumeStatusChange = (event) => {
			setSelectedVolumeStatusError(false)
			setSelectedVolumeStatus(event)
	}

	const handleVolumeTransferNameChange = (event) => {
			setVolumeTransferNameError(false)
			setVolumeTransferName(event.target.value)
	}

	const getDataForm = (form,form_options,data,onDataChange) => {
			return (
					<FormGroup>
							{form.map(field => {
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_options[field.field_key]}
											)
									)
							})}
					</FormGroup>
			)
	}

	const getImageDataForm = (form,form_options,data,onDataChange) => {
			return (
					<FormGroup>
							{form.map(field => {
									if (field.field_key === "disk_format") {
											form_options[field.field_key] = {}
											form_options[field.field_key]["items"] = [...field.items]
											form_options[field.field_key]["self_item_titles"] = false
											form_options[field.field_key]["item_titles"] = defaultTexts
									}
									return (
											getFormFieldComponent(
													field,
													data,
													onDataChange,
													defaultTexts[field.label],
													{...form_options[field.field_key]}
											)
									)
							})}
					</FormGroup>
			)
	}

	const handleVolumeUpdateDataChange = (event,field_key) => {
			let new_form_data = {...volumeUpdateData}
			if (volumeUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else {
					new_form_data[field_key] = event.target.value
			}
			setVolumeUpdateData(new_form_data)
	}

	const handleSnapshotDataChange = (event,field_key) => {
			let new_form_data = {...snapshotData}
			if (volumeUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else {
					new_form_data[field_key] = event.target.value
			}
			setSnapshotData(new_form_data)
	}

	const handleBackupDataChange = (event,field_key) => {
			let new_form_data = {...backupData}
			if (volumeUpdateForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else {
					new_form_data[field_key] = event.target.value
			}
			setBackupData(new_form_data)
	}

	const handleUploadImageDataChange = (event,field_key) => {
			setUploadImageOptions({})
			let new_form_data = {...uploadImageData}
			if (uploadImageDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "bool") {
					new_form_data[field_key] = event.target.checked
			} else if (uploadImageDataForm.filter(
					item => item.field_key === field_key)[0].field_type === "select") {
					new_form_data[field_key] = event
			} else {
					new_form_data[field_key] = event.target.value
			}
			setUploadImageData(new_form_data)
	}

	const handleUpdateVolumeDialogOpen = () => {
			setUpdateVolumeDialogOpen(true)
	}

	const handleUpdateVolumeDialogClose = () => {
			setUpdateVolumeDialogOpen(false)
	}

	const handleVolumeStatusUpdateDialogClose = () => {
			setSelectedVolumeStatus("")
			setSelectedVolumeStatusError(false)
			setVolumeStatusUpdateDialogOpen(false)
	}

	const onVolumeStatusUpdate = async () => {
			if (selectedVolumeStatus.length === 0) {
					setSelectedVolumeStatusError(true)
					return false
			} else {
					
					
				const method = "POST"
				const volume_response = await volumeCinderRequest({
						url: `${common_url}/action`, 
						method: method, 
						data: {"os-reset_status": {status: selectedVolumeStatus}},
						token,
						has_response: false
				})
				if (volume_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
						setCurrentAction("")
						handleVolumeStatusUpdateDialogClose()
						handleDataFetch()
				} else {
						setError(volume_response.error)
				}
					
			}
	}

	const handleVolumeAttachmentDialogClose = () => {
			setSelectedVolumeInstance("")
			setSelectedVolumeInstanceError(false)
			setVolumeAttachmentDialogOpen(false)
	}

	const handleVolumeAttachmentDialogOpen = () => {
			setVolumeAttachmentDialogOpen(true)
	}

	const handleVolumeInstanceChange = (event) => {
			setSelectedVolumeInstanceError(false)
			setSelectedVolumeInstance(event)
	}

	const handleDeleteOnTerminateChange = (event) => {
			setDeleteOnTerminate(event.target.checked)
	}

	const onVolumeAttachment = async () => {
			if (selectedVolumeInstance.length === 0) {
					setSelectedVolumeInstanceError(true)
					return false
			} else { 
					
					
				const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${selectedVolumeInstance}/os-volume_attachments`
				const method = "POST"
				const server_response = await computeNovaRequest({
						url:url, 
						method:method, 
						data: {
								volumeAttachment: {
										volumeId: selectedRow.id, 
										delete_on_termination: deleteOnTerminate
								}},
						token,
				})
				if (server_response.status_code === 200) {
						setCurrentAction("")
						handleVolumeAttachmentDialogClose()
						handleDataFetch()
				} else {
						setError(server_response.error)
				}
					
			}
	}

	const handleVolumeSizeExtendDialogClose = () => {
			setSelectedVolumeSize("")
			setSelectedVolumeSizeError(false)
			setVolumeSizeExtendDialogOpen(false)
	}

	const handleVolumeSizeExtendDialogOpen = () => {
			setVolumeSizeExtendDialogOpen(true)
	}

	const handleVolumeSizeChange = (event) => {
			setSelectedVolumeSizeError(false)
			setSelectedVolumeSize(event.target.value)
	}

	const onVolumeSizeExtend = async () => {
			if (selectedVolumeSize <= selectedRow.size) {
					setSelectedVolumeSizeError(true)
					return false
			} else {
					
					
				const method = "POST"
				const volume_response = await volumeCinderRequest({
						url: `${common_url}/action`, 
						method: method, 
						data: {"os-extend": {new_size: selectedVolumeSize}},
						token,
						has_response: false
				})
				if (volume_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
						setCurrentAction("")
						handleVolumeSizeExtendDialogClose()
						handleDataFetch()
				} else {
						setError(volume_response.error)
				}
					
			}
	}

	const onVolumeUpdate = async () => {
			
		const method = "PUT"
		const volume_response = await volumeCinderRequest({
				url: common_url, 
				method: method, 
				data: {volume: volumeUpdateData},
				token,
		})
		if (volume_response.status_code === volumeUrlResponses.put.success_response.status_code) {
				setCurrentAction("")
				handleUpdateVolumeDialogClose()
				handleDataFetch()
		} else {
				setError(volume_response.error)
		}
			
	}

	const onSnapshotCreate = async () => {
			if (snapshotData.name.length === 0) {
					let updatedDataFormOptions = {...snapshotDataOptions}
					if (snapshotData.name.length === 0) {
							updatedDataFormOptions.name = {}
							updatedDataFormOptions.name["error"] = true
							updatedDataFormOptions.name["errorText"] = defaultTexts.requiredFormFieldError
					}
					setSnapshotDataOptions(updatedDataFormOptions)
					return false
			} else {
					
					
				const method = "POST"
				const snapshot_response = await volumeCinderRequest({
						url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}`, 
						method: method, 
						data: {snapshot: {
								...snapshotData,
								force: true,
								volume_id: selectedRow.id
						}},
						token,
				})
				if (snapshot_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
						setCurrentAction("")
						handleCreateSnapshotDialogClose()
						handleDataFetch()
				} else {
						setError(snapshot_response.error)
				}
					
			}
	}

	const onBackupCreate = async () => {
			

		const method = "POST"
		const backup_response = await volumeCinderRequest({
				url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}`, 
				method: method, 
				data: {backup: {
						...backupData,
						volume_id: selectedRow.id,
						force: true
				}},
				token,
		})
		if (backup_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
				setCurrentAction("")
				handleCreateBackupDialogClose()
				handleDataFetch()
		} else {
				setError(backup_response.error)
		}
			
	}

	const onUploadImage = async () => {
			const validated = validateUploadImageFormData()
			if (validated) {
					
					
				const method = "POST"
				const image_response = await volumeCinderRequest({
						url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${selectedRow.id}/action`, 
						method: method, 
						data: {"os-volume_upload_image": {
								...uploadImageData,
								force: true
						}},
						token,
				})

				if (image_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
						setCurrentAction("")
						handleUploadImageDialogClose()
						handleDataFetch()
				} else {
						setError(image_response.error)
				}
					
			}
	}

	const onVolumeTransfer = async () => {
			if (volumeTransferName.length === 0) {
					setVolumeTransferNameError(true)
					return false
			} else {
					
					
				const method = "POST"
				const volume_response = await volumeCinderRequest({
						url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}`, 
						method: method, 
						data: {transfer: {
								volume_id: selectedRow.id,
								name: volumeTransferName
						}},
						token,
				})
				if (volume_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
						setCurrentAction("")
						handleVolumeTransferDialogClose()
						handleDataFetch()
						setVolumeTransferSuccess(`${defaultTexts.volumeTransferAuthKeyDetails} ${volume_response.data.transfer.auth_key}`)
						handleVolumeTransferSuccessDialogOpen()
				} else {
						setError(volume_response.error)
				}
					
			}
	}

	const onVolumeMetadataUpdate = async () => {
			let o_k = []
			let o_v = []
			let n_k = []
			let n_v = []
			for (const [old_k,old_v] of Object.entries(selectedRow.metadata)) {
					o_k.push(old_k)
					o_v.push(old_v)
			}
			for (const [new_k,new_v] of Object.entries(updatedMetadata)) {
					n_k.push(new_k)
					n_v.push(new_v)
			}
			const removed_keys = o_k.filter(item => !n_k.includes(item))
			
			
			let method = "POST"
			const volume_response = await volumeCinderRequest({
					url: `${common_url}/metadata`, 
					method: method, 
					data: {metadata: updatedMetadata},
					token,
			})
			for (let key in removed_keys) {
					let url = `${common_url}/metadata/${removed_keys[key]}`
					method="DELETE"
					await volumeCinderRequest({
							url: url, 
							method: method, 
							token,
					})
			}
			if (volume_response.status_code === volumeUrlResponses.post.success_response.status_code) {
					setCurrentAction("")
					handleVolumeMetadataSelectionDialogClose()
					handleDataFetch()
			} else {
					setError(volume_response.error)
			}
			
	}

	const handleVolumeMetadataDialogOpen = () => {
			setVolumeMetadataDialogOpen(true)
	}

	const handleVolumeMetadataSelectionDialogClose = () => {
			setUpdatedMetadata([])
			setVolumeMetadataDialogOpen(false)
	}

	const handleSnapshotWarningMessageOpen = useCallback(() => {
			const formatted_volume = getFormattedVolumeData(selectedRow)
			const warning_message = `<span>${defaultTexts.nonAllowedActionExecutionWarning}: <strong>${formatted_volume[blockStorageCinderConstants.volumeProjectIdField]}</strong></span>
																	<br>
																	<br> 
															<span>${defaultTexts.notRequiredRole}: <strong>${ALLOWED_ROLE}</strong></span>`
			
			const warning = <Box 
					onClick={handleRoleAssignment} 
					sx={{
							cursor: "pointer",
							color: "info.main",
							textAlign: "start"
					}}
					>
					{`${defaultTexts.getAdminRoleToGivenProject}: ${ALLOWED_ROLE} -> ${formatted_volume[blockStorageCinderConstants.volumeProjectIdField]}`}
					</Box>
			setWarningMessage(warning_message)
			setWarning(warning)
			setWarningDialogOpen(true)
	},[
			defaultTexts,
			handleRoleAssignment,
			getFormattedVolumeData,
			selectedRow
	])

	const handleVolumeTypeUpdateDialogClose = () => {
			setSelectedVolumeType("")
			setSelectedMigrationPolicy("never")
			setSelectedVolumeTypeError(false)
			setVolumeTypeUpdateDialogOpen(false)
	}

	const handleVolumeTypeUpdateDialogOpen = useCallback(() => {
			setVolumeTypeUpdateDialogOpen(true)
	},[])

	const handleVolumeTypeChange = (event) => {
			setSelectedVolumeTypeError(false)
			setSelectedVolumeType(event)
	}

	const onVolumeTypeUpdate = async () => {
			let data = {}
			data["os-retype"] = {new_type: selectedVolumeType, migration_policy: selectedMigrationPolicy}
			
			handleVolumeTypeUpdateDialogClose()

			
			const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${selectedRow.id}/action`
			const method = "POST"
			
			const v_response = await volumeCinderRequest({
					url:url, 
					method:method,
					data: data,
					token,
					has_response: false
			})
			if (v_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
					setCurrentAction("")
					handleDataFetch()
			} else {
					return false
			}
			
	}

	const handleApplyAction = () => {
		if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			volumeActions.filter(action => action.value === currentAction)[0].action()
		}
	}

	useEffect(() => {
			setVolumeStatusMenu(volumeStatusList.filter(s => s.value !== selectedRow.status))
	},[selectedRow]);

	useEffect(() => {
			const scoped_actions_allowed = adminProjects.map(
					p => p.id).includes(
							selectedRow[blockStorageCinderConstants.volumeProjectIdField])
			let volume_actions = []
			let new_action = {}
			new_action["value"] = "update_volume"
			new_action["action"] = handleUpdateVolumeDialogOpen
			new_action["keyword"] = "volumeUpdateActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			volume_actions.push({...new_action})
			if (selectedRow.status === "available" ||
					(parseFloat(maxAPIVersion) >= parseFloat(EXTEND_ACTION_API_VERSION) && 
					selectedRow.status === "in-use")
			) {
					new_action = {}
					new_action["value"] = "extend_volume"
					new_action["action"] = handleVolumeSizeExtendDialogOpen
					new_action["keyword"] = "volumeExtendActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					volume_actions.push({...new_action})
			}
			if (selectedRow.status === "available") {
					new_action = {}
					new_action["value"] = "volume_attach"
					new_action["action"] = handleVolumeAttachmentDialogOpen
					new_action["keyword"] = "volumeAttachToServerActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					volume_actions.push({...new_action})
			}

			// new_action = {}
			// new_action["value"] = "volume_status_update"
			// new_action["action"] = handleVolumeStatusUpdateDialogOpen
			// new_action["keyword"] = "volumeStatusUpdateActionTitle"
			// new_action["button_text"] = "selectButtonTitleText"
			// volume_actions.push({...new_action})

			new_action = {}
			new_action["value"] = "update_bootable_status"
			new_action["action"] = onChangeBootableDialogOpen
			new_action["keyword"] = "updateBootableStatusActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			volume_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "change_type"
			new_action["action"] = handleVolumeTypeUpdateDialogOpen
			new_action["keyword"] = "changeVolumeTypeActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			volume_actions.push({...new_action})
			new_action = {}
			new_action["value"] = "create_snapshot"
			new_action["keyword"] = "createSnapshotActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			if (scoped_actions_allowed) {
					new_action["action"] = handleCreateSnapshotDialogOpen
			} else {
					new_action["action"] = handleSnapshotWarningMessageOpen
			}
			volume_actions.push({...new_action})
			if (selectedRow.status === "available" || selectedRow.status === "in-use") {
					new_action = {}
					new_action["value"] = "create_backup"
					new_action["keyword"] = "createBackupActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					if (scoped_actions_allowed) {
							new_action["action"] = handleCreateBackupDialogOpen
					} else {
							new_action["action"] = handleSnapshotWarningMessageOpen
					}
					volume_actions.push({...new_action})
			}
			if (selectedRow.status === "available" && 
					volumeSnapshots.filter(item => item.status !== "available").length === 0 &&
					!selectedRow.encrypted
			) {
					new_action = {}
					new_action["value"] = "create_transfer"
					new_action["action"] = handleVolumeTransferDialogOpen
					new_action["keyword"] = "createTransferActionTitle"
					new_action["button_text"] = "selectButtonTitleText"
					volume_actions.push({...new_action})
			}

			if (selectedRow.status === "available") {
				new_action = {}
				new_action["value"] = "upload_to_image"
				new_action["action"] = handleUploadImageDialogOpen
				new_action["keyword"] = "uploadToImageActionTitle"
				new_action["button_text"] = "selectButtonTitleText"
				volume_actions.push({...new_action})
			}			

			new_action = {}
			new_action["value"] = "update_metadata"
			new_action["action"] = handleVolumeMetadataDialogOpen
			new_action["keyword"] = "updateMetadataActionTitle"
			new_action["button_text"] = "selectButtonTitleText"
			volume_actions.push({...new_action})

			// new_action = {}
			// new_action["value"] = "migrate_volume"
			// new_action["action"] = () => {}
			// new_action["keyword"] = "volumeMigrateActionTitle"
			// new_action["button_text"] = "selectButtonTitleText"
			// volume_actions.push({...new_action})
			
			setVolumeActions(volume_actions)
	},[
			selectedRow,
			maxAPIVersion,
			handleChangeBootableDialogOpen,
			handleVolumeTypeUpdateDialogOpen,
			volumeSnapshots,
			adminProjects,
			onChangeBootableDialogOpen,
			handleSnapshotWarningMessageOpen
	])

	useEffect(() => {			
		let new_form_data = {}
		for (const n in volumeUpdateForm) {
				new_form_data[volumeUpdateForm[n].field_key] = selectedRow[volumeUpdateForm[n].field_key]
		}
		setVolumeUpdateData(new_form_data)			
	},[selectedRow]);
	
	useEffect(() => {
			if (Object.keys(snapshotData).length === 0) {
					let new_form_data = {}
					for (const n in volumeUpdateForm) {
							new_form_data[volumeUpdateForm[n].field_key] = ""
					}
					setSnapshotData(new_form_data)
			}
	},[snapshotData]);

	useEffect(() => {
			if (Object.keys(backupData).length === 0) {
					let new_form_data = {}
					for (const n in volumeUpdateForm) {
							new_form_data[volumeUpdateForm[n].field_key] = ""
					}
					setBackupData(new_form_data)
			}
	},[backupData]);

	useEffect(() => {
			if (Object.keys(uploadImageData).length === 0) {
					let new_form_data = {}
					for (const n in uploadImageDataForm) {
							new_form_data[uploadImageDataForm[n].field_key] = uploadImageDataForm[n].default_value ? 
							uploadImageDataForm[n].default_value : ""
					}
					setUploadImageData(new_form_data)
			}
	},[uploadImageData]);

	useEffect(() => {
			if (selectedRow.attachments.length > 0 && serversList.length > 0) {
					const attached_servers = selectedRow.attachments.map(a => {
							const server = serversList.filter(s => s.id === a.server_id)

							return {
									id: server.length > 0 ? server[0].id : '',
									name: server.length > 0 ? server[0].name : '',
									device: a.device,
									host_name: a.host_name
							}
					})
					setAttachedServers(attached_servers)
			} else {
				setAttachedServers([])
			}
	},[
			serversList,
			selectedRow
	]);
	
	useEffect(() => {
			(async () => {
					
					
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail?`
				const method = "GET"

				const snapshots_response = await volumeCinderRequest({
						url:url, 
						method:method, 
						token,
				})
				if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
						const volume_snapshots = snapshots_response.data.snapshots.filter(item => item.volume_id === selectedRow.id)
						setVolumeSnapshots(volume_snapshots)
				}
					
			})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion, 
			volumeTransfersUrl,
			defaultAdminProject,
			snapshotsUrl,
			selectedRow,
			token,
	]);

	useEffect(() => {
			(async () => {
					
					
				let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail?`
				const method = "GET"

				const backups_response = await volumeCinderRequest({
						url:url, 
						method:method, 
						token,
				})
				if (backups_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
						const volume_backups = backups_response.data.backups.filter(item => item.volume_id === selectedRow.id)
						setVolumeBackups(volume_backups)
				}
					
			})();
	},[
			cinderServiceDomain, 
			cinderServiceVersion,
			defaultAdminProject,
			backupsUrl,
			selectedRow,
			token,
	]);

	useEffect(() => {
			if (serversList.length > 0) {
					const attached_instances = attachedServers.map(item => item.id)
					const server_items = serversList.filter(
							item => item.tenant_id === selectedRow[blockStorageCinderConstants.volumeProjectIdField] &&
							!attached_instances.includes(item.id)
					)
					if (server_items.length > 0) {
							const formatted_data = server_items.map(item => {
									return {keyword: item.name, value: item.id, default: false}
							})
							setInstanceList(formatted_data)
					}
			}
	},[
			serversList,
			attachedServers,
			selectedRow
	]);

	useEffect(() => {
			(async () => {
					const url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces`
					const method = "GET"
					
					
					let namespace_list = []
					const metadata_response = await openstackRequest({
							url:url, 
							method:method, 
							token,
					})
					if (metadata_response.status_code === imagesUrlResponses.get.success_response.status_code) {
							const namespaces = metadata_response.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
									item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
							)
							namespace_list = [...namespace_list, ...namespaces]
							if (Object.keys(metadata_response.data).includes("next")) {
									let next_url = `${imageServiceDomain}${metadata_response.data.next}`
									while (next_url) {
											const next_request = await openstackRequest({
													url:next_url, 
													method:method, 
													token,
											})
											const next_namespaces = next_request.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
													item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
											)
											namespace_list = [...namespace_list, ...next_namespaces]
											if (Object.keys(next_request.data).includes("next")) {
													next_url = `${imageServiceDomain}${next_request.data.next}`
											} else {
													next_url = null
											}
									}
							}
							const compare = ( a, b ) => {
									if ( a["display_name"].toLowerCase() < b["display_name"].toLowerCase() ){
										return -1;
									}
									if ( a["display_name"].toLowerCase() > b["display_name"].toLowerCase() ){
										return 1;
									}
									return 0;
							}
							namespace_list.sort(compare)
							setMetadataNamespaces(namespace_list)
					} else {
							setMetadataNamespaces([])
					}
					
			})();
	},[
			selectedRow,
			imageServiceDomain,
			imageServiceVersion,
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
			if (metadataNamespaces.length > 0) {
					(async () => {
							let total_objects = {}
							let total_properties = {}
							
							const method = "GET"
							for (let i in metadataNamespaces) {
									let namespace_objects = []
									let namespace_properties = {}
									
									const object_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/objects`
									const object_response = await openstackRequest({
											url:object_url, 
											method:method, 
											token,
									})
									if (object_response.status_code === imagesUrlResponses.get.success_response.status_code) {
											namespace_objects = [...namespace_objects, ...object_response.data.objects]
									}
									const props_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/properties`
									const props_response = await openstackRequest({
											url:props_url, 
											method:method, 
											token,
									})
									if (props_response.status_code === imagesUrlResponses.get.success_response.status_code) {
											namespace_properties = {...namespace_properties, ...props_response.data.properties}
									}
									
									total_objects[metadataNamespaces[i].namespace] = namespace_objects
									total_properties[metadataNamespaces[i].namespace] = namespace_properties
							}
							handleMetadataFormatting(total_objects, total_properties)
					})();
			}
	},[
			metadataNamespaces,
			imageServiceDomain,
			imageServiceVersion,
			selectedRow,
			handleMetadataFormatting,
			defaultAdminProject,
			token,
	]);

	useEffect(() => {
		const volumeTypeObjects = volumeTypes.map(item => {
			return {keyword: item.name, value: item.id, default: false}
		})
		setVolumeTypesList(volumeTypeObjects)
	},[
			volumeTypes,
	]);

	useEffect(() => {
			setTimeout(() => setIsCardLoading(false), 600)
	},[])

	useEffect(() => {
		setCurrentTab('/volume-details')
		setVolumeSubMenu((prev) => {
			return prev.map(item => {
				if (item.navigation === '/volume-details') {
					item.is_active = true
				} else {
					item.is_active = false
				}
	
				return item
			})
		})
	}, [selectedRow])
	
	return (
		<>
			{
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={volumeSubMenu}
						service_menu_titles={defaultTexts}
						onClick={handleVolumeDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedVolume}
					/>
				</WrapperBox>
			}

			<Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
				{
					currentTab === "/volume-details" &&
					<VolumeSpecsV3 
						volumeData={getFormattedVolumeData(selectedRow)}
						attachedServers={attachedServers}
						handleNavigateToServer={handleNavigateToServer}
						handleNavigateToVolumeGroup={handleNavigateToVolumeGroup}
						selectedRow={selectedRow}
					/>
				}

				{
					currentTab === "/volume-attachments" &&
					<VolumeAttachmentsV3  
						attachedServers={attachedServers}
						handleNavigateToServer={handleNavigateToServer}
						selectedRow={selectedRow}
						handleDataFetch={handleDataFetch}
					/>
				}

				{
					currentTab === "/volume-metadata" && 
					<VolumeMetadataV3
						volumeMetadata={selectedRow.metadata}
						volumeData={selectedRow}
						handleDataFetch={handleDataFetch}
					/>
				}
				
				{
					currentTab === "/volume-snapshots" && 
					<Box>
						{
							volumeSnapshots.length > 0 ?
							<VolumSnapshotsV3
								volumeSnapshots={volumeSnapshots}
								handleDataFetch={handleDataFetch}
								handleNavigateToSnapshot={handleNavigateToSnapshot}
							/>
							:
							<NoDataNote text={defaultTexts.noSnapshotsNoteText} />
						}
					</Box>
				}

				{
					currentTab === "/volume-backups" && 
					<Box>
						{
							volumeBackups.length > 0 ?
							<VolumBackupsV3
								volumeBackups={volumeBackups}
								handleDataFetch={handleDataFetch}
								handleNavigateToBackup={handleNavigateToBackup}
							/>
							:
							<NoDataNote text={defaultTexts.noBackupsNoteText} />
						}
					</Box>
				}
			</Box>
			
			{
				!isCardLoading && 
				<Paper sx={{ 
					position: 'fixed',
					width: `calc(100% - ${drawerWidth}px)`,
					bottom: 0, 
					height: '60px',
					left: `${drawerWidth}px`,
					borderTop: `${theme.palette.customGrayDark} 2px solid`,
					borderRadius: '0px',
					padding: '0px 20px 0px 12px',
				}} 
			>
					<Grid 
							container 
							alignItems="center"  
							justifyContent="space-between"
					>
							<Grid item>
									<CustomSelectField 
											items={volumeActions} 
											currentValue={currentAction}
											setCurrentValue={setCurrentAction}
											item_titles={defaultTexts}
											label={defaultTexts.actionsDropdownLabelText}
											empty={true}
											size="small"
											sx={{m: 1}}
									/>
									{currentAction.length > 0 && 
											<Button 
															variant="contained"
															color="secondary"
															sx={{m: 1, height: '70%'}}
															onClick={handleApplyAction}
													>
													{defaultTexts[volumeActions.filter(
															action => action.value === currentAction)[0].button_text]}
											</Button>
									}
							</Grid>
							<Grid item>
									{selectedRow !== null && 
											<IconButton onClick={handleConfirmDeleteDialogOpen}>
													<DeleteIcon 
															color="error"
													/>
											</IconButton>}
							</Grid>
					</Grid>
				</Paper>
			}

			<CustomDialog
					open={updateVolumeDialogOpen}
					onClose={handleUpdateVolumeDialogClose}
					dialogTitle={{
							title: defaultTexts.updateVolumeActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							volumeUpdateForm,
							{},
							volumeUpdateData,
							handleVolumeUpdateDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={uploadImageDialogOpen}
					onClose={handleUploadImageDialogClose}
					dialogTitle={{
							title: defaultTexts.uploadVolumeToImageActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onUploadImage, 
							sx: {color: 'primary.main'}}]}
			>
					{getImageDataForm(
							uploadImageDataForm,
							uploadImageOptions,
							uploadImageData,
							handleUploadImageDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={volumeStatusUpdateDialogOpen}
					onClose={handleVolumeStatusUpdateDialogClose}
					dialogTitle={{
							title: defaultTexts.updateVolumeStatusActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeStatusUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomSelectField
							items={volumeStatusMenu}
							required={true}
							error={selectedVolumeStatusError}
							errorText={defaultTexts.requiredFormFieldError}
							currentValue={selectedVolumeStatus}
							setCurrentValue={handleVolumeStatusChange}
							label={defaultTexts.statusFormFieldLabel}
							self_item_titles={false}
							empty={true}
							item_titles={defaultTexts}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
			</CustomDialog>

			<CustomDialog
					open={volumeAttachmentDialogOpen}
					onClose={handleVolumeAttachmentDialogClose}
					dialogTitle={{
							title: defaultTexts.attachVolumeToServerActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeAttachment, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomSelectField
							items={instanceList}
							required={true}
							error={selectedVolumeInstanceError}
							errorText={defaultTexts.requiredFormFieldError}
							currentValue={selectedVolumeInstance}
							setCurrentValue={handleVolumeInstanceChange}
							label={defaultTexts.serverFormFieldLabel}
							self_item_titles={true}
							empty={true}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
					<CustomCheckboxField
							currentValue={deleteOnTerminate}
							setCurrentValue={handleDeleteOnTerminateChange}
							label={defaultTexts.deleteOnTerminateFormFieldLabel}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
			</CustomDialog>

			<CustomDialog
					open={volumeSizeExtendDialogOpen}
					onClose={handleVolumeSizeExtendDialogClose}
					dialogTitle={{
							title: defaultTexts.extendVolumeSizeActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeSizeExtend, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomTextField
							error={selectedVolumeSizeError}
							errorText={defaultTexts.invalidVolumeSizeFormFieldError}
							currentValue={selectedVolumeSize}
							setCurrentValue={handleVolumeSizeChange}
							label={`${defaultTexts.sizeFormFieldLabel} GB`}
							type="number"
							min={selectedRow.size}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
			</CustomDialog>

			<CustomDialog
					open={volumeTransferDialogOpen}
					onClose={handleVolumeTransferDialogClose}
					dialogTitle={{
							title: defaultTexts.createVolumeTransferActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeTransfer, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomTextField
							required={true}
							error={volumeTransferNameError}
							errorText={defaultTexts.requiredFormFieldError}
							currentValue={volumeTransferName}
							setCurrentValue={handleVolumeTransferNameChange}
							label={defaultTexts.transferNameFormFieldLabel}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
			</CustomDialog>

			<CustomDialog
					open={createSnapshotDialogOpen}
					onClose={handleCreateSnapshotDialogClose}
					dialogTitle={{
							title: defaultTexts.createSnapshotActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onSnapshotCreate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							volumeUpdateForm,
							snapshotDataOptions,
							snapshotData,
							handleSnapshotDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={createBackupDialogOpen}
					onClose={handleCreateBackupDialogClose}
					dialogTitle={{
							title: defaultTexts.createBackupActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onBackupCreate, 
							sx: {color: 'primary.main'}}]}
			>
					{getDataForm(
							volumeUpdateForm,
							{},
							backupData,
							handleBackupDataChange
					)}
			</CustomDialog>

			<CustomDialog
					open={volumeMetadataDialogOpen}
					onClose={handleVolumeMetadataSelectionDialogClose}
					dialogTitle={{
							title: defaultTexts.volumeMetadataUpdateActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeMetadataUpdate, 
							sx: {color: 'primary.main'}}]}
					maxWidth="lg"
			>
					<CustomTransferList 
							withCustomList={true}
							withInstructions={true}
							catalogList={metadataCatalog}
							appliedFieldList={selectedRow.metadata}
							onDataUpdate={setUpdatedMetadata}
					/>
			</CustomDialog>

			<CustomDialog
					open={volumeTypeUpdateDialogOpen}
					onClose={handleVolumeTypeUpdateDialogClose}
					dialogTitle={{
							title: defaultTexts.updateVolumeTypeActionTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: "", 
							sx: {color: 'text.primary'}}}
					actionButtons={[{
							title: defaultTexts.submitButtonText, 
							onClick: onVolumeTypeUpdate, 
							sx: {color: 'primary.main'}}]}
			>
					<CustomSelectField
							items={volumeTypesList}
							required={true}
							error={selectedVolumeTypeError}
							errorText={defaultTexts.requiredFormFieldError}
							currentValue={selectedVolumeType}
							setCurrentValue={handleVolumeTypeChange}
							label={defaultTexts.volumeTypeFormFieldLabel}
							self_item_titles={true}
							empty={true}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
					<CustomSelectField
							items={migrationPolicies}
							required={false}
							currentValue={selectedMigrationPolicy}
							setCurrentValue={setSelectedMigrationPolicy}
							label={defaultTexts.migrationPolicyFormFieldLabel}
							self_item_titles={false}
							item_titles={defaultTexts}
							empty={false}
							size="large"
							sx={{my: 1, width: '90%'}}
					/>
			</CustomDialog>

			{
				error &&
				<CustomDialog
					open={errorDialogOpen}
					onClose={handleErrorDialogClose}
					dialogTitle={{
							title: defaultTexts.failedActionErrorDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
											<br>
											<br>
											<span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
											<span style="color: orange">
													${error}
											</span>`, 
							sx: {color: 'text.primary'}}}
				/>
			}

			{
				warningDialogOpen &&
				<CustomDialog
					open={warningDialogOpen}
					onClose={handleWarningDialogClose}
					dialogTitle={{
							title: defaultTexts.warningDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${warningMessage}</span>
							<br>
							<br>`, 
							sx: {color: 'text.primary'}}}
				>
					{warning}
				</CustomDialog>
			}

			<CustomDialog
					open={volumeTransferSuccessDialogOpen}
					onClose={handleVolumeTransferSuccessDialogClose}
					dialogTitle={{
							title: defaultTexts.volumeTransferSuccessDialogTitle, 
							sx: {color: 'primary.main'}}}
					dialogBody={{
							text: `<span>${defaultTexts.volumeTransferSuccessDialogMessage}</span>
											<br>
											<br> 
											<span style="color: orange">
													${volumeTransferSuccess}
											</span>`, 
							sx: {color: 'text.primary'}}}
			/>
		</>
	)
}

export default VolumeDetailV3